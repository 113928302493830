import React from 'react'
import { withTranslation } from 'react-i18next'

import SearchBar from 'components/search/SearchBar'

class CollectionsFilter extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			filters: {
				search: '',
			}
		}

		// bind methods
		this.handleFilterChange = this.handleFilterChange.bind(this)
	}

	handleFilterChange(e, filterName, value) {
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		//const { filters } = this.state

		console.log('handleFilterChange filterName', filterName)
		console.log('handleFilterChange value', value)
		console.log('---')
	}

	render() {
		const { t } = this.props

		return (
			<header className="catalogue__header collections">
				<div className="catalogue__header__infos">
					<p className="catalogue__header__title text--blue-electric">{t('collectionsIndexTitle')}</p>

					<SearchBar
						placeholder='collectionsIndexFilterSearch'
						onSubmit={this.handleFilterChange}
						className="catalogue__header__search"
					/>
				</div>
			</header>
		)
	}
}

export default withTranslation()(CollectionsFilter)