import React from 'react'
import { withTranslation } from 'react-i18next'

import icons from 'assets/svg'
import AccessControl from 'auth/access-control'
import userRoles from 'constants/user-roles'
import projectStatuses from 'constants/project-statuses'
import PartnersList from 'components/partners/PartnersList'
import ProjectDashboardBox from 'components/projects/ProjectDashboardBox'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'
import SubmitButton from 'components/forms/inputs/SubmitButton'
import TaskDetail from 'components/tasks/TaskDetail'
import ProjectPartnerTasksDetail from 'components/projects/ProjectPartnerTasksDetail'
import CompleteProjectStep from 'components/projects/CompleteProjectStep'
/*
import RequestProposal from 'components/offers/RequestProposal'
import ProjectDocuments from 'components/projects/ProjectDocuments'
*/

const getPartnersTitle = (t, project, addPartnerCallback, displayPending, currentProjectPipelineSection) => {
	const { partners } = project

	if (!partners) {
		return null
	}

	let partnersCount = 0
	if (currentProjectPipelineSection) {
		partners.map((partner, iP) => {
			if (partner.projectPipelineSection.id === currentProjectPipelineSection.id) {
				partnersCount++
			}

			return null
		})
	}

	return (
		<div>
			{!displayPending &&
				<h3>
					{t('projectDetailPartnersTitle')} {partnersCount > 0 && <span className="count">{partnersCount}</span>}
				</h3>
			}

			{displayPending &&
				<h3>
					{t('projectDetailPartnersOwner')}
				</h3>
			}

			<div className="float-right">
				<ImageButton image={icons.add} altText='Add a Partner' className="upper" onClick={(e) => addPartnerCallback(e, project)} />
				<TextButton text='projectDetailPartnersAdd' className="upper small" onClick={(e) => addPartnerCallback(e, project)} />
			</div>
		</div>
	)
}

export const ProjectSectionDashboard = (props) => {
	const {
		t,
		project,
		projectTasks,
		currentProjectPipelineSection,
		activeTaskPartner,
		addPartnerCallback,
		taskFileUploadCallback,
		approveProjectCallback,
		removePartnerCallback,
		assignTaskCallback,
		partnerItemClickCallback,
		signatureCallback,
		editTaskCallback,
		taskDetailCloseCallback,
		completeSectionCallback,
/*		userOfferRequests,
		projetDocumentCheckboxCallback,
		uploadFilesCallback,
		taskFilesCountClickCallback,
		*/
	} = props

	const accessControl = new AccessControl()
	const displayPending = (accessControl.hasRole(userRoles.ROLE_SUPER_ADMIN) || accessControl.hasRole(userRoles.ROLE_ADMIN)) &&
				projectStatuses.PROJECT_STATUS_PENDING === project.status
	const partnersTitle = getPartnersTitle(t, project, addPartnerCallback, displayPending, currentProjectPipelineSection)

	if (activeTaskPartner && projectTasks) {
		return (
			<div className="ProjectSection--dashboard">
				<ProjectPartnerTasksDetail
					project={project}
					projectTasks={projectTasks}
					partner={activeTaskPartner}
					currentProjectPipelineSection={currentProjectPipelineSection}
					taskDetailCloseCallback={taskDetailCloseCallback}
					partnerItemClickCallback={partnerItemClickCallback}
					removePartnerCallback={removePartnerCallback}
					taskFileUploadCallback={taskFileUploadCallback}
					signatureCallback={signatureCallback}
					editTaskCallback={editTaskCallback}
				/>
			</div>
		)
	}

	return (
		<div className="ProjectSection--dashboard">
			<CompleteProjectStep
				project={project}
				projectTasks={projectTasks}
				completeSectionCallback={completeSectionCallback}
				currentProjectPipelineSection={currentProjectPipelineSection}
			/>

			<ProjectDashboardBox className="partners" title={partnersTitle}>
				<PartnersList
					project={project}
					projectTasks={projectTasks}
					widget='tiles'
					currentProjectPipelineSection={currentProjectPipelineSection}
					addPartnerCallback={addPartnerCallback}
					displayPending={displayPending}
					partnerItemClickCallback={partnerItemClickCallback}
					removePartnerCallback={removePartnerCallback}
					assignTaskCallback={assignTaskCallback}
					displayTasksCount={true}
					displayRoles={true}
				/>
			</ProjectDashboardBox>

			{projectTasks && projectTasks.map((task, i) => {
				// check task's project pipeline section
				if (
					currentProjectPipelineSection
					&& task.projectPipelineSection && task.projectPipelineSection.id
					&& task.projectPipelineSection.id !== currentProjectPipelineSection.id
				) {
					return null
				}

				return (
					<TaskDetail
						key={i}
						task={task}
						projectDetailCallback={taskFileUploadCallback}
						signatureCallback={signatureCallback}
						editTaskCallback={editTaskCallback}
					/>
				)
			})}

			{displayPending && project.partners.length > 0 &&
				<ProjectDashboardBox title='APPROVE PROJECT'>
					<h4 className="Approve__title">
						{t('projectDetailProjectPending')}
					</h4>

					<SubmitButton text='projectDetailProjectPendingApproveBtn' onClick={approveProjectCallback} />
				</ProjectDashboardBox>
			}
		</div>
	)
}

export default withTranslation()(ProjectSectionDashboard)