import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'

import { callApi, resetApiResponse } from 'actions/api'
import ApiClient from 'api/ApiClient'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ProjectHeader from 'components/projects/ProjectHeader'
import Spinner from 'components/spinner/Spinner'
import routesList from 'routing/routes-list'
import UbikonForm from 'components/forms/UbikonForm'

class CreateProject extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			projectFormSchema: undefined,
		}

		// bind methods
		this.handleCreateProjectSubmit = this.handleCreateProjectSubmit.bind(this)
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)

		// set private properties
		this.apiClient = new ApiClient()
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		// get form model
		const formEndpoint = this.endpointBuilder.getEndpoint('get_project_form')

		this.props.callApi(formEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props
		const formEndpoint = this.endpointBuilder.getEndpoint('get_project_form')
		const projectEndpoint = this.endpointBuilder.getEndpoint('projects')

		// set form model
		if (apiResponse && apiResponse.model && apiResponseEndpoint === formEndpoint) {
			this.props.resetApiResponse()

			this.setState((state, props) => ({
				projectFormSchema: apiResponse.model
			}))
		}

		// redirect if project has been created
		if (apiResponse && apiResponse.id && apiResponseEndpoint === projectEndpoint) {
			// refresh user projects list
			const projectsEndpoint = this.endpointBuilder.getEndpoint('projects')
			this.props.callApi(projectsEndpoint)

			const redirectPath = reverse(routesList.projects.detail.index, { projectId: apiResponse.id })
			this.props.history.push(redirectPath)
		}
	}

	handleCreateProjectSubmit(formData) {
		const projectEndpoint = this.endpointBuilder.getEndpoint('projects')
		const newProjectData = this.apiClient.normalizeRequest(formData, [{
			name: 'projectType',
			path: '/api/project_types/{id}'
		}, {
			name: 'bucket',
			path: '/api/buckets/{id}'
		}, {
			name: 'land',
			path: '/api/lands/{id}'
		}])

		this.props.callApi(projectEndpoint, 'post', {
			body: JSON.stringify(newProjectData)
		})
	}

	handleBackBtnClick(e) {
		e.preventDefault()

		this.props.history.push(reverse(routesList.projects.index))
	}

	render() {
		const { projectFormSchema } = this.state

		return (
			<div className="ProjectDetail">
				<span className="project__decorator"></span>

				<ProjectHeader
					title='Create project'
					backBtnCallback={this.handleBackBtnClick}
				/>

				<section className="project-views">
					<span className="project-views__decorator"></span>

					{!projectFormSchema &&
						<Spinner />
					}

					{projectFormSchema &&
						<div>
							<UbikonForm
								form={projectFormSchema}
								onSubmit={this.handleCreateProjectSubmit}
							/>
						</div>
					}
				</section>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api

	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)