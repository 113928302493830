export const ActionTypes = {
	CALL_API: 'CALL_API',
	API_ERROR: 'API_ERROR',
	API_SUCCESS: 'API_SUCCESS',
	UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
	RESET_UPLOAD_RESPONSE: 'RESET_UPLOAD_RESPONSE',
	API_GET: 'API_GET',
	API_POST: 'API_POST',
	API_DELETE: 'API_DELETE',
	API_RECEIVE_FORM_SCHEMA: 'API_RECEIVE_FORM_SCHEMA',
	API_RESET_RESPONSE: 'API_RESET_RESPONSE',
	API_RESET_ERROR: 'API_RESET_ERROR',
	MESSAGING_HIDE: 'MESSAGING_HIDE',
	MESSAGING_SET_ACTIVE_CONVERSATION: 'MESSAGING_SET_ACTIVE_CONVERSATION',
	MESSAGING_LISTEN_TOPIC: 'MESSAGING_LISTEN_TOPIC',
	NOTIFIER_GET_NOTIFICATIONS: 'NOTIFIER_GET_NOTIFICATIONS',
	NOTIFIER_ERROR: 'NOTIFIER_ERROR',
	NOTIFIER_RESET: 'NOTIFIER_RESET',
	NOTIFIER_DISPLAY_OVERLAY: 'NOTIFIER_DISPLAY_OVERLAY',
	NOTIFIER_SET_NOTIFICATIONS_READ: 'NOTIFIER_SET_NOTIFICATIONS_READ',
	TEMPORARY_DATA_SET: 'TEMPORARY_DATA_SET',
	TEMPORARY_DATA_RESET: 'TEMPORARY_DATA_RESET',
	SET_CENTER_CLASS: 'SET_CENTER_CLASS',
	SET_DISPLAY_HEADER: 'SET_DISPLAY_HEADER',
	SET_DISPLAY_DECORATORS: 'SET_DISPLAY_DECORATORS',
	MENU_COLLAPSE: 'MENU_COLLAPSE',
	OPEN_COLLECTIONS_POPIN: 'OPEN_COLLECTIONS_POPIN',
	CLOSE_COLLECTIONS_POPIN: 'CLOSE_COLLECTIONS_POPIN',
	DID_ADD_TO_COLLECTION: 'DID_ADD_TO_COLLECTION',
	SET_ELEMENT_SIZE: 'SET_ELEMENT_SIZE',
	SET_FILTERS: 'SET_FILTERS',
	SET_CONFIGURATION: 'SET_CONFIGURATION',
}

export default ActionTypes;