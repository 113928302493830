import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { setActiveConversation, listenToTopic } from 'actions/messaging'
import AccessControl from 'auth/access-control'
import { ReactComponent as IconAddRound } from 'assets/svg/icon-add-round.svg'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ConversationsList from 'components/chat/ConversationsList'
import ImageButton from 'components/buttons/ImageButton'
import NavSectionTitle from 'components/navigation/NavSectionTitle'
import Popin from 'components/layout/Popin'
import SearchBar from 'components/search/SearchBar'
import Spinner from 'components/spinner/Spinner'
import UbikonForm from 'components/forms/UbikonForm'

class Chat extends React.Component {
	constructor(props) {
		super(props)

		this.defaultDisplayLimit = 2

		// set state
		this.state = {
			hubUrls: [],
			didGetFirstConversations: false,
			conversations: undefined,
			contacts: undefined,
			activeConversation: undefined,
			openCreateConversationPopin: false,
			conversationForm: undefined,
			isFiltering: false,
			displayLimits: {
				offers: this.defaultDisplayLimit,
				groups: 5,
				chat: 5,
			},
			originalDisplayLimits: {
				offers: this.defaultDisplayLimit,
				groups: 5,
				chat: 5,
			},
		}

		// bind methods
		this.chatListener = this.chatListener.bind(this)
		this.handleConversationClick = this.handleConversationClick.bind(this)
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.handleExpandClick = this.handleExpandClick.bind(this)
		this.handleClosePopin = this.handleClosePopin.bind(this)
		this.handleCreateConversationClick = this.handleCreateConversationClick.bind(this)
		this.handleConversationSubmit = this.handleConversationSubmit.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
		this.hubUrl = new URL(process.env.REACT_APP_MERCURE_HUB_URL)
	}

	componentDidMount() {
		// get user's chat (conversations, contacts, etc.)
		const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat')
		this.props.callApi(userChatEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		//const { hubUrls } = this.state
		//const { hubUrls: prevHubUrls } = prevState
		const { apiResponse, apiResponseEndpoint } = this.props
		const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat').split('?')[0]
		const conversationFormEndpoint = this.endpointBuilder.getEndpoint('conversation_form')
		const conversationsEndpoint = this.endpointBuilder.getEndpoint('conversations')

		let formatedApiResponseEndpoint = apiResponseEndpoint && apiResponseEndpoint.split('?')[0]

		if (apiResponse) {
			this.props.resetApiResponse()

			// did get list of conversations
			if (formatedApiResponseEndpoint === userChatEndpoint) {
				const { conversations, contacts } = apiResponse.chat

				// init hub listener
				const userChatHub = `ubikon-chat-${this.accessControl.getUserId()}`
				this.props.listenToTopic(
					process.env.REACT_APP_MERCURE_HUB_URL,
					userChatHub,
					this.chatListener
				)

				this.setState((state, props) => ({
					conversations,
					contacts,
					isFiltering: false,
					didGetFirstConversations: true,
				}))
			}

			// set conversation form
			if (apiResponseEndpoint === conversationFormEndpoint) {
				this.setState((state, props) => ({
					conversationForm: apiResponse.model,
				}))
			}

			// did create conversation
			if (apiResponseEndpoint === conversationsEndpoint) {
				this.props.setActiveConversation(apiResponse.conversation)

				// reset user chat
				const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat')
				this.props.callApi(userChatEndpoint, 'get')

				this.setState((state, props) => ({
					conversationForm: undefined,
					openCreateConversationPopin: false,
				}))
			}
		}
	}

	chatListener(e) {
		const message = JSON.parse(e.data)

		if (message.conversation && message.conversation.id) {
			if (message.isNewConversation && message.chat) {
				// refresh conversations/contacts list if new conversation was created
				const { conversations, contacts } = message.chat

				const finalConversations = conversations.map((c) => {
					if (c.id === message.conversation.id) {
						c.isAlert = true
					} else {
						c.isAlert = false
					}

					return c
				})

				const finalContacts = contacts.map((ct) => {
					if (ct.id === message.conversation.id) {
						ct.isAlert = true
					} else {
						ct.isAlert = false
					}

					return ct
				})

				this.setState((state, props) => ({
					conversations: finalConversations,
					contacts: finalContacts,
				}))
			} else {
				const { contacts, conversations } = this.state
				const { conversation, author } = message
				const currentUserId = this.accessControl.getUserId()

				let isConversationInList = false
				let newConversations = [ ...conversations ]

				if (conversations) {
					// check if conversation is already in list
					conversations.map((c) => {
						if (c.id === conversation.id) {
							isConversationInList = true
						}

						return null
					})
				}

				if (!isConversationInList) {
					newConversations = [
						...conversations,
						conversation
					]
				}

				const finalConversations = newConversations.map((c) => {
					if (c.id === conversation.id && author !== currentUserId) {
						c.isAlert = true
					} else {
						c.isAlert = false
					}

					return c
				})

				const finalContacts = contacts.map((ct) => {
					if (ct.id === conversation.id && author !== currentUserId) {
						ct.isAlert = true
					} else {
						ct.isAlert = false
					}

					return ct
				})

				this.setState((state, props) => ({
					conversations: finalConversations,
					contacts: finalContacts,
				}))
			}
		}
	}

	getConversationsByType(conversations, conversationType) {
		if (conversations) {
			if ('offers' === conversationType) {
				return conversations.filter((conversation, iC) => {
					return false === conversation.isGroup && true === conversation.isOffer
				})
			} else if ('groups' === conversationType) {
				return conversations.filter((conversation, iC) => {
					return true === conversation.isGroup && false === conversation.isOffer
				})
			}
		}

		return undefined
	}

	handleConversationClick(conversation) {
		// add conversation IRI to list of URLs
		const { hubUrls } = this.state
		const newHubUrls = [
			...hubUrls,
			conversation.iri
		]

		this.props.setActiveConversation(conversation)

		this.setState((state, props) => ({
			hubUrls: [...new Set(newHubUrls)],
			activeConversation: conversation
		}))
	}

	handleSearchChange(uselessVar, inputName, searchQuery) {
		// TODO: handle search on Chat

		const userChatEndpoint = `${this.endpointBuilder.getEndpoint('user_chat')}?q=${searchQuery}`
		this.props.callApi(userChatEndpoint, 'get')

		this.setState((state, props) => ({
			isFiltering: true,
			conversations: undefined,
			contacts: undefined,
		}))
	}

	handleExpandClick(e, listType) {
		e.preventDefault()
		
		const { displayLimits, originalDisplayLimits } = this.state
		const currentDisplayLimit = displayLimits[listType]

		if (currentDisplayLimit) {
			const newDisplayLimits = {
				...displayLimits,
				[listType]: undefined,
			}

			this.setState((state, props) => ({
				displayLimits: newDisplayLimits
			}))
		} else {
			const newDisplayLimits = {
				...displayLimits,
				[listType]: originalDisplayLimits[listType],
			}

			this.setState((state, props) => ({
				displayLimits: newDisplayLimits
			}))
		}
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			openCreateConversationPopin: false,
		}))
	}

	handleCreateConversationClick() {
		const conversationFormEndpoint = this.endpointBuilder.getEndpoint('conversation_form')
		this.props.callApi(conversationFormEndpoint)

		this.setState((state, props) => ({
			openCreateConversationPopin: true,
		}))
	}

	handleConversationSubmit(formData) {
		const users = Object.keys(formData.users).map((userId) => {
			return userId
		})

		const newFormData = {
			...formData,
			users: users
		}

		const conversationsEndpoint = this.endpointBuilder.getEndpoint('conversations')

		this.props.callApi(conversationsEndpoint, 'post', {
			body: JSON.stringify(newFormData),
		})
	}

	render() {
		const {
			t,
			conversations,
			contacts,
			displayLimits,
			openCreateConversationPopin,
			conversationForm,
			isFiltering,
			didGetFirstConversations,
		} = this.state

		const { activeConversation: chatActiveConversation } = this.props

		// filter conversations
		const offerConversations = this.getConversationsByType(conversations, 'offers')
		const groupConversations = this.getConversationsByType(conversations, 'groups')
		const chatConversations = contacts

		return (
			<div className="Chat sidebar-block__inner">
				<NavSectionTitle label='chatMenuTitleConversations' className='sidebar-block__title text--blue-electric' />

				<section className="search">
					<div className="search__items">
						<div className="search__field">
							<SearchBar
								placeholder='chatMenuSearchPlaceholder'
								inputClassName="search__field__input"
								onSubmit={this.handleSearchChange}
							/>
						</div>

						<ImageButton
							svgImage={IconAddRound}
							altText='Create conversation'
							className="search__create"
							onClick={this.handleCreateConversationClick}
						/>
					</div>
				</section>

				<section className="suggests"></section>

				{!isFiltering && didGetFirstConversations && !offerConversations && !groupConversations && !chatConversations &&
					<p className="Chat__empty__notice">{t('chatConversationsEmpty')}</p>
				}

				{isFiltering &&
					<Spinner />
				}

				{offerConversations && offerConversations.length > 0 &&
					<ConversationsList
						title='chatMenuSectionOffers'
						listType='offers'
						className="chat"
						conversations={offerConversations}
						displayLimit={displayLimits.offers}
						chatActiveConversation={chatActiveConversation}
						onClickCallback={this.handleConversationClick}
						onExpandClickCallback={this.handleExpandClick}
					/>
				}

				{groupConversations && groupConversations.length > 0 &&
					<ConversationsList
						title='chatMenuSectionGroups'
						listType='groups'
						className="chat"
						conversations={groupConversations}
						displayLimit={displayLimits.groups}
						chatActiveConversation={chatActiveConversation}
						onClickCallback={this.handleConversationClick}
						onExpandClickCallback={this.handleExpandClick}
					/>
				}

				{chatConversations && chatConversations.length > 0 &&
					<ConversationsList
						title='chatMenuSectionChat'
						listType='chat'
						className="chat"
						conversations={chatConversations}
						displayLimit={displayLimits.chat}
						chatActiveConversation={chatActiveConversation}
						onClickCallback={this.handleConversationClick}
						onExpandClickCallback={this.handleExpandClick}
					/>
				}

				{openCreateConversationPopin &&
					<Popin
						isOpened={openCreateConversationPopin}
						transTitle='chatCreateConversationPopinTitle'
						transTitleKey='chatCreateConversationPopinTitle'
						onClose={this.handleClosePopin}
					>
						{conversationForm &&
							<UbikonForm form={conversationForm} onSubmit={this.handleConversationSubmit} />
						}

						{!conversationForm && <Spinner />}
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { activeConversation } = state.messaging

	return {
		apiResponse,
		apiResponseEndpoint,
		activeConversation,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
		listenToTopic: (hubUrl, topic, callback) => dispatch(listenToTopic(hubUrl, topic, callback)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Chat))