import Cookies from 'js-cookie'
import cookieNames from 'constants/cookies'

class AccessControl {
	authenticate(token, refreshToken, user, callback) {
		if (typeof token !== 'undefined') {
			// remove cookies
			this.logout(undefined, () => {
				// set cookies
				Cookies.set(cookieNames.SESSION, token)
				Cookies.set(cookieNames.REFRESH_TOKEN, refreshToken)
				Cookies.set(cookieNames.USER_ID, user)
			})
		}

		// callback if defined
		if (typeof callback !== 'undefined') {
			const isAuthenticated = this.isAuthenticated()

			callback(isAuthenticated)
		}
	}

	isAuthenticated() {
		// check if cookie exists
 		const token = this.getToken()

 		if (typeof token !== 'undefined') {
			// check expiration date
			const isExpired = this.isTokenExpired(token)

			if (!isExpired) {
				return true
			}
 		}

		return false
	}

	setRefreshToken(refreshToken) {
		Cookies.set(cookieNames.REFRESH_TOKEN, refreshToken)
	}

	getRefreshToken() {
		return Cookies.get(cookieNames.REFRESH_TOKEN)
	}

	unsetRefreshToken() {
		Cookies.remove(cookieNames.REFRESH_TOKEN, { path: '/' })
	}

	getUserId() {
		const userString = Cookies.get(cookieNames.USER_ID)
		const userJson = (userString) ? JSON.parse(userString): undefined

		return (userJson && userJson.id) ? userJson.id: undefined
	}

	unsetUserId() {
		Cookies.remove(cookieNames.USER_ID, { path: '/' })
	}

	hasRole(roleName) {
	    const isAuthenticated = this.isAuthenticated()
	    const currentRoles = (isAuthenticated) ? this.getUserRoles() : undefined

	    return (currentRoles && currentRoles.indexOf(roleName) !== -1) ? true : false
	}

	isProjectOwner(project) {
		if (this.isAuthenticated()) {
			const { owner } = project
			const token = this.getToken()
			const userEmail = this.decodeToken(token, 'email')

			return owner.email === userEmail
		}

		return false
	}

	isOrganizationManager(organization) {
		if (organization) {
			const userId = parseInt(this.getUserId())
			const managerId = parseInt(organization.manager.id)

			if (userId > 0 && managerId > 0 && userId === managerId) {
				return true
			}
		}

		return false		
	}

	isOfferPartner(offer) {
		if (offer && offer.partners) {
			const userId = parseInt(this.getUserId())
			let isPartner = false

			offer.partners.map((partner, i) => {
				if (
					partner.user
					&& partner.user.id
					&& parseInt(partner.user.id) === userId
				) {
					isPartner = true
				}

				return null
			})

			return isPartner
		}

		return false
	}

	isOfferCreator(offer) {
		const userId = parseInt(this.getUserId())
		const createdById = (offer.createdBy && offer.createdBy.id) ? parseInt(offer.createdBy.id) : -1

		return userId === createdById
	}

	didPostQuote(offer) {
		if (offer && offer.offerQuotes) {
			const userId = parseInt(this.getUserId())
			let hasOfferQuote = false

			offer.offerQuotes.map((offerQuote, i) => {
				if (
					offerQuote.partner
					&& offerQuote.partner.user
					&& offerQuote.partner.user.id
					&& parseInt(offerQuote.partner.user.id) === userId
				) {
					hasOfferQuote = true
				}

				return null
			})

			return hasOfferQuote
		}

		return false
	}

	didRefuseQuote(offer) {
		if (offer && offer.offerRefusals) {
			const userId = parseInt(this.getUserId())
			let hasOfferRefusal = false

			offer.offerRefusals.map((offerRefusal, i) => {
				if (
					offerRefusal.partner
					&& offerRefusal.partner.user
					&& offerRefusal.partner.user.id
					&& parseInt(offerRefusal.partner.user.id) === userId
				) {
					hasOfferRefusal = true
				}

				return null
			})

			return hasOfferRefusal
		}

		return false
	}

	getUserPartnerId(offer) {
		if (offer && offer.partners) {
			const userId = parseInt(this.getUserId())
			let partnerId = undefined

			offer.partners.map((partner, i) => {
				if (
					partner.user
					&& partner.user.id
					&& parseInt(partner.user.id) === userId
				) {
					partnerId = parseInt(partner.id)
				}

				return null
			})

			return partnerId
		}

		return undefined
	}

	isTokenExpired(token) {
		const expirationTime = this.decodeToken(token, 'exp')
		const now = Math.floor(Date.now() / 1000)

		if (expirationTime > now) {
			return false
		}

		// unset token
		this.unsetToken()

		return true
	}

	getToken() {
		return Cookies.get(cookieNames.SESSION)
	}

	unsetToken() {
		Cookies.remove(cookieNames.SESSION, { path: '/' })
	}

	getUserRoles() {
		const token = this.getToken()
		return this.decodeToken(token, 'roles')
	}

	decodeToken(token, property) {
		if (!token) {
			return undefined
		}

		// decode token
		const base64Url = token.split('.')[1]
		const base64 = base64Url.replace('-', '+').replace('_', '/')
		const decodedToken = JSON.parse(window.atob(base64))

		if (typeof property !== 'undefined' && typeof decodedToken[property] !== 'undefined') {
			return decodedToken[property]
		}

		return decodedToken
	}

	logout(e, callback) {
		// prevent default if event is defined
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		this.unsetToken()
		this.unsetRefreshToken()
		this.unsetUserId()

		// callback if defined
		if (typeof callback !== 'undefined') {
			callback()
		}
	}

	getUsername() {
		if (this.isAuthenticated()) {
			const token = this.getToken()
			return this.decodeToken(token, 'email')
		}

		return undefined
	}

	getTokenProperty(property) {
		const token = this.getToken()
		if (typeof token !== 'undefined') {
			return this.decodeToken(token, property)
		}

		return undefined
	}

	getUserProperty(property) {
		const userString = Cookies.get(cookieNames.USER_ID)
		const userJson = (userString) ? JSON.parse(userString): undefined

		return (userJson && userJson[property]) ? userJson[property]: undefined
	}

	mustRefreshToken(callback) {
		const token = this.getToken()
		const refreshToken = this.getRefreshToken()
		const expirationLimit = 60
		let mustRefresh = false

		if (typeof token !== 'undefined') {
			const expirationTime = this.decodeToken(token, 'exp')
			const now = Math.floor(Date.now() / 1000)

			if ((expirationTime - now) <= expirationLimit) {
				mustRefresh = true
			}
		}

		callback(mustRefresh, refreshToken)
	}
}

export default AccessControl