import React from 'react'

import FilterCheckbox from 'components/filters/FilterCheckbox'

class BenefitsGroup extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			selectedValues: [],
		}
	}

	handleOptionClick(field, selectedValue, onChange) {
		const { selectedValues } = this.state
		let newSelectedValues = selectedValues

		// if value is in array, remove it
		if (newSelectedValues.indexOf(selectedValue.id) > -1) {
			newSelectedValues = newSelectedValues.filter((valueId) => {
				return valueId !== selectedValue.id
			})
		} else {
			// else, add it
			newSelectedValues.push(selectedValue.id)
		}

		if (onChange) {
			onChange(newSelectedValues, field)
		}

		this.setState((state, props) => ({
			selectedValues: newSelectedValues,
		}))
	}

	render () {
		const { selectedValues } = this.state
		const { field, onChange } = this.props
		const { benefits } = field

		if (!benefits) {
			return null
		}

		return (
			<div className="filters-modal__needs__list">
				<div className="filters-modal__needs__checkboxes">
					{Object.keys(benefits).map((i) => {
						const benefit = benefits[i]

						// check if option is selected
						let isChecked = false

						if (selectedValues) {
							selectedValues.map((benefitId) => {
								if (benefitId === benefit.id) {
									isChecked = true
								}

								return null
							})
						}

						return (
							<FilterCheckbox
								key={i}
								isChecked={isChecked}
								optionLabel={benefit.name}
								optionValue={benefit.slug}
								clickCallback={() => this.handleOptionClick(field, benefit, onChange)}
							/>
						)
					})}
				</div>
			</div>
		)
	}
}

export default BenefitsGroup