import React from 'react'
import { connect } from 'react-redux'

import { listenToTopic } from 'actions/messaging'
import { displayDecorators } from 'actions/layout'
import {
	getNotifications,
	resetNotifications,
	displayNotificationsOverlay,
	setReadNotifications
} from 'actions/notifier'
import AccessControl from 'auth/access-control'
import NotifierHeader from 'components/notifier/NotifierHeader'

class Notifier extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			notifications: undefined,
			unreadCount: 0,
			isHeaderDropdownOpen: false,
			isFetchingNotifications: true,
		}

		// bind methods
		this.openHeaderDropdown = this.openHeaderDropdown.bind(this)
		this.notificationsListener = this.notificationsListener.bind(this)
		this.handleNotificationClick = this.handleNotificationClick.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
	}

	componentDidMount() {
		// init hub listener
		const userChatHub = `ubikon-notifications-${this.accessControl.getUserId()}`
		this.props.listenToTopic(
			process.env.REACT_APP_MERCURE_HUB_URL,
			userChatHub,
			this.notificationsListener
		)

		// get notifications
		this.props.getNotifications()
	}

	componentDidUpdate(prevProps, prevState) {
		const { isHeaderDropdownOpen } = this.state
		const { apiNotifications, unreadNotificationsCount, isNotificationsOverlayIsOpened } = this.props

		let unreadCount = unreadNotificationsCount

		if (apiNotifications) {
			// remove notifications from apiNotifications
			this.props.resetNotifications()

			// set notifications as read
			if (apiNotifications && apiNotifications.length > 0 && unreadNotificationsCount > 0) {
				const notificationIds = apiNotifications.map((notification, i) => {
					return notification.id
				})

				this.props.setReadNotifications(notificationIds)
			}

			if (apiNotifications && isHeaderDropdownOpen) {
				unreadCount = 0
			}

			this.setState((state, props) => ({
				notifications: apiNotifications,
				unreadCount: unreadCount,
				isFetchingNotifications: false,
			}))
		}

		if (!isNotificationsOverlayIsOpened && isHeaderDropdownOpen) {
			this.setState((state, props) => ({
				isHeaderDropdownOpen: false,
			}))
		}
	}

	openHeaderDropdown(e) {
		e.preventDefault()

		const { isHeaderDropdownOpen, isFetchingNotifications } = this.state
		const { unreadNotificationsCount } = this.props

		const newIsHeaderDropdownOpen = !isHeaderDropdownOpen
		const newUnreadCount = (true === newIsHeaderDropdownOpen) ? 0 : unreadNotificationsCount
		let newIsFetchingNotifications = isFetchingNotifications

		// display overlay
		this.props.displayNotificationsOverlay(newIsHeaderDropdownOpen)

		// display/hide decorators if opened
		this.props.displayDecorators(!newIsHeaderDropdownOpen)

		if (newIsHeaderDropdownOpen && !isFetchingNotifications) {
			// get notifications
			this.props.getNotifications()

			newIsFetchingNotifications = true
		}

		this.setState((state, props) => ({
			isHeaderDropdownOpen: newIsHeaderDropdownOpen,
			unreadCount: newUnreadCount,
			isFetchingNotifications: newIsFetchingNotifications,
		}))
	}

	notificationsListener(e) {
		const { unreadCount } = this.state
		//const message = JSON.parse(e.data)

		this.setState((state, props) => ({
			unreadCount: unreadCount + 1,
		}))
	}

	handleNotificationClick(notification) {
		const { notificationClickCallback } = this.props

		notificationClickCallback(notification)

		this.props.displayDecorators(true)

		this.setState((state, props) => ({
			isHeaderDropdownOpen: false,
		}))
	}

	render() {
		const {
			notifications,
			unreadCount,
			isHeaderDropdownOpen,
			isFetchingNotifications,
		} = this.state
		const { widget } = this.props

		if ('header' === widget) {
			return <NotifierHeader
				notifications={notifications}
				unreadCount={unreadCount}
				isDropdownOpen={isHeaderDropdownOpen}
				notificationHeaderClickCallback={this.openHeaderDropdown}
				isFetchingNotifications={isFetchingNotifications}
				notificationClickCallback={this.handleNotificationClick}
			/>
		}

		return null
	}
}

const mapStateToProps = state => {
	const { notifications, unreadNotificationsCount, isNotificationsOverlayIsOpened } = state.notifier

	return {
		apiNotifications: notifications,
		unreadNotificationsCount,
		isNotificationsOverlayIsOpened,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getNotifications: () => dispatch(getNotifications()),
		resetNotifications: () => dispatch(resetNotifications()),
		setReadNotifications: (notificationIds) => dispatch(setReadNotifications(notificationIds)),
		listenToTopic: (hubUrl, topic, callback) => dispatch(listenToTopic(hubUrl, topic, callback)),
		displayNotificationsOverlay: (isOpened) => dispatch(displayNotificationsOverlay(isOpened)),
		displayDecorators: (displayBool) => dispatch(displayDecorators(displayBool)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifier)