import ActionTypes from 'constants/action-types'

const initialState = {
	notifier: [],
}

const notifierReducer = (state = initialState, action) => {
	const {
		apiResponse,
		notificationsError,
		isNotificationsOverlayIsOpened
	} = action

	switch(action.type) {
		case ActionTypes.NOTIFIER_GET_NOTIFICATIONS:

			return {
				...state,
				notifications: apiResponse.notifications.notifications,
				unreadNotificationsCount: apiResponse.notifications.unread
			}

		case ActionTypes.NOTIFIER_ERROR:

			return {
				...state,
				notificationsError
			}

		case ActionTypes.NOTIFIER_RESET:

			return {
				...state,
				notifications: undefined,
				unreadNotificationsCount: undefined,
				notificationsError: undefined,
			}

		case ActionTypes.NOTIFIER_DISPLAY_OVERLAY:

			return {
				...state,
				isNotificationsOverlayIsOpened: isNotificationsOverlayIsOpened
			}

		case ActionTypes.NOTIFIER_SET_NOTIFICATIONS_READ:

			return {
				...state,
			}

		default:
			return {...state}
	}
}

export default notifierReducer