import React from 'react'

import icons from 'assets/svg'

class PropertyFormFieldsGroup extends React.Component {
	constructor(props) {
		super(props)

		const isGroupActive = (typeof props.isActive !== 'undefined') ? props.isActive : false

		// set state
		this.state = {
			isActive: isGroupActive,
		}

		// bind methods
		this.handleItemClick = this.handleItemClick.bind(this)
	}

	handleItemClick() {
		const { isActive } = this.state

		this.setState((state, props) => ({
			isActive: !isActive,
		}))
	}

	render() {
		const {
			isActive,
		} = this.state

		const {
			appartmentNbr,
			propertyForm,
			fieldGroup,
			parentFieldName,
		} = this.props

		const activeClass = (true === isActive) ? 'is-active' : ''

		return (
			<div className={`PropertyFormFieldsGroup ${activeClass}`}>
				<label className="Group__label" onClick={this.handleItemClick}>
					Appartment {appartmentNbr}
					<img src={icons.arrowDown} className="Group__arrow" alt="" />
				</label>

				<div className="Group__content">
					{fieldGroup.map((field, iFGF) => {
						const fieldKey = `${parentFieldName}-${iFGF}-${appartmentNbr}`

						const parentField = {
							name: parentFieldName,
						}

						const fieldField = {
							...field.props.field,
							isRepeatedField: true,
							parentField: parentField,
							repeatNumber: appartmentNbr,
						}

						return propertyForm.renderFormField(fieldField, fieldKey)
					})}
				</div>
			</div>
		)
	}
}

export default PropertyFormFieldsGroup