import React from 'react'
import { reverse } from 'named-urls'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { displayMessaging } from 'actions/messaging'
import { setTemporaryData } from 'actions/temporary-data'
import { collapseLeftMenu } from 'actions/left-menu'
import { setFilters } from 'actions/filters'
import { ReactComponent as IconSearch } from 'assets/svg/icon-search.svg'
import { ReactComponent as IconFilters } from 'assets/svg/icon-filters.svg'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ImageButton from 'components/buttons/ImageButton'
import LandsFilters from 'components/lands/LandsFilters'
import LandsListItem from 'components/lands/LandsListItem'
import MapList from 'components/map/MapList'
import MapWrapper from 'components/map/MapWrapper'
import Pagination from 'components/pagination'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import SubmitButton from 'components/forms/inputs/SubmitButton'

class LandsIndex extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			lands: undefined,
			mapHeight: undefined,
			totalLands: undefined,
			displayMap: true,
			landsPage: 1,
			isFiltering: false,
			isChangingPage: false,
			filterValues: undefined,
		}

		// bind methods
		this.handleCreateLandClick = this.handleCreateLandClick.bind(this)
		this.handleLandItemClick = this.handleLandItemClick.bind(this)
		this.handleFeatureClick = this.handleFeatureClick.bind(this)
		this.handleHideMapClick = this.handleHideMapClick.bind(this)
		this.handlePageClick = this.handlePageClick.bind(this)
		this.handleLandsFilters = this.handleLandsFilters.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
		this.landsPerPage = 4
		this.temporaryDataKey = 'lands-list'
	}

	componentDidMount() {
		const { temporaryData } = this.props

		// collapse menu
		this.props.collapseLeftMenu(true)

		// hide messaging
		this.props.displayMessaging(false)

		// get lands
		const getLandsEndpoint = this.getLandsEndpoint()
		this.props.callApi(getLandsEndpoint, 'get', {
			headers: {
				'Accept': 'application/ld+json',
			}
		})

		// set projects list if previously loaded
		if (typeof temporaryData[this.temporaryDataKey] !== 'undefined') {
			this.setState((state, props) => ({
				lands: temporaryData[this.temporaryDataKey]
			}))
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { lands, mapHeight } = this.state
		const { apiResponse } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const apiContext = (typeof apiResponse['@context'] !== 'undefined') ? apiResponse['@context'] : undefined

			if (apiContext === '/api/contexts/Land') {
				const response = (typeof apiResponse['hydra:member'] !== 'undefined') ? apiResponse['hydra:member'] : undefined

				const responseTotalLands = response[0]
				const responseLands = response[1]

				this.setState((state, props) => ({
					lands: responseLands,
					totalLands: responseTotalLands,
					isFiltering: false,
					isChangingPage: false,
				}))
			}
		}

		if (!mapHeight && lands && this.mapContainer) {
			// set map height
			this.setState((state, props) => ({
				mapHeight: this.mapContainer.clientHeight
			}))
		}
	}

	componentWillUnmount() {
		// show messaging
		this.props.displayMessaging(true)
	}

	getLandsEndpoint(page, filters) {
		const { storeFilters } = this.props
		let getLandsEndpoint = this.endpointBuilder.getEndpoint('lands_get')
		let urlOperator = '?'

		if (page) {
			getLandsEndpoint = `${getLandsEndpoint}${urlOperator}page=${page}`
			urlOperator = '&'
		}

		let serializedFilters = undefined

		if (filters) {
			serializedFilters = this.getSerializedFilters(filters)
		} else if (storeFilters && storeFilters.lands) {
			serializedFilters = this.getSerializedFilters(storeFilters.lands)
		}

		if (serializedFilters) {
			getLandsEndpoint = `${getLandsEndpoint}${urlOperator}${serializedFilters}`
		}

		return getLandsEndpoint
	}

	getSerializedFilters(filters) {
		if (!filters) {
			return undefined
		}

		return Object.keys(filters).map((key) => {
			if (typeof filters[key] !== 'undefined') {
				return `filters[${key}]=${encodeURIComponent(filters[key])}`
			}

			return `${key}=''`
		}).join('&')
	}

	handleCreateLandClick() {
		this.props.history.push(reverse(routesList.lands.new))
	}

	handleLandItemClick(e, land) {
		const { filterValues } = this.state

		e.preventDefault()

		// set filters for next page
		this.props.setFilters('lands', filterValues)

		// set temporary data for next page
		this.props.setTemporaryData(`tmp-land-${land.id}`, land)

		this.props.history.push(reverse(routesList.lands.detail.index, { landId: land.id }))
	}

	handleFeatureClick(mapEvent) {
		const land = mapEvent.feature.properties

		// redirect to land detail
		this.props.history.push(reverse(routesList.lands.detail.index, { landId: land.featureId }))
	}

	handleHideMapClick(e) {
		e.preventDefault()

		const { displayMap } = this.state

		this.setState((state, props) => ({
			displayMap: !displayMap
		}))
	}

	handlePageClick(page) {
		// get items by page
		const getLandsEndpoint = this.getLandsEndpoint(page)
		this.props.callApi(getLandsEndpoint, 'get', {
			headers: {
				'Accept': 'application/ld+json',
			}
		})

		this.setState((state, props) => ({
			landsPage: page,
			isChangingPage: true,
		}))
	}

	handleLandsFilters(filters) {
		const { isFiltering, landsPage } = this.state

		if (!isFiltering) {
			const getLandsEndpoint = this.getLandsEndpoint(landsPage, filters)

			this.props.callApi(getLandsEndpoint, 'get', {
				headers: {
					'Accept': 'application/ld+json',
				}
			})

			this.setState((state, props) => ({
				isFiltering: true,
				filterValues: filters,
			}))
		}
	}

	render() {
		const {
			lands,
			mapHeight,
			displayMap,
			totalLands,
			isFiltering,
			isChangingPage,
		} = this.state

		return (
			<div className="catalogue">
				<div className="all-projects__header">
					<div className="all-projects__actions is-mobile">
						<div className="all-projects__action filters">
							<ImageButton
								svgImage={IconFilters}
								className="action__filters"
								altText='Filter projects'
								//onClick={(e) => this.handleFilterBtnClick(e, 'filters')}
							/>					
						</div>

						<h1>
							<Trans i18nKey="landsIndexPageTitle">landsIndexPageTitle</Trans>
						</h1>

						<div className="all-projects__action search">
							<ImageButton
								svgImage={IconSearch}
								className="action__search"
								altText='Search projects'
								//onClick={(e) => this.handleFilterBtnClick(e, 'search')}
							/>
						</div>
					</div>
				</div>

				<LandsFilters filterCallback={this.handleLandsFilters} />

				{(!lands || isFiltering) && <Spinner />}

				{!isFiltering && lands && lands.length === 0 &&
					<div className="Lands__empty__list">
						<p><Trans i18nKey="landsIndexEmpty">landsIndexEmpty</Trans></p>

						<SubmitButton text='landsIndexCreateBtn' onClick={this.handleCreateLandClick} />
					</div>
				}

				{!isFiltering && lands && lands.length > 0 &&
					<div
						className="catalogue__wrapper"
						ref={ (mapContainer) => this.mapContainer = mapContainer}
					>
						<div className="catalogue__properties">
							{isFiltering && <Spinner />}

							{!isFiltering &&
								<div className="catalogue__properties__header">
									<p className="catalogue__properties__title">
										<Trans i18nKey="landsIndexCount" count={parseInt(totalLands)}>
											We've found {{count: parseInt(totalLands)}} land
										</Trans>
									</p>

									<Pagination
										totalItems={totalLands}
										itemsPerPage={this.landsPerPage}
										pageClickCallback={this.handlePageClick}
									/>
								</div>
							}

							{!isFiltering && !isChangingPage &&
								<MapList
									items={lands}
									ItemComponent={LandsListItem}
									itemCallback={this.handleLandItemClick}
									itemsPerPage={this.landsPerPage}
									totalItems={totalLands}
									createItemCallback={this.handleCreateLandClick}
									createItemText='landsIndexCreateBtn'
									className="catalogue__properties__wrapper"
									itemClassName="catalogue__property"
								/>
							}

							{!isFiltering && isChangingPage &&
								<Spinner />
							}
						</div>

						{mapHeight && displayMap &&
							<MapWrapper
								items={lands}
								mapType='lands'
								mapHeight={mapHeight}
								className='catalogue__map'
								featureClickCallback={(e) => this.handleFeatureClick(e)}
							/>
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { ...temporaryData } = state.temporary_data
	const { filters: storeFilters } = state.filters

	return { apiResponse, apiResponseEndpoint, temporaryData, storeFilters }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
		setTemporaryData: (key, data) => dispatch(setTemporaryData(key, data)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
		setFilters: (filterType, filterValues) => dispatch(setFilters(filterType, filterValues)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandsIndex)