import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { withTranslation } from 'react-i18next'

import { media } from 'media'

class PartnersSearch extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			searchValue: '',
			selectedPartners: [],
		}

		// bind methods
		this.handleSearch = this.handleSearch.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
		this.handlePartnerSelect = this.handlePartnerSelect.bind(this)
		this.handleDeletePartner = this.handleDeletePartner.bind(this)
	}

	handleSearch(e) {
		const newSearchValue = e.target.value

		this.setState((state, props) => ({
			searchValue: newSearchValue
		}))
	}

	handleClickOutside() {
		this.setState((state, props) => ({
			searchValue: '',
		}))
	}

	handlePartnerSelect(e, partner) {
		e.preventDefault()

		const { selectedPartners } = this.state
		let newSelectedPartners = selectedPartners
		newSelectedPartners[partner.value] = partner

		this.onPartnersChange(newSelectedPartners)

		this.setState((state, props) => ({
			selectedPartners: newSelectedPartners
		}))
	}

	handleDeletePartner(e, partner) {
		e.preventDefault()

		const { selectedPartners } = this.state
		let newSelectedPartners = []

		Array.from(Object.keys(selectedPartners)).map((partnerId) => {
			if (partner.value !== partnerId) {
				newSelectedPartners[partnerId] = selectedPartners[partnerId]
			}

			return null
		})

		this.onPartnersChange(newSelectedPartners)

		this.setState((state, props) => ({
			selectedPartners: newSelectedPartners
		}))
	}

	onPartnersChange(selectedPartners) {
		const { field, onChange } = this.props
		const selectedPartnersIds = Array.from(Object.keys(selectedPartners)).map((partnerId) => {
			return partnerId
		})

		onChange(selectedPartnersIds, field)
	}

	getFilteredPartners() {
		const { searchValue } = this.state
		const { field } = this.props
		const partners = field.options

		if (searchValue.length <= 2) {
			return null
		}

		return partners.filter((partner) => {
			console.log('partner', partner)

			if (
				typeof partner.label !== 'undefined'
				&& partner.label.indexOf(searchValue) > -1
			) {
				return true
			}

			if (
				typeof partner.partner_type_name !== 'undefined'
				&& null !== partner.partner_type_name
				&& partner.partner_type_name.indexOf(searchValue) > -1
			) {
				return true
			}

			if (
				typeof partner.organization_name !== 'undefined'
				&& null !== partner.organization_name
				&& partner.organization_name.indexOf(searchValue) > -1
			) {
				return true
			}

			return false
		})
	}

	getPartnerItem(partner, key, isSearchResult) {
		const avatarStyle = {
			backgroundImage: (partner.avatar_url) ? 
				`url(${media.getMediaUrl(partner.avatar_url)})`
				: null,
		}

		const onItemClick = (isSearchResult) ? (e) => this.handlePartnerSelect(e, partner) : null
		const itemClass = (isSearchResult) ? 'PartnersSearch__list__item': 'PartnersSearch__list__item--selected'
		const partnerTypeName = partner.partner_type_name && partner.partner_type_name.length > 15
			? `${partner.partner_type_name.substring(0, 15)}...` 
			: partner.partner_type_name

		return (
			<li key={key} className={itemClass} onClick={onItemClick}>
				<div className="Partner__avatar" style={avatarStyle}></div>

				<div className="Partner__infos">
					<span className="Partner__username medium">{partner.label}</span>

					{partner.partner_type_name && null !== partner.partner_type_name &&
						<span className="Partner__role medium">{partnerTypeName}</span>
					}

					{!isSearchResult && null !== partner.organization_name &&
						<span className="Partner__organization small">{partner.organization_name}</span>
					}
				</div>

				{!isSearchResult &&
					<div className="Partner__actions">
						<span className="CancelButton" onClick={(e) => this.handleDeletePartner(e, partner)}>x</span>
					</div>
				}
			</li>
		)
	}

	render() {
		const { searchValue, selectedPartners } = this.state
		const { t, field } = this.props
		const partners = this.getFilteredPartners()

		const placeholder = (field.props && field.props.placeholder) ? field.props.placeholder : ''

		return (			
			<div className="PartnersSearch">
				<OutsideClickHandler onOutsideClick={() => this.handleClickOutside(undefined, false)}>
					<div className="PartnersSearch__input">
						<input
							type='text'
							className="TextInput"
							value={searchValue}
							onChange={this.handleSearch}
							placeholder={t(placeholder)}
						/>

						<ul className="PartnersSearch__list">
							{null !== partners && partners.map((partner, i) => {
								return this.getPartnerItem(partner, i, true)
							})}
						</ul>
					</div>
				</OutsideClickHandler>

				{selectedPartners && selectedPartners.length > 0 &&
					<ul className="PartnersSearch__selected_list">
						{Array.from(Object.keys(selectedPartners)).map((partnerId) => {
							const partner = selectedPartners[partnerId]
							return this.getPartnerItem(partner, partnerId, false)
						})}
					</ul>
				}
			</div>
		)
	}
}

export default withTranslation()(PartnersSearch)