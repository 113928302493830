import React from 'react'

import AccessControl from 'auth/access-control'
import Spinner from 'components/spinner/Spinner'
import taskTypes from 'constants/task-types'
import TextButton from 'components/buttons/TextButton'

class CompleteProjectStep extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isCompleting: false,
			forceComplete: false,
		}

		// bind method
		this.handleCompleteClick = this.handleCompleteClick.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
	}

	componentDidUpdate(prevProps, prevState) {
		const { isCompleting, forceComplete } = this.state
		const { project, currentProjectPipelineSection } = this.props

		if (!forceComplete && project && currentProjectPipelineSection && project.projectPipelineSections) {
			project.projectPipelineSections.map((pps, iPPS) => {
				if (pps.id === currentProjectPipelineSection.id && pps.isComplete) {
					this.setState((state, props) => ({
						forceComplete: true,
					}))
				}

				return null
			})
		}

		if (true === isCompleting && true === currentProjectPipelineSection.isComplete) {
			this.setState((state, props) => ({
				isCompleting: false,
			}))
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true
	}

	handleCompleteClick() {
		const { completeSectionCallback, currentProjectPipelineSection } = this.props

		completeSectionCallback(currentProjectPipelineSection)

		this.setState((state, props) => ({
			isCompleting: true,
		}))
	}

	render() {
		const {
			isCompleting,
			forceComplete,
		} = this.state

		const {
			project,
			projectTasks,
			currentProjectPipelineSection,
		} = this.props

		const isProjectOwner = this.accessControl.isProjectOwner(project)
		if (!isProjectOwner) {
			return null
		}

		if (
			(currentProjectPipelineSection && true === currentProjectPipelineSection.isComplete)
			|| true === forceComplete
		) {
			return (
				<div className="Complete__project__step">
					<p className="Complete__title">
						This step is closed
					</p>
				</div>
			)
		}

		let completedTasksCount = 0

		if (projectTasks) {
			projectTasks.map((task, iT) => {
				if (taskTypes.TASK_TYPE_FILE_UPLOAD === task.fileType && task.filesToUpload) {
					let uploadedFilesCount = 0

					// check if all files have been uploaded
					task.filesToUpload.map((taskFileToUpload, iFTU) => {
						if (true === taskFileToUpload.isUploaded) {
							uploadedFilesCount++
						}

						return null
					})

					if (uploadedFilesCount === task.filesToUpload.length) {
						completedTasksCount++
					}
				}

				if (taskTypes.TASK_TYPE_SIGNATURE === task.fileType && task.taskFiles) {
					// a task is complete if all partners have signed all files
					task.taskFiles.map((taskFile, iTF) => {
						if (taskFile.taskSignatures && taskFile.taskSignatures.length > 0) {
							let signedFilesCount = 0

							taskFile.taskSignatures.map((taskSignature, iTS) => {
								if (true === taskSignature.isSigned) {
									signedFilesCount++
								}

								return null
							})

							if (signedFilesCount === task.partners.length) {
								completedTasksCount++
							}
						}

						return null
					})
				}

				return null
			})
		}

		if (!projectTasks || projectTasks.length <= 0) {
			return null
		}

		if (completedTasksCount !== projectTasks.length) {
			return null
		}

		return (
			<div className="Complete__project__step">
				<p className="Complete__title">
					All tasks have been completed.
					<br />
					Do you want to close this step ?
				</p>

				<div className="Complete__button">
					{isCompleting &&
						<Spinner />
					}

					{!isCompleting &&
						<TextButton
							className="BlueButton BigButton upper"
							text='Close this step'
							onClick={this.handleCompleteClick}
						/>
					}
				</div>
			</div>
		)
	}
}

export default CompleteProjectStep