import ActionTypes from 'constants/action-types'
import ApiClient from 'api/ApiClient'

export function callApi(endpoint, method, options) {
    return (dispatch) => {
    	const apiUrl = process.env.REACT_APP_API_URL
        const apiClient = new ApiClient(apiUrl)

        apiClient.sendRequest(endpoint, method, options).then((apiResponse) => {
            dispatch(receiveApiResponse(apiResponse, options, endpoint))
        }).catch((exception) => dispatch(apiHasErrored(exception, endpoint)))
    }
}

export function upload(endpoint, fileName, file, linkedEntity) {
    return (dispatch) => {
        const apiUrl = process.env.REACT_APP_API_URL
        const apiClient = new ApiClient(apiUrl)

        apiClient.upload(endpoint, fileName, file, linkedEntity).then((apiResponse) => {
            dispatch(receiveUploadResponse(apiResponse, file, endpoint))
        }).catch((exception) => dispatch(apiHasErrored(exception, endpoint)))
    }
}

export function getFormSchema(endpoint, schemaName) {
    return (dispatch) => {
    	const apiUrl = process.env.REACT_APP_API_URL
        const apiClient = new ApiClient(apiUrl)

        apiClient.sendRequest(endpoint, 'get').then((apiResponse) => {
            dispatch(receiveFormSchema(apiResponse, schemaName))
        }).catch((exception) => dispatch(apiHasErrored(exception, endpoint)))
    }
}

export const receiveApiResponse = (apiResponse, apiOptions, endpoint) => {
	return {
		type: ActionTypes.API_SUCCESS,
		apiResponse,
		apiOptions,
        endpoint
	}
}

export const receiveUploadResponse = (uploadResponse, uploadedFiles, endpoint) => {
    return {
        type: ActionTypes.UPLOAD_SUCCESS,
        uploadResponse,
        uploadedFiles,
        uploadEndpoint: endpoint,
    }
}

export const receiveFormSchema = (apiResponse, schemaName) => {
	return {
		type: ActionTypes.API_RECEIVE_FORM_SCHEMA,
		formSchema: apiResponse,
		schemaName: schemaName
	}
}

export const apiHasErrored = (error, endpoint) => {
    console.error('apiHasErrored', error)

	return {
		type: ActionTypes.API_ERROR,
        error: error,
        endpoint: endpoint
	}
}

export const resetApiResponse = () => {
    return {
        type: ActionTypes.API_RESET_RESPONSE
    }
}

export const resetApiError = () => {
    return {
        type: ActionTypes.API_RESET_ERROR
    }
}

export const resetUploadResponse = () => {
    return {
        type: ActionTypes.RESET_UPLOAD_RESPONSE
    }
}