import React from 'react'
import { withTranslation } from 'react-i18next'

const handleLabelClick = (e, props) => {
	const { onClick, onClickProps } = props

	if (onClick && onClickProps) {
		e.preventDefault()
		onClick(onClickProps)
	}
}

export const Label = (props) => {
	const {
		t,
		forInput,
		labelText,
		transLabelKey,
		transLabelText,
		required,
		className,
	} = props

	let labelClassName = (required) ? 'Label--required': 'Label'
	labelClassName = `${labelClassName} ${(className) ? className: ''}`

	let finalLabelText = t(labelText)
	if (transLabelText && transLabelKey) {
		finalLabelText = t(transLabelKey)
	}

	return (
		<label className={labelClassName} htmlFor={forInput} onClick={(e) => handleLabelClick(e, props)}>
			{finalLabelText}
		</label>
	)
}

export default withTranslation()(Label)