import React from 'react'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import Address from 'components/address/Address'
import TextButton from 'components/buttons/TextButton'

export const OrganizationDetailContent = (props) => {
	const {
		t,
		organization,
		addUserCallback,
		isOrganizationManager,
		isPendingRequest,
	} = props

	const { address, users, logo } = organization
	const logoStyle = {
		backgroundImage: (logo && logo.contentUrl) ? `url(${media.getMediaUrl(logo.contentUrl)})`: null,
	}

	return (
		<div className="Organization__content">
			<div className="Column__left">
				<div className="Organization__Logo" style={logoStyle}></div>

				<div className="Organization__name">
					<h1>{organization.name}</h1>
				</div>

				{organization.website &&
					<div className="Organization__infobox">
						<label>{t('organizationDetailWebsite')}</label>
						<p>
							<a href={organization.website} target="_blank" rel="noopener noreferrer">
								{organization.website}
							</a>
						</p>
					</div>
				}

				{organization.contactEmail &&
					<div className="Organization__infobox">
						<label>{t('organizationDetailContact')}</label>
						<p>
							<a href={`mailto:${organization.contactEmail}`}>{organization.contactEmail}</a>
						</p>
					</div>
				}

				{organization.phone &&
					<div className="Organization__infobox">
						<label>{t('organizationDetailPhone')}</label>
						<p>
							{organization.phone}
						</p>
					</div>
				}

				{address &&
					<div className="Organization__infobox">
						<Address title='organizationDetailAddress' address={address} />
					</div>
				}
			</div>

			<div className="Column__right">
				{organization.description &&
					<p className="Organization__description">{organization.description}</p>
				}

				<div className="Organization__header is-mobile">
					<div className="Organization__cover"></div>
				</div>

				<div className="Organization__members">
					<h2>{t('organizationDetailMembers')}</h2>

					{users && users.length > 0 &&
						<ul className="Members__list">
							{users.map((user, i) => {
								const { avatar } = user
								const avatarStyle = {
									backgroundImage: (avatar) ? `url(${media.getMediaUrl(avatar.contentUrl)})`: null,
								}

								return (
									<li key={i}>
										<div className="Avatar" style={avatarStyle}></div>
										
										<label>{user.username}</label>
									</li>
								);
							})}
						</ul>
					}
					{(!users || users.length === 0) &&
						<p className="Organization__empty__members">
							{t('organizationDetailMembersEmpty')}
						</p>
					}

					{isOrganizationManager && addUserCallback &&
						<div className="Edit__btn__wrapper">
							<TextButton
								text="organizationDetailAddMemberBtn"
								onClick={addUserCallback}
								className="BlueButton"
							/>
						</div>
					}
				</div>

				{isPendingRequest &&
					<div className="Organization__content__pending">
						<h3>{t('organizationDetailPendingRequest')}</h3>
					</div>
				}
			</div>
		</div>
	)
}

export default withTranslation()(OrganizationDetailContent)