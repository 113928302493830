import React from 'react'

import { media } from 'media'
import notificationTypes from 'constants/notification-types'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import Spinner from 'components/spinner/Spinner'

const getNotificationTitle = (notificationType) => {
	switch(notificationType) {
		case notificationTypes.NOTIFICATION_TYPE_PROJECT_CREATED:
			return 'New Project'

		case notificationTypes.NOTIFICATION_TYPE_PROJECT_APPROVED:
			return 'Project approved'

		case notificationTypes.NOTIFICATION_TYPE_PROJECT_DENIED:
			return 'Project denied'

		case notificationTypes.NOTIFICATION_TYPE_LAND_CREATED:
			return 'New Land'

		case notificationTypes.NOTIFICATION_TYPE_LAND_APPROVED:
			return 'Land approved'

		case notificationTypes.NOTIFICATION_TYPE_LAND_DENIED:
			return 'Land denied'

		case notificationTypes.NOTIFICATION_TYPE_NEW_ORGANIZATION_REQUEST:
			return 'New Organization Member Request'

		case notificationTypes.NOTIFICATION_TYPE_TASK_CREATED:
			return 'New Task'

		case notificationTypes.NOTIFICATION_TYPE_TASK_SIGNED:
			return 'Task file signed'

		case notificationTypes.NOTIFICATION_TYPE_OFFER_CREATED:
			return 'New Offer'

		case notificationTypes.NOTIFICATION_TYPE_OFFER_QUOTE_CREATED:
			return 'New Offer Quote'

		case notificationTypes.NOTIFICATION_TYPE_OFFER_QUOTE_APPROVED:
			return 'Offer Quote approved'

		case notificationTypes.NOTIFICATION_TYPE_OFFER_QUOTE_DENIED:
			return 'Offer Quote denied'

		default:
			return undefined
	}
}

const NotifierHeader = (props) => {
	const {
		notifications,
		unreadCount,
		isDropdownOpen,
		isFetchingNotifications,
		notificationHeaderClickCallback,
		notificationClickCallback,
	} = props

	const btnClassName = (parseInt(unreadCount) > 0) ? 'has-notifications': ''

	return (
		<div className="NotifierHeader">
			<ImageButton
				image={icons.notifications}
				altText={`Notifications (${(typeof unreadCount !== undefined) ? unreadCount: '0'})`}
				onClick={(e) => notificationHeaderClickCallback(e)}
				className={btnClassName}
			/>

			{isDropdownOpen &&
				<div className="Notifications__Dropdown">
					<h3>Notifications</h3>

					{!isFetchingNotifications && notifications &&
						<ul className="Notifications__Dropdown_List">
							{notifications.map((notification, i) => {
								const notificationTitle = getNotificationTitle(notification.type)
								const readClass = (true !== notification.is_read) ? 'unread': ''

								const notificationDate = (notification.created_at) ? new Date(notification.created_at): null
								const dateString = notificationDate.toLocaleDateString()

								const avatarStyle = {
									backgroundImage: (notification.sent_from && notification.sent_from.avatar) ? 
										`url(${media.getMediaUrl(notification.sent_from.avatar)})`
										: null,
								}

								if (null === notificationTitle) {
									return null
								}

								return (
									<li
										key={i}
										className="Notifications__Dropdown_List_Item"
										onClick={() => notificationClickCallback(notification)}
									>
										<p className={`Notification__title ${readClass}`}>
											<span>{notificationTitle}</span>
										</p>

										<p className="Notification__date">
											{dateString}
										</p>

										{notification.sent_from &&
											<div className="Notification__user">
												<p>
													{notification.sent_from.username}
												</p>

												{null !== avatarStyle &&
													<div className="Notification__Avatar" style={avatarStyle}></div>
												}
											</div>
										}
									</li>
								)
							})}
						</ul>
					}

					{isFetchingNotifications &&
						<Spinner />
					}

					{!isFetchingNotifications && notifications && notifications.length <= 0 &&
						<p className="Notifications__empty">No notifications</p>
					}
				</div>
			}
		</div>
	)
}

export default NotifierHeader