import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { setTemporaryData } from 'actions/temporary-data'
import { displayMessaging } from 'actions/messaging'
import { collapseLeftMenu } from 'actions/left-menu'
import { media } from 'media'
import ApiClient from 'api/ApiClient'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import CollectionsFilter from 'components/collections/CollectionsFilter'
import iconCalendar from 'assets/png/icon-calendar.png'
import iconProperty from 'assets/png/icon-property.png'
import Popin from 'components/layout/Popin'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import UbikonForm from 'components/forms/UbikonForm'

class CollectionsIndex extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			collections: undefined,
			openCreateCollectionPopin: false,
			collectionForm: undefined,
		}

		// bind methods
		this.handleCreateCollectionClick = this.handleCreateCollectionClick.bind(this)
		this.handleCollectionFormSubmit = this.handleCollectionFormSubmit.bind(this)
		this.handleCollectionClick = this.handleCollectionClick.bind(this)

		// set private properties
		this.apiClient = new ApiClient()
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		// collapse menu
		this.props.collapseLeftMenu(true)

		// hide messaging
		this.props.displayMessaging(false)

		this.getCollections()
	}

	componentDidUpdate(prevProps, prevState) {
		const { collectionForm } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const collectionFormEndpoint = this.endpointBuilder.getEndpoint('collections_get_form')
			const collectionsEndpoint = this.endpointBuilder.getEndpoint('collections')

			if (apiResponseEndpoint === collectionFormEndpoint) {
				this.setState((state, props) => ({
					collectionForm: apiResponse.model,
				}))
			}

			if (apiResponseEndpoint === collectionsEndpoint) {
				if (collectionForm) {
					// refresh collections list
					this.getCollections()

					this.setState((state, props) => ({
						collectionForm: undefined,
						openCreateCollectionPopin: false,
					}))
				} else {
					this.setState((state, props) => ({
						collections: apiResponse,
					}))
				}
			}
		}
	}

	handleCreateCollectionClick() {
		const { openCreateCollectionPopin } = this.state
		const newOpenPopin = !openCreateCollectionPopin

		if (true === newOpenPopin) {
			const collectionFormEndpoint = this.endpointBuilder.getEndpoint('collections_get_form')

			this.props.callApi(collectionFormEndpoint, 'get')
		}

		this.setState((state, props) => ({
			openCreateCollectionPopin: !openCreateCollectionPopin,
		}))
	}

	handleCollectionFormSubmit(formData) {
		const collectionsEndpoint = this.endpointBuilder.getEndpoint('collections')

		// format users ()
		const users = Object.keys(formData.users).map((userId) => {
			return userId
		})

		const newFormData = {
			...formData,
			users: users
		}

		this.props.callApi(collectionsEndpoint, 'post', {
			body: JSON.stringify(newFormData)
		})
	}

	handleCollectionClick(collection) {
		// set temporary data for next page
		this.props.setTemporaryData(`tmp-collection-${collection.id}`, collection)

		// redirect to collection detail
		const path = reverse(routesList.collections.detail.index, { collectionId: collection.id })
		this.props.history.push(path)
	}

	getCollections() {
		const collectionsEndpoint = this.endpointBuilder.getEndpoint('collections')

		this.props.callApi(collectionsEndpoint, 'get')
	}

	render() {
		const {
			collections,
			openCreateCollectionPopin,
			collectionForm,
		} = this.state

		return (
			<div className="CollectionsIndex all-projects">
				<CollectionsFilter />

				<div className="all-projects__actions"></div>

				{!collections &&
					<div className="all-projects__list">
						<Spinner />
					</div>
				}

				{collections &&
					<div className="all-projects__list">
						<div className="all-projects__create" onClick={this.handleCreateCollectionClick}>
							<div className="all-projects__create__cross"></div>
							<p className="all-projects__create__text">
								<Trans i18nKey="createCollectionBtn">createCollectionBtn</Trans>
							</p>
						</div>

						{collections && collections.map((collection, iC) => {
							const collectionDate = new Date(collection.createdAt)
							const propertiesCount = collection.collectionItems.length

							const createdByStyle = {
								backgroundImage: (collection.createdBy && collection.createdBy.avatar && collection.createdBy.avatar.contentUrl) ? 
									`url(${media.getMediaUrl(collection.createdBy.avatar.contentUrl)})`
									: null,
							}

							const avatarsLimit = 2
							let moreUsersCount = 0

							let collectionImage = undefined
							if (propertiesCount > 0) {
								collection.collectionItems.map((collectionItem, iCI) => {
									if (
										'undefined' === typeof collectionImage
										&& collectionItem.propertyItem
										&& collectionItem.propertyItem.photos
										&& collectionItem.propertyItem.photos[0]
									) {
										collectionImage = collectionItem.propertyItem.photos[0]
									}

									return null
								})
							}

							const collectionImageStyle = {
								backgroundImage: (collectionImage) ? `url(${media.getMediaUrl(collectionImage.contentUrl)})`: null,
							}
							const collectionImageClass = ('undefined' === typeof collectionImage) ? 'is-empty' : ''

							return (
								<div key={iC} className="all-projects__project" onClick={() => this.handleCollectionClick(collection)}>
									<div className="all-projects__project__infos">
										<p className="all-projects__project__title">
											{collection.name}
										</p>

										<div className="all-projects__project__profiles collection">
											<div className="all-projects__project__profile" style={createdByStyle}></div>

											{collection.collectionUsers && collection.collectionUsers.map((collectionUser, iU) => {
												const index = iU + 1

												if (index > avatarsLimit) {
													moreUsersCount++
													return null
												}

												const user = collectionUser.user
												const userStyle = {
													backgroundImage: (user.avatar && user.avatar.contentUrl) ? 
														`url(${media.getMediaUrl(user.avatar.contentUrl)})`
														: null,
												}

												return (
													<div key={iU} className="all-projects__project__profile" style={userStyle}></div>
												)
											})}

											{moreUsersCount > 0 &&
												<div className="all-projects__project__profile--more">
													+ {moreUsersCount}
												</div>
											}
										</div>

										<p className="all-projects__project__location collection">
											<img src={iconCalendar} className="collection__infos__icon" alt="calendar" /> {collectionDate.toLocaleDateString()}
										</p>

										<p className="all-projects__project__location collection">
											<img src={iconProperty} className="collection__infos__icon" alt="calendar" /> 
											{propertiesCount > 0 &&
												<Trans i18nKey="collectionDetailPropertiesCount" count={propertiesCount}>
													{{count: propertiesCount}} property
												</Trans>
											}

											{propertiesCount <= 0 &&
												<Trans i18nKey="collectionDetailNoProperties">collectionDetailNoProperties</Trans>
											}
										</p>
									</div>

									<div className={`all-projects__project__picture ${collectionImageClass}`} style={collectionImageStyle}></div>
								</div>
							)
						})}
					</div>
				}

				{openCreateCollectionPopin &&
					<Popin
						isOpened={openCreateCollectionPopin}
						transTitle='createCollectionPopinTitle'
						transTitleKey='createCollectionPopinTitle'
						onClose={this.handleCreateCollectionClick}
					>
						{!collectionForm &&
							<Spinner />
						}

						{collectionForm &&
							<UbikonForm form={collectionForm} onSubmit={this.handleCollectionFormSubmit} />
						}
					</Popin>
				}
			</div>
		)
	}
}
const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setTemporaryData: (key, data) => dispatch(setTemporaryData(key, data)),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsIndex)