import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { displayHeader, displayDecorators } from 'actions/layout'
import { ReactComponent as IconCross } from 'assets/svg/icon-cross.svg'
import ImageButton from 'components/buttons/ImageButton'

class Popin extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isVisible: this.props.isOpened,
		}

		// bind methods
		this.handleOverlayClick = this.handleOverlayClick.bind(this)
		this.handleCrossClick = this.handleCrossClick.bind(this)
	}

	componentDidMount() {
		const { isVisible } = this.state
		if (true === isVisible) {
			this.props.displayHeader(false)
			this.props.displayDecorators(false)
		}

		document.addEventListener('mousedown', this.handleOverlayClick, false)
	}

	componentWillUnmount() {
		this.props.displayHeader(true)
		this.props.displayDecorators(true)

		document.removeEventListener('mousedown', this.handleOverlayClick, false)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isOpened !== this.props.isOpened) {
			this.setState((state, props) => ({
				isVisible: this.props.isOpened,
			}))
		}
	}

	handleOverlayClick(e) {
		if (this.node === e.target) {
			this.setState((state, props) => ({
				isVisible: false,
			}))

			this.props.displayHeader(true)
			this.props.displayDecorators(true)

			const { onClose } = this.props
			if (typeof onClose !== 'undefined') {
				onClose()
			}
		}
	}

	handleCrossClick() {
		const { onClose } = this.props
		if (typeof onClose !== 'undefined') {
			onClose()
		}
	}

	render() {
		const {
			children,
			title,
			transTitle,
			transTitleKey,
			className,
		} = this.props

		const { isVisible } = this.state

		const overlayClass = (isVisible) ? '--visible': ''

		let finalTitle = title
		if (transTitle && transTitleKey) {
			finalTitle = <Trans i18nKey={transTitleKey}>{transTitle}</Trans>
		}

		return (
			<div
				className={`Popin__overlay${overlayClass}`}
				ref={node => this.node = node }
			>
				<div className={`Popin__center ${(className) ? className: ''}`}>
					<ImageButton
						svgImage={IconCross}
						onClick={this.handleCrossClick}
						altText='Close'
						className="Close__btn"
					/>

					{finalTitle && finalTitle !== '' && 
						<div className="Popin__title">
							<h4>{finalTitle}</h4>
						</div>
					}

					<div className="Popin__content">
						{children}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { }
}

const mapDispatchToProps = dispatch => {
	return {
		displayHeader: (displayBool) => dispatch(displayHeader(displayBool)),
		displayDecorators: (displayBool) => dispatch(displayDecorators(displayBool)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Popin)