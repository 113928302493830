import React from 'react'

import { media } from 'media'
import AdminPopinBlock from 'components/admin/AdminPopinBlock'
import BenefitsList from 'components/benefits/BenefitsList'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import priceFormat from 'utils/priceFormat'
import PropertyAttributes from 'components/properties/PropertyAttributes'
import PropertyPerformance from 'components/properties/PropertyPerformance'

export const AdminPropertyItemBlock = (props) => {
	const { propertyItem } = props

	return (
		<div className="Property__item__wrapper">
			<AdminPopinBlock title='Property item' className="inner">
				<LandInfosIcons entity={propertyItem} fullAddress={true} />

				<p className="Property__item__price blue no-margin-bottom">
					{priceFormat(propertyItem.price)}
				</p>
			</AdminPopinBlock>

			<AdminPopinBlock title='Number of rooms' className="inner">
				<PropertyAttributes propertyItem={propertyItem} />
			</AdminPopinBlock>

			{propertyItem.benefits && propertyItem.benefits.length > 0 &&
				<AdminPopinBlock title='Benefits' className="inner">
					<BenefitsList benefits={propertyItem.benefits} displayTitle={false} />
				</AdminPopinBlock>
			}

			{(propertyItem.energyPerformance || propertyItem.greenhouseIndex) &&
				<AdminPopinBlock title='Performance' className="inner">
					<PropertyPerformance propertyItem={propertyItem} />
				</AdminPopinBlock>
			}

			{propertyItem.photos && propertyItem.photos.length > 0 &&
				<AdminPopinBlock title='Photos' className="inner">
					<div className="Photos">
						{propertyItem.photos && propertyItem.photos.map((photoUrl, iPhoto) => {
							return (
								<img key={iPhoto} src={media.getMediaUrl(photoUrl)} alt='land' />
							)
						})}
					</div>
				</AdminPopinBlock>
			}
		</div>
	)
}

export default AdminPropertyItemBlock