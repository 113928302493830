import React from 'react'
import { withTranslation } from 'react-i18next'

import taskTypes from 'constants/task-types'
import TaskFileFolder from 'components/tasks/TaskFileFolder'
import GreyBlockActionButton from 'components/buttons/GreyBlockActionButton'

export const TasksList = (props) => {
	const {
		t,
		projectTasks,
		openedFolders,
		downloadCallback,
		downloadFolderCallback,
		newFileFolderBtnCallback,
		folderClickCallback,
		previewCallback,
	} = props

	return (
		<div className="ProjectFiles">
			<div className="Files__header">
				<GreyBlockActionButton
					text='projectFilesNewFolderBtn'
					onClick={newFileFolderBtnCallback}
				/>
			</div>

			<TaskFileFolder
				title={t('projectFilesTaskDocumentsTitle')}
				projectTasks={projectTasks}
				openedFolders={openedFolders}
				taskType={taskTypes.TASK_TYPE_FILE_UPLOAD}
				downloadCallback={downloadCallback}
				downloadFolderCallback={downloadFolderCallback}
				folderClickCallback={folderClickCallback}
				previewCallback={previewCallback}
			/>

			<TaskFileFolder
				title={t('projectFilesSignaturesTitle')}
				projectTasks={projectTasks}
				openedFolders={openedFolders}
				taskType={taskTypes.TASK_TYPE_SIGNATURE}
				downloadCallback={downloadCallback}
				downloadFolderCallback={downloadFolderCallback}
				folderClickCallback={folderClickCallback}
				previewCallback={previewCallback}
			/>
		</div>
	)
}

export default withTranslation()(TasksList)