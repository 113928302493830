import apiEndpoints from 'constants/api-endpoints'

class ApiEndpointBuilder {
	getEndpoint(actionName, endpointParams) {
		switch(actionName) {
			case 'activity_feed':
				return apiEndpoints.ACTIVITY_FEED

			case 'admin_sections':
				return apiEndpoints.ADMIN_SECTIONS

			case 'admin_projects_list':
				return apiEndpoints.ADMIN_PROJECTS_LIST

			case 'admin_send_deny_message':
				return apiEndpoints.ADMIN_SEND_DENY_MESSAGE

			case 'benefits':
				return apiEndpoints.BENEFITS

			case 'collections':
				return apiEndpoints.COLLECTIONS

			case 'collections_get_form':
				return apiEndpoints.COLLECTIONS_GET_FORM

			case 'collection':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.COLLECTION.replace(':id', endpointParams.id)

			case 'collection_items':
				return apiEndpoints.COLLECTION_ITEMS

			case 'collection_item':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.COLLECTION_ITEM.replace(':id', endpointParams.id)

			case 'collection_conversation':
				if (!endpointParams || !endpointParams.collectionId) {
					this.throwMissingParameterError('collectionId')
				}

				return apiEndpoints.COLLECTION_CONVERSATION.replace(':collectionId', endpointParams.collectionId)

			case 'conversations':
				return apiEndpoints.CONVERSATIONS

			case 'conversation':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.CONVERSATION.replace(':id', endpointParams.id)

			case 'conversation_form':
				return apiEndpoints.CONVERSATION_FORM

			case 'conversation_with_user':
				if (!endpointParams || !endpointParams.receiverId) {
					this.throwMissingParameterError('receiverId')
				}

				if (typeof endpointParams.offerId !== 'undefined') {
					return `${apiEndpoints.GET_USERS_CONVERSATION.replace(':receiverId', endpointParams.receiverId)}?offerId=${endpointParams.offerId}`
				}

				return apiEndpoints.GET_USERS_CONVERSATION.replace(':receiverId', endpointParams.receiverId)

			case 'conversation_message':
				if (!endpointParams || !endpointParams.conversationId) {
					this.throwMissingParameterError('conversationId')
				}

				return apiEndpoints.POST_MESSAGE.replace(':conversationId', endpointParams.conversationId)

			case 'conversation_messages':
				if (!endpointParams || !endpointParams.conversationId) {
					this.throwMissingParameterError('conversationId')
				}

				return apiEndpoints.GET_CONVERSATION_MESSAGES.replace(':conversationId', endpointParams.conversationId)

			case 'conversations_get_add_user_form':
				if (!endpointParams || !endpointParams.conversationId) {
					this.throwMissingParameterError('conversationId')
				}

				return apiEndpoints.GET_CONVERSATION_ADD_USER_FORM.replace(':conversationId', endpointParams.conversationId)

			case 'file_folders':
				return apiEndpoints.FILE_FOLDERS

			case 'file_folders_get_upload_form':
				if (!endpointParams || !endpointParams.projectPipelineSectionId) {
					this.throwMissingParameterError('projectPipelineSectionId')
				}

				return apiEndpoints.GET_PROJECT_FILE_UPLOAD_FORM.replace(':projectPipelineSectionId', endpointParams.projectPipelineSectionId)

			case 'lands_get':
				return apiEndpoints.GET_LANDS

			case 'lands':
				return apiEndpoints.POST_LAND

			case 'land':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.LAND.replace(':landId', endpointParams.id)

			case 'get_land_form':
				return apiEndpoints.GET_LAND_FORM

			case 'get_notifications':
				return apiEndpoints.NOTIFICATIONS

			case 'set_notifications_read':
				return apiEndpoints.NOTIFICATIONS_READ

			case 'get_project_analysis':
				if (!endpointParams || !endpointParams.projectId) {
					this.throwMissingParameterError('projectId')
				}

				return apiEndpoints.GET_PROJECT_ANALYSIS.replace(':projectId', endpointParams.projectId)

			case 'persist_analysis':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.PERSIST_ANALYSIS.replace(':id', endpointParams.id)

			case 'analysis_project_configs':
				return apiEndpoints.ANALYSIS_PROJECT_CONFIGS

			case 'analysis_project_config':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.ANALYSIS_PROJECT_CONFIG.replace(':id', endpointParams.id)

			case 'get_project_request_form':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				if (!endpointParams || !endpointParams.projectRequestType) {
					this.throwMissingParameterError('projectRequestType')
				}

				return apiEndpoints.GET_PROJECT_REQUEST_FORM
					.replace(':id', endpointParams.id)
					.replace(':projectRequestType', endpointParams.projectRequestType)

			case 'offer_directories':
				return apiEndpoints.OFFER_DIRECTORIES

			case 'offer_directory':
				if (!endpointParams || !endpointParams.offerId) {
					this.throwMissingParameterError('offerId')
				}

				return apiEndpoints.OFFER_DIRECTORY.replace(':offerId', endpointParams.offerId)

			case 'offers_index':
				return apiEndpoints.OFFERS_INDEX

			case 'offer_quotes':
				return apiEndpoints.OFFER_QUOTES

			case 'offer_quote':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.OFFER_QUOTE.replace(':id', endpointParams.id)

			case 'offer_refusals':
				return apiEndpoints.OFFER_REFUSALS

			case 'organizations':
				return apiEndpoints.ORGANIZATIONS

			case 'organization':
				if (!endpointParams || !endpointParams.organizationId) {
					this.throwMissingParameterError('organizationId')
				}

				return apiEndpoints.ORGANIZATION.replace(':organizationId', endpointParams.organizationId)

			case 'organization_requests':
				return apiEndpoints.ORGANIZATION_REQUESTS

			case 'organization_request':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.ORGANIZATION_REQUEST.replace(':id', endpointParams.id)

			case 'organization_form':
				if (!endpointParams || !endpointParams.organizationId) {
					this.throwMissingParameterError('organizationId')
				}

				return `${apiEndpoints.GET_ORGANIZATION_FORM}?id=${endpointParams.organizationId}`

			case 'partner':
				if (!endpointParams || !endpointParams.partnerId) {
					this.throwMissingParameterError('partnerId')
				}

				return `${apiEndpoints.PARTNER.replace(':partnerId', endpointParams.partnerId)}`

			case 'forgotten_password':
				return apiEndpoints.FORGOTTEN_PASSWORD

			case 'forgotten_password_validity':
				return apiEndpoints.FORGOTTEN_PASSWORD_VALIDITY

			case 'projects':
				return apiEndpoints.PROJECTS

			case 'project':
				if (!endpointParams || !endpointParams.projectId) {
					this.throwMissingParameterError('projectId')
				}

				return `${apiEndpoints.PROJECT.replace(':projectId', endpointParams.projectId)}`

			case 'project_buckets':
				if (!endpointParams || !endpointParams.projectId) {
					this.throwMissingParameterError('projectId')
				}

				return `${apiEndpoints.PROJECT_BUCKETS.replace(':projectId', endpointParams.projectId)}`

			case 'project_pipeline_section':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return `${apiEndpoints.PROJECT_PIPELINE_SECTION.replace(':id', endpointParams.id)}`

			case 'project_requests':
				return apiEndpoints.PROJECT_REQUESTS

			case 'project_request':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.PROJECT_REQUEST.replace(':projectRequestId', endpointParams.id)

			case 'get_project_form':
				return apiEndpoints.GET_PROJECT_FORM

			case 'get_project_edit_form':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.GET_PROJECT_EDIT_FORM.replace(':id', endpointParams.id)

			case 'project_filters_form':
				return apiEndpoints.GET_PROJECTS_FILTERS_FORM

			case 'project_types':
				return apiEndpoints.PROJECT_TYPES

			case 'post_edit_project':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.POST_EDIT_PROJECT.replace(':id', endpointParams.id)

			case 'properties':
				return apiEndpoints.PROPERTIES

			case 'property':

				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.PROPERTY.replace(':propertyId', endpointParams.id)

			case 'property_items':
				return apiEndpoints.PROPERTY_ITEMS

			case 'property_item':

				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.PROPERTY_ITEM.replace(':id', endpointParams.id)

			case 'property_form':
				return apiEndpoints.GET_PROPERTY_FORM

			case 'property_post':
				return apiEndpoints.POST_PROPERTY

			case 'get_project_tasks':
				if (!endpointParams || !endpointParams.projectId) {
					this.throwMissingParameterError('projectId')
				}

				if (!endpointParams || !endpointParams.projectPipelineSectionId) {
					this.throwMissingParameterError('projectPipelineSectionId')
				}

				return `${apiEndpoints.GET_PROJECT_TASK}?project=${endpointParams.projectId}&projectPipelineSection=${endpointParams.projectPipelineSectionId}`

			case 'task_file_to_upload':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.TASK_FILE_TO_UPLOAD.replace(':id', endpointParams.id)

			case 'task_signatures':
				return apiEndpoints.TASK_SIGNATURES

			case 'task_signature':
				if (!endpointParams || !endpointParams.id) {
					this.throwMissingParameterError('id')
				}

				return apiEndpoints.TASK_SIGNATURE.replace(':id', endpointParams.id)

			case 'task_signature_url':
				if (!endpointParams || !endpointParams.taskFileId) {
					this.throwMissingParameterError('taskFileId')
				}

				return apiEndpoints.GET_TASK_SIGNATURE_URL.replace(':taskFileId', endpointParams.taskFileId)

			case 'set_task_signature_data':
				if (!endpointParams || !endpointParams.taskId) {
					this.throwMissingParameterError('taskId')
				}

				return apiEndpoints.SET_TASK_SIGNATURE_DATA.replace(':taskId', endpointParams.taskId)

			case 'assign_tasks':
				return apiEndpoints.ASSIGN_TASKS

			case 'upload_task_file':
				if (!endpointParams || !endpointParams.taskId) {
					this.throwMissingParameterError('taskId')
				}

				return apiEndpoints.UPLOAD_TASK_FILE.replace(':taskId', endpointParams.taskId)

			case 'upload':
				return apiEndpoints.UPLOAD

			case 'user':
				if (!endpointParams || !endpointParams.userId) {
					this.throwMissingParameterError('userId')
				}

				return apiEndpoints.USER.replace(':userId', endpointParams.userId)

			case 'user_chat':
				return apiEndpoints.CHAT_GET_USER_CHAT

			case 'user_organization':
				return apiEndpoints.GET_USER_ORGANIZATION

			case 'post_project_file':
				if (!endpointParams || !endpointParams.projectPipelineSectionId) {
					this.throwMissingParameterError('projectPipelineSectionId')
				}

				return apiEndpoints.POST_PROJECT_FILE.replace(':projectPipelineSectionId', endpointParams.projectPipelineSectionId)

			case 'tabs_counts':
				return apiEndpoints.GET_TABS_COUNTS

			case 'contact_form_submit':
				return apiEndpoints.CONTACT_FORM_SUBMIT

			case 'contact_property_seller':
				if (!endpointParams || !endpointParams.propertyItemId) {
					this.throwMissingParameterError('propertyItemId')
				}

				return apiEndpoints.CONTACT_PROPERTY_SELLER.replace(':propertyItemId', endpointParams.propertyItemId)

			case 'reset_password':
				return apiEndpoints.RESET_PASSWORD

			case 'config':
				return apiEndpoints.CONFIG

			default:
				return undefined
		}
	}

	throwMissingParameterError(parameterName) {
		const errorMsg = `ApiEndpointBuilder - Missing parameter: ${parameterName}`
		throw new Error(errorMsg)
	}
}

export default ApiEndpointBuilder