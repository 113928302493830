import React from 'react'

import Radio from 'components/forms/inputs/Radio'

class RadioGroup extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			selectedOption: undefined,
		}

		// bind methods
		this.handleRadioChange = this.handleRadioChange.bind(this)
	}

	handleRadioChange(selectedOption, field) {
		const { onChange } = this.props

		onChange(selectedOption, field)

		this.setState((state, props) => ({
			selectedOption: selectedOption,
		}))
	}

	render() {
		const { selectedOption } = this.state
		const { field } = this.props
		const { options } = field

		if (!options) {
			return null
		}

		return (
			<div className="RadioGroup">
				{options.map((option, i) => {
					const isChecked = (selectedOption && option.value === selectedOption.value) ? true : false

					return (
						<Radio
							key={i}
							field={field}
							option={option}
							isChecked={isChecked}
							onChange={this.handleRadioChange}
						/>
					)
				})}
			</div>
		)
	}
}

export default RadioGroup