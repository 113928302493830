import React from 'react'

import ProjectsListFilters from 'components/projects/ProjectsListFilters'

export const MobileProjectsHeader = (props) => {
	const {
		filtersSchema,
		filtersCallback,
	} = props

	return (
		<div className="MobileProjectsHeader all-projects__header">
			<ProjectsListFilters
				isMobile={true}
				filtersSchema={filtersSchema}
				filtersCallback={filtersCallback}
			/>
		</div>
	)
}

export default MobileProjectsHeader