import React from 'react'

import TextButton from 'components/buttons/TextButton'

export const ChatSectionTitle = (props) => {
	const {
		title,
		listType,
		className,
		displayLimit,
		sectionCount,
		onExpandClick,
	} = props
	const titleClass = `chat__title ${(typeof className !== 'undefined') ? className: ''}`
	const expandTitle = (displayLimit) ? 'see all': 'hide'

	return (
		<div className={titleClass}>
			<h3 className="title--small">
				{title}

				<span className="title--small__infos">{sectionCount}</span>
			</h3>

			{(!displayLimit || sectionCount > displayLimit) &&
				<TextButton
					text={expandTitle}
					onClick={(e) => onExpandClick(e, listType)}
					className="title--hide text--blue-carolina"
				/>
			}
		</div>
	)
}

export default ChatSectionTitle