import React from 'react'
import { withTranslation } from 'react-i18next'

export const ProjectsListTabs = (props) => {
	const { t, tabs, onClick } = props

	return (
		<nav className="all-projects__nav">
			{tabs.map((tab, i) => {
				const activeClass = (tab.active) ? 'is-active' : ''

				return (
					<p
						key={tab.name}
						className={`all-projects__nav__item ${activeClass}`}
						onClick={(e) => onClick(e, tab)}
					>
						{t(tab.label)}
						{tab.count && 
							<span className="all-projects__nav__item__infos">{tab.count}</span>
						}

						{!tab.count && <span className="all-projects__nav__item__infos__empty"></span>}
					</p>
				)
			})}
		</nav>
	)
}

export default withTranslation()(ProjectsListTabs)