import React from 'react'

import { media } from 'media'
import AdminPopinBlock from 'components/admin/AdminPopinBlock'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import priceFormat from 'utils/priceFormat'

export const LandBlock = (props) => {
	const { land, fullAddress, displayZipcode } = props

	const landPrice = (land) ? priceFormat(land.price) : undefined

	return (
		<AdminPopinBlock title='Land'>
			<LandInfosIcons entity={land} displayZipcode={displayZipcode} fullAddress={fullAddress} />

			{landPrice &&
				<p className="Land__price blue">{landPrice}</p>
			}

			<div className="Photos">
				{land.photos && land.photos.map((photoUrl, i) => {
					return (
						<img key={i} src={media.getMediaUrl(photoUrl)} alt='land' />
					)
				})}
			</div>
		</AdminPopinBlock>
	)
}

export default LandBlock