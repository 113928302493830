import React from 'react'

class CustomCheckbox extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isChecked: (typeof props.isChecked !== 'undefined') ? props.isChecked: false,
		}

		// bind methods
		this.handleOnChange = this.handleOnChange.bind(this)
	}

	handleOnChange(e, value) {
		const { isChecked } = this.state
		const { onChangeCallback } = this.props
		const isCheckedNow = !isChecked

		if (typeof onChangeCallback !== 'undefined') {
			onChangeCallback(isCheckedNow, value)
		}

		this.setState((state, props) => ({
			isChecked: isCheckedNow
		}))
	}

	handleOnBtnClick(e) {
		e.preventDefault();

		const { disabled, value } = this.props

		if (!disabled) {
			this.handleOnChange(e, value)
		}
	}

	render() {
		const { isChecked } = this.state
		const { disabled, value, checkboxLabel } = this.props
		const isDisabled = (typeof disabled !== 'undefined') ? disabled: false

		const checkedClass = (isChecked) ? '--checked' : ''
		const disabledClass = (isDisabled) ? 'disabled' : ''

		return (
			<div className="Custom__checkbox">
				<input
					className="Checkbox"
					type="checkbox"
					checked={isChecked}
					disabled={isDisabled}
					onChange={(e) => this.handleOnChange(e, value)}
					value={value}
				/>

				<button
					className={`Checkbox__button${checkedClass} ${disabledClass}`}
					disabled={isDisabled}
					onClick={(e) => this.handleOnBtnClick(e)}
				></button>

				{checkboxLabel &&
					<span className="Checkbox__label" onClick={(e) => this.handleOnBtnClick(e)}>
						{checkboxLabel}
					</span>
				}
			</div>
		)
	}
}

export default CustomCheckbox