import React from 'react'
import { withTranslation } from 'react-i18next'

import ProjectListItem from 'components/projects/ProjectListItem'

export const ProjectsList = (props) => {
	const {
		t,
		projects,
		addBtn,
		projectClickCallback,
		addProjectCallback,
	} = props

	return (
		<div className="all-projects__list">
			{addBtn &&
				<div className="all-projects__create" onClick={addProjectCallback}>
					<div className="all-projects__create__cross"></div>
					<p className="all-projects__create__text">{t('projectsIndexCreateProjectBtn')}</p>
				</div>
			}

			{projects && projects.map((project, i) => (
					<ProjectListItem key={i} project={project} projectClickCallback={projectClickCallback} />
				))
			}

			{!addBtn && (!projects || projects.length === 0) &&
				<p className="Projects__empty__notice">
					{t('projectsIndexEmpty')}
				</p>
			}
		</div>
	)
}

export default withTranslation()(ProjectsList)