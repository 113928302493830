import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'

import { callApi, resetApiResponse } from 'actions/api'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import contactForm from 'components/forms/schemas/contactForm'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import UbikonForm from 'components/forms/UbikonForm'

class ContactForm extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isSubmittingMessage: false,
			didSendMessage: false,
		}

		// bind methods
		this.handleContactSubmit = this.handleContactSubmit.bind(this)
		this.handleBackBtn = this.handleBackBtn.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const submitEndpoint = this.endpointBuilder.getEndpoint('contact_form_submit')

			if (apiResponseEndpoint === submitEndpoint) {
				this.setState((state, props) => ({
					isSubmittingMessage: false,
					didSendMessage: true,
				}))
			}
		}
	}

	handleContactSubmit(formData) {
		const submitEndpoint = this.endpointBuilder.getEndpoint('contact_form_submit')
		this.props.callApi(submitEndpoint, 'post', {
			body: JSON.stringify(formData),
		})

		this.setState((state, props) => ({
			isSubmittingMessage: true,
		}))
	}

	handleBackBtn() {
		window.location.href = reverse(routesList.index)
	}

	render() {
		const { isSubmittingMessage, didSendMessage } = this.state

		return (
			<div className="ContactForm">
				{isSubmittingMessage && !didSendMessage &&
					<Spinner />
				}

				{!isSubmittingMessage && didSendMessage &&
					<div className="Contact__success">
						<p>Your message has been sent.<br />We'll get back to you as soon as possible !</p>

						<TextButton
							text='Back to homepage'
							onClick={this.handleBackBtn}
						/>
					</div>
				}

				{!isSubmittingMessage && !didSendMessage &&
					<UbikonForm form={contactForm} onSubmit={this.handleContactSubmit} />
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)