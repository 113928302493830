const signUpForm = {
	name: 'signup',
	submitBtn: 'Create your account',
	multipart: false,
	fields: [
		{
			type: 'text',
			label: 'signupFormPlaceholderName',
			attachLabel: true,
			displayLabel: false,
			props: {
				name: 'username',
				id: 'username',
				required: true,
				placeholder: 'signupFormPlaceholderName',
				autoComplete: 'new-password',
			},
			validation: {
				minLength: 3,
			}
		},
		{
			type: 'text',
			label: 'signupFormPlaceholderEmail',
			attachLabel: true,
			displayLabel: false,
			props: {
				name: 'email',
				id: 'email',
				required: true,
				placeholder: 'signupFormPlaceholderEmail',
				autoComplete: 'new-password',
			},
			validation: {
				email: true,
			}
		},
		{
			type: 'password',
			label: 'signupFormPlaceholderPassword',
			attachLabel: true,
			displayLabel: false,
			props: {
				name: 'password',
				id: 'password',
				required: true,
				placeholder: 'signupFormPlaceholderPassword',
				autoComplete: 'new-password',
			},
			validation: {
				minLength: 8,
				specialChars: true,
			}
		},
		{
			type: 'password',
			label: 'signupFormPlaceholderPasswordCheck',
			attachLabel: true,
			displayLabel: false,
			props: {
				name: 'password-check',
				id: 'password-check',
				required: true,
				placeholder: 'signupFormPlaceholderPasswordCheck',
				autoComplete: 'new-password',
			},
			validation: {
				minLength: 8,
				specialChars: true,
				sameAs: 'password',
			}
		},
		{
			type: 'radio',
			forceRadioValue: true,
			props: {
				name: 'optin',
				id: 'optin',
				required: true,
				value: '',
			},
			validation: {
				checked: true,
			}
		},
	],	
}

export default signUpForm