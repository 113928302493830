import React from 'react'
import { Trans } from 'react-i18next'

import {
	SubmitButton
} from 'components/forms/inputs'
import TextButton from 'components/buttons/TextButton'

class signUpFormTheme extends React.Component {
	render() {
		const {
			formFields,
			formHelper,
			submitCallback,
		} = this.props
		const { helpClickCallback, isError } = formHelper.props

		if (!formFields) {
			return false;
		}

		const isFormValid = formHelper.isFormValid()
		const submitClass = (!isFormValid) ? ' is-disabled' : ''

		return (
			<div>
				<form name="signup" onSubmit={submitCallback} autoComplete="new-password">
					{formHelper.renderField(formFields[0], 'username')}
					{formHelper.renderField(formFields[1], 'email')}
					{formHelper.renderField(formFields[2], 'password')}
					{formHelper.renderField(formFields[3], 'password-check')}

					<div className="FormRow help">
						<div className="Terms__wrapper">
							{formHelper.renderField(formFields[4], 'optin')}

							<TextButton
								text='signupAcceptTerms'
								onClick={() => helpClickCallback('terms')}
							/>
						</div>

						<TextButton
							text='loginHelpBtnText'
							onClick={() => helpClickCallback('help')}
						/>
					</div>

					{true === isError &&
						<p className="Alert--danger">
							<Trans i18nKey="signupErrorMessage">signupErrorMessage</Trans>
						</p>
					}

					<SubmitButton
						className={`upper ${submitClass}`}
						text='signupSubmitBtn'
					/>
				</form>
			</div>
		)
	}
}

export default signUpFormTheme