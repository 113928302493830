import React from 'react'

import analysisConfigForm from 'components/forms/schemas/analysisConfigForm'
import UbikonForm from 'components/forms/UbikonForm'

export const AnalysisConfigForm = (props) => {
	const { project, config, submitCallback } = props

	const landSquareMeters = (project && project.land) ? project.land.squareMeters : 0

	const newFields = analysisConfigForm.fields.map((field, iF) => {
		let newField = { ...field }
		let newFieldProps = { ...newField.props }

		if (newFieldProps.name === 'landSquareMeters') {
			newFieldProps.value = landSquareMeters
		} else if (newFieldProps.name === 'livingSpace' && config && config.living_space) {
			newFieldProps.value = config.living_space
		} else if (newFieldProps.name === 'floorSpace' && config && config.floor_space) {
			newFieldProps.value = config.floor_space
		} else if (newFieldProps.name === 'landCharge' && config && config.land_charge) {
			newFieldProps.value = config.land_charge
		}

		newField.props = newFieldProps

		return newField
	})

	const newConfigForm = {
		...analysisConfigForm,
		fields: newFields,
	}

	return (
		<div className="AnalysisConfigForm">
			<UbikonForm form={newConfigForm} onSubmit={submitCallback} />
		</div>
	)
}

export default AnalysisConfigForm