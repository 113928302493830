import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { withRouter } from 'react-router'

import { callApi, resetApiResponse } from 'actions/api'
import { media } from 'media'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'

class ActivityFeed extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			activityFeed: undefined,
			isLoadingFeed: true,
		}

		// bind methods
		this.getFeedEntityTitle = this.getFeedEntityTitle.bind(this)
		this.getFeedEntityDetails = this.getFeedEntityDetails.bind(this)
		this.getFeedActionLabel = this.getFeedActionLabel.bind(this)
		this.handleFeedAction = this.handleFeedAction.bind(this)

		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const activityFeedEndpoint = this.getActivityFeedEndpoint()
		this.props.callApi(activityFeedEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props
		const activityFeedEndpoint = this.getActivityFeedEndpoint()

		if (apiResponse) {
			this.props.resetApiResponse()

			if (apiResponseEndpoint === activityFeedEndpoint) {
				let activityFeedArray = []
				for(let key in apiResponse){
					apiResponse[key]['_timestamp'] = key;
					activityFeedArray.push(apiResponse[key]);
				}

				const orderedActivityFeed = activityFeedArray.sort((a, b) => b['_timestamp'] - a['_timestamp'])

				this.setState((state, props) => ({
					activityFeed: orderedActivityFeed,
					isLoadingFeed: false,
				}))
			}
		}
	}

	getActivityFeedEndpoint() {
		return this.endpointBuilder.getEndpoint('activity_feed')
	}

	getFeedEntityTitle(feedEntity) {

		if (feedEntity.feedType) {
			switch(feedEntity.feedType) {
				case 'tasks':
					return 'New Task'
				case 'partners':
					return 'New Partner'
				case 'signatures':
					return 'New Signature'
				case 'uploads':
					return 'File upload'
				case 'offers':
					return 'New request for offers'
				case 'offerQuotes':
					return 'New answer on offer'
				case 'offerRefusals':
					return 'Offer refusal'
				case 'collections':
					return 'New Collection'
				case 'collectionItems':
					return 'New Collection Item'
				case 'collectionUsers':
					return 'You have joined Collection'

				default:
					return undefined
			}
		}

		return undefined
	}

	getFeedEntityDetails(feedEntity) {
		if (feedEntity.feedType) {
			switch(feedEntity.feedType) {
				case 'tasks':
					return (feedEntity.title) ? feedEntity.title : ''

				case 'partners':
					return (feedEntity.user) ? `${feedEntity.user.username} was added to ${feedEntity.project.name}` : ''

				case 'signatures':
					if (feedEntity.taskFile) {
						if (feedEntity.taskFile.filename) {
							return `You signed ${feedEntity.taskFile.filename}`
						} else if (feedEntity.task) {
							return `You signed task ${feedEntity.task.title}`
						}
					}
					break

				case 'uploads':
					if (feedEntity.file) {
						if (feedEntity.file.originalFilename) {
							return `You uploaded a file : ${feedEntity.file.originalFilename}`
						} else if (feedEntity.filename) {
							return `You uploaded a file : ${feedEntity.file.filename}`
						}
					}
					break

				case 'offers':
					if (feedEntity.project) {
						return `You added a request for offers to ${feedEntity.project.name}`
					}
					break

				case 'offerQuotes':
					// handle offerQuote status
					if ('approved' === feedEntity.status) {
						return `Your answer has been approved on ${feedEntity.offer.title}`
					} else if ('denied' === feedEntity.status) {
						return `Your answer has been denied on ${feedEntity.offer.title}`
					}

					return `You answered to an offer on ${feedEntity.offer.title}`

				case 'offerRefusals':
					return `You refused to answer an offer on ${feedEntity.offer.title}`

				case 'collections':
					return `You created collection ${feedEntity.name}`

				case 'collectionItems':
					return `You added ${feedEntity.itemName} to collection ${feedEntity.collection.name}`

				case 'collectionUsers':
					return `You were added to collection ${feedEntity.collection.name}`

				default:
					return undefined
			}
		}

		return undefined
	}

	getFeedActionLabel(feedEntity) {
		if (feedEntity.feedType) {
			switch(feedEntity.feedType) {
				case 'tasks':
					return 'See task'
				case 'partners':
					return 'See project'
				case 'signatures':
					return 'See task'
				case 'uploads':
					return 'See task'
				case 'offers':
				case 'offerQuotes':
				case 'offerRefusals':
					return 'See offer'
				case 'collections':
				case 'collectionItems':
				case 'collectionUsers':
					return 'See collection'

				default:
					return undefined
			}
		}

		return undefined
	}

	handleFeedAction(feedEntity) {
		const {
			collection,
			feedType,
			id,
			offer,
			project,
			projectId,
			projectPipelineSectionId,
			task,
			taskId,
		} = feedEntity

		let path = undefined

		console.log('feedType', feedType)

		switch(feedType) {
			case 'tasks':
				// redirect to project detail
				path = reverse(routesList.projects.detail.index, {
					projectId: projectId,
				})

				// add project section and task
				path = `${path}?pps=${projectPipelineSectionId}&t=${id}`
				break

			case 'partners':
				// redirect to project detail
				path = reverse(routesList.projects.detail.index, {
					projectId: project.id,
				})

				// add project section
				path = `${path}?pps=${projectPipelineSectionId}`
				break

			case 'signatures':
				// redirect to project detail
				path = reverse(routesList.projects.detail.index, {
					projectId: projectId,
				})

				// add project section and task
				path = `${path}?pps=${projectPipelineSectionId}&t=${taskId}`
				break

			case 'uploads':
				// redirect to project detail
				path = reverse(routesList.projects.detail.index, {
					projectId: task.projectId,
				})

				// add project section and task
				path = `${path}?pps=${task.projectPipelineSectionId}&t=${task.id}`
				break

			case 'offers':
				// redirect to project detail
				path = reverse(routesList.projects.detail.index, {
					projectId: project.id,
				})

				// add offer id
				path = `${path}?pps=${projectPipelineSectionId}&o=${id}`
				break

			case 'offerQuotes':
			case 'offerRefusals':
				// redirect to project detail
				path = reverse(routesList.projects.detail.index, {
					projectId: offer.project.id,
				})

				// add offer id
				path = `${path}?pps=${offer.projectPipelineSectionId}&o=${offer.id}`
				break

			case 'collections':
				// redirect to collection detail
				path = reverse(routesList.collections.detail.index, {
					collectionId: id,
				})
				break

			case 'collectionItems':
			case 'collectionUsers':
				// redirect to collection detail
				path = reverse(routesList.collections.detail.index, {
					collectionId: collection.id,
				})
				break

			default:
				return false
		}

		if (typeof path !== 'undefined') {
			this.props.history.push(path)
		}
	}

	render() {
		const { activityFeed, isLoadingFeed } = this.state

		return (
			<div className="ActivityFeed">

				{isLoadingFeed &&
					<Spinner />
				}

				{!isLoadingFeed && activityFeed &&
					<div className="ActivityFeed__list">
						<div className="ActivityFeed__list__header">
							<p>Activity name</p>
							<p>Date</p>
							<p>Status</p>
							<p>Assigned to</p>
							<p>Action</p>
						</div>

						<div className="ActivityFeed__list__items">
							{Array.from(Object.keys(activityFeed)).map((feedEntityKey) => {

								const feedEntity = activityFeed[feedEntityKey]
								const feedEntityTitle = this.getFeedEntityTitle(feedEntity)

								if (!feedEntityTitle) {
									return null
								}

								const feedEntityDetails = this.getFeedEntityDetails(feedEntity)
								const feedEntityDate = new Date(feedEntity.feedDate)
								const feedActionLabel = this.getFeedActionLabel(feedEntity)

								let createdByAvatar = undefined
								if ('partners' === feedEntity.feedType && feedEntity.user) {
									createdByAvatar = {
										backgroundImage: (feedEntity.user.avatar) ? `url(${media.getMediaUrl(feedEntity.user.avatar)})`: null,
									}
								}

								return (
									<div className="ActivityFeed__list__item" key={feedEntityKey}>
										<p>
											{feedEntityTitle}
											{feedEntityDetails && <span className="Activity__details">{feedEntityDetails}</span>}
										</p>
										<p>{feedEntityDate.toLocaleDateString()}</p>
										<p>{feedEntity.status}</p>
										<div className="Activity__users">
											{'tasks' === feedEntity.feedType && feedEntity.partners && feedEntity.partners.map((partner, iP) => {
												const { user } = partner
												const avatarStyle = {
													backgroundImage: (user.avatar) ? `url(${media.getMediaUrl(user.avatar)})`: null,
												}

												return (
													<div className="Activity__user" style={avatarStyle} key={iP}></div>
												)
											})}

											{createdByAvatar &&
												<div className="Activity__user" style={createdByAvatar}></div>
											}
										</div>
										<div className="Activity__actions">
											<TextButton
												text={feedActionLabel}
												className="BlueBorderButton upper"
												onClick={() => this.handleFeedAction(feedEntity)}
											/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api

	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityFeed))