import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducer as formReducer } from 'redux-form'
import apiReducer from 'reducers/api'
import configurationReducer from 'reducers/configuration'
import filtersReducer from 'reducers/filters'
import layoutReducer from 'reducers/layout'
import messagingReducer from 'reducers/messaging'
import notifierReducer from 'reducers/notifier'
import temporaryDataReducer from 'reducers/temporary-data'
import leftMenuReducer from 'reducers/left-menu'
import collectionsReducer from 'reducers/collections'
import thunk from 'redux-thunk'

const initialState = {
	api: undefined,
	configuration: undefined,
	form: undefined,
	layout: undefined,
	messaging: undefined,
	notifier: undefined,
	temporary_data: undefined,
	leftMenu: undefined,
	collections: undefined,
	filters: undefined,
}

const rootReducer = combineReducers({
	api: apiReducer,
	configuration: configurationReducer,
	form: formReducer,
	layout: layoutReducer,
	messaging: messagingReducer,
	notifier: notifierReducer,
	temporary_data: temporaryDataReducer,
	leftMenu: leftMenuReducer,
	collections: collectionsReducer,
	filters: filtersReducer,
})

const store = createStore(rootReducer, initialState, applyMiddleware(thunk))

export default store