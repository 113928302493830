import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { reverse } from 'named-urls'

import { callApi, upload, resetApiResponse, resetUploadResponse } from 'actions/api'
import { media } from 'media'
import { UbikonForm } from 'components/forms'
import AccessControl from 'auth/access-control'
//import FileUpload from 'components/forms/inputs/FileUpload'
import ApiClient from 'api/ApiClient'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import apiEndpoints from 'constants/api-endpoints'
import ImageButton from 'components/buttons/ImageButton'
import icons from 'assets/svg'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import UserProfileForm from 'components/user/UserProfileForm'

class UserProfile extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			user: undefined,
			userAvatarSchema: undefined,
			userFormSchema: undefined,
			isEditMode: false,
			isUpdating: false,
		}

		// set services
		this.accessControl = new AccessControl()
		this.apiClient = new ApiClient()

		// bind methods
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)
		this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this)
		this.handleAvatarSubmit = this.handleAvatarSubmit.bind(this)
		this.handleEditSwitch = this.handleEditSwitch.bind(this)
		this.handleCancelClick = this.handleCancelClick.bind(this)

		// set private properties
		this.endpoitBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		// get user data
		const userEndpoint = this.getUserEndpoint()
		this.props.callApi(userEndpoint, 'get')

		// get user edit form
		//this.getUserEditForm()
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint, uploadResponse, uploadedFiles } = this.props
		const userFormEndpoint = this.getUserFormEndpoint()
		const userEndpoint = this.getUserEndpoint()

		// user form schema API response
		if (apiResponse && apiResponseEndpoint === userFormEndpoint) {
			this.props.resetApiResponse()

			this.setState((state, props) => ({
				userFormSchema: apiResponse.model,
				userAvatarSchema: apiResponse.avatarModel,
			}))
		}

		// user update API response
		if (apiResponse && apiResponseEndpoint === userEndpoint) {
			this.props.resetApiResponse()

			// refresh edit form
			this.getUserEditForm()

			// set state's user data
			this.setState((state, props) => ({
				user: apiResponse,
				isUpdating: false,
			}))
		}

		// post created mediaObject to USER endpoint
		if (uploadResponse && uploadedFiles) {
			this.props.resetUploadResponse()

			const userEndpoint = this.getUserEndpoint()
			const avatarId = `/api/media_objects/${uploadResponse.id}`
			const userAvatarPayload = {
				avatar: avatarId
			}

			this.props.callApi(userEndpoint, 'put', {
				body: JSON.stringify(userAvatarPayload)
			})
		}
	}

	getUserFormEndpoint() {
		return apiEndpoints.GET_USER_EDIT_FORM
	}

	getUserEditForm() {
		const userFormEndpoint = this.getUserFormEndpoint()
		this.props.callApi(userFormEndpoint, 'get')
	}

	getUserEndpoint() {
		const userId = this.accessControl.getUserId()
		return this.endpoitBuilder.getEndpoint('user', {
			userId: userId
		})
	}

	handleBackBtnClick() {
		// go back to index
		this.props.history.push(reverse(routesList.projects.index))
	}

	handleUserFormSubmit(formData) {
		const userOrganization = (formData['organization'] && formData['organization'].length > 0) ? formData['organization'] : null
		const newFormData = {
			username: formData['username'],
			email: formData['email'],
			bio: formData['bio'],
			organization: userOrganization,
		}

		// persist new User data
		const userEndpoint = this.getUserEndpoint()
		const userData = this.apiClient.normalizeRequest(newFormData, [{
			name: 'organization',
			path: '/api/organizations/{id}'
		}])

		// call API
		this.props.callApi(userEndpoint, 'put', {
			body: JSON.stringify(userData)
		})

		// unset previous schema and values
		this.setState((state, props) => ({
			userFormSchema: undefined,
			isEditMode: false,
			isUpdating: true,
		}))
	}

	handleAvatarSubmit(e, schema, selectedFiles) {
		this.props.upload(apiEndpoints.UPLOAD, 'avatar', selectedFiles)
	}

	handleEditSwitch() {
		const { userFormSchema, isEditMode } = this.state
		const newEditMode = !isEditMode

		if (true === newEditMode && !userFormSchema) {
			// get edit form
			this.getUserEditForm()
		}

		this.setState((state, props) => ({
			isEditMode: newEditMode,
		}))
	}

	handleCancelClick() {
		this.setState((state, props) => ({
			userFormSchema: undefined,
			isEditMode: false,
			isUpdating: false,
		}))
	}

	render() {
		const {
			user,
			//userAvatarSchema,
			userFormSchema,
			isEditMode,
			isUpdating,
		} = this.state

		if (!user) {
			return (
				<div className="UserProfile Organization__Wrapper">
					<Spinner />
				</div>
			)
		}

		const { avatar } = user
		const avatarStyle = {
			backgroundImage: (avatar && avatar.contentUrl) ? `url(${media.getMediaUrl(avatar.contentUrl)})`: null,
		}

		const userId = this.accessControl.getUserId()
		const isUserAdmin = user && parseInt(user.id) === parseInt(userId)

		return (
			<div className="UserProfile Organization__Wrapper">
				<div className="Organization__Detail">
					<div className="Organization__nav">
						<ImageButton
							image={icons.arrow}
							altText='Back'
							onClick={this.handleBackBtnClick}
						/>

						{!isEditMode && isUserAdmin &&
							<div className="Edit__btns">
								<ImageButton
									image={icons.edit}
									altText='userProfileEditBtn'
									onClick={this.handleEditSwitch}
								/>

								<TextButton
									text='userProfileEditBtn'
									onClick={this.handleEditSwitch}
									className="blue"
								/>
							</div>
						}
					</div>

					<div className="Organization__header">
						<div className="Organization__name">
							{user && <h1>{user.username}</h1>}
						</div>

						<div className="Organization__cover"></div>
					</div>

					{!isEditMode && !isUpdating &&
						<div className="Organization__content">
							<div className="Column__left">
								<div className="Organization__Logo" style={avatarStyle}></div>

								<div className="Organization__name">
									{user && <h1>{user.username}</h1>}
								</div>

								{user.email &&
									<div className="Organization__infobox">
										<label><Trans i18nKey="userProfileLabelEmail">userProfileLabelEmail</Trans></label>
										<p>
											<a href={`mailto:${user.email}`}>
												{user.email}
											</a>
										</p>
									</div>
								}

								{user.organization && user.organization.name &&
									<div className="Organization__infobox">
										<label><Trans i18nKey="userProfileLabelOrganization">userProfileLabelOrganization</Trans></label>
										<p>{user.organization.name}</p>
									</div>
								}
							</div>

							<div className="Column__right">
								{user.bio &&
									<p className="Organization__description">{user.bio}</p>
								}

								<div className="Organization__header is-mobile">
									<div className="Organization__cover"></div>
								</div>
							</div>

							<div className="Column__full Profile__bottom">
								{user.properties && user.properties.length > 0 &&
									<div className="Profile__bottom__item">
										<Trans i18nKey="userProfileLabelPropertiesCount" count={user.properties.length}>
											<span>{{count: user.properties.length}}</span> property
										</Trans>
									</div>
								}

								{user.ownedLands && user.ownedLands.length > 0 &&
									<div className="Profile__bottom__item">
										<Trans i18nKey="userProfileLabelLandsCount" count={user.ownedLands.length}>
											<span>{{count: user.ownedLands.length}}</span> land
										</Trans>
									</div>
								}

								{user.projectRequests && user.projectRequests.length > 0 &&
									<div className="Profile__bottom__item project-request">
										<Trans i18nKey="userProfileLabelProjectRequestsCount" count={user.projectRequests.length}>
											<span>{{count: user.projectRequests.length}}</span> project request
										</Trans>
									</div>
								}
							</div>
						</div>
					}

					{!isUpdating && isEditMode && isUserAdmin && !userFormSchema &&
						<div className="Organization__content">
							<Spinner />
						</div>
					}

					{!isUpdating && isEditMode && isUserAdmin && userFormSchema &&
						<UbikonForm
							form={userFormSchema}
							theme={UserProfileForm}
							onSubmit={this.handleUserFormSubmit}
							onCancel={this.handleCancelClick}
						/>
					}

					{isUpdating &&
						<Spinner />
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint, uploadResponse, uploadedFiles } = state.api

	return { apiResponse, apiResponseEndpoint, uploadResponse, uploadedFiles }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		upload: (endpoint, fileName, files) => dispatch(upload(endpoint, fileName, files)),
		resetUploadResponse: () => dispatch(resetUploadResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)