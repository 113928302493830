import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import AdminPopin from 'components/admin/AdminPopin'
import AdminSection from 'components/admin/AdminSection'
import adminSectionTypes from 'constants/admin-section-types'
import Popin from 'components/layout/Popin'
import Spinner from 'components/spinner/Spinner'

class AdminIndex extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			adminSections: undefined,
			popinEntity: undefined,
			popinSection: undefined,
			displayDenyForm: false,
		}

		// bind methods
		this.handleEntityClick = this.handleEntityClick.bind(this)
		this.handleAdminPopinClose = this.handleAdminPopinClose.bind(this)
		this.hanlePopinAction = this.hanlePopinAction.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		this.props.callApi(this.endpointBuilder.getEndpoint('admin_sections'), 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const { popinSection, popinEntity } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const getSectionsEndpoint = this.endpointBuilder.getEndpoint('admin_sections')

			if (apiResponseEndpoint === getSectionsEndpoint) {
				this.setState((state, props) => ({
					adminSections: apiResponse.sections
				}))
			}

			if (popinSection && popinEntity) {
				const validateEntityEndpoint = this.getValidateEntityEndpoint(popinSection, popinEntity)

				if (validateEntityEndpoint && apiResponseEndpoint === validateEntityEndpoint) {
					// refresh admin sections
					this.props.callApi(getSectionsEndpoint, 'get')

					this.setState((state, props) => ({
						adminSections: undefined,
						popinSection: undefined,
						popinEntity: undefined,
					}))
				}
			}
		}
	}

	getValidateEntityEndpoint(section, entity) {
		if (section && entity) {
			if (adminSectionTypes.PROJECT_REQUESTS === section.type) {
				return this.endpointBuilder.getEndpoint('project_request', {id: entity.id})
			} else if (adminSectionTypes.LAND_REQUESTS === section.type) {
				return this.endpointBuilder.getEndpoint('land', {id: entity.id})
			} else if (adminSectionTypes.PROPERTY_REQUESTS === section.type) {
				return this.endpointBuilder.getEndpoint('property', {id: entity.id})
			} else if (adminSectionTypes.ORGANIZATION_REQUESTS === section.type) {
				return this.endpointBuilder.getEndpoint('organization_request', {id: entity.id})
			}
		}

		return undefined
	}

	handleEntityClick(section, entity) {
		this.setState((state, props) => ({
			popinEntity: entity,
			popinSection: section,
		}))
	}

	handleAdminPopinClose() {
		this.setState((state, props) => ({
			popinEntity: undefined,
			popinSection: undefined,
		}))
	}

	hanlePopinAction(actionType, section, entity, message) {
		if ('display-denied' === actionType) {
			this.setState((state, props) => ({
				displayDenyForm: true,
			}))
		} else if ('cancel-deny' === actionType) {
			this.setState((state, props) => ({
				displayDenyForm: false,
			}))
		} else {
			const validateEntityEndpoint = this.getValidateEntityEndpoint(section, entity)

			if (typeof validateEntityEndpoint !== 'undefined') {
				const bodyData = {
					status: actionType
				}

				this.props.callApi(validateEntityEndpoint, 'put', {
					body: JSON.stringify(bodyData)
				})

				if (typeof message !== 'undefined') {
					// send deny message
					const sendDenyMessageEndpoint = this.endpointBuilder.getEndpoint('admin_send_deny_message')
					this.props.callApi(sendDenyMessageEndpoint, 'post', {
						body: JSON.stringify({
							message: message,
							entity: entity,
						})
					})
				}
			}
		}
	}

	render() {
		const { adminSections, popinSection, popinEntity, displayDenyForm } = this.state

		return (
			<div className="AdminIndex">
				{!adminSections &&
					<Spinner />
				}

				{adminSections && adminSections.map((section, i) => {
					return <AdminSection
						key={i}
						section={section}
						entityClickCallback={this.handleEntityClick}
					/>
				})}

				{popinSection && popinEntity &&
					<Popin isOpened={true} title={popinSection.title} className="admin" onClose={this.handleAdminPopinClose}>
						<AdminPopin
							section={popinSection}
							entity={popinEntity}
							actionCallback={this.hanlePopinAction}
							cancelCallback={this.hanlePopinAction}
							displayDenyForm={displayDenyForm}
						/>
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
		uploadResponse,
		uploadedFiles,
		uploadEndpoint
	} = state.api

	const { ...temporaryData } = state.temporary_data

	return {
		apiResponse,
		apiResponseEndpoint,
		temporaryData,
		uploadResponse,
		uploadedFiles,
		uploadEndpoint
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminIndex)