import ActionTypes from 'constants/action-types'

const initialState = {}

const temporaryDataReducer = (state = initialState, action) => {
	const {
        temporaryDataKey,
        temporaryData,
	} = action

	switch(action.type) {
		case ActionTypes.TEMPORARY_DATA_SET:
			return {
				...state,
				[temporaryDataKey]: temporaryData
			}

		default:
			return {...state}
	}
}

export default temporaryDataReducer