import React from 'react'

export const NumberInput = (props) => {
	const { field, formData, onChange } = props

	let fieldProps = field.props
	if (
		field.props
		&& field.props.name
		&& formData
		&& formData[field.props.name]
	) {
		fieldProps = {
			...field.props,
			value: formData[field.props.name]
		}
	}

	return (
		<input
			type="number"
			className="FormInput NumberInput"
			onChange={(e) => onChange(e, field)}
			{...fieldProps}
		/>
	)
}

export default NumberInput