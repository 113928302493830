import React from 'react'

const Index = (props) => {
	return (
		<div className="Index">
			<h1>Ubikon Index</h1>
		</div>
	)
}

export default Index