import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import Liform from 'liform-react'

import { callApi, upload, resetApiResponse, resetUploadResponse } from 'actions/api'
import AccessControl from 'auth/access-control'
import { apiEndpointBuilder } from 'api'
import apiEndpoints from 'constants/api-endpoints'
import CreateOrganization from 'components/organizations/CreateOrganization'
import OrganizationDetail from 'components/organizations/OrganizationDetail'
import Popin from 'components/layout/Popin'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'

class OrganizationEdit extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			organization: undefined,
			isOrganizationManager: false,
			openAddUserPopin: false,
			addUserSchema: undefined,
			selectedNewUserId: undefined,
			editMode: false,
			organizationSchema: undefined,
			didGetOrganization: false,
		}

		// bind methods
		this.handleClosePopin = this.handleClosePopin.bind(this)
		this.handleAddUserBtnClick = this.handleAddUserBtnClick.bind(this)
		this.handleAddUserSubmit = this.handleAddUserSubmit.bind(this)
		this.handleOrganizationSubmit = this.handleOrganizationSubmit.bind(this)
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)
		this.switchEditMode = this.switchEditMode.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
	}

	componentDidMount() {
		// get user's organization
		const userOrganizationEndpoint = this.getUserOrganizationEndpoint()
		if (typeof userOrganizationEndpoint !== 'undefined') {
			this.props.callApi(userOrganizationEndpoint, 'get')
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { organization, selectedNewUserId } = this.state
		const { apiResponse, apiResponseEndpoint, uploadResponse, uploadedFiles } = this.props
		const userOrganizationEndpoint = this.getUserOrganizationEndpoint()
		const organizationSubmitEndpoint = this.getOrganizationSubmitEndpoint()
		const organizationSchemaEndpoint = this.getOrganizationSchemaEndpoint()
		const addUserFormEndpoint = this.getAddUserFormEndpoint()
		const userEndpoint = this.getUserEndpoint(selectedNewUserId)
		const addressesEndpoint = this.getAddressesEndpoint()

		if (null === apiResponse && apiResponseEndpoint === userOrganizationEndpoint) {
			this.props.resetApiResponse()

			this.setState((state, props) => ({
				isOrganizationManager: false,
				didGetOrganization: true,
			}))
		}

		if (apiResponse) {
			this.props.resetApiResponse()

			// get organization API response
			if (apiResponseEndpoint === userOrganizationEndpoint || apiResponseEndpoint === organizationSubmitEndpoint) {
				// check if current user is manager
				const isOrganizationManager = this.accessControl.isOrganizationManager(apiResponse)
				if (isOrganizationManager) {
					// get organisation form schema
					if (typeof organizationSchemaEndpoint !== 'undefined') {
						this.props.callApi(organizationSchemaEndpoint, 'get')
					}
				}

				this.setState((state, props) => ({
					organization: apiResponse,
					isOrganizationManager: isOrganizationManager,
					didGetOrganization: true,
				}))
			}

			// get organization form schema response
			if (apiResponseEndpoint === organizationSchemaEndpoint) {
				this.setState((state, props) => ({
					organizationSchema: apiResponse.model
				}))
			}

			// get add user form API response
			if (apiResponseEndpoint === addUserFormEndpoint) {
				this.setState((state, props) => ({
					addUserSchema: apiResponse.model
				}))
			}

			// put user organization API response
			if (apiResponseEndpoint === userEndpoint) {
				// reset organization data
				this.props.callApi(userOrganizationEndpoint, 'get')

				// close and reset add user popin
				this.setState((state, props) => ({
					openAddUserPopin: false,
					addUserSchema: undefined,
				}))
			}

			// submit address API response
			if (apiResponseEndpoint === addressesEndpoint) {
				// if new address, link address to organization
				const addressesMethod = this.getAddressesMethod()
				if ('post' === addressesMethod) {
					const organizationAddressData = {
						address: `/api/addresses/${apiResponse.id}`
					}

					this.props.callApi(userOrganizationEndpoint, 'put', {
						body: JSON.stringify(organizationAddressData)
					})
				}

				this.switchEditMode()
			}
		}

		// did upload files
		if (uploadResponse && uploadedFiles) {
			this.props.resetUploadResponse()

			// link logo to organization
			const organizationEndpoint = apiEndpointBuilder.getEndpoint('organization', {organizationId: organization.id})
			const organizationLogoData = {
				logo: `/api/media_objects/${uploadResponse.id}`
			}

			console.log('will update organization logo', organizationLogoData)

			this.props.callApi(organizationEndpoint, 'put', {
				body: JSON.stringify(organizationLogoData)
			})
		}
	}

	getOrganizationSubmitEndpoint() {
		const { organization } = this.state
		if (organization) {
			return apiEndpoints.ORGANIZATION.replace(':organizationId', organization.id)
		}

		return undefined
	}

	getUserOrganizationEndpoint() {
		return apiEndpointBuilder.getEndpoint('user_organization')
	}

	getOrganizationSchemaEndpoint() {
		const { organization } = this.state

		if (organization && this.accessControl.isOrganizationManager(organization)) {
			return apiEndpointBuilder.getEndpoint('organization_form', {
				organizationId: organization.id,
			})
		}

		return undefined
	}

	getAddUserFormEndpoint() {
		const { organization } = this.state

		if (organization) {
			return apiEndpoints.GET_ORGANIZATION_ADD_USER_FORM.replace(':organizationId', organization.id)
		}

		return undefined
	}

	getUserEndpoint(userId) {
		if (userId) {
			return apiEndpoints.USER.replace(':userId', userId)
		}

		return undefined
	}

	getAddressesEndpoint() {
		const { organization } = this.state

		if (organization) {
			if (organization.address && organization.address.id) {
				return apiEndpoints.ADDRESS.replace(':addressId', organization.address.id)
			} else {
				return apiEndpoints.ADDRESSES
			}
		}

		return undefined
	}

	getAddressesMethod() {
		const { organization } = this.state

		if (organization && organization.address && organization.address.id) {
			return 'put'
		}

		return 'post'
	}

	switchEditMode(e) {
		if (e) {
			e.preventDefault()
		}

		const { isOrganizationManager, editMode } = this.state
		if (isOrganizationManager) {
			const newEditMode = !editMode

			if (true === newEditMode) {
				const organizationSchemaEndpoint = this.getOrganizationSchemaEndpoint()
				this.props.callApi(organizationSchemaEndpoint)
			}

			this.setState((state, props) => ({
				editMode: newEditMode,
			}))
		}
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			openAddUserPopin: false,
		}))
	}

	handleAddUserBtnClick(e) {
		e.preventDefault()

		// get add user schema
		const addUserFormEndpoint = this.getAddUserFormEndpoint()
		if (addUserFormEndpoint) {
			this.props.callApi(addUserFormEndpoint, 'get')

			// open popin
			this.setState((state, props) => ({
				openAddUserPopin: true,
			}))
		}
	}

	handleAddUserSubmit(formData) {
		const { organization } = this.state
		const selectedUser = formData.user
		const organizationIRI = `/api/organizations/${organization.id}`
		const userPutData = {
			organization: organizationIRI
		}
		const userEndpoint = this.getUserEndpoint(formData.user)

		this.props.callApi(userEndpoint, 'put', {
			body: JSON.stringify(userPutData)
		})

		this.setState((state, props) => ({
			selectedNewUserId: selectedUser,
			addUserSchema: undefined,
		}))
	}

	handleOrganizationSubmit(formData) {
		const { organization } = this.state
		const organizationEndpoint = this.getOrganizationSubmitEndpoint()
		const { address, logo } = formData

		delete formData['address']
		delete formData['logo']
		delete formData['address-city']
		delete formData['address-zipcode']
		delete formData['address-street']

		// create/update address
		if (address) {
			const addressesEndpoint = this.getAddressesEndpoint()
			const addressesMethod = this.getAddressesMethod()
			this.props.callApi(addressesEndpoint, addressesMethod, {
				body: JSON.stringify(address)
			})
		}

		// upload logo
		if (logo) {
			const logoFilename = `organization-${organization.id}-logo`
			this.props.upload(apiEndpoints.UPLOAD, logoFilename, logo)
		}

		this.props.callApi(organizationEndpoint, 'put', {
			body: JSON.stringify(formData)
		})
	}

	handleBackBtnClick() {
		this.props.history.push(reverse(routesList.projects.index))
	}

	render() {
		const {
			organization,
			isOrganizationManager,
			editMode,
			openAddUserPopin,
			addUserSchema,
			organizationSchema,
			didGetOrganization,
		} = this.state

		return (
			<div className="Organization">
				{!didGetOrganization && !organization &&
					<Spinner />
				}

				{didGetOrganization && !organization &&
					<CreateOrganization />
				}

				{organization &&
					<OrganizationDetail
						organization={organization}
						isEditMode={editMode}
						addUserCallback={this.handleAddUserBtnClick}
						switchEditModeCallback={this.switchEditMode}
						isOrganizationManager={isOrganizationManager}
						organizationSchema={organizationSchema}
						organizationSubmitCallback={this.handleOrganizationSubmit}
						backBtnCallback={this.handleBackBtnClick}
					/>
				}

				{organization && openAddUserPopin &&
					<Popin
						isOpened={openAddUserPopin}
						title={`Add User to ${organization.name}`}
						onClose={this.handleClosePopin}
					>
						<h2>{`Add User to ${organization.name}`}</h2>

						{!addUserSchema &&
							<Spinner />
						}

						{addUserSchema &&
							<Liform schema={addUserSchema} onSubmit={this.handleAddUserSubmit} />
						}
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
		uploadResponse,
		uploadedFiles
	} = state.api

	return { apiResponse, apiResponseEndpoint, uploadResponse, uploadedFiles }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		upload: (endpoint, fileName, file) => dispatch(upload(endpoint, fileName, file)),
		resetUploadResponse: () => dispatch(resetUploadResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationEdit)