const loginForm = {
	name: 'login',
	submitBtn: 'Connection',
	multipart: false,
	fields: [
		{
			type: 'text',
			props: {
				name: 'email',
				id: 'email',
				required: true,
				placeholder: 'loginFormPlaceholderEmail',
			},
		},
		{
			type: 'password',
			props: {
				name: 'password',
				id: 'password',
				required: true,
				placeholder: 'loginFormPlaceholderPassword',
			},
		},
	],
}

export default loginForm