import React from 'react'
import { Trans } from 'react-i18next'

import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import PartnersListItem from 'components/partners/PartnersListItem'
import TaskDetail from 'components/tasks/TaskDetail'

export const ProjectPartnerTasksDetail = (props) => {
	const {
		partner,
		project,
		projectTasks,
		currentProjectPipelineSection,
		taskDetailCloseCallback,
		partnerItemClickCallback,
		removePartnerCallback,
		signatureCallback,
		editTaskCallback,
		taskFileUploadCallback,
	} = props

	return (
		<div className="ProjectPartnerTasksDetail">
			<div className="Tasks__header">
				<ImageButton
					image={icons.arrow}
					altText='Back'
					onClick={taskDetailCloseCallback}
				/>

				<p className="Tasks__title">
					<Trans i18nKey="partnerTasksTitle" username={partner.user.username}>
						{{username: partner.user.username}}'s ongoing tasks
					</Trans>
				</p>
			</div>

			<div className="PartnersList--tiles">
				<div className="PartnersList__partners">
					<PartnersListItem
						project={project}
						projectTasks={projectTasks}
						partner={partner}
						widget='tiles'
						partnerItemClickCallback={partnerItemClickCallback}
						removePartnerCallback={removePartnerCallback}
						displayTasksCount={true}
					/>
				</div>
			</div>

			<div className="Tasks__list">
				{projectTasks && projectTasks.map((task, i) => {
					// check if task belong to user
					let isUserTask = false
					task.partners.map((taskPartner) => {
						if (taskPartner.user.id === partner.user.id) {
							isUserTask = true
						}

						return null
					})

					if (!isUserTask) {
						return null
					}

					// check task's project pipeline section
					if (
						currentProjectPipelineSection
						&& task.projectPipelineSection && task.projectPipelineSection.id
						&& task.projectPipelineSection.id !== currentProjectPipelineSection.id
					) {
						return null
					}

					return (
						<TaskDetail
							key={i}
							task={task}
							projectDetailCallback={taskFileUploadCallback}
							signatureCallback={signatureCallback}
							editTaskCallback={editTaskCallback}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default ProjectPartnerTasksDetail