import React from 'react'

import { media } from 'media'
import { ReactComponent as IconGroup } from 'assets/svg/icon-conversation-group.svg'

export const ConversationListItem = (props) => {
	const {
		conversation,
		listType,
		avatar,
		title,
		chatActiveConversation,
		onClick,
	} = props

	const conversationItemStyle = {
		backgroundImage: (avatar) ? `url(${media.getMediaUrl(avatar)})`: null,
	}

	let isActive = false
	if (chatActiveConversation && chatActiveConversation.id === conversation.id) {
		isActive = true
	}

	const alertClass = (!isActive && conversation.isAlert) ? ' has-texted' : ''
	const activeClass = (true === isActive) ? ' is-active' : ''

	return (
		<div className={`profile${alertClass}${activeClass}`} onClick={onClick}>
			{('chat' === listType || 'offers' === listType) &&
				<div className="profile__picture" style={conversationItemStyle}></div>
			}

			{'groups' === listType &&
				<div className="group__icon">
					<IconGroup />
				</div>
			}

			<p className="profile__name">
				{title}
			</p>
		</div>
	)
}

export default ConversationListItem