import ActionTypes from 'constants/action-types'
import ApiClient from 'api/ApiClient'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'

export function getNotifications() {
    return (dispatch) => {
        const endpointBuilder = new ApiEndpointBuilder()
    	const endpoint = endpointBuilder.getEndpoint('get_notifications')
        const apiClient = new ApiClient(process.env.REACT_APP_API_URL)

        apiClient.sendRequest(endpoint, 'get').then((apiResponse) => {
            dispatch(receiveNotifications(apiResponse))
        }).catch((exception) => dispatch(notificationsHasErrored(exception)))
    }
}

export function setReadNotifications(notificationIds) {
    return (dispatch) => {
        const endpointBuilder = new ApiEndpointBuilder()
        const endpoint = endpointBuilder.getEndpoint('set_notifications_read')
        const apiClient = new ApiClient(process.env.REACT_APP_API_URL)

        const body = {
            notificationIds
        }

        apiClient.sendRequest(endpoint, 'post', {body: JSON.stringify(body)}).then((apiResponse) => {
            dispatch(receiveSetNotificationsRead(apiResponse))
        }).catch((exception) => dispatch(notificationsHasErrored(exception)))
    }
}

export const receiveNotifications = (apiResponse) => {
	return {
		type: ActionTypes.NOTIFIER_GET_NOTIFICATIONS,
		apiResponse
	}
}

export const receiveSetNotificationsRead = (apiResponse) => {
    return {
        type: ActionTypes.NOTIFIER_SET_NOTIFICATIONS_READ,
        apiResponse
    }
}

export const notificationsHasErrored = (error) => {
    return {
        type: ActionTypes.NOTIFIER_ERROR,
        notificationsError: error
    }
}

export const resetNotifications = () => {
    return {
        type: ActionTypes.NOTIFIER_RESET
    }
}

export const displayNotificationsOverlay = (isNotificationsOverlayIsOpened) => {
    return {
        type: ActionTypes.NOTIFIER_DISPLAY_OVERLAY,
        isNotificationsOverlayIsOpened
    }
}