import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import routesList from 'routing/routes-list'
import AccessControl from 'auth/access-control'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const accessControl = new AccessControl()
    const isAuthenticated = accessControl.isAuthenticated()
    const { role } = rest
    const hasRouteRole = accessControl.hasRole(role)

    return (
        <Route {...rest}
            render={props =>
                isAuthenticated && hasRouteRole ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: routesList.login,
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute