import React from 'react'
import ReactMapboxGl, { Feature, Layer, Popup } from "react-mapbox-gl";

import MapPopup from 'components/map/MapPopup'
import markerIcon from 'assets/svg/icon-location-black.svg'

class MapBox extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			activeFeature: undefined,
			closedActiveFeature: undefined,
		}

		// bind methods
		this.handleFeatureClick = this.handleFeatureClick.bind(this)
		this.handleFeaturePopupClose = this.handleFeaturePopupClose.bind(this)

		// set private properties
		this.zoom = [5]

		// init MapBox API
		this.mapComponent = ReactMapboxGl({
			accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
			/*zoom: this.zoom,*/
		})
	}

	getActiveFeatureCoordinates() {
		const { activeFeature } = this.state

		let activeFeatureCoordinates = undefined
		if (activeFeature && activeFeature.properties && activeFeature.properties.coordinates) {
			const coordinatesObject = JSON.parse(activeFeature.properties.coordinates)
			activeFeatureCoordinates = {
				lng: coordinatesObject.longitude,
				lat: coordinatesObject.latitude,
			}
		}

		return activeFeatureCoordinates
	}

	getFilteredFeatures() {
		const { features } = this.props

		// filter features if coordinates are not defined
		return features.filter((feature, i) => {
			return (feature && feature.coordinates && typeof feature.coordinates.latitude !== 'undefined') ? true:false
		})
	}

	getMapCenter(filteredFeatures, activeFeature) {
		const { closedActiveFeature } = this.state
		const { mapType } = this.props

		let firstLatitude = 0
		let firstLongitude = 0

		const centerLatitudePadding = ('lands' === mapType) ? 0.1 : 0

		if (activeFeature && activeFeature.properties && activeFeature.properties.coordinates) {
			const coordinatesObject = JSON.parse(activeFeature.properties.coordinates)

			firstLatitude = coordinatesObject.latitude - centerLatitudePadding
			firstLongitude = coordinatesObject.longitude
		} else if (!closedActiveFeature && filteredFeatures) {
			filteredFeatures.map((feature, i) => {
				const hasLatitude = (feature && feature.coordinates && typeof feature.coordinates.latitude !== 'undefined') ? true:false

				if (hasLatitude && i === 0) {
					firstLatitude = feature.coordinates.latitude
					firstLongitude = feature.coordinates.longitude
				}

				return null
			})
		} else if (closedActiveFeature) {
			const coordinatesObject = JSON.parse(closedActiveFeature.properties.coordinates)

			firstLatitude = coordinatesObject.latitude - centerLatitudePadding
			firstLongitude = coordinatesObject.longitude
		}

		return [firstLongitude, firstLatitude]
	}

	getPopupCenter(feature) {
		if (feature.properties && feature.properties.coordinates) {
			const coordinatesObject = JSON.parse(feature.properties.coordinates)

			return [coordinatesObject.longitude, coordinatesObject.latitude]
		}

		return undefined
	}

	handleFeatureClick(mapEvent) {
		if (mapEvent && mapEvent.feature) {
			const { activeFeature } = this.state
			const property = (mapEvent.feature.properties) ? mapEvent.feature.properties : undefined

			let newActiveFeature = (mapEvent && mapEvent.feature) ? mapEvent.feature : undefined
			let closedActiveFeature = undefined

			// close popup if feature is already active
			if (
				activeFeature
				&& property
				&& activeFeature.properties.featureId === property.featureId
			) {
				newActiveFeature = undefined

				closedActiveFeature = activeFeature
			} else {
				// center map on popup
				const popupCenter = this.getPopupCenter(mapEvent.feature)
				if (popupCenter) {
					const flyToObject = {
						center: popupCenter,
					}

					mapEvent.map.flyTo(flyToObject)
				}
			}

			this.setState((state, props) => ({
				activeFeature: newActiveFeature,
				closedActiveFeature: closedActiveFeature,
			}))
		}
	}

	handleFeaturePopupClose() {
		this.setState((state, props) => ({
			activeFeature: undefined,
		}))
	}

	render() {
		const { activeFeature } = this.state
		const { mapType, mapHeight, featureClickCallback, collectionClickCallback } = this.props

		const Map = this.mapComponent

		// get features
		const filteredFeatures = this.getFilteredFeatures()

		// get map center
		const mapCenter = this.getMapCenter(filteredFeatures, activeFeature)

		// set custom marker
		const marker = new Image(14, 20)
		marker.src = markerIcon
		marker.class = 'Mapbox__marker'

		// get popup coordinates if defined
		const activeFeatureCoordinates = this.getActiveFeatureCoordinates()

		return (
			<div className="MapBox">
				<Map
					// eslint-disable-next-line
					style="mapbox://styles/mapbox/light-v10"
					containerStyle={{
						width: '100%',
						height: `${mapHeight}px`,
					}}
					center={mapCenter}
				>
					<Layer
						type="symbol"
						id="marker"
						layout={{ "icon-image": "customMarker" }}
						images={['customMarker', marker]}
					>
						{filteredFeatures && filteredFeatures.map((feature, i) => {
							const { latitude, longitude } = feature.coordinates

							return (
								<Feature
									key={i}
									coordinates={[longitude, latitude]}
									onMouseEnter={(param) => {
										param.map.getCanvas().style.cursor = 'pointer';
									}}
									onMouseLeave={(param) => {
										param.map.getCanvas().style.cursor = 'inherit';
									}}
									onClick={this.handleFeatureClick}
									properties={{...feature, featureId: feature.id}}
								/>
							)
						})}
					</Layer>

					{activeFeature && activeFeatureCoordinates &&
						<Popup
							coordinates={activeFeatureCoordinates}
							closeButton={true}
							closeOnClick={false}
							dynamicPosition={false}
							onClose={this.handleFeaturePopupClose}
							anchor="top"
							offsetTop={25}
						>
							<MapPopup
								mapType={mapType}
								feature={activeFeature}
								onClose={this.handleFeaturePopupClose}
								onFeatureClick={featureClickCallback}
								collectionClickCallback={collectionClickCallback}
							/>
						</Popup>
					}
				</Map>
			</div>
		)
	}
}

export default MapBox