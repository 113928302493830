import ActionTypes from 'constants/action-types'

const initialState = {}

const filtersReducer = (state = initialState, action) => {
	const {
        filterType,
        filterValues,
	} = action

	switch(action.type) {
		case ActionTypes.SET_FILTERS:
			const { filters } = state
			const newFilters = {
				...filters,
				[filterType]: filterValues,
			}

			return {
				...state,
				filters: newFilters,
			}

		default:
			return {...state}
	}
}

export default filtersReducer