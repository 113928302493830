import React from 'react'
import { withTranslation } from 'react-i18next'

import { AttachedLabelInput } from 'components/forms/inputs'

export const TextInput = (props) => {
	const { t, field, formData, onChange } = props

	let fieldProps = field.props
	if (
		field.props
		&& field.props.name
		&& formData
		&& (formData[field.props.name] || '' === formData[field.props.name])
	) {
		fieldProps = {
			...field.props,
			value: formData[field.props.name]
		}
	}

	const inputType = (field.type) ? field.type : 'text'

	const finalProps = {
		...fieldProps,
		placeholder: (fieldProps && fieldProps.placeholder) ? t(fieldProps.placeholder) : '',
	}

	if (field.attachLabel && true === field.attachLabel && field.label) {
		const attachedValue = (finalProps.value) ? finalProps.value : ''

		return (
			<AttachedLabelInput
				field={field}
				value={attachedValue}
				inputType={inputType}
				fieldProps={finalProps}
				formData={formData}
				onChange={onChange}
			/>
		)
	}

	return (
		<input
			type={inputType}
			className="FormInput TextInput"
			onChange={(e) => onChange(e, field)}
			{...finalProps}
		/>
	)
}

export default withTranslation()(TextInput)