import React from 'react'

export const CarouselImage = (props) => {
	const { imageUrl, isActive } = props
	const imageClass = `CarouselImage${(isActive) ? '--active' : ''}`
	const backgroundStyle = {
		backgroundImage: `url(${imageUrl})`
	}

	return (
		<div className={imageClass} style={backgroundStyle}></div>
	)
}

export default CarouselImage