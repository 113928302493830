export const notificationTypes = {
	NOTIFICATION_TYPE_PROJECT_CREATED: 'project.created',
	NOTIFICATION_TYPE_PROJECT_APPROVED: 'project.approved',
	NOTIFICATION_TYPE_PROJECT_DENIED: 'project.denied',
	NOTIFICATION_TYPE_LAND_CREATED: 'land.created',
	NOTIFICATION_TYPE_LAND_APPROVED: 'land.approved',
	NOTIFICATION_TYPE_LAND_DENIED: 'land.denied',
	NOTIFICATION_TYPE_NEW_ORGANIZATION_REQUEST: 'organization_request.new',
	NOTIFICATION_TYPE_TASK_CREATED: 'task.created',
	NOTIFICATION_TYPE_TASK_SIGNED: 'task.signed',
	NOTIFICATION_TYPE_OFFER_CREATED: 'offer.created',
	NOTIFICATION_TYPE_OFFER_APPROVED: 'offer.approved',
	NOTIFICATION_TYPE_OFFER_DENIED: 'offer.denied',
	NOTIFICATION_TYPE_OFFER_QUOTE_CREATED: 'offer_quote.created',
	NOTIFICATION_TYPE_OFFER_QUOTE_APPROVED: 'offer_quote.approved',
	NOTIFICATION_TYPE_OFFER_QUOTE_DENIED: 'offer_quote.denied',
}

export default notificationTypes