import React from 'react'
import { connect } from 'react-redux'
import Liform from 'liform-react'
import { reverse } from 'named-urls'
import HelloSign from 'hellosign-embedded'

import { callApi, upload, resetApiResponse, resetUploadResponse } from 'actions/api'
import { setCenterClass } from 'actions/layout'
import { resetTemporaryData } from 'actions/temporary-data'
import AccessControl from 'auth/access-control'
import ApiClient from 'api/ApiClient'
import apiEndpoints from 'constants/api-endpoints'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import AssignTaskPopin from 'components/partners/AssignTaskPopin'
import projectStatuses from 'constants/project-statuses'
import FileUpload from 'components/forms/inputs/FileUpload'
import offerActionTypes from 'constants/offer-action-types'
import offerStatuses from 'constants/offer-statuses'
import ProjectPartnersFormTheme from 'components/projects/ProjectPartnersFormTheme'
import ProjectDetailTabs from 'components/projects/ProjectDetailTabs'
import Popin from 'components/layout/Popin'
import routesList from 'routing/routes-list'
import UbikonForm from 'components/forms/UbikonForm'
import CreateProjectBucket from 'components/projects/CreateProjectBucket'
import Spinner from 'components/spinner/Spinner'
import TaskPopinForm from 'components/tasks/TaskPopinForm'
import fileFolderForm from 'components/forms/schemas/fileFolderForm'
import ProjectHeader from 'components/projects/ProjectHeader'

class ProjectDetail extends React.Component {
	constructor(props) {
		super(props)

		const notificationOffer = new URL(window.location.href).searchParams.get('o')
		const isDashboardActive = (notificationOffer && notificationOffer.length > 0) ? false : true
		const isOffersActive = (notificationOffer && notificationOffer.length > 0) ? true : false

		// set state
		this.state = {
			project: undefined,
			projectTasks: undefined,
			originalTasks: undefined,
			currentProjectPipelineSection: undefined,
			completingProjectPipelineSection: undefined,
			userOfferRequests: undefined,
			openPartnerPopin: false,
			partnerFormSchema: undefined,
			openTaskPopin: false,
			taskFormSchema: undefined,
			selectedTaskId: undefined,
			displayOfferForm: false,
			activeOfferAction: undefined,
			openOfferMessagePopin: false,
			offerMessageSchema: undefined,
			openTaskFileUploadPopin: false,
			taskFileUploadSchema: undefined,
			openOfferRequestPopin: false,
			openBucketDropdown: false,
			deletingPartnerId: undefined,
			tabs: [{
				name: 'projectDetailTabDashboard',
				slug: 'dashboard',
				active: isDashboardActive,
			}, {
				name: 'projectDetailTabOffers',
				slug: 'offers',
				active: isOffersActive,
			}, {
				name: 'projectDetailTabFiles',
				slug: 'files',
				active: false,
			}, {
				name: 'projectDetailTabPlanning',
				slug: 'planning',
				active: false,
			}, {
				name: 'projectDetailTabFinancialAnalysis',
				slug: 'financial-analysis',
				active: false,
			}],
			buckets: undefined,
			selectedBucket: undefined,
			openPropertyFormPopin: false,
			propertyForm: undefined,
			signaturePopinTaskFile: undefined,
			editedTask: undefined,
			activeTaskPartner: undefined,
			openFileFolderPopin: false,
			openUploadFilePopin: false,
			projectFileUploadForm: undefined,
			openEditProjectPopin: false,
			isUpdatingTasks: false,
			projectTypes: undefined,
			isCreatingProject: false,
			openAssignTaskPopin: false,
			assignTaskPartner: undefined,
		}

		// bind methods
		this.changeTab = this.changeTab.bind(this)
		this.handleTabClick = this.handleTabClick.bind(this)

		this.handleAddPartnerClick = this.handleAddPartnerClick.bind(this)
		this.handlePartnerSubmit = this.handlePartnerSubmit.bind(this)
		this.handlePartnerClick = this.handlePartnerClick.bind(this)

		this.handleClosePopin = this.handleClosePopin.bind(this)

		this.handleAddTaskClick = this.handleAddTaskClick.bind(this)
		this.handleTaskSubmit = this.handleTaskSubmit.bind(this)
		this.handleTaskFileUpload = this.handleTaskFileUpload.bind(this)
		this.taskFileUploadCallback = this.taskFileUploadCallback.bind(this)
		this.handleEditTaskClick = this.handleEditTaskClick.bind(this)

		this.handleRequestBtnClick = this.handleRequestBtnClick.bind(this)
		this.handleProjectDocumentCheckbox = this.handleProjectDocumentCheckbox.bind(this)
		this.handleProjectDocumentFileUpload = this.handleProjectDocumentFileUpload.bind(this)
		this.handleProjectDocumentSignatureClick = this.handleProjectDocumentSignatureClick.bind(this)

		this.handleAddOfferClick = this.handleAddOfferClick.bind(this)
		this.handleOfferSubmit = this.handleOfferSubmit.bind(this)
		this.handleOfferDetailAction = this.handleOfferDetailAction.bind(this)
		this.handleOfferMessageSubmit = this.handleOfferMessageSubmit.bind(this)
		this.handleOfferBackClick = this.handleOfferBackClick.bind(this)

		this.handleCreateOfferRequestClick = this.handleCreateOfferRequestClick.bind(this)
		this.handleRequestOfferSubmit = this.handleRequestOfferSubmit.bind(this)

		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)

		this.handleBucketClick = this.handleBucketClick.bind(this)
		this.handleBucketChange = this.handleBucketChange.bind(this)

		this.handleUploadFileClick = this.handleUploadFileClick.bind(this)
		this.handleDownloadDocumentClick = this.handleDownloadDocumentClick.bind(this)
		this.handleDownloadFolderClick = this.handleDownloadFolderClick.bind(this)
		this.handleApproveProjectClick = this.handleApproveProjectClick.bind(this)

		this.handleRemovePartnerClick = this.handleRemovePartnerClick.bind(this)
		this.handleAssignTaskToPartnerClick = this.handleAssignTaskToPartnerClick.bind(this)
		this.handleSaveAssignTasks = this.handleSaveAssignTasks.bind(this)
		this.handlePipelineSectionClick = this.handlePipelineSectionClick.bind(this)
		this.handleCompleteSectionClick = this.handleCompleteSectionClick.bind(this)
		this.handleClickOutsideBucketList = this.handleClickOutsideBucketList.bind(this)
		this.handleCreateProjectBucket = this.handleCreateProjectBucket.bind(this)
		this.handlePropertySubmit = this.handlePropertySubmit.bind(this)

		this.handleNewFileFolder = this.handleNewFileFolder.bind(this)
		this.handleFileFolderSubmit = this.handleFileFolderSubmit.bind(this)
		this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this)

		this.handleEditProjectBtnClick = this.handleEditProjectBtnClick.bind(this)
		this.handleEditProjectSubmit = this.handleEditProjectSubmit.bind(this)

		// set private properties
		this.projectId = this.props.match.params.projectId
		this.temporaryDataKey = `tmp-project-${this.projectId}`
		this.apiClient = new ApiClient()
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const { temporaryData } = this.props

		// remove temporary data
		this.props.resetTemporaryData(this.temporaryDataKey)

		// set center class (no padding for center column)
		this.props.setCenterClass('no-padding no-border')

		if (typeof temporaryData[this.temporaryDataKey] !== 'undefined') {
			this.setState((state, props) => ({
				project: temporaryData[this.temporaryDataKey]
			}))
		}

		// get project
		const projectEndpoint = this.getProjectEndpoint()
		this.props.callApi(projectEndpoint, 'get')

		// get user's offer requests
		this.getUserOfferRequests()

		// get project buckets
		const projectBucketsEndpoint = this.endpointBuilder.getEndpoint('project_buckets', {projectId: this.projectId})
		this.props.callApi(projectBucketsEndpoint, 'get')

		// get project types
		const projectTypesEndpoint = this.endpointBuilder.getEndpoint('project_types')
		this.props.callApi(projectTypesEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			project,
			activeOfferAction,
			selectedTaskId,
			deletingPartnerId,
			completingProjectPipelineSection,
			originalTasks,
			signaturePopinTaskFile,
			currentProjectPipelineSection,
			editedTask,
			isCreatingProject,
		} = this.state

		const {
			apiResponse,
			apiResponseEndpoint,
			uploadResponse,
			uploadedFiles,
			uploadEndpoint
		} = this.props

		const projectEndpoint = this.getProjectEndpoint()
		const projectsEndpoint = this.endpointBuilder.getEndpoint('projects')
		const projectPartnerFormEndpoint = this.getPartnerFormEndpoint()
		const offerEndpoint = this.getOfferEndpoint(activeOfferAction)
		const offerMsgEndpoint = this.getOfferMessageFormEndpoint(activeOfferAction)
		const taskFormEndpoint = this.getTaskFormEndpoint()
		const taskFileUploadFormEndpoint = this.getTaskFileUploadFormEndpoint(selectedTaskId)
		const taskFileUploadEndpoint = this.getTaskFileUploadEndpoint(selectedTaskId)
		const tasksEndpoint = this.getTasksEndpoint()
		const editTaskFormEndpoint = (editedTask) ? `${this.getTaskFormEndpoint()}?taskId=${editedTask.id}` : undefined
		const requestOfferSchemaEndpoint = this.getRequestOfferSchemaEndpoint()
		const postOfferRequestEndpoint = this.getNewRequestOfferEndpoint()
		const partnerOfferRequestEndpoint = this.getPartnerOfferRequestEndpoint()
		const approveEndpoint = this.endpointBuilder.getEndpoint('project', { projectId: this.projectId})
		const removePartnerEndpoint = (deletingPartnerId) ? this.endpointBuilder.getEndpoint('partner', { partnerId: deletingPartnerId }) : undefined
		const projectBucketsEndpoint = this.endpointBuilder.getEndpoint('project_buckets', {projectId: this.projectId})
		const propertyFormEndpoint = this.endpointBuilder.getEndpoint('property_form')
		const postPropertyEndpoint = this.endpointBuilder.getEndpoint('property_post')
		const signatureUrlEndpoint = (signaturePopinTaskFile) ? this.endpointBuilder.getEndpoint('task_signature_url', {taskFileId: signaturePopinTaskFile.id}) : undefined
		const newFileFolderEndpoint = this.endpointBuilder.getEndpoint('file_folders')
		const projectTypesEndpoint = this.endpointBuilder.getEndpoint('project_types')

		let projectFileUploadEndpoint = undefined
		if (currentProjectPipelineSection) {
			projectFileUploadEndpoint = this.endpointBuilder.getEndpoint('file_folders_get_upload_form', {
				projectPipelineSectionId: currentProjectPipelineSection.id,
			})
		}

		let postProjectFileEndpoint = undefined
		if (currentProjectPipelineSection) {
			postProjectFileEndpoint = this.endpointBuilder.getEndpoint('post_project_file', {
				projectPipelineSectionId: currentProjectPipelineSection.id,
			})
		}

		let projectEditFormEndpoint = undefined
		let editProjectEndpoint = undefined

		if (project) {
			projectEditFormEndpoint = this.endpointBuilder.getEndpoint('get_project_edit_form', {
				id: project.id,
			})

			editProjectEndpoint = this.endpointBuilder.getEndpoint('post_edit_project', {
				id: project.id,
			})
		}

		// get tasks on pipeline section change
		if (
			prevState.currentProjectPipelineSection !== currentProjectPipelineSection
			&& tasksEndpoint
		) {
			this.props.callApi(tasksEndpoint)

			this.setState((state, props) => ({
				isUpdatingTasks: true,
			}))
		}

		if (apiResponse) {
			this.props.resetApiResponse()

			// project API response
			if (apiResponseEndpoint === projectEndpoint) {
				let pps = this.getCurrentProjectPipelineSection(apiResponse)
				const notificationPpsId = new URL(window.location.href).searchParams.get('pps')

				if (
					null !== notificationPpsId
					&& typeof project === 'undefined'
					&& apiResponse.projectPipelineSections
				) {
					apiResponse.projectPipelineSections.map((projectPipelineSection, iPPS) => {
						if (parseInt(projectPipelineSection.id) === parseInt(notificationPpsId)) {
							pps = projectPipelineSection
						}

						return false
					})
				}

				this.setState((state, props) => ({
					project: apiResponse,
					currentProjectPipelineSection: pps,
				}))
			}

			// project tasks API response
			if (apiResponseEndpoint && tasksEndpoint && apiResponseEndpoint === tasksEndpoint) {
				const newOriginalTasks = (typeof originalTasks === 'undefined' && apiResponse.length > 0) 
					? apiResponse : originalTasks

				const notificationTaskId = new URL(window.location.href).searchParams.get('t')

				let newTasks = apiResponse

				if (null !== notificationTaskId) {
					newTasks = apiResponse.map((task, iT) => {
						const isActiveNotification = (parseInt(task.id) === parseInt(notificationTaskId))

						return {
							...task,
							isActiveNotification: isActiveNotification
						}
					})
				}

				this.setState((state, props) => ({
					projectTasks: newTasks,
					originalTasks: newOriginalTasks,
					isUpdatingTasks: false,
				}))
			}

			// edit task form API reponse
			if (apiResponse.model && apiResponseEndpoint === editTaskFormEndpoint) {
				this.props.resetApiResponse()

				this.setState((state, props) => ({
					taskFormSchema: apiResponse.model
				}))
			}

			// partner form API response
			if (apiResponse.model && apiResponseEndpoint === projectPartnerFormEndpoint) {
				this.props.resetApiResponse()

				this.setState((state, props) => ({
					partnerFormSchema: apiResponse.model
				}))
			}

			// new partner API response
			if (apiResponseEndpoint === apiEndpoints.POST_PROJECT_PARTNER) {
				this.props.resetApiResponse()

				// reset project data
				this.props.callApi(projectEndpoint, 'get')

				this.setState((state, props) => ({
					openPartnerPopin: false
				}))
			}

			// task form API response
			if (apiResponse.model && apiResponseEndpoint === taskFormEndpoint) {
				this.props.resetApiResponse()

				this.setState((state, props) => ({
					taskFormSchema: apiResponse.model
				}))
			}

			// new task API response
			if (apiResponseEndpoint === apiEndpoints.POST_PROJECT_TASK) {
				this.props.resetApiResponse()

				// refresh tasks data
				this.props.callApi(tasksEndpoint)

				this.setState((state, props) => ({
					openTaskPopin: false,
					projectTasks: undefined,
					taskFormSchema: undefined,
					editedTask: undefined,
					isUpdatingTasks: true,
				}))
			}

			// task signature URL response
			if (signatureUrlEndpoint && apiResponseEndpoint === signatureUrlEndpoint) {
				this.props.resetApiResponse()

				// open HelloSign client
				const helloSignClient = new HelloSign({
					clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
				})

				const isTestMode = (process.env.REACT_APP_HELLO_SIGN_TEST === 'true')

				helloSignClient.open(apiResponse.url, {
					skipDomainVerification: isTestMode
				})

				// on sign, set signature as signed
				helloSignClient.on('sign', (data) => {
					const taskSignatureEndpoint = this.endpointBuilder.getEndpoint('set_task_signature_data', {
						taskId: data.signatureId
					})

					this.props.callApi(taskSignatureEndpoint, 'post')

					this.setState((state, props) => ({
						signaturePopinTaskFile: undefined
					}))
				})
			}

			// task was marked as signed
			if (apiResponse.signedTaskSignatureId) {
				this.props.resetApiResponse()

				// refresh tasks data
				this.props.callApi(tasksEndpoint)

				this.setState((state, props) => ({
					projectTasks: undefined,
					isUpdatingTasks: true,
				}))
			}

			// task file upload form API response
			if (apiResponseEndpoint === taskFileUploadFormEndpoint) {
				this.props.resetApiResponse()

				this.setState((state, props) => ({
					taskFileUploadSchema: apiResponse.model
				}))
			}

			// new offer API response
			if (apiResponseEndpoint === apiEndpoints.POST_PROJECT_OFFER) {
				this.props.resetApiResponse()

				// reset project endpoint
				this.props.callApi(projectEndpoint, 'get')

				// remove offer form
				this.setState((state, props) => ({
					displayOfferForm: false
				}))
			}

			// update offer API response
			if (apiResponseEndpoint === offerEndpoint) {
				this.props.resetApiResponse()

				// reset active offer action
				this.setState((state, props) => ({
					activeOfferAction: undefined
				}))

				// reset project data
				this.props.callApi(projectEndpoint)
			}

			// offer message form API response
			if (apiResponseEndpoint === offerMsgEndpoint) {
				this.props.resetApiResponse()

				// open offer message popin and set form schema
				this.setState((state, props) => ({
					offerMessageSchema: apiResponse.model
				}))
			}

			// offer request form API response
			if (apiResponseEndpoint === requestOfferSchemaEndpoint) {
				this.props.resetApiResponse()

				// set offer request schema
				this.setState((state, props) => ({
					requestOfferSchema: apiResponse.model
				}))
			}

			// post offer request API response
			if (apiResponseEndpoint === postOfferRequestEndpoint) {
				this.props.resetApiResponse()

				// close offer request popin
				this.setState((state, props) => ({
					openOfferRequestPopin: false,
					requestOfferSchema: undefined,
				}))

				// reset project data
				this.props.callApi(projectEndpoint)
			}

			// get user's offer requests
			if (apiResponseEndpoint === partnerOfferRequestEndpoint) {
				this.props.resetApiResponse()

				this.setState((state, props) => ({
					userOfferRequests: apiResponse
				}))
			}

			// approve project API response
			if (
				project
				&& apiResponseEndpoint === approveEndpoint
				&& apiResponse.status !== project.status
			) {
				// refresh page
				window.location.reload()
			}

			// remove partner API response
			if (apiResponseEndpoint === removePartnerEndpoint) {
				// reset project data
				this.props.callApi(projectEndpoint)
			}

			// complete project pipeline section API response
			if (typeof completingProjectPipelineSection !== 'undefined') {
				const ppsEndpoint = this.endpointBuilder.getEndpoint('project_pipeline_section', {id: completingProjectPipelineSection.id})
				if (apiResponseEndpoint === ppsEndpoint) {
					// reset project data
					this.props.callApi(projectEndpoint)

					// refresh tasks data
					this.props.callApi(tasksEndpoint)

					this.setState((state, props) => ({
						completingProjectPipelineSection: undefined
					}))
				}
			}

			// get buckets API response
			if (apiResponseEndpoint === projectBucketsEndpoint) {
				this.setState((state, props) => ({
					buckets: apiResponse.buckets
				}))
			}

			// get property form API response
			if (apiResponseEndpoint === propertyFormEndpoint) {
				this.setState((state, props) => ({
					propertyForm: apiResponse.model
				}))
			}

			// post property API response
			if (apiResponseEndpoint === postPropertyEndpoint) {
				// reset project data
				this.props.callApi(projectEndpoint)

				this.setState((state, props) => ({
					propertyForm: undefined,
					openPropertyFormPopin: false,
				}))
			}

			// new file folder API response
			if (apiResponseEndpoint === newFileFolderEndpoint) {
				// reload files list
				const fileFoldersEndpoint = this.getFileFoldersEndpoint()
				this.props.callApi(fileFoldersEndpoint, 'get')

				this.setState((state, props) => ({
					openFileFolderPopin: false,
				}))
			}

			// get project file upload form response
			if (apiResponseEndpoint === projectFileUploadEndpoint) {
				this.setState((state, props) => ({
					projectFileUploadForm: apiResponse.model,
				}))
			}

			// did upload project file
			if (apiResponseEndpoint === postProjectFileEndpoint) {
				// reload files list
				const fileFoldersEndpoint = this.getFileFoldersEndpoint()
				this.props.callApi(fileFoldersEndpoint, 'get')

				this.setState((state, props) => ({
					openUploadFilePopin: false,
					projectFileUploadForm: undefined,
				}))
			}

			// get project edit form
			if (apiResponseEndpoint === projectEditFormEndpoint) {
				this.setState((state, props) => ({
					editProjectForm: apiResponse.model,
				}))
			}

			if (apiResponseEndpoint === editProjectEndpoint) {
				// reset project data
				this.props.callApi(projectEndpoint)

				this.setState((state, props) => ({
					openEditProjectPopin: false,
					editProjectForm: undefined,
				}))
			}

			// did get project types
			if (apiResponseEndpoint === projectTypesEndpoint) {
				this.setState((state, props) => ({
					projectTypes: apiResponse,
				}))
			}

			// did create project
			if (isCreatingProject && apiResponseEndpoint === projectsEndpoint) {
				this.setState((state, props) => ({
					isCreatingProject: false,
				}))

				// redirect to project
				const bucketProjectUrl = reverse(routesList.projects.detail.index, { projectId: apiResponse.id })
				window.location.href = bucketProjectUrl
			}
		}

		// task file upload API response
		if (uploadResponse && uploadedFiles && uploadEndpoint === taskFileUploadEndpoint) {
			this.props.resetUploadResponse()

			// reset project data
			this.props.callApi(projectEndpoint)

			// close task file upload popin
			this.setState((state, props) => ({
				openTaskFileUploadPopin: false
			}))
		}
	}

	componentWillUnmount() {
		this.props.setCenterClass(undefined)
	}

	getProjectEndpoint() {
		return `${apiEndpoints.GET_PROJECT}${this.projectId}`
	}

	getTasksEndpoint() {
		const { project } = this.state
		const projectPipelineSection = this.getCurrentProjectPipelineSection(project)

		if (typeof projectPipelineSection !== 'undefined') {
			return this.endpointBuilder.getEndpoint('get_project_tasks', {
				projectId: project.id,
				projectPipelineSectionId: projectPipelineSection.id
			})
		}

		return undefined
	}

	getPartnerFormEndpoint() {
		return apiEndpoints.GET_PROJECT_PARTNER_FORM.replace(':projectId', this.projectId)
	}

	getTaskFormEndpoint() {
		const { project } = this.state
		const projectPipelineSection = this.getCurrentProjectPipelineSection(project)

		if (typeof projectPipelineSection !== 'undefined') {
			return apiEndpoints.GET_PROJECT_TASK_FORM
				.replace(':projectId', this.projectId)
				.replace(':projectPipelineSectionId', projectPipelineSection.id)
		}
	}

	getTaskFileUploadFormEndpoint(taskId) {
		if (typeof taskId !== 'undefined') {
			return apiEndpoints.GET_TASK_FILE_UPLOAD_FORM.replace(':taskId', taskId)
		}

		return undefined
	}

	getOfferEndpoint(offer) {
		if (typeof offer === 'undefined') {
			return undefined
		}

		return apiEndpoints.OFFER_PUT.replace(':offerId', offer.id)
	}

	getOfferMessageFormEndpoint(offer) {
		if (typeof offer === 'undefined') {
			return undefined
		}

		return apiEndpoints.GET_OFFER_MESSAGE_FORM.replace(':offerId', offer.id)
	}

	getTaskFileUploadEndpoint(taskId) {
		return apiEndpoints.UPLOAD_TASK_FILE.replace(':taskId', taskId)
	}

	getRequestOfferSchemaEndpoint() {
		return apiEndpoints.GET_REQUEST_OFFER_FORM
	}

	getNewRequestOfferEndpoint() {
		return apiEndpoints.OFFER_REQUESTS
	}

	getPartnerOfferRequestEndpoint() {
		return apiEndpoints.OFFER_REQUESTS
	}

	getFileFoldersEndpoint() {
		const { currentProjectPipelineSection } = this.state
		if (!currentProjectPipelineSection) {
			return undefined
		}

		return `${this.endpointBuilder.getEndpoint('file_folders')}?projectPipelineSection=${currentProjectPipelineSection.id}`
	}

	getCurrentProjectPipelineSection(project) {
		const { currentProjectPipelineSection } = this.state

		if (typeof currentProjectPipelineSection !== 'undefined') {
			return currentProjectPipelineSection
		}

		let currentPps = undefined

		if (project && project.projectPipelineSections) {
			project.projectPipelineSections.map((pps, i) => {
				const position = parseInt(pps.pipelineSection.position)

				if (
					0 !== position
					&& false !== pps.enabled
					&& (false === pps.isComplete || null === pps.isComplete)
					&& projectStatuses.PROJECT_STATUS_PENDING !== project.status
					&& typeof currentPps === 'undefined'
				) {
					currentPps = pps
				}

				return false
			})

			// default : return first section
			if (typeof currentPps === 'undefined') {
				project.projectPipelineSections.map((pps, i) => {
					const position = parseInt(pps.pipelineSection.position)

					if (
						0 !== position
						&& false !== pps.enabled
						&& typeof currentPps === 'undefined'
					) {
						currentPps = pps
					}

					return false
				})
			}
		}

		return currentPps
	}

	getUserOfferRequests() {
		const partnerOfferRequestEndpoint = this.getPartnerOfferRequestEndpoint()
		this.props.callApi(partnerOfferRequestEndpoint, 'get')
	}

	getActiveBucket() {
		const { project, buckets, selectedBucket } = this.state
		let activeBucket = undefined

		if (project && buckets) {
			buckets.map((bucket, i) => {
				if (typeof selectedBucket !== 'undefined') {
					activeBucket = selectedBucket
				} else if (bucket.slug === project.bucket.slug) {
					activeBucket = bucket
				}

				return null
			})
		}

		return activeBucket
	}

	changeTab(tabSlug) {
		const { tabs } = this.state
		const newTabs = tabs.map((tab, i) => {
			const isTabActive = (tabSlug === tab.slug) ? true: false
			return {
				...tab,
				active: isTabActive,
			}
		})

		this.setState((state, props) => ({
			tabs: newTabs
		}))
	}

	handleTabClick(e, selectedTab) {
		e.preventDefault()

		this.changeTab(selectedTab.slug)

		this.setState((state, props) => ({
			displayOfferForm: false,
		}))
	}

	handleAddPartnerClick(e, project) {
		e.preventDefault()

		// get partner form
		const partnerFormEndpoint = this.getPartnerFormEndpoint()
		this.props.callApi(partnerFormEndpoint, 'get')

		this.setState((state, props) => ({
			openPartnerPopin: true
		}))
	}

	handlePartnerSubmit(formData) {
		const { project } = this.state
		const projectPipelineSection = this.getCurrentProjectPipelineSection(project)

		const normalizedPartnerData = this.apiClient.normalizeRequest(formData, [{
			name: 'partnerType',
			path: '/api/partner_types/{id}'
		}, {
			name: 'user',
			path: '/api/users/{id}'
		}])

		const finalPartnerData = {
			...normalizedPartnerData,
			project: `/api/projects/${this.projectId}`,
			projectPipelineSection: `/api/project_pipeline_sections/${projectPipelineSection.id}`,
		}

		this.props.callApi(apiEndpoints.POST_PROJECT_PARTNER, 'post', {
			body: JSON.stringify(finalPartnerData)
		})

		this.setState((state, props) => ({
			partnerFormSchema: undefined
		}))
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			openPartnerPopin: false,
			openTaskPopin: false,
			openOfferMessagePopin: false,
			openTaskFileUploadPopin: false,
			openOfferRequestPopin: false,
			openPropertyFormPopin: false,
			openFileFolderPopin: false,
			openUploadFilePopin: false,
			openEditProjectPopin: false,
			openAssignTaskPopin: false,
			editedTask: undefined,
			taskFormSchema: undefined,
			projectFileUploadForm: undefined,
			assignTaskPartner: undefined,
		}))
	}

	handleAddTaskClick(e) {
		e.preventDefault()

		// get task schema
		const taskFormEndpoint = this.getTaskFormEndpoint()
		this.props.callApi(taskFormEndpoint, 'get')

		// open task popin
		this.setState((state, props) => ({
			openTaskPopin: true,
		}))
	}

	handleTaskSubmit(formData, formFiles) {
		const taskData = {
			...formData,
			taskFiles: formFiles.taskFiles
		}

		this.props.callApi(apiEndpoints.POST_PROJECT_TASK, 'post', {
			body: JSON.stringify(taskData),
		})
	}

	handleRequestBtnClick(e) {
		e.preventDefault()
		this.changeTab('offers')
	}

	handleProjectDocumentCheckbox(isChecked, taskId) {
		const { project } = this.state
		const { tasks } = project
		const newTasks = tasks.map((task, i) => {
			const taskIsChecked = (parseInt(task.id) === parseInt(taskId)) ? isChecked: task.isChecked

			return {
				...task,
				isChecked: taskIsChecked
			}
		})

		const newProject = {
			...project,
			tasks: newTasks,
		}

		this.setState((state, props) => ({
			project: newProject,
			selectedTaskId: taskId
		}))
	}

	handleProjectDocumentFileUpload(e) {
		e.preventDefault()

		const { selectedTaskId } = this.state

		if (parseInt(selectedTaskId) > 0) {
			// get file upload schema
			const taskFileUploadFormEndpoint = this.getTaskFileUploadFormEndpoint(selectedTaskId)
			this.props.callApi(taskFileUploadFormEndpoint, 'get')

			// open file upload popin
			this.setState((state, props) => ({
				openTaskFileUploadPopin: true
			}))
		}
	}

	handleProjectDocumentSignatureClick(task, taskFile) {
		// get signature url
		const signatureUrlEndpoint = this.endpointBuilder.getEndpoint('task_signature_url', {
			taskFileId: taskFile.id
		})

		this.props.callApi(signatureUrlEndpoint, 'get')

		this.setState((state, props) => ({
			signaturePopinTaskFile: taskFile,
		}));
	}

	handleAddOfferClick(e) {
		e.preventDefault()

		this.setState((state, props) => ({
			displayOfferForm: true
		}))
	}

	handleOfferSubmit(formData, formFiles) {
		const offerData = {
			...formData,
			files: formFiles.files
		}

		// TODO: handle new offer
		this.props.callApi(apiEndpoints.POST_PROJECT_OFFER, 'post', {
			body: JSON.stringify(offerData)
		})
	}

	handleOfferDetailAction(e, offer, actionType) {
		e.preventDefault()

		const offerEndpoint = this.getOfferEndpoint(offer)
		const createdBy = this.apiClient.getIRI(apiEndpoints.USER, ':userId', offer.createdBy.id)
		const conversation = (null !== offer.conversation) ? this.apiClient.getIRI(apiEndpoints.GET_CONVERSATION, ':conversationId', offer.conversation.id): undefined

		const newOfferData = {
			...offer,
			createdBy,
			conversation
		}

		// deny offer
		if (offerActionTypes.DENY === actionType) {
			const denyOfferData = {
				...newOfferData,
				status: offerStatuses.DENIED,
			}

			this.props.callApi(offerEndpoint, 'put', {
				body: JSON.stringify(denyOfferData)
			})
		}

		// send message
		if (offerActionTypes.MESSAGE === actionType) {
			// get offer message form
			const offerMsgEndpoint = this.getOfferMessageFormEndpoint(offer)
			this.props.callApi(offerMsgEndpoint, 'get')

			// open popin
			this.setState((state, props) => ({
				openOfferMessagePopin: true,
				offerMessageSchema: undefined,
			}))
		}

		// approve offer
		if (offerActionTypes.APPROVE === actionType) {
			const approveOfferData = {
				...newOfferData,
				status: offerStatuses.APPROVED,
			}

			this.props.callApi(offerEndpoint, 'put', {
				body: JSON.stringify(approveOfferData)
			})
		}

		// set active offer action
		this.setState((state, props) => ({
			activeOfferAction: offer
		}))
	}

	handleOfferBackClick() {
		this.setState((state, props) => ({
			displayOfferForm: false,
		}))
	}

	handleOfferMessageSubmit(formData) {
		const newMessageData = this.apiClient.normalizeRequest(formData, [{
			name: 'conversation',
			path: '/api/conversations/{id}'
		}])

		this.props.callApi(apiEndpoints.POST_MESSAGE, 'post', {
			body: JSON.stringify(newMessageData)
		})

		this.setState((state, props) => ({
			openOfferMessagePopin: false,
		}))
	}

	handleTaskFileUpload(e, schema, selectedFiles) {
		// upload to Amazon S3 and associate task to file
		const { selectedTaskId } = this.state

		if (typeof selectedTaskId !== 'undefined') {
			const taskFileUploadEndpoint = this.getTaskFileUploadEndpoint(selectedTaskId)

			console.log('selectedFiles', selectedFiles)

			this.props.upload(taskFileUploadEndpoint, selectedFiles)

			// remove upload form schema
			this.setState((state, props) => ({
				taskFileUploadSchema: undefined,
			}))
		}
	}

	handleTaskFilesCountClick(e, task) {
		e.preventDefault()

		console.log('handleTaskFilesCountClick', task)

		// TODO: set selected task to filter files

		this.changeTab('files')
	}

	handleCreateOfferRequestClick(e) {
		e.preventDefault()

		// get request offer form
		const endpoint = this.getRequestOfferSchemaEndpoint()
		this.props.callApi(endpoint, 'get')

		this.setState((state, props) => ({
			openOfferRequestPopin: true,
		}))
	}

	handleRequestOfferSubmit(formData) {
		const newOfferRequestData = this.apiClient.normalizeRequest(formData, [{
			name: 'partner',
			path: '/api/partners/{id}'
		}, {
			name: 'projectPipelineSection',
			path: '/api/project_pipeline_sections/{id}'
		}])

		const postOfferRequestEndpoint = this.getNewRequestOfferEndpoint()

		this.props.callApi(postOfferRequestEndpoint, 'post', {
			body: JSON.stringify(newOfferRequestData)
		})
	}

	handleBackBtnClick(e) {
		e.preventDefault()

		this.props.history.push(reverse(routesList.projects.index))
	}

	handleBucketClick(e) {
		e.preventDefault()

		const { openBucketDropdown } = this.state

		this.setState((state, props) => ({
			openBucketDropdown: !openBucketDropdown
		}))
	}

	handleBucketChange(selectedBucket) {
		const { buckets } = this.state

		if (buckets) {
			if (selectedBucket.hasProject) {
				// redirect to project
				const bucketProjectUrl = reverse(routesList.projects.detail.index, { projectId: selectedBucket.projectId })
				window.location.href = bucketProjectUrl
			} else {
				this.setState((state, props) => ({
					openBucketDropdown: false,
					selectedBucket: selectedBucket,
				}))
			}
		}
	}

	taskFileUploadCallback() {
		const { isUpdatingTasks } = this.state

		if (!isUpdatingTasks) {
			// reset tasks
			const tasksEndpoint = this.getTasksEndpoint()
			this.props.callApi(tasksEndpoint)

			this.setState((state, props) => ({
				isUpdatingTasks: true,
			}))
		}
	}

	handleUploadFileClick(selectedFiles) {
		// get upload form schema
		const { currentProjectPipelineSection } = this.state
		const projectFileUploadEndpoint = this.endpointBuilder.getEndpoint('file_folders_get_upload_form', {
			projectPipelineSectionId: currentProjectPipelineSection.id,
		})

		this.props.callApi(projectFileUploadEndpoint, 'get')

		this.setState((state, props) => ({
			openUploadFilePopin: true,
		}))
	}

	handleDownloadDocumentClick(e, file) {
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		// redirect to download link
		const downloadUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}s3/download/${file.id}`
		window.location.href = downloadUrl
	}

	handleDownloadFolderClick(folder, taskType) {
		const { project } = this.state
		const currentProjectPipelineSection = this.getCurrentProjectPipelineSection()

		// redirect to download link
		if (typeof folder !== 'undefined') {
			const downloadUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}file_folders/download/${folder.id}`
			window.location.href = downloadUrl
		} else if (taskType !== 'undefined') {
			const downloadUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}file_folders/download/file_type/${project.id}/${currentProjectPipelineSection.id}/${taskType}`
			window.location.href = downloadUrl
		}
	}

	handleApproveProjectClick() {
		const approveEndpoint = this.endpointBuilder.getEndpoint('project', { projectId: this.projectId})
		const projectData = {
			status: projectStatuses.PROJECT_STATUS_APPROVED
		}

		this.props.callApi(approveEndpoint, 'put', {
			body: JSON.stringify(projectData)
		})
	}

	handlePartnerClick(partner) {
		this.setState((state, props) => ({
			activeTaskPartner: partner,
		}))
	}

	handleRemovePartnerClick(partner) {
		const removePartnerEndpoint = this.endpointBuilder.getEndpoint('partner', { partnerId: partner.id })

		this.props.callApi(removePartnerEndpoint, 'delete')

		this.setState((state, props) => ({
			deletingPartnerId: partner.id
		}))
	}

	handleAssignTaskToPartnerClick(partner) {
		this.setState((state, props) => ({
			openAssignTaskPopin: true,
			assignTaskPartner: partner,
		}))
	}

	handleSaveAssignTasks() {
		// reset project tasks
		const tasksEndpoint = this.getTasksEndpoint()
		this.props.callApi(tasksEndpoint)

		this.setState((state, props) => ({
			isUpdatingTasks: true,
			openAssignTaskPopin: false,
			assignTaskPartner: undefined,
		}))
	}

	handlePipelineSectionClick(e, projectPipelineSection) {
		e.preventDefault()

		this.setState((state, props) => ({
			currentProjectPipelineSection: projectPipelineSection,
		}))
	}

	handleCompleteSectionClick(projectPipelineSection) {
		const ppsData = {
			isComplete: true
		}
		const ppsEndpoint = this.endpointBuilder.getEndpoint('project_pipeline_section', {id: projectPipelineSection.id})

		this.props.callApi(ppsEndpoint, 'put', {
			body: JSON.stringify(ppsData),
		})

		this.setState((state, props) => ({
			completingProjectPipelineSection: projectPipelineSection
		}))
	}

	handleClickOutsideBucketList() {
		this.setState((state, props) => ({
			openBucketDropdown: false,
		}))
	}

	handleCreateProjectBucket(bucket) {
		if ('sale' === bucket.slug) {
			this.props.callApi(this.endpointBuilder.getEndpoint('property_form'), 'get')

			// open property form popin
			this.setState((state, props) => ({
				openPropertyFormPopin: true,
			}))
		} else {
			const { projectTypes } = this.state
			let projectTypeId = undefined

			if (projectTypes) {
				projectTypes.map((projectType, iPT) => {
					if (
						parseInt(projectType.bucket.id) === parseInt(bucket.id)
						&& projectType.slug === bucket.slug
					) {
						projectTypeId = projectType.id
					}

					return null
				})
			}

			const { project } = this.state
			const projectsEndpoint = this.endpointBuilder.getEndpoint('projects')

			const projectData = {
				name: project.name,
				bucket: `/api/buckets/${bucket.id}`,
				land: `/api/lands/${project.land.id}`,
				projectType: `/api/project_types/${projectTypeId}`,
			}

			this.props.callApi(projectsEndpoint, 'post', {
				body: JSON.stringify(projectData)
			})

			this.setState((state, props) => ({
				isCreatingProject: true,
			}))
		}
	}

	handlePropertySubmit(formData) {
		const { project } = this.state
		const postPropertyEndpoint = this.endpointBuilder.getEndpoint('property_post')

		const newFormData = {
			...formData,
			project: project.id
		}

		this.props.callApi(postPropertyEndpoint, 'post', {
			body: JSON.stringify(newFormData)
		})
	}

	handleEditTaskClick(e, task) {
		e.preventDefault()

		// get task schema
		const taskFormEndpoint = `${this.getTaskFormEndpoint()}?taskId=${task.id}`
		this.props.callApi(taskFormEndpoint, 'get')

		// open task popin
		this.setState((state, props) => ({
			openTaskPopin: true,
			editedTask: task,
		}))
	}

	handleNewFileFolder() {
		this.setState((state, props) => ({
			openFileFolderPopin: true,
		}))
	}

	handleFileFolderSubmit(formData) {
		const { currentProjectPipelineSection } = this.state

		const newFormData = {
			...formData,
			projectPipelineSection: currentProjectPipelineSection.id,
		}

		const normalizedFileFolderData = this.apiClient.normalizeRequest(newFormData, [{
			name: 'projectPipelineSection',
			path: '/api/project_pipeline_sections/{id}'
		}])

		const newFileFolderEndpoint = this.endpointBuilder.getEndpoint('file_folders')

		this.props.callApi(newFileFolderEndpoint, 'post', {
			body: JSON.stringify(normalizedFileFolderData),
		})
	}

	handleFileUploadSubmit(formData, formFiles) {
		const { currentProjectPipelineSection } = this.state
		const postProjectFileEndpoint = this.endpointBuilder.getEndpoint('post_project_file', {
			projectPipelineSectionId: currentProjectPipelineSection.id,
		})

		const newFormData = {
			...formData,
			files: formFiles.files,
		}

		this.props.callApi(postProjectFileEndpoint, 'post', {
			body: JSON.stringify(newFormData)
		})
	}

	handleEditProjectBtnClick() {
		// get edit project form
		const { project } = this.state
		const projectEditFormEndpoint = this.endpointBuilder.getEndpoint('get_project_edit_form', {
			id: project.id,
		})

		this.props.callApi(projectEditFormEndpoint, 'get')

		this.setState((state, props) => ({
			openEditProjectPopin: true,
		}))
	}

	handleEditProjectSubmit(formData, formFiles) {
		const { project } = this.state
		const newFormData = {
			...formData,
			coverFile: formFiles.cover,
		}

		const editProjectEndpoint = this.endpointBuilder.getEndpoint('post_edit_project', {
			id: project.id,
		})

		this.props.callApi(editProjectEndpoint, 'post', {
			body: JSON.stringify(newFormData),
		})
	}

	render() {
		const {
			project,
			projectTasks,
			userOfferRequests,
			tabs,
			openPartnerPopin,
			partnerFormSchema,
			openTaskPopin,
			taskFormSchema,
			displayOfferForm,
			openOfferMessagePopin,
			offerMessageSchema,
			openTaskFileUploadPopin,
			taskFileUploadSchema,
			openOfferRequestPopin,
			requestOfferSchema,
			currentProjectPipelineSection,
			openBucketDropdown,
			buckets,
			openPropertyFormPopin,
			propertyForm,
			editedTask,
			activeTaskPartner,
			openFileFolderPopin,
			openUploadFilePopin,
			projectFileUploadForm,
			openEditProjectPopin,
			editProjectForm,
			openAssignTaskPopin,
			assignTaskPartner,
		} = this.state

		const activeBucket = this.getActiveBucket()

		const taskPopinTitle = (editedTask) ? 'projectDetailTaskEditBtn' : 'projectDetailTaskCreateBtn'

		return (
			<div className="ProjectDetail" id="project-detail">
				<span className="project__decorator"></span>

				{!project &&
					<Spinner />
				}

				{project &&
					<ProjectHeader
						project={project}
						buckets={buckets}
						backBtnCallback={this.handleBackBtnClick}
						activeBucket={activeBucket}
						activeBucketCallback={this.handleBucketClick}
						openBucketDropdown={openBucketDropdown}
						currentProjectPipelineSection={currentProjectPipelineSection}
						pipelineSectionClickCallback={this.handlePipelineSectionClick}
						outsideClickCallback={() => this.handleClickOutsideBucketList()}
						bucketChangeCallback={this.handleBucketChange}
						editProjectBtnCallback={this.handleEditProjectBtnClick}
					/>
				}

				{project && activeBucket && !activeBucket.hasProject &&
					<CreateProjectBucket
						bucket={activeBucket}
						initialProject={project}
						createProjectCallback={this.handleCreateProjectBucket}
					/>
				}

				{project && !activeBucket && <Spinner />}

				{project && activeBucket && activeBucket.hasProject && buckets &&
					<ProjectDetailTabs
						project={project}
						projectTasks={projectTasks}
						userOfferRequests={userOfferRequests}
						tabs={tabs}
						tabClickCallback={this.handleTabClick}
						currentProjectPipelineSection={currentProjectPipelineSection}
						addPartnerCallback={this.handleAddPartnerClick}
						requestCallback={this.handleRequestBtnClick}
						projetDocumentCheckboxCallback={this.handleProjectDocumentCheckbox}
						uploadFilesCallback={this.handleProjectDocumentFileUpload}
						signatureCallback={this.handleProjectDocumentSignatureClick}
						addOfferCallback={this.handleAddOfferClick}
						offerButtonsCallback={this.handleOfferDetailAction}
						taskFilesCountClickCallback={this.handleTaskFilesCountClick}
						createOfferRequestCallback={this.handleCreateOfferRequestClick}
						addTaskCallback={this.handleAddTaskClick}
						taskFileUploadCallback={this.taskFileUploadCallback}
						uploadFileCallback={this.handleUploadFileClick}
						downloadCallback={this.handleDownloadDocumentClick}
						downloadFolderCallback={this.handleDownloadFolderClick}
						displayOfferForm={displayOfferForm}
						createOfferCallback={this.handleOfferSubmit}
						approveProjectCallback={this.handleApproveProjectClick}
						partnerItemClickCallback={this.handlePartnerClick}
						removePartnerCallback={this.handleRemovePartnerClick}
						assignTaskCallback={this.handleAssignTaskToPartnerClick}
						editTaskCallback={this.handleEditTaskClick}
						offerBackArrowClickCallback={this.handleOfferBackClick}
						activeTaskPartner={activeTaskPartner}
						taskDetailCloseCallback={() => this.handlePartnerClick(undefined)}
						newFileFolderBtnCallback={this.handleNewFileFolder}
						completeSectionCallback={this.handleCompleteSectionClick}
					/>
				}

				{openPartnerPopin &&
					<Popin
						isOpened={openPartnerPopin}
						partnerTitle='projectDetailPartnersAdd'
						partnerTitleKey='projectDetailPartnersAdd'
						onClose={this.handleClosePopin}
					>
						{partnerFormSchema &&
							<UbikonForm
								form={partnerFormSchema}
								theme={ProjectPartnersFormTheme}
								currentProjectPipelineSection={currentProjectPipelineSection}
								onSubmit={this.handlePartnerSubmit}
							/>
						}

						{!partnerFormSchema && <Spinner />}
					</Popin>
				}

				{openTaskPopin &&
					<Popin
						isOpened={openTaskPopin}
						tansTitle={taskPopinTitle}
						tansTitleKey={taskPopinTitle}
						onClose={this.handleClosePopin}
					>
						{taskFormSchema &&
							<UbikonForm
								form={taskFormSchema}
								theme={TaskPopinForm}
								onSubmit={this.handleTaskSubmit}
							/>
						}

						{!taskFormSchema &&
							<Spinner />
						}
					</Popin>
				}

				{openOfferMessagePopin &&
					<Popin
						isOpened={openOfferMessagePopin}
						title='Offer Message'
						onClose={this.handleClosePopin}
					>
						{offerMessageSchema &&
							<Liform schema={offerMessageSchema} onSubmit={this.handleOfferMessageSubmit} />
						}

						{!offerMessageSchema &&
							<Spinner />
						}
					</Popin>
				}

				{openTaskFileUploadPopin &&
					<Popin
						isOpened={openTaskFileUploadPopin}
						title='Task File Upload'
						onClose={this.handleClosePopin}
					>
						{taskFileUploadSchema &&
							<FileUpload
								schema={taskFileUploadSchema}
								onSubmitCallback={this.handleTaskFileUpload}
							/>
						}

						{!taskFileUploadSchema &&
							<Spinner />
						}
					</Popin>
				}

				{openOfferRequestPopin &&
					<Popin
						isOpened={openOfferRequestPopin}
						title='Create Offer Request'
						onClose={this.handleClosePopin}
					>
						{requestOfferSchema &&
							<Liform schema={requestOfferSchema} onSubmit={this.handleRequestOfferSubmit} />
						}

						{!requestOfferSchema &&
							<Spinner />
						}
					</Popin>
				}

				{openPropertyFormPopin &&
					<Popin
						isOpened={openPropertyFormPopin}
						title='Sell your property'
						onClose={this.handleClosePopin}
					>
						{propertyForm &&
							<UbikonForm form={propertyForm} onSubmit={this.handlePropertySubmit} />
						}

						{!propertyForm &&
							<Spinner />
						}
					</Popin>
				}

				{openFileFolderPopin &&
					<Popin
						isOpened={openFileFolderPopin}
						transTitle='projectFileNewFolderPopinTitle'
						transTitleKey='projectFileNewFolderPopinTitle'
						onClose={this.handleClosePopin}
					>
						<UbikonForm form={fileFolderForm} onSubmit={this.handleFileFolderSubmit} />
					</Popin>
				}

				{openUploadFilePopin &&
					<Popin
						isOpened={openUploadFilePopin}
						title='Upload file'
						onClose={this.handleClosePopin}
					>
						{!projectFileUploadForm &&
							<Spinner />
						}

						{projectFileUploadForm &&
							<UbikonForm form={projectFileUploadForm} onSubmit={this.handleFileUploadSubmit} />
						}
					</Popin>
				}

				{openEditProjectPopin &&
					<Popin
						isOpened={openEditProjectPopin}
						transTitle='projectDetailHeaderEditBtn'
						transTitleKey='projectDetailHeaderEditBtn'
						onClose={this.handleClosePopin}
					>
						{!editProjectForm &&
							<Spinner />
						}

						{editProjectForm &&
							<UbikonForm form={editProjectForm} onSubmit={this.handleEditProjectSubmit} />
						}
					</Popin>
				}

				{openAssignTaskPopin &&
					<Popin
						isOpened={openAssignTaskPopin}
						title='Assign tasks to partner'
						onClose={this.handleClosePopin}
					>
						<AssignTaskPopin
							partner={assignTaskPartner}
							projectPipelineSection={currentProjectPipelineSection}
							projectTasks={projectTasks}
							saveCallback={this.handleSaveAssignTasks}
						/>
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
		uploadResponse,
		uploadedFiles,
		uploadEndpoint
	} = state.api

	const { ...temporaryData } = state.temporary_data

	return {
		apiResponse,
		apiResponseEndpoint,
		temporaryData,
		uploadResponse,
		uploadedFiles,
		uploadEndpoint
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		upload: (endpoint, files) => dispatch(upload(endpoint, files)),
		resetUploadResponse: () => dispatch(resetUploadResponse()),
		resetTemporaryData: (temporaryProjectKey) => dispatch(resetTemporaryData(temporaryProjectKey)),
		setCenterClass: (className) => dispatch(setCenterClass(className)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail)