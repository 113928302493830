import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import PreviewPopin from 'components/preview/PreviewPopin'
import Spinner from 'components/spinner/Spinner'
import TaskFileFolder from 'components/tasks/TaskFileFolder'
import TasksList from 'components/tasks/TasksList'

class ProjectSectionFiles extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			fileFolders: undefined,
			previewedFile: undefined,
			openedFolders: [],
		}

		// bind methods
		this.handleFolderClick = this.handleFolderClick.bind(this)
		this.handlePreviewClick = this.handlePreviewClick.bind(this)
		this.handleClosePreview = this.handleClosePreview.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		// get file folders
		const newFileFolderEndpoint = this.getFileFoldersEndpoint()
		this.props.callApi(newFileFolderEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			apiResponse,
			apiResponseEndpoint,
		} = this.props

		const newFileFolderEndpoint = this.getFileFoldersEndpoint()

		if (apiResponse) {
			this.props.resetApiResponse()

			if (apiResponseEndpoint === newFileFolderEndpoint) {
				this.setState((state, props) => ({
					fileFolders: apiResponse,
				}))
			}
		}
	}

	getFileFoldersEndpoint() {
		const { projectPipelineSection } = this.props

		if (!projectPipelineSection) {
			return undefined
		}

		return `${this.endpointBuilder.getEndpoint('file_folders')}?projectPipelineSection=${projectPipelineSection.id}`
	}

	handleFolderClick(folderTitle) {
		const { openedFolders } = this.state
		let newOpenedFolders = openedFolders

		const folderIndex = newOpenedFolders.indexOf(folderTitle)

		// check if folder was opened
		if (folderIndex > -1) {
			delete newOpenedFolders[folderIndex]
		} else {
			// add to opened folders
			newOpenedFolders.push(folderTitle)
		}

		this.setState((state, props) => ({
			openedFolders: newOpenedFolders,
		}))
	}

	handlePreviewClick(fileObject) {
		this.setState((state, props) => ({
			previewedFile: fileObject,
		}))
	}

	handleClosePreview() {
		this.setState((state, props) => ({
			previewedFile: undefined,
		}))
	}

	render() {
		const {
			fileFolders,
			previewedFile,
			openedFolders,
		} = this.state

		const {
			projectTasks,
			downloadCallback,
			downloadFolderCallback,
			newFileFolderBtnCallback,
		} = this.props

		return (
			<div className="ProjectSection--files">
				{!projectTasks &&
					<Spinner />
				}

				{projectTasks &&
					<TasksList
						projectTasks={projectTasks}
						openedFolders={openedFolders}
						downloadCallback={downloadCallback}
						downloadFolderCallback={downloadFolderCallback}
						newFileFolderBtnCallback={newFileFolderBtnCallback}
						folderClickCallback={this.handleFolderClick}
						previewCallback={this.handlePreviewClick}
					/>
				}

				{fileFolders &&
					<TaskFileFolder
						title='projectFilesGeneralDocumentsTitle'
						fileFolders={fileFolders}
						openedFolders={openedFolders}
						downloadCallback={downloadCallback}
						downloadFolderCallback={downloadFolderCallback}
						folderClickCallback={this.handleFolderClick}
						previewCallback={this.handlePreviewClick}
					/>
				}

				{previewedFile &&
					<PreviewPopin
						file={previewedFile}
						fileType='amazon'
						downloadCallback={downloadCallback}
						closePreviewCallback={this.handleClosePreview}
					/>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSectionFiles)