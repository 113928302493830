import React from 'react'

import ContactForm from 'components/forms/ContactForm'
import textPages from 'config/text-pages'

class TextPage extends React.Component {
	render() {
		const { textType } = this.props
		const language = 'en'
		const textPageContent = (textPages[textType] && textPages[textType][language]) ? textPages[textType][language] : undefined

		return (
			<div className="TextPage">
				<h1>{textPageContent.title}</h1>

				<div className="Content">
					{textPageContent.content}
				</div>

				{'contact' === textType &&
					<ContactForm />
				}					
			</div>
		)
	}
}

export default TextPage