import React from 'react'
import { Trans } from 'react-i18next'

import {
	SubmitButton
} from 'components/forms/inputs'
import TextButton from 'components/buttons/TextButton'

class LoginFormTheme extends React.Component {
	render () {
		const {
			formFields,
			formHelper,
			submitCallback,
		} = this.props
		const { loginFormValues, helpClickCallback, isError } = formHelper.props

		if (!formFields) {
			return false;
		}

		let emailField = formFields[0]
		if (loginFormValues && loginFormValues.email) {
			const emailFieldProps = {
				...emailField.props,
				value: loginFormValues.email,
			}

			emailField = {
				...emailField,
				props: emailFieldProps,
			}
		}

		return (
			<div>
				<form name="login" onSubmit={submitCallback}>
					{formHelper.renderField(emailField, 'email')}
					{formHelper.renderField(formFields[1], 'password')}

					<div className="FormRow help">
						<TextButton text='loginHelpBtnText' onClick={() => helpClickCallback('help')} />
						<TextButton text='loginForgottenPasswordBtnText' onClick={() => helpClickCallback('password')} />
					</div>

					{true === isError &&
						<p className="Alert--danger">
							<Trans i18nKey="loginErrorMessage">loginErrorMessage</Trans>
						</p>
					}

					<SubmitButton
						className="upper"
						text='loginSubmitBtn'
					/>
				</form>
			</div>
		)
	}
}

export default LoginFormTheme