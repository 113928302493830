import React from 'react'
import { withTranslation } from 'react-i18next'

import AccessControl from 'auth/access-control'
import ChatSectionTitle from 'components/chat/ChatSectionTitle'
import ConversationListItem from 'components/chat/ConversationListItem'
import getConversationTitle from 'utils/getConversationTitle'

const onConversationClick = (e, conversation, callback) => {
	e.preventDefault()

	callback(conversation)
}

const getConversationAvatar = (type, conversation) => {
	const { users } = conversation
	let avatar = undefined

	if (('chat' === type || 'offers' === type) && users) {
		const accessControl = new AccessControl()
		const currentUserId = accessControl.getUserId()

		users.map((user, i) => {
			if (typeof avatar === 'undefined' && user.id !== currentUserId) {
				avatar = user.avatar
			}

			return null
		})
	}

	return avatar
}

export const ConversationsList = (props) => {
	const {
		t,
		title,
		listType,
		displayLimit,
		conversations,
		className,
		chatActiveConversation,
		isMobile,
		onClickCallback,
		onExpandClickCallback,
	} = props

	const listClass = (className) ? `ConversationsList ${className}`: 'ConversationsList'

	return (
		<section className={listClass}>
			{!isMobile &&
				<ChatSectionTitle
					className='upper medium'
					title={t(title)}
					displayLimit={displayLimit}
					listType={listType}
					sectionCount={conversations.length}
					onExpandClick={onExpandClickCallback}
				/>
			}

			<div className="chat__profiles">
				{conversations && conversations.map((conversation, i) => {
					let isVisible = true
					if (!isMobile && displayLimit && displayLimit < (i + 1)) {
						isVisible = false
					}

					if (!isVisible) {
						return null
					} else {
						return (
							<ConversationListItem
								key={conversation.id}
								conversation={conversation}
								listType={listType}
								chatActiveConversation={chatActiveConversation}
								avatar={getConversationAvatar(listType, conversation)}
								title={getConversationTitle(listType, conversation)}
								onClick={(e) => onConversationClick(e, conversation, onClickCallback)}
							/>
						)
					}
				})}
			</div>
		</section>
	)
}

export default withTranslation()(ConversationsList)