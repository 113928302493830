import React from 'react'
import { withTranslation } from 'react-i18next'

const getSvgIcon = (IconComponent, className) => {

	const iconClassname = (className) ? `nav__item__icon--${className}` : 'nav__item__icon'

	return (
		<span className="nav__item__icon">
			<IconComponent className={iconClassname} />
		</span>
	)
}

export const NavItem = (props) => {
	const { t, item, isMobile, navCallback } = props
	const { label, icon, svgIcon, svgClass, path } = item
	const isActiveClass = (window.location.pathname === path) ? ' is-active': ''

	if (false === isMobile && true === item.mobile) {
		return null
	}

	return (
		<li className={`nav__item${isActiveClass}`} onClick={(e) => navCallback(e, item)}>
			{icon && !svgIcon &&
				<span className="nav__item__icon">
					<img className="NavSection__item__icon" src={icon} alt={label} />
				</span>
			}

			{icon && svgIcon &&
				getSvgIcon(icon, svgClass)
			}

			{!isMobile &&
				<span className={`nav__item__text ${isActiveClass}`}>
					{t(label)}
				</span>
			}
		</li>
	)
}

export default withTranslation()(NavItem)