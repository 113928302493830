import React from 'react'

import ProjectSectionDashboard from 'components/projects/ProjectSectionDashboard'
import ProjectSectionOffers from 'components/projects/ProjectSectionOffers'
import ProjectSectionFiles from 'components/projects/ProjectSectionFiles'
import ProjectSectionFinancialAnalysis from 'components/projects/ProjectSectionFinancialAnalysis'
import ProjectSectionPlanning from 'components/projects/ProjectSectionPlanning'

export const ProjectDetailTabContent = (props) => {
	const {
		project,
		projectTasks,
		userOfferRequests,
		tab,
		addPartnerCallback,
		requestCallback,
		projetDocumentCheckboxCallback,
		uploadFilesCallback,
		signatureCallback,
		addOfferCallback,
		offerButtonsCallback,
		taskFilesCountClickCallback,
		createOfferRequestCallback,
		currentProjectPipelineSection,
		taskFileUploadCallback,
		downloadCallback,
		downloadFolderCallback,
		displayOfferForm,
		createOfferCallback,
		approveProjectCallback,
		partnerItemClickCallback,
		removePartnerCallback,
		assignTaskCallback,
		editTaskCallback,
		offerBackArrowClickCallback,
		activeTaskPartner,
		taskDetailCloseCallback,
		newFileFolderBtnCallback,
		completeSectionCallback,
	} = props

	return (
		<div className="ProjectDetailTabContent">
			{tab.slug === 'dashboard' &&
				<ProjectSectionDashboard
					project={project}
					projectTasks={projectTasks}
					userOfferRequests={userOfferRequests}
					currentProjectPipelineSection={currentProjectPipelineSection}
					addPartnerCallback={addPartnerCallback}
					requestCallback={requestCallback}
					projetDocumentCheckboxCallback={projetDocumentCheckboxCallback}
					uploadFilesCallback={uploadFilesCallback}
					signatureCallback={signatureCallback}
					taskFilesCountClickCallback={taskFilesCountClickCallback}
					taskFileUploadCallback={taskFileUploadCallback}
					approveProjectCallback={approveProjectCallback}
					partnerItemClickCallback={partnerItemClickCallback}
					removePartnerCallback={removePartnerCallback}
					assignTaskCallback={assignTaskCallback}
					editTaskCallback={editTaskCallback}
					activeTaskPartner={activeTaskPartner}
					taskDetailCloseCallback={taskDetailCloseCallback}
					completeSectionCallback={completeSectionCallback}
				/>
			}

			{tab.slug === 'offers' &&
				<ProjectSectionOffers
					project={project}
					addOfferCallback={addOfferCallback}
					displayOfferForm={displayOfferForm}
					offerButtonsCallback={offerButtonsCallback}
					createOfferRequestCallback={createOfferRequestCallback}
					createOfferCallback={createOfferCallback}
					projectPipelineSection={currentProjectPipelineSection}
					offerBackArrowClickCallback={offerBackArrowClickCallback}
				/>
			}

			{tab.slug === 'files' &&
				<ProjectSectionFiles
					projectPipelineSection={currentProjectPipelineSection}
					projectTasks={projectTasks}
					downloadCallback={downloadCallback}
					downloadFolderCallback={downloadFolderCallback}
					newFileFolderBtnCallback={newFileFolderBtnCallback}
				/>
			}

			{tab.slug === 'planning' &&
				<ProjectSectionPlanning />
			}

			{tab.slug === 'financial-analysis' &&
				<ProjectSectionFinancialAnalysis
					project={project}
				/>
			}
		</div>
	)
}

export default ProjectDetailTabContent