import React from 'react'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import ProjectPipeline from 'components/projects/ProjectPipeline'

const handleBucketChange = (e, buckets, bucketChangeCallback) => {
	let bucket = undefined
	if (buckets) {
		buckets.map((bucketInList, i) => {
			if (bucketInList.slug === e.target.value) {
				bucket = bucketInList
			}

			return null
		})
	}

	bucketChangeCallback(bucket)
}

export const ProjectHeader = (props) => {
	const {
		t,
		project,
		title,
		backBtnCallback,
		activeBucket,
		buckets,
		openBucketDropdown,
		currentProjectPipelineSection,
		pipelineSectionClickCallback,
		bucketChangeCallback,
		editProjectBtnCallback,
	} = props

	let bgImage = undefined
	if (project) {
		const headerImage = (project && project.cover) ? project.cover: undefined
		bgImage = (headerImage) ? `url(${media.getMediaUrl(headerImage.contentUrl)})` : undefined
	}

	const headerStyle = (bgImage) ? {backgroundImage: bgImage} : {}
	const bgClass = (bgImage) ? ' has-image' : ''

	return (
		<div className="project">
			<div className={`project__bg${bgClass}`} style={headerStyle}></div>

			<div className="project__head">
				<div className="project__head__back" onClick={backBtnCallback}>
	                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13">
	                    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="square">
	                        <path d="M.5 6.5l5.806 5.806M.5 6.5L6.296.704M1.547 6.5H17.5"/>
	                    </g>
	                </svg>

	                <p className="project__head__back__text">{t('projectDetailHeaderBackBtn')}</p>
				</div>

				{project &&
					<h1 className="project__head__title">{project.name}</h1>
				}

				{!project && title &&
					<h1 className="project__head__title">{title}</h1>
				}

				{project &&
					<div className="project__head__edit" onClick={editProjectBtnCallback}>
		                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
		                    <g fill="none" fillRule="evenodd">
		                        <path fill="#FFF" d="M10.136 12.444l-2.87.662a.286.286 0 0 1-.343-.343l.663-2.87a.278.278 0 0 1 .199-.211.281.281 0 0 1 .282.073l2.207 2.208a.28.28 0 0 1 .073.28.282.282 0 0 1-.211.2zm6.078-11.236a1.519 1.519 0 0 1 2.147-.001l.46.46a1.521 1.521 0 0 1 0 2.149l-7.894 7.895-.201-.2-2.208-2.208-.2-.2 7.896-7.895zm-5.323 11.37l-.196.268 8.578-8.58a2.158 2.158 0 0 0 0-3.05l-.46-.46a2.16 2.16 0 0 0-3.051 0L7.195 9.323l.255-.185a.906.906 0 0 0-.487.61L6.3 12.62a.915.915 0 0 0 .247.86.92.92 0 0 0 .862.248l2.87-.662a.903.903 0 0 0 .61-.487z"/>
		                        <path stroke="#FFF" strokeWidth=".25" d="M10.136 12.444l-2.87.662a.286.286 0 0 1-.343-.343l.663-2.87a.278.278 0 0 1 .199-.211.281.281 0 0 1 .282.073l2.207 2.208a.28.28 0 0 1 .073.28.282.282 0 0 1-.211.2zm6.078-11.236a1.519 1.519 0 0 1 2.147-.001l.46.46a1.521 1.521 0 0 1 0 2.149l-7.894 7.895-.201-.2-2.208-2.208-.2-.2 7.896-7.895zm-5.323 11.37l-.196.268 8.578-8.58a2.158 2.158 0 0 0 0-3.05l-.46-.46a2.16 2.16 0 0 0-3.051 0L7.195 9.323l.255-.185a.906.906 0 0 0-.487.61L6.3 12.62a.915.915 0 0 0 .247.86.92.92 0 0 0 .862.248l2.87-.662a.903.903 0 0 0 .61-.487z"/>
		                        <path stroke="#FFF" d="M17.507 10.299v8.218H.5V2.51h8.592"/>
		                    </g>
		                </svg>
		                <p className="project__head__edit__text">
		                	{t('projectDetailHeaderEditBtn')}
		                </p>
					</div>
				}
			</div>

			{project && project.land &&
				<div className="project__infos">
					<p className="project__location">
						{`${project.land.city}, ${project.land.zipcode}`}
					</p>
				</div>
			}

			{project && activeBucket &&
				<div className="project__steps__buttons">
                    <div className="custom-select">
                        <svg className="custom-select__icon--arrow-down" xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8">
                            <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="square" opacity=".75">
                                <path d="M.5.5l6.813 6.813M14.148.5L7.336 7.313"/>
                            </g>
                        </svg>
                        <select
                        	className="custom-select__inner"
                        	defaultValue={activeBucket.slug}
                        	onChange={(e) => handleBucketChange(e, buckets, bucketChangeCallback)}
                        >
							{buckets.map((bucket, i) => {
								return (
									<option
										key={i}
										className="custom-select__option"
										value={bucket.slug}
									>
										{bucket.name}
									</option>
								)
							})}
                        </select>
                    </div>
				</div>
			}

			{/* project pipeline */}
			{currentProjectPipelineSection &&
				<ProjectPipeline
					project={project}
					currentProjectPipelineSection={currentProjectPipelineSection}
					pipelineSectionClickCallback={pipelineSectionClickCallback}
					isBlured={openBucketDropdown}
				/>
			}
		</div>
	)
}

export default withTranslation()(ProjectHeader)