import React from 'react'

import UbikonForm from 'components/forms/UbikonForm'

export const ProjectsListFiltersForm = (props) => {
	const { schema, callback, changeCallback, isVisible } = props

	const containerClass = `ProjectsListFilters__form ${(isVisible) ? 'visible': ''}`

	return (
		<div className={containerClass}>
			<UbikonForm
				form={schema}
				onChange={changeCallback}
				onSubmit={callback}
			/>
		</div>
	)
}

export default ProjectsListFiltersForm