import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import AccessControl from 'auth/access-control'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import TextButton from 'components/buttons/TextButton'
import RequestForm from 'components/forms/RequestForm'
import landForm from 'components/forms/schemas/landForm'
import Spinner from 'components/spinner/Spinner'

class RequestFormPopin extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			projectRequestType: undefined,
			landRequestId: undefined,
			projectRequestId: undefined,
			propertyForm: undefined,
			benefits: undefined,
			isLoading: false,
			isFinished: false,
		}

		// bind methods
		this.handleProjectRequestTypeClick = this.handleProjectRequestTypeClick.bind(this)
		this.handleFormSubmit = this.handleFormSubmit.bind(this)
		this.handleCancelClick = this.handleCancelClick.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()

		// init access control helper
		this.accessControl = new AccessControl()
	}

	componentDidMount() {
		// get property form and benefits list
		if (this.accessControl.isAuthenticated()) {
			this.props.callApi(this.endpointBuilder.getEndpoint('benefits'))
			this.props.callApi(this.endpointBuilder.getEndpoint('property_form'))
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const getPropertyFormEndpoint = this.endpointBuilder.getEndpoint('property_form')
			const getBenefitsEndpoint = this.endpointBuilder.getEndpoint('benefits')
			const postLandEndpoint = this.endpointBuilder.getEndpoint('lands')
			const postPropertyEndpoint = this.endpointBuilder.getEndpoint('property_post')

			// did get property form
			if (apiResponseEndpoint === getPropertyFormEndpoint) {
				this.setState((state, props) => ({
					propertyForm: apiResponse.model
				}))
			}

			// did get benefits list
			if (apiResponseEndpoint === getBenefitsEndpoint) {
				this.setState((state, props) => ({
					benefits: apiResponse
				}))
			}

			// did post land
			if (apiResponseEndpoint === postLandEndpoint) {
				// display property form
				this.setState((state, props) => ({
					isFinished: true,
					isLoading: false,
					projectRequestType: undefined,
					projectRequestId: apiResponse.projectRequestId,
					landRequestId: apiResponse.land,
				}))
			}

			// did post property
			if (apiResponseEndpoint === postPropertyEndpoint) {
				this.setState((state, props) => ({
					projectRequestType: undefined,
					propertyForm: undefined,
					isLoading: false,
					isFinished: true,
					projectRequestId: undefined,
				}))
			}
		}
	}

	handleProjectRequestTypeClick(e, projectRequestType) {
		e.preventDefault()

		this.setState((state, props) => ({
			projectRequestType: projectRequestType,
		}))
	}

	handleCancelClick() {
		const { cancelCallback } = this.props

		this.setState((state, props) => ({
			projectRequestType: undefined,
			projectRequestId: undefined,
			propertyForm: undefined,
			isLoading: false,
			isFinished: false,
		}))

		if (typeof cancelCallback !== 'undefined') {
			cancelCallback()
		}
	}

	handleFormSubmit(formData, formFiles) {
		const { projectRequestType, landRequestId } = this.state

		if ('land' === projectRequestType) {
			const landData = {
				...formData,
				files: formFiles.photos,
				//isProjectRequest: true,
				//projectRequestId: projectRequestId,
			}

			console.log('landData', landData)

			this.props.callApi(this.endpointBuilder.getEndpoint('lands'), 'post', {
				body: JSON.stringify(landData),
			})

			this.setState((state, props) => ({
				isLoading: true,
			}))
		} else if ('property' === projectRequestType) {
			const newFormData = {
				...formData,
				//isProjectRequest: true,
				//projectRequestId: projectRequestId,
				landRequestId: landRequestId,
			}

			this.props.callApi(this.endpointBuilder.getEndpoint('property_post'), 'post', {
				body: JSON.stringify(newFormData)
			})
		}
	}

	render() {
		const {
			projectRequestType,
			propertyForm,
			benefits,
			isLoading,
			isFinished,
		} = this.state

		const isLoadingClass = (isLoading) ? 'is-loading' : ''
		const isFinishedClass = (isFinished) ? 'is-finished' : ''

		return (
			<div className={`RequestFormPopin ${isLoadingClass} ${isFinishedClass}`}>
				{isFinished &&
					<div>
						<p>Your request has been submitted to Ubikon for approval</p>

						<TextButton text='Close' onClick={this.handleCancelClick} />
					</div>
				}

				{!isFinished &&
					<div>
						{isLoading &&
							<Spinner />
						}

						{!isLoading && !projectRequestType &&
							<div className="RequestFormPopin__choices">
								<h2 className="big">Do you want to sell&nbsp;</h2>

								<div className="RequestFormPopin__choices__buttons">
									<TextButton
										text='Land'
										className="ProjectRequestType__button"
										onClick={(e) => this.handleProjectRequestTypeClick(e, 'land')}
									/>

									<TextButton
										text='Property'
										className="ProjectRequestType__button"
										onClick={(e) => this.handleProjectRequestTypeClick(e, 'property')}
									/>
								</div>
							</div>
						}

						{!isLoading && projectRequestType &&
							<RequestForm
								projectRequestType={projectRequestType}
								landForm={landForm}
								propertyForm={propertyForm}
								benefits={benefits}
								onSubmitCallback={this.handleFormSubmit}
								onCancelCallback={this.handleCancelClick}
							/>
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
 
	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestFormPopin)