import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import { callApi, resetApiResponse, resetApiError } from 'actions/api'
import { displayNotificationsOverlay } from 'actions/notifier'
import { setActiveConversation } from 'actions/messaging'
import { collapseLeftMenu } from 'actions/left-menu'
import { closeCollectionsPopin } from 'actions/collections'
import { setElementSize, displayDecorators } from 'actions/layout'
import { setConfiguration } from 'actions/configuration'
import AccessControl from 'auth/access-control'
import apiEndpoints from 'constants/api-endpoints'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ChatCenterWrapper from 'components/chat/ChatCenterWrapper'
import CollectionsPopin from 'components/collections/CollectionsPopin'
import Header from 'components/layout/Header'
import LeftMenu from 'components/layout/LeftMenu'
import leftMenuConfig from 'config/left-menu'
import MessagingMenu from 'components/layout/MessagingMenu'
import MobileLeftMenu from 'components/layout/MobileLeftMenu'
import notificationTypes from 'constants/notification-types'
import Popin from 'components/layout/Popin'
import RequestFormPopin from 'components/forms/RequestFormPopin'
import Routes from 'routing/Routes'
import routesList from 'routing/routes-list'
import userRoles from 'constants/user-roles'

import './App.scss'

class App extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			headerUsername: undefined,
			refreshTokenFailed: false,
			openProjectRequestPopin: false,
			isInformationExpanded: false,
			elements: {},
		}

		// bind methods
		this.handleLogout = this.handleLogout.bind(this)
		this.handleNavCallback = this.handleNavCallback.bind(this)
		this.handleCreateProjectCallback = this.handleCreateProjectCallback.bind(this)
		this.handleUserProfileClick = this.handleUserProfileClick.bind(this)
		this.handleLogoClick = this.handleLogoClick.bind(this)
		this.handleCancelRequestForm = this.handleCancelRequestForm.bind(this)
		this.refreshTokenCallback = this.refreshTokenCallback.bind(this)
		this.handleNotificationsOverlayClick = this.handleNotificationsOverlayClick.bind(this)
		this.handleNotificationClick = this.handleNotificationClick.bind(this)
		this.handleCollapseLeftMenuClick = this.handleCollapseLeftMenuClick.bind(this)
		this.handleInformationsClick = this.handleInformationsClick.bind(this)
		this.handleProjectNavClick = this.handleProjectNavClick.bind(this)
		this.handleCreateAdminProjectCallback = this.handleCreateAdminProjectCallback.bind(this)
		this.refCallback = this.refCallback.bind(this)
		this.setElementsSizes = this.setElementsSizes.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
		this.isAdmin = this.accessControl.hasRole(userRoles.ROLE_SUPER_ADMIN)
	}

	componentDidMount() {
		this.setState((state, props) => ({
			headerUsername: this.accessControl.getUserProperty('username'),
		}))

		if (this.accessControl.isAuthenticated()) {
			// get config
			const configEndpoint = this.endpointBuilder.getEndpoint('config')
			this.props.callApi(configEndpoint)
		}

		// refresh token if necessary
		setInterval(() => {
			this.accessControl.mustRefreshToken(this.refreshTokenCallback)
		}, 10000)

		// listen to resize
		window.addEventListener('resize', this.setElementsSizes);

		// get admin's projects list
		if (true === this.isAdmin) {
			const adminProjectsListEndpoint = this.endpointBuilder.getEndpoint('admin_projects_list')
			this.props.callApi(adminProjectsListEndpoint)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { headerUsername } = this.state
		const {
			apiResponse,
			apiResponseEndpoint,
			apiError,
		} = this.props

		const configEndpoint = this.endpointBuilder.getEndpoint('config')
		const userEndpoint = this.getUserEndpoint()
		const adminProjectsListEndpoint = this.endpointBuilder.getEndpoint('admin_projects_list')

		// handle sizes change
		this.setElementsSizes()

		// if api has errored with Unauthorized, redirect to login
		if (apiError && 'Unauthorized' === apiError.message) {
			this.handleLogout()
		}

		// refresh User on profile update
		if (apiResponse && userEndpoint === apiResponseEndpoint) {
			const { username } = apiResponse

			if (typeof username !== 'undefined') {
				this.setState((state, props) => ({
					headerUsername: username,
				}))
			}
		}

		// set config in store
		if (apiResponse && configEndpoint === apiResponseEndpoint) {
			this.props.setConfiguration(apiResponse)
		}

		// get username if empty
		if (typeof headerUsername === 'undefined') {
			const newUsername = this.accessControl.getUserProperty('username')

			if (typeof newUsername !== 'undefined') {
				this.setState((state, props) => ({
					headerUsername: newUsername,
				}))
			}
		}

		// refresh token
		if (apiResponse && apiEndpoints.REFRESH_TOKEN === apiResponseEndpoint) {
			const { token, refresh_token, user } = apiResponse

			this.accessControl.authenticate(token, refresh_token, user, (isAuthenticated) => {
				this.props.resetApiResponse()
			})
		}

		// did get admin projects list
		if (apiResponse && apiResponseEndpoint === adminProjectsListEndpoint) {
			this.setState((state, props) => ({
				adminProjects: apiResponse.projects,
			}))
		}

		if (apiError) {
			this.props.resetApiError()

			this.setState((state, props) => ({
				refreshTokenFailed: true
			}))
		}

		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	refCallback(element) {
		if (element) {
			const { elements } = this.state
			const newElements = {
				...elements,
				'middle-block': element
			}

			this.setState((state, props) => ({
				elements: newElements
			}))
		}
	}

	getSize(bounding) {
		if (bounding.width) {
			return bounding.width
		}

		return undefined
	}

	setElementsSizes() {
		const { elements } = this.state
		const { sizes } = this.props

		// sizes stuff
		if (elements) {
			Object.keys(elements).map((elementName) => {
				const element = elements[elementName]

				const elementSize = this.getSize(element.getBoundingClientRect())
				const previousElementSize = sizes && sizes[elementName]

				if (elementSize !== previousElementSize) {
					this.props.setElementSize(elementName, elementSize)
				}

				return null
			})
		}
	}

	getSizeClass(size) {
		const { displayMessaging } = this.props

		let smallMaxSize = 600
		let mediumMaxSize = 850

		if (false === displayMessaging) {
			smallMaxSize += 150
			mediumMaxSize += 150
		}

		if (size <= smallMaxSize) {
			return 'size-small'
		}

		if (size > smallMaxSize && size <= mediumMaxSize) {
			return 'size-medium'
		}

		return ''
	}

	onRouteChanged() {
		// close chat on route change
		this.props.setActiveConversation(undefined)
	}

	getUserEndpoint() {
		const userId = this.accessControl.getTokenProperty('id')
		return apiEndpoints.USER.replace(':userId', userId)
	}

	refreshTokenCallback(mustRefresh, refreshToken) {
		const { refreshTokenFailed } = this.state

		if (!refreshTokenFailed && mustRefresh && refreshToken) {
			const refreshTokenBody = {
				refresh_token: refreshToken
			}

			this.props.callApi(apiEndpoints.REFRESH_TOKEN, 'post', {
				body: JSON.stringify(refreshTokenBody)
			})
		}
	}

	handleLogout(e) {
		this.accessControl.logout(e, () => {
			// reload page if not on login
			const currentPath = window.location.pathname
			const loginPath = reverse(routesList.login)

			if (currentPath !== loginPath) {
				window.location.reload()
			}
		})
	}

	handleNavCallback(e, navItem) {
		e.preventDefault()

		this.props.history.push(reverse(navItem.path))
	}

	handleCreateProjectCallback() {
		this.setState((state, props) => ({
			openProjectRequestPopin: true,
		}))
	}

	handleUserProfileClick(e) {
		e.preventDefault()
		this.props.history.push(reverse(routesList.user_profile))
	}

	handleLogoClick(e) {
		e.preventDefault()
		this.props.history.push(reverse(routesList.projects.index))
	}

	handleCancelRequestForm() {
		this.setState((state, props) => ({
			openProjectRequestPopin: false,
		}))
	}

	handleNotificationsOverlayClick(e) {
		e.preventDefault()

		this.props.displayNotificationsOverlay(false)
		this.props.displayDecoratorsAction(true)
	}

	handleNotificationClick(notification) {
		console.log('handleNotificationClick notification', notification)

		this.props.displayNotificationsOverlay(false)

		switch(notification.type) {
			// redirect to project
			case notificationTypes.NOTIFICATION_TYPE_TASK_CREATED:
				if (notification.data && notification.data.task) {
					const {
						id: taskId,
						projectId,
						projectPipelineSectionId,
					} = notification.data.task

					// redirect to project detail
					let path = reverse(routesList.projects.detail.index, {
						projectId: projectId,
					})

					path = `${path}?pps=${projectPipelineSectionId}&t=${taskId}`

					console.log('this.props.history', this.props.history)
					console.log('path', path)

					this.props.history.push(path)
				}
				break
			default:
				return null
		}
	}

	handleCollapseLeftMenuClick() {
		const newCollapse = !this.props.displayLeftMenu

		this.props.collapseLeftMenu(newCollapse)
	}

	handleInformationsClick() {
		const { isInformationExpanded } = this.state
		this.setState((state, props) => ({
			isInformationExpanded: !isInformationExpanded,
		}))
	}

	handleProjectNavClick(e, project) {
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		// redirect to project detail
		const path = reverse(routesList.projects.detail.index, { projectId: project.id })
		this.props.history.push(path)
	}

	handleCreateAdminProjectCallback() {
		this.props.history.push(reverse(routesList.projects.new))
	}

	render() {
		const {
			headerUsername,
			openProjectRequestPopin,
			isInformationExpanded,
			adminProjects,
		} = this.state

		const {
			displayMessaging,
			displayHeader,
			displayDecorators,
			displayLeftMenu,
			centerClass,
			isNotificationsOverlayIsOpened,
			isCollectionsPopinOpen,
			collectionType,
			collectionItem,
			sizes,
		} = this.props

		const collapsedClass = (displayLeftMenu) ? 'is-collapsed': ''
		const messagingClass = (false === displayMessaging) ? '--large': ''

		const appClass = `App app ${collapsedClass}`

		const headerClass = (true === displayHeader || typeof displayHeader === 'undefined') ? '' : 'hidden'

		const middleClassSize = (sizes && sizes['middle-block']) ? this.getSizeClass(sizes['middle-block']) : ''

		const loginFinishClass = (centerClass === 'full-width no-padding no-margin') ? 'is-done' : ''
		const loginClass = (!this.accessControl.isAuthenticated()) ? `login-form ${loginFinishClass}` : ''

	    return (
	        <section className={appClass}>

	        	{true === displayDecorators && <span className="app__decorator app__decorator--top"></span>}
	        	{true === displayDecorators && <span className="app__decorator app__decorator--left"></span>}
	        	{true === displayDecorators && <span className="app__decorator app__decorator--right"></span>}

		        {true === displayDecorators && this.accessControl.isAuthenticated()
		        	&& <span className="app__decorator app__decorator--bot"></span>
		        }

		        {true === displayDecorators && true === displayHeader && <span className="app__decorator app__decorator--header"></span>}
		        {true === displayDecorators && true === displayHeader && <span className="app__decorator app__decorator--wrapper"></span>}

		        {true === displayDecorators && this.accessControl.isAuthenticated() && <span className="app__decorator app__decorator--sidebar-left"></span>}
		        {true === displayDecorators && this.accessControl.isAuthenticated() && false !== displayMessaging && <span className="app__decorator app__decorator--sidebar-right"></span>}

		        {true === displayDecorators && this.accessControl.isAuthenticated() && <span className="app__decorator app__decorator--middleblock-left"></span>}
		        {true === displayDecorators && this.accessControl.isAuthenticated() && false !== displayMessaging && <span className="app__decorator app__decorator--middleblock-right"></span>}

		    	{ /* MOBILE DECORATORS */ }
		        {true === displayDecorators && this.accessControl.isAuthenticated() && <span className="app__decorator app__decorator--mobile-wrapper-bot"></span>}
		        {true === displayDecorators && this.accessControl.isAuthenticated() && <span className="app__decorator app__decorator--mobile-nav-top"></span>}

	        	{ /* HEADER */ }
	        	{true === displayHeader &&
	        		<Header
	        			logoutCallback={this.handleLogout}
	        			userProfileCallback={this.handleUserProfileClick}
	        			username={headerUsername}
	        			logoClickCallback={this.handleLogoClick}
	        			notificationClickCallback={this.handleNotificationClick}
	        			className={headerClass}
	        			informationClickCallback={this.handleInformationsClick}
	        			isInformationExpanded={isInformationExpanded}
	        		/>
	        	}

	        	<div className={`app__wrapper ${loginClass}`}>
		        	{/* LEFT MENU */}
		        	{this.accessControl.isAuthenticated() &&
		        		<LeftMenu
			        		sections={leftMenuConfig.sections}
			        		adminProjects={adminProjects}
			        		isAdmin={this.isAdmin}
			        		navCallback={this.handleNavCallback}
			        		createProjectCallback={this.handleCreateProjectCallback}
			        		createAdminProjectCallback={this.handleCreateAdminProjectCallback}
			        		collapseCallback={this.handleCollapseLeftMenuClick}
			        		collapseMenu={displayLeftMenu}
			        		projectCallback={this.handleProjectNavClick}
		        		/>
		        	}

		        	<section
		        		className={`middle-block App__content__center${messagingClass} ${middleClassSize} ${(centerClass) ? centerClass : ''}`}
		        		ref={this.refCallback}
		        	>
			        	{/* BODY */}
			        	<ChatCenterWrapper />

			        	<Routes />
		        	</section>

		        	{/* MESSAGING MENU */}
		        	{false !== displayMessaging && this.accessControl.isAuthenticated() &&
		        		<MessagingMenu />
		        	}

		        	{openProjectRequestPopin &&
		        		<Popin
		        			isOpened={openProjectRequestPopin}
		        			title='Sell on Ubikon'
		        			onClose={this.handleCancelRequestForm}
		        		>
		        			<RequestFormPopin cancelCallback={this.handleCancelRequestForm} />
		        		</Popin>
		        	}
	        	</div>

	        { /* MOBILE BOTTOM NAV */ }
	        	{this.accessControl.isAuthenticated() &&
		        	<MobileLeftMenu
		        		sections={leftMenuConfig.sections}
		        		navCallback={this.handleNavCallback}
		        	/>
	        	}

	        	{isNotificationsOverlayIsOpened &&
	        		<div className="Notifications__overlay" onClick={(e) => this.handleNotificationsOverlayClick(e)}></div>
	        	}

				{true === isCollectionsPopinOpen &&
					<Popin
						isOpened={isCollectionsPopinOpen}
						title='Add to a collection'
						className="fourty"
						onClose={this.handleCollectionsPopinClose}
					>
						<CollectionsPopin
							collectionType={collectionType}
							collectionItem={collectionItem}
						/>
					</Popin>
				}
	        </section>
	    );
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint, apiError, apiErrorEndpoint } = state.api
	const { displayMessaging } = state.messaging
	const { centerClass, displayHeader, displayDecorators, sizes } = state.layout
	const { isNotificationsOverlayIsOpened } = state.notifier
	const { displayLeftMenu } = state.leftMenu
	const { isCollectionsPopinOpen, collectionType, collectionItem } = state.collections

	return {
		apiResponse,
		apiResponseEndpoint,
		displayMessaging,
		displayLeftMenu,
		apiError,
		apiErrorEndpoint,
		centerClass,
		displayHeader,
		displayDecorators,
		isNotificationsOverlayIsOpened,
		isCollectionsPopinOpen,
		collectionType,
		collectionItem,
		sizes,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		resetApiError: () => dispatch(resetApiError()),
		displayNotificationsOverlay: (isOpened) => dispatch(displayNotificationsOverlay(isOpened)),
		displayDecoratorsAction: (displayBool) => dispatch(displayDecorators(displayBool)),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
		closeCollectionsPopin: () => dispatch(closeCollectionsPopin()),
		setElementSize: (key, value) => dispatch(setElementSize(key, value)),
		setConfiguration: (configuration) => dispatch(setConfiguration(configuration)),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(App)))