import ActionTypes from 'constants/action-types';

export const displayMessaging = (displayBool) => {
	return {
		type: ActionTypes.MESSAGING_HIDE,
		displayBool
	}
}

export const setActiveConversation = (conversation) => {
	return {
		type: ActionTypes.MESSAGING_SET_ACTIVE_CONVERSATION,
		conversation
	}
}

export const listenToTopic = (hubUrl, topic, callback) => {
	return {
		type: ActionTypes.MESSAGING_LISTEN_TOPIC,
		hubUrl,
		topic,
		callback
	}
}