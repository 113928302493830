import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { withTranslation } from 'react-i18next'

import FilterButton from 'components/buttons/FilterButton'
import TextButton from 'components/buttons/TextButton'

export const FilterRange = (props) => {
	const {
		t,
		clickOutsideCallback,
		btnClickCallback,
		filterChangeCallback,
		filterSubmitCallback,
		isClicked,
		className,
		valueMin,
		valueMax,
		boxTitle,
		boxName,
		boxUnit,
	} = props

	const filterClass = (className) ? className : boxName
	const boxNameUpper = boxName.charAt(0).toUpperCase() + boxName.slice(1)
	const callbackName = `isClicked${boxNameUpper}`

	const minCallbackName = `${boxName}Min`
	const maxCallbackName = `${boxName}Max`

	const isSelectedClass = (valueMin || valueMax) ? 'is-selected' : ''

	if (typeof filterSubmitCallback === 'undefined') {
		return (
			<div className={isSelectedClass}>
				<p className="filters-modal__range__title">
					{t(boxTitle)}
				</p>

				<div className="filters-modal__range">
					<div className="filters-modal__range__elem">
						<p className="filters-modal__range__elem__title">{t('filterRangeMin')}</p>
						<div className="filters-modal__range__input-ctn">
							<input
								type='text'
								className="filters-modal__range__input"
								onChange={(e) => filterChangeCallback(e, minCallbackName)}
								value={valueMin}
							/>

							{boxUnit &&
								<span className="filters-modal__range__metric">
									{boxUnit}
								</span>
							}
						</div>
					</div>

					<div className="filters-modal__range__elem">
						<p className="filters-modal__range__elem__title">{t('filterRangeMax')}</p>
						<div className="filters-modal__range__input-ctn">
							<input
								type='text'
								className="filters-modal__range__input"
								onChange={(e) => filterChangeCallback(e, maxCallbackName)}
								value={valueMax}
							/>

							{boxUnit &&
								<span className="filters-modal__range__metric">
									{boxUnit}
								</span>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<FilterButton
			text={t(boxTitle)}
			onClick={(e) => btnClickCallback(e, callbackName)}
			isClicked={isClicked}
			className={`${filterClass} ${isSelectedClass}`}
		>
			<OutsideClickHandler onOutsideClick={() => clickOutsideCallback()}>
				<div className="Filter__input__wrapper">
					<label className="small upper">{t('filterRangeMin')}</label>
					<input
						type='text'
						className="TextInput SquareMetersFilter"
						onChange={(e) => filterChangeCallback(e, minCallbackName)}
						value={valueMin}
					/>
				</div>

				<div className="Filter__input__wrapper">
					<label className="small upper">{t('filterRangeMax')}</label>
					<input
						type='text'
						className="TextInput SquareMetersFilter"
						onChange={(e) => filterChangeCallback(e, maxCallbackName)}
						value={valueMax}
					/>
				</div>

				<TextButton
					text='filterRangeSubmitBtn'
					className="Filter__button__submit small upper blue float-right"
					onClick={(e) => filterSubmitCallback(e, boxName)}
				/>
			</OutsideClickHandler>
		</FilterButton>
	)
}

export default withTranslation()(FilterRange)