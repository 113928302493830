import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse, upload, resetUploadResponse } from 'actions/api'
import { media } from 'media'
import { CustomCheckbox, FileInput } from 'components/forms/inputs'
import AccessControl from 'auth/access-control'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import Label from 'components/forms/inputs/Label'
import taskTypes from 'constants/task-types'
import TextButton from 'components/buttons/TextButton'
import ProjectDashboardBox from 'components/projects/ProjectDashboardBox'
import Spinner from 'components/spinner/Spinner'

class TaskDetail extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			selectedFilesToUpload: [],
			isUploadingFiles: false,
			uploadSuccess: undefined,
		}

		// bind methods
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
		this.handleFileInputChange = this.handleFileInputChange.bind(this)
		this.handleESignClick = this.handleESignClick.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
		this.accessControl = new AccessControl()
		this.myRef = React.createRef()
	}

	componentDidMount() {
		const { task } = this.props
		const { isActiveNotification } = task

		if (true === isActiveNotification) {
			this.scrollToMyRef()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { uploadSuccess, selectedFilesToUpload } = this.state
		const {
			task,
			apiResponse,
			uploadResponse,
			uploadEndpoint,
			projectDetailCallback,
		} = this.props

		const taskUploadEndpoint = `tasks/${task.id}/upload-file`

		if (uploadResponse && taskUploadEndpoint === uploadEndpoint) {
			this.props.resetUploadResponse()

			let isUploadSuccess = false

			if (true === uploadResponse.success) {
				// update selected task filenames
				selectedFilesToUpload.map((fileToUpload) => {
					const endpoint = this.endpointBuilder.getEndpoint('task_file_to_upload', {id: fileToUpload.id})
					const fileToUploadData = {
						isUploaded: true
					}

					this.props.callApi(endpoint, 'put', {
						body: JSON.stringify(fileToUploadData)
					})

					return null
				})

				isUploadSuccess = true
			}

			this.setState((state, props) => ({
				isUploadingFiles: false,
				uploadSuccess: isUploadSuccess,
			}))
		}

		if (apiResponse) {
			this.props.resetApiResponse()
		}

		if (true === uploadSuccess) {
			setTimeout(() => {
				// callback to ProjectDetail
				projectDetailCallback()

				this.setState((state, props) => ({
					uploadSuccess: undefined
				}))
			}, 3000)
		}
	}

	getTasksTitle(task, editTaskCallback) {
		const boxTitle = (taskTypes.TASK_TYPE_FILE_UPLOAD === task.fileType) 
			? 'Documents to upload' : 'Documents to sign'
		const dueDate = new Date(task.dueDate)

		const avatars = []
		task.partners.map((partner, i) => {
			const avatar = (partner.user.avatar) ? media.getMediaUrl(partner.user.avatar.contentUrl) : undefined
			avatars.push(avatar)

			return null
		})

		const taskTitle = (task.title) ? task.title : boxTitle

		// check if user can edit task
		const userId = this.accessControl.getUserId()
		const canEdit = (parseInt(userId) === parseInt(task.createdBy.id))

		// check if due date is coming soon
		const comingSoonInterval = 5
		const dateNow = new Date()
		const dateInterval = parseInt((((dueDate.getTime() - dateNow.getTime()) / 1000) / 3600) / 24)

		return (
			<div>
				<h3>
					{taskTitle} <span className="count">{dueDate.toLocaleDateString()}</span>
				</h3>

				{dateInterval >= 0 && dateInterval <= comingSoonInterval &&
					<p className="TaskBox__date__alert">
						<Trans i18nKey="projectDetailTaskComingSoon">projectDetailTaskComingSoon</Trans>
					</p>
				}

				<div className="TaskBox__Partners__List">
					<span className="medium">
						<Trans i18nKey="projectDetailTaskAssignedTo">projectDetailTaskAssignedTo</Trans>
					</span>

					{Array.from(avatars).map((avatarUrl, i) => {
						const avatarStyle = {
							backgroundImage: (avatarUrl) ? `url(${avatarUrl})`: null,
						}

						return (
							<div className="Taskbox__Partner__Avatar" key={i} style={avatarStyle}></div>
						)
					})}
				</div>

				{canEdit &&
					<div className="float-right">
						<ImageButton image={icons.edit} altText='Edit Task' onClick={(e) => editTaskCallback(e, task)} />
						<TextButton text='projectDetailTaskEditBtn' className="upper small" onClick={(e) => editTaskCallback(e, task)} />
					</div>
				}
			</div>
		)
	}

	getTaskFileUploadEndpoint(task) {
		return this.endpointBuilder.getEndpoint('upload_task_file', {taskId: task.id})
	}

	truncateFilename(fullStr, strLen, separator) {
	    if (fullStr.length <= strLen) return fullStr;

	    const sepLen = separator.length,
	        charsToShow = strLen - sepLen,
	        frontChars = Math.ceil(charsToShow/2),
	        backChars = Math.floor(charsToShow/2);

	    return fullStr.substr(0, frontChars) + 
	           separator + 
	           fullStr.substr(fullStr.length - backChars);
	}

	handleCheckboxChange(isChecked, fileToUpload) {
		const { selectedFilesToUpload } = this.state

		if (isChecked) {
			const newSelectedFiles = [
				...selectedFilesToUpload,
				fileToUpload,
			]

			this.setState((state, props) => ({
				selectedFilesToUpload: newSelectedFiles
			}))
		} else {
			const newSelectedFiles = selectedFilesToUpload.filter((file) => {
				return fileToUpload.id !== file.id
			})

			this.setState((state, props) => ({
				selectedFilesToUpload: newSelectedFiles
			}))
		}
	}

	handleFileInputChange(selectedFiles, field) {
		// upload files
		const uploadEndpoint = this.getTaskFileUploadEndpoint(field.task)

		Object.keys(selectedFiles).map((filename) => {
			const file = selectedFiles[filename]
			this.props.upload(uploadEndpoint, filename, file)

			return null
		})

		this.setState((state, props) => ({
			isUploadingFiles: true,
			selectedFilesToUpload: [],
		}))
	}

	handleESignClick(task, taskFile) {
		const { signatureCallback } = this.props

		signatureCallback(task, taskFile)
	}

	scrollToMyRef() {
		const divToScrollTo = document.getElementById('project-detail')
		if (divToScrollTo) {
			divToScrollTo.scrollBy({
				top: this.myRef.current.offsetTop + 80,
				left: 0,
				behavior: 'smooth',
			})
		}
	}

	render() {
		const { isUploadingFiles, uploadSuccess, selectedFilesToUpload } = this.state
		const { task, editTaskCallback } = this.props
		const { isActiveNotification } = task
		const boxTitle = this.getTasksTitle(task, editTaskCallback)
		const fileInputKey = `task-${task.id}`
		const field = {
			task,
			props: {
				multiple: true,
				name: 'task_files',
				id: 'task_files',
			}
		}

		const userId = this.accessControl.getUserId()
		const boxClass = (true === isActiveNotification) ? 'notification-active' : ''

		const isBtnDisabled = !selectedFilesToUpload || selectedFilesToUpload.length === 0

		return (
			<ProjectDashboardBox title={boxTitle} className={boxClass}>
				{task.description &&
					<div className="Task__description">
						<h4>Description</h4>
						<p>{task.description}</p>
					</div>
				}

				{taskTypes.TASK_TYPE_FILE_UPLOAD === task.fileType &&
					<div ref={this.myRef} id={task.id}>
						<form>
							<ul className="Task__FilesList">
								{task.filesToUpload && task.filesToUpload.map((fileToUpload, ift) => {
									return (
										<li className="Task__FilesList__Item" key={ift}>
											<CustomCheckbox
												value={fileToUpload}
												isChecked={fileToUpload.isUploaded}
												onChangeCallback={this.handleCheckboxChange}
												disabled={fileToUpload.isUploaded}
												checkboxLabel={fileToUpload.filename}
											/>

											{(fileToUpload.isUploaded || uploadSuccess) &&
												<span className="done small">
													<Trans i18nKey="projectDetailTaskDone">projectDetailTaskDone</Trans>
												</span>
											}
										</li>
									)
								})}
							</ul>

							<Label
								forInput={fileInputKey}
								labelText='projectDetailTaskUploadListedFiles'
								className={`TaskFile__Upload__Input small upper ${(isBtnDisabled) ? 'is-disabled' : ''}`}
							/>

							{!isBtnDisabled &&
								<div className="Task__File__input">
									<FileInput
										field={field}
										displayPreviews={false}
										fileInputKey={fileInputKey}
										onChange={this.handleFileInputChange}
									/>
								</div>
							}

							{isUploadingFiles &&
								<Spinner className="TaskDetail" />
							}

							{true === uploadSuccess &&
								<p className="UploadMessage success">
									<Trans i18nKey="projectDetailTaskUploadSuccess">projectDetailTaskUploadSuccess</Trans>
								</p>
							}

							{false === uploadSuccess &&
								<p className="UploadMessage error">
									<Trans i18nKey="projectDetailTaskUploadError">projectDetailTaskUploadError</Trans>
								</p>
							}
						</form>
					</div>
				}

				{taskTypes.TASK_TYPE_SIGNATURE === task.fileType &&
					<div className="signatures" ref={this.myRef}>
						{task.taskFiles && task.taskFiles.map((taskFile, iFile) => {
							const { file } = taskFile
							const fullFilename = `${file.filename}.${file.extension}`
							const filename = this.truncateFilename(fullFilename, 30, '...')

							let signedByUsers = []
							let userHasSigned = false

							if (taskFile.taskSignatures) {
								taskFile.taskSignatures.map((signature, iS) => {
									const { isSigned, signer } = signature

									if (true === isSigned) {
										signedByUsers.push(signer.user)

										if (signer.user.id === userId) {
											userHasSigned = true
										}
									}

									return null
								})
							}

							return (
								<div className="sign" key={iFile}>
									<p className="sign__title">{filename}</p>

									<div className="sign__action">
										<TextButton
											text='taskDetailSignBtn'
											className="BlueButton upper"
											onClick={() => this.handleESignClick(task, taskFile)}
											disabled={userHasSigned}
										/>

										{signedByUsers.length > 0 && 
											<div className="sign__people">
												<p className="sign__people__text">
													<Trans i18nKey="projectDetailTaskSignedBy">projectDetailTaskSignedBy</Trans>
												</p>

												{signedByUsers.map((user, iU) => {
													const avatarStyle = {
														backgroundImage: (user.avatar) ? 
															`url(${media.getMediaUrl(user.avatar.contentUrl)})`
															: null,
													}

													return (
														<div
															className="sign__people__profile"
															style={avatarStyle}
															key={iU}
														>
														</div>
													)
												})}
											</div>
										}
									</div>
								</div>
							)
						})}
					</div>
				}
			</ProjectDashboardBox>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
		uploadResponse,
		uploadedFiles,
		uploadEndpoint
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
		uploadResponse,
		uploadedFiles,
		uploadEndpoint
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		upload: (endpoint, fileName, file, linkedEntity) => dispatch(upload(endpoint, fileName, file, linkedEntity)),
		resetUploadResponse: () => dispatch(resetUploadResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetail)