import React from 'react'

import { ReactComponent as IconArrowDown } from 'assets/svg/icon-arrow-down.svg'
import AdminSectionEntity from 'components/admin/AdminSectionEntity'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'

class AdminSection extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isExpanded: false,
		}

		// bind methods
		this.handleExpandClick = this.handleExpandClick.bind(this)
	}

	handleExpandClick() {
		const { isExpanded } = this.state

		this.setState((state, props) => ({
			isExpanded: !isExpanded
		}))
	}

	render() {
		const { isExpanded } = this.state
		const { section, entityClickCallback } = this.props

		const expandedClass = (true === isExpanded) ? 'is-expanded' : ''
		const buttonText = (!isExpanded) ? 'See more': 'See less'

		return (
			<div className={`AdminSection ${expandedClass}`}>
				<div className="AdminSection__header" onClick={this.handleExpandClick}>
					<h3 className="upper medium">{section.title}</h3>

					<div className="AdminSection__right float-right">
						{section.entities && section.entities.length > 0 &&
							<span className="small">{`${section.entities.length} update${(section.entities.length > 1) ? 's' : ''}`}</span>
						}

						<TextButton
							text={`${buttonText}`}
							className="upper small blue"
							onClick={this.handleExpandClick}
						/>

						<ImageButton
							svgImage={IconArrowDown}
							altText={buttonText}
							onClick={this.handleExpandClick}
						/>
					</div>
				</div>

				<div className="AdminSection__entities">
					{section.entities && section.entities.map((entity, i) => {
						return (
							<AdminSectionEntity
								key={i}
								entity={entity}
								section={section}
								entityClickCallback={entityClickCallback}
							/>
						)
					})}
				</div>
			</div>
		)
	}
}

export default AdminSection