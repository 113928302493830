import React from 'react'

import NavSection from 'components/navigation/NavSection'
import AccessControl from 'auth/access-control'

export const MobileLeftMenu = (props) => {
	const {
		sections,
		navCallback,
	} = props
	const accessControl = new AccessControl()

	const menuClass = `left-sidebar sidebar-block is-mobile`

	return (
		<aside className={menuClass}>
			<div className="sidebar-block__inner">
				{
					sections && sections.map((section, i) => {
						// check role
						const hasRole = typeof section.role !== 'undefined' ? accessControl.hasRole(section.role): true

						if (hasRole) {
							return (
								<NavSection
									key={i}
									section={section}
									isMobile={true}
									navCallback={navCallback}
								/>
							)
						} else {
							return null
						}
					})
				}
			</div>
		</aside>
	)
}

export default MobileLeftMenu