import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from 'translations/en'
import fr from 'translations/fr'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en,
            fr,
        },
        fallbackLng: 'fr',
        // debug: process.env.NODE_ENV !== 'production',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    })

export default i18n