export const apiEndpoints = {
	GET_AUTH_TOKEN: 'authentication_token',
	REFRESH_TOKEN: 'token/refresh',
	POST_USERS: 'users',
	USER: 'users/:userId',
	GET_PROJECT_FORM: 'projects/get-project-form',
	GET_PROJECT_EDIT_FORM: 'projects/:id/get-project-edit-form',
	PROJECTS: 'projects',
	POST_PROJECT: 'projects',
	GET_PROJECTS: 'projects',
	GET_PROJECT_TYPES: 'project_types',
	GET_PROJECTS_FILTERS_FORM: 'projects/get-projects-filters-form',
	GET_PROJECT_PARTNER_FORM: 'projects/:projectId/get-project-partner-form',
	GET_PROJECT_TASK_FORM: 'projects/:projectId/get-project-task-form/:projectPipelineSectionId',
	GET_TASK_FILE_UPLOAD_FORM: 'task_files/get-file-upload-form',
	GET_PROJECT_ANALYSIS: 'projects/:projectId/get-analysis',
	PERSIST_ANALYSIS: 'analysis_project_row/:id/update',
	ANALYSIS_PROJECT_CONFIGS: 'analysis_project_configs',
	ANALYSIS_PROJECT_CONFIG: 'analysis_project_configs/:id',
	GET_PROJECT_OFFER_FORM: 'projects/:projectId/:projectPipelineSectionId/get-project-offer-form',
	GET_REQUEST_OFFER_FORM: 'offer_requests/get-offer-request-form',
	OFFER_REQUESTS: 'offer_requests',
	OFFERS_INDEX: 'offers/get-offers-index',
	POST_PROJECT_PARTNER: 'partners',
	POST_PROJECT_TASK: 'tasks',
	GET_PROJECT_TASK: 'tasks',
	POST_PROJECT_OFFER: 'offers/post-offer',
	GET_PROJECT: 'projects/',
	GET_LAND_FORM: 'lands/get-land-form',
	POST_LAND: 'lands/post-land',
	GET_LANDS: 'lands',
	GET_LAND: 'lands/',
	LAND: 'lands/:landId',
	LAND_VALIDATE: 'lands/:landId/validate',
	GET_BUCKETS: 'buckets',
	OFFER_PUT: 'offers/:offerId',
	GET_OFFER_MESSAGE_FORM: 'offers/:offerId/get-offer-message-form',
	POST_MESSAGE: 'messages/post-message/:conversationId',
	GET_CONVERSATION: 'conversations/:conversationId',
	GET_CONVERSATION_MESSAGES: 'conversations/:conversationId/messages',
	GET_USERS_CONVERSATION: 'conversations/start-conversation/:receiverId',
	CONVERSATION_FORM: 'conversations/get-conversation-form',
	CONVERSATIONS: 'conversations',
	CONVERSATION: 'conversations/:id/put',
	GET_CONVERSATION_ADD_USER_FORM: 'conversations/:conversationId/get-add-user-form',
	GET_USER_EDIT_FORM: 'users/get-user-edit-form',
	UPLOAD: 'media_objects',
	UPLOAD_TASK_FILE: 'tasks/:taskId/upload-file',
	ORGANIZATIONS: 'organizations',
	ORGANIZATION: 'organizations/:organizationId',
	GET_ORGANIZATION_ADD_USER_FORM: 'organizations/:organizationId/get-add-user-form',
	GET_ORGANIZATION_FORM: 'organizations/get-form',
	ADDRESSES: 'addresses',
	ADDRESS: 'addresses/:addressId',
	NOTIFICATIONS: 'notifications',
	NOTIFICATIONS_READ: 'notifications/set-read',
	CHAT_GET_USER_CHAT: 'users/get-user-chat',
	TASK_FILE_TO_UPLOAD: 'task_file_to_uploads/:id',
	DOWNLOAD_AMAZON_S3: 'amazon_s3_objects/:id/download',
	PROJECT_REQUESTS: 'projects/project-requests',
	PROJECT: 'projects/:projectId',
	PROJECT_BUCKETS: 'projects/:projectId/get-buckets',
	PARTNER: 'partners/:partnerId',
	PROJECT_PIPELINE_SECTION: 'project_pipeline_sections/:id',
	PROPERTIES: 'properties',
	GET_PROPERTY_FORM: 'properties/get-property-form',
	POST_PROPERTY: 'properties/post-property',
	PROPERTY_ITEMS: 'property_items',
	PROPERTY_ITEM: 'property-item-single/:id',
	PROPERTY: 'properties/:propertyId',
	ADMIN_SECTIONS: 'admin/get-sections',
	ADMIN_PROJECTS_LIST: 'admin/get-admin-projects-list',
	ADMIN_SEND_DENY_MESSAGE: 'admin/send-deny-message',
	//PROJECT_REQUESTS: 'project_requests',
	PROJECT_REQUEST: 'project_requests/:projectRequestId',
	GET_PROJECT_REQUEST_FORM: 'project_requests/get-project-request-form/:projectRequestType/:id',
	ORGANIZATION_REQUESTS: 'organization_requests',
	ORGANIZATION_REQUEST: 'organization_requests/:id',
	OFFER_DIRECTORIES: 'offer_directories',
	OFFER_DIRECTORY: 'offer_directories/:offerId',
	OFFER_QUOTES: 'offer_quotes',
	OFFER_QUOTE: 'offer_quotes/:id',
	TASK_SIGNATURES: 'task_signatures',
	TASK_SIGNATURE: 'task_signatures/:id',
	GET_TASK_SIGNATURE_URL: 'task_signatures/:taskFileId/get-hello-sign-url',
	SET_TASK_SIGNATURE_DATA: 'task_signatures/:taskId/set-signature-data',
	OFFER_REFUSALS: 'offer_refusals',
	ACTIVITY_FEED: 'projects/get-activity-feed',
	FILE_FOLDERS: 'file_folders',
	GET_PROJECT_FILE_UPLOAD_FORM: 'file_folders/:projectPipelineSectionId/get-project-file-upload-form',
	POST_PROJECT_FILE: 'projects/:projectPipelineSectionId/post-file',
	POST_EDIT_PROJECT: 'projects/:id/post-project-edit-form',
	COLLECTIONS: 'collections',
	COLLECTION: 'collections/:id',
	COLLECTIONS_GET_FORM: 'collections/get-collections-form',
	COLLECTION_ITEMS: 'collection_items',
	COLLECTION_ITEM: 'collection_items/:id',
	COLLECTION_CONVERSATION: 'conversations/get-collection-conversation/:collectionId',
	PROJECT_TYPES: 'project_types',
	GET_USER_ORGANIZATION: 'organizations/get-user-organization',
	GET_TABS_COUNTS: 'projects/get-tabs-counts',
	ASSIGN_TASKS: 'tasks/assign-tasks',
	CONTACT_FORM_SUBMIT: 'contact-form/submit',
	CONTACT_PROPERTY_SELLER: 'contact-property-seller/:propertyItemId',
	FORGOTTEN_PASSWORD: 'forgotten-password/send-email',
	FORGOTTEN_PASSWORD_VALIDITY: 'forgotten-password/token-validity',
	RESET_PASSWORD: 'reset-password',
	BENEFITS: 'benefits',
	CONFIG: 'configurations',
}

export default apiEndpoints