import React from 'react'
import { Trans, withTranslation } from 'react-i18next'

import { media } from 'media'
import { ReactComponent as IconArrowSmall } from 'assets/svg/icon-arrow-small.svg'
import { ReactComponent as IconDownload } from 'assets/svg/icon-download.svg'
import { ReactComponent as IconFile } from 'assets/svg/icon-file.svg'
import { ReactComponent as IconFolder } from 'assets/svg/icon-folder.svg'
import { ReactComponent as IconFolderActive } from 'assets/svg/icon-folder-active.svg'
import truncateFilename from 'utils/truncateFilename'
import ImageButton from 'components/buttons/ImageButton'

const convertFilesize = (aSize) => {
	aSize = Math.abs(parseInt(aSize, 10));
	const def = [[1, 'octets'], [1024, 'ko'], [1024*1024, 'Mo'], [1024*1024*1024, 'Go'], [1024*1024*1024*1024, 'To']];

	for(let i = 0; i < def.length; i++){
		if (aSize < def[i][0]) {
			return (aSize / def[i - 1][0]).toFixed(2) +' '+ def[i - 1][1];
		}
	}
}

const getFolderTitle = (title, count, countLabel, isOpened, folderClickCallback) => {
	const arrowClass = (true === isOpened) ? 'is-opened' : ''

	return (
		<div className="folder__group" onClick={() => {
			if (folderClickCallback) {
				folderClickCallback(title)
			}
		}}>
			<h3 className="folder__title">
				<IconArrowSmall className={arrowClass} />

				{true === isOpened &&
					<IconFolderActive />
				}

				{true !== isOpened &&
					<IconFolder />
				}

				{title}

				{getFolderCount(count, countLabel)}
			</h3>
		</div>
	)
}

const getFolderCount = (count, countLabel) => {
	if (!count || count <= 0) {
		return null
	}

	return (
		<span className="folder__title__infos">
			<Trans i18nKey="projectFilesFolderCount" count={count}>
				{{count}} files
			</Trans>
		</span>
	)
}

const getIsOpened = (openedFolders, folderTitle) => {
	let isOpened = false

	if (openedFolders && openedFolders.length > 0) {
		openedFolders.map((openedFolderTitle) => {
			if (openedFolderTitle === folderTitle) {
				isOpened = true
			}

			return null
		})
	}

	return isOpened
}

const isFolderEmpty = (projectTasks, taskType) => {
	if (typeof projectTasks !== 'undefined') {
		let tasksCount = 0

		projectTasks.map((task) => {
			if (taskType === task.fileType) {
				tasksCount++
			}

			return null
		})

		return tasksCount === 0
	}

	return undefined
}

const handleFolderClick = (downloadFolderCallback, folder, taskType) => {
	if (typeof downloadFolderCallback !== 'undefined') {
		downloadFolderCallback(folder, taskType)
	}
}

const handleFolderLineClick = (fileObject, previewCallback) => {
	if (typeof previewCallback !== 'undefined') {
		previewCallback(fileObject)
	}
}

const getFolderHeader = (t, downloadFolderCallback, folder, taskType) => {
	return (
		<div className="folder__breadcrumb">
			<div className="folder__name">{t('projectFilesFolderHeaderName')}</div>
			<div className="folder__date">{t('projectFilesFolderHeaderSize')}</div>
			<div className="folder__date">{t('projectFilesFolderHeaderDate')}</div>
			<div className="folder__pending">{t('projectFilesFolderHeaderUploadedBy')}</div>
			<div className="folder__actions">
				<ImageButton
					svgImage={IconDownload}
					className="folder__actions__download"
					onClick={() => handleFolderClick(downloadFolderCallback, folder, taskType)}
				/>
			</div>
		</div>
	)
}

const getFolderLine = (key, filename, filesize, uploadDate, user, downloadCallback, previewCallback, fileObject) => {
	const { avatar, username } = user
	const partnerStyle = {
		backgroundImage: (avatar && avatar.contentUrl) ? `url(${media.getMediaUrl(avatar.contentUrl)})`: null,
	}

	return (
		<div className="folder__line" key={key} onClick={() => handleFolderLineClick(fileObject, previewCallback)}>
			<div className="folder__name">
				<IconFile className="folder__file__icon" />

				{truncateFilename(filename, 30, '...')}
			</div>
			<div className="folder__date">{convertFilesize(filesize)}</div>
			<div className="folder__date">{uploadDate.toLocaleDateString()}</div>
			<div className="folder__pending">
				<div className="folder__profile" style={partnerStyle}></div>
				<p>{username}</p>
			</div>
			<div className="folder__actions">
				{downloadCallback &&
					<ImageButton
						svgImage={IconDownload}
						className="folder__actions__download"
						altText={`Download ${filename}`}
						onClick={downloadCallback}
					/>
				}
			</div>
		</div>
	)
}

export const TaskFileFolder = (props) => {
	const {
		t,
		title,
		projectTasks,
		taskType,
		fileFolders,
		openedFolders,
		downloadCallback,
		downloadFolderCallback,
		folderClickCallback,
		previewCallback,
	} = props

	const isEmpty = isFolderEmpty(projectTasks, taskType)

	return (
		<div className="TaskFileFolder">
			<h3 className="offers__title">
				<p className="offers__title__text upper">
					{t(title)}
				</p>
			</h3>

			{fileFolders && fileFolders.length === 0 &&
				<div className="folder__line">{t('projectFilesEmpty')}</div>
			}

			{/* FILE FOLDER */}
			{fileFolders && fileFolders.map((fileFolder, iFF) => {
				const isOpened = getIsOpened(openedFolders, fileFolder.name)

				return (
					<div className="folders" key={iFF}>
						<div className="folder">
							{getFolderTitle(fileFolder.name, fileFolder.amazonS3Objects.length, 'files', isOpened, folderClickCallback)}

							{isOpened && getFolderHeader(t, downloadFolderCallback, fileFolder)}

							{isOpened && (!fileFolder.amazonS3Objects || fileFolder.amazonS3Objects.length === 0)  &&
								<div className="folder__line" key={`empty-${iFF}`}>
									<div className="folder__name">{t('projectFilesFolderEmpty')}</div>
								</div>
							}

							{isOpened && fileFolder.amazonS3Objects && fileFolder.amazonS3Objects.map((amazonFile, iAF) => {
								const filename = (amazonFile.originalFilename) ? truncateFilename(amazonFile.originalFilename, 16, '...') : `${truncateFilename(amazonFile.filename, 16, '...')}.${amazonFile.extension}`

								return getFolderLine(
									iAF,
									filename,
									amazonFile.filesize,
									new Date(amazonFile.createdAt),
									amazonFile.createdBy,
									(e) => downloadCallback(e, amazonFile, filename),
									previewCallback,
									amazonFile
								)
							})}
						</div>
					</div>
				)
			})}

			{true === isEmpty &&
				<div className="folder__line is-empty">{t('projectFilesEmpty')}</div>
			}

			{/* TASKS FILES */}
			{projectTasks && projectTasks.map((task, iT) => {
				if (taskType !== task.fileType) {
					return null
				}

				const isOpened = getIsOpened(openedFolders, task.title)

				return (
					<div className="folders" key={iT}>
						{task.taskFiles &&
							<div className="folder">
								{getFolderTitle(task.title, task.taskFiles.length, 'files', isOpened, folderClickCallback)}

								{isOpened && getFolderHeader(t, downloadFolderCallback, undefined, taskType)}

								{isOpened && (!task.taskFiles || task.taskFiles.length === 0)  &&
									<div className="folder__line" key={`empty-${iT}`}>
										<div className="folder__name">{t('projectFilesFolderEmpty')}</div>
									</div>
								}

								{isOpened && task.taskFiles && task.taskFiles.map((taskFile, iTF) => {
									const filename = (taskFile.file.originalFilename) ? truncateFilename(taskFile.file.originalFilename, 16, '...') : `${truncateFilename(taskFile.file.filename, 16, '...')}.${taskFile.file.extension}`

									return getFolderLine(
										iTF,
										filename,
										taskFile.file.filesize,
										new Date(taskFile.createdAt),
										taskFile.createdBy,
										(e) => downloadCallback(e, taskFile.file, filename),
										previewCallback,
										taskFile.file
									)
								})}
							</div>
						}
					</div>
				)
			})}
		</div>
	)
}

export default withTranslation()(TaskFileFolder)