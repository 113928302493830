import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import planningPng from 'assets/png/planning.png'

class ProjectSectionPlanning extends React.Component {
	constructor(props) {
		super(props)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	render() {
		return (
			<div className="ProjectSection--planning">
				<img src={planningPng} className="Planning__placeholder" alt="Planning" />
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSectionPlanning)