import AccessControl from 'auth/access-control'
import ApiNormalizer from 'api/ApiNormalizer'
import apiEndpoints from 'constants/api-endpoints'

class ApiClient {
	constructor(apiUrl) {
		this.apiUrl = apiUrl

		this.accessControl = new AccessControl()
		this.normalizer = new ApiNormalizer()
	}

	sendRequest(endpoint, method, params) {
		const requestUrl = `${this.apiUrl}${endpoint}`

		let defaultParams = {
			method: method,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}

		if (
			apiEndpoints.REFRESH_TOKEN !== endpoint
			&& this.accessControl.isAuthenticated()
		) {
			const token = this.accessControl.getToken()
			let newHeaders = {
				...defaultParams.headers,
				'Authorization': `Bearer ${token}`
			}

			if (params && params.headers) {
				newHeaders = {
					...newHeaders,
					...params.headers
				}

				delete params.headers
			}

			defaultParams = {
				...defaultParams,
				headers: newHeaders
			}
		}

		const requestParams = {
			...defaultParams,
			...params,
		}

		//console.log('requestParams', requestParams)

		return fetch(requestUrl, requestParams).then((response) => {
			if (!response.ok) {
				throw Error(response.statusText)
			}

			if (
				response.ok
				&& response.status === 204
				&& method === 'delete'
			) {
				return 204
			}

			return response.json().then((responseJson) => responseJson)
		})
	}

	upload(endpoint, fileName, file, linkedEntity) {
		// construct form data
		let bodyFormData = new FormData()
		bodyFormData.append('filename', fileName)
		bodyFormData.append('file', file)

		if (linkedEntity) {
			const { fieldName, className, id } = linkedEntity
			bodyFormData.append('linked_entity_field', fieldName)
			bodyFormData.append('linked_entity_classname', className)
			bodyFormData.append('linked_entity_id', id)
		}

		const requestUrl = `${this.apiUrl}${endpoint}`
		let headers = {}

		if (this.accessControl.isAuthenticated()) {
			const token = this.accessControl.getToken()
			headers = {
				...headers,
				'Authorization': `Bearer ${token}`
			}
		}

		return fetch(requestUrl, {
			method: 'post',
			body: bodyFormData,
			headers
		}).then((response) => {
			return response.json().then((responseJson) => responseJson)
		})
	}

	normalizeRequest(requestParams, fields) {
		return this.normalizer.normalize(requestParams, fields)
	}

	getIRI(endpoint, search, replace) {
		const iriPrefix = process.env.REACT_APP_IRI_PREFIX

		return `${iriPrefix}${endpoint.replace(search, replace)}`
	}
}

export default ApiClient