export const validateField = (field, value, formData) => {
	let minLengthValid = true
	let emailValid = true
	let specialCharsValid = true
	let sameAsValid = true
	let checkedValid = true

	if (typeof value !== 'undefined' && field.validation) {
		const { minLength, email, specialChars, sameAs, checked } = field.validation

		if (minLength && minLength > 0) {
			minLengthValid = value.length >= minLength
		}

		if (email && true === email) {
			emailValid = validateEmail(value)
		}

		if (specialChars && true === specialChars) {
			specialCharsValid = validateSpecialChars(value)
		}

		if (sameAs && formData) {
			sameAsValid = value === formData[sameAs]
		}

		if (checked && formData && field.props && field.props.name) {
			checkedValid = ('1' === formData[field.props.name] || true === formData[field.props.name])
		}
	}

	return minLengthValid && emailValid && specialCharsValid && sameAsValid && checkedValid
}

const validateEmail = (email) => {
	// eslint-disable-next-line
	const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regex.test(email)
}

const validateSpecialChars = (value) => {
	var regex = /^[A-Za-z0-9 ]+$/
	return !regex.test(value)
}

export default validateField