import React from 'react'
import PDFViewer from 'mgr-pdf-viewer-react'
import { Trans } from 'react-i18next'

import { ReactComponent as IconDownload } from 'assets/svg/icon-download.svg'
import ImageButton from 'components/buttons/ImageButton'
import Popin from 'components/layout/Popin'
import TextButton from 'components/buttons/TextButton'
import TextFileReader from 'components/preview/TextFileReader'
import truncateFilename from 'utils/truncateFilename'

class PreviewPopin extends React.Component {
	constructor(props) {
		super(props)

		// bind methods
		this.getPreviewType = this.getPreviewType.bind(this)
	}

	getPreviewType(file, fileType) {
		const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg']
		const txtExtensions = ['txt', 'rtf']

		let fileExtension = ('amazon' === fileType) ? file.extension : file.filePath.slice((file.filePath.lastIndexOf(".") - 1 >>> 0) + 2)

		if (fileExtension && imageExtensions.indexOf(fileExtension) > -1) {
			return 'image'
		} else if (fileExtension && txtExtensions.indexOf(fileExtension) > -1) {
			return 'text'
		} else if (fileExtension && 'pdf' === fileExtension) {
			return 'pdf'
		}

		return undefined
	}

	render() {
		const { file, fileType, downloadCallback, closePreviewCallback } = this.props

		if (!file) {
			return null
		}

		let filename = (file.originalFilename) ? file.originalFilename : ''
		if ('media_object' === fileType) {
			filename = (file.filePath) ? file.filePath : ''
		}

		const popinTitle = truncateFilename(filename, 45, '...')
		const fileUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}file/preview/${fileType}/${file.id}`
		const previewType = this.getPreviewType(file, fileType)

		return (
			<Popin
				isOpened={true}
				title={popinTitle}
				className="Preview__popin"
				onClose={closePreviewCallback}
			>
				{typeof previewType === 'undefined' &&
					<div className="Preview__missing">
						<p>
							<Trans i18nKey="previewFileMissing">This file cannot be displayed in a web browser.</Trans>
						</p>

						{downloadCallback &&
							<div className="Preview__download">
								<ImageButton
									svgImage={IconDownload}
									className="folder__actions__download"
									onClick={(e) => downloadCallback(e, file)}
								/>

								<TextButton
									text="Download"
									onClick={(e) => downloadCallback(e, file)}
								/>
							</div>
						}
					</div>
				}

				{'image' === previewType &&
					<img src={fileUrl} className="Preview__image" alt={`${filename} Preview`} />
				}

				{'text' === previewType &&
					<TextFileReader txt={fileUrl} />
				}

				{'pdf' === previewType &&
					<PDFViewer
						document={{
							url: fileUrl
						}}
						css="Preview__pdf"
					/>
				}
			</Popin>
		)
	}
}

export default PreviewPopin