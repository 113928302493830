import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { setTemporaryData } from 'actions/temporary-data'
import { displayMessaging } from 'actions/messaging'
import { collapseLeftMenu } from 'actions/left-menu'
import { openCollectionsPopin } from 'actions/collections'
import { setFilters } from 'actions/filters'
import { ReactComponent as IconSearch } from 'assets/svg/icon-search.svg'
import { ReactComponent as IconFilters } from 'assets/svg/icon-filters.svg'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ImageButton from 'components/buttons/ImageButton'
import MapList from 'components/map/MapList'
import MapWrapper from 'components/map/MapWrapper'
import PropertiesFilters from 'components/properties/PropertiesFilters'
import PropertiesFiltersPopin from 'components/properties/PropertiesFiltersPopin'
import PropertyListItem from 'components/properties/PropertyListItem'
import Pagination from 'components/pagination'
import Popin from 'components/layout/Popin'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'

class PropertiesIndex extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			properties: undefined,
			totalProperties: undefined,
			mapHeight: undefined,
			displayMap: true,
			propertiesPage: 1,
			isFiltering: false,
			isChangingPage: false,
			openFiltersPopin: false,
			filterValues: undefined,
			needsOptions: undefined,
		}

		// bind methods
		this.handlePropertyItemClick = this.handlePropertyItemClick.bind(this)
		this.handleFeatureClick = this.handleFeatureClick.bind(this)
		this.handlePageClick = this.handlePageClick.bind(this)
		this.handlePropertiesFilters = this.handlePropertiesFilters.bind(this)
		this.handleCloseFiltersPopin = this.handleCloseFiltersPopin.bind(this)
		this.handleOpenFiltersPopin = this.handleOpenFiltersPopin.bind(this)
		this.handlePropertyCollectionClick = this.handlePropertyCollectionClick.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
		this.propertiesPerPage = 4
	}

	componentDidMount() {
		// collapse menu
		this.props.collapseLeftMenu(true)

		// hide messaging
		this.props.displayMessaging(false)

		// get property items
		const getPropertiesEndpoint = this.getPropertiesEndpoint(1)
		this.props.callApi(getPropertiesEndpoint, 'get', {
			headers: {
				'Accept': 'application/ld+json',
			}
		})

		// get benefits options
		const getBenefitsOptionsEndpoint = this.endpointBuilder.getEndpoint('benefits')
		this.props.callApi(getBenefitsOptionsEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const { properties, mapHeight } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const apiContext = (typeof apiResponse['@context'] !== 'undefined') ? apiResponse['@context'] : undefined
			if (apiContext === '/api/contexts/PropertyItem') {
				const response = (typeof apiResponse['hydra:member'] !== 'undefined') ? apiResponse['hydra:member'] : undefined

				const responseTotalProperties = response[0]
				const responseProperties = response[1]

				this.setState((state, props) => ({
					properties: responseProperties,
					totalProperties: responseTotalProperties,
					isFiltering: false,
					isChangingPage: false,
				}))
			} else {
				const getBenefitsOptionsEndpoint = this.endpointBuilder.getEndpoint('benefits')

				if (apiResponseEndpoint === getBenefitsOptionsEndpoint) {
					this.setState((state, props) => ({
						needsOptions: apiResponse,
					}))
				}
			}
		}

		if (!mapHeight && properties && this.mapContainer) {
			// set map height
			this.setState((state, props) => ({
				mapHeight: this.mapContainer.clientHeight
			}))
		}
	}

	componentWillUnmount() {
		//const { filters } = this.state

		// show messaging
		this.props.displayMessaging(true)

		// set filter data for detail page
		//this.props.setTemporaryData('properties-filters', filters)
	}

	getPropertiesEndpoint(page, filters) {
		const { storeFilters } = this.props
		let getPropertiesEndpoint = this.endpointBuilder.getEndpoint('property_items')
		let urlOperator = '?'

		if (page) {
			getPropertiesEndpoint = `${getPropertiesEndpoint}${urlOperator}page=${page}`
			urlOperator = '&'
		}

		let serializedFilters = undefined

		if (filters) {
			serializedFilters = this.getSerializedFilters(filters)
		} else if (storeFilters && storeFilters.properties) {
			serializedFilters = this.getSerializedFilters(storeFilters.properties)
		}

		if (serializedFilters) {
			getPropertiesEndpoint = `${getPropertiesEndpoint}${urlOperator}${serializedFilters}`
		}

		return getPropertiesEndpoint
	}

	getSerializedFilters(filters) {
		if (!filters) {
			return undefined
		}

		return Object.keys(filters).map((key) => {
			if (typeof filters[key] !== 'undefined') {
				return `filters[${key}]=${encodeURIComponent(filters[key])}`
			}

			return `${key}=''`
		}).join('&')
	}

	handlePropertyItemClick(e, propertyItem) {
		const { filterValues } = this.state
		this.props.setFilters('properties', filterValues)

		// redirect to property detail
		this.props.history.push(reverse(routesList.properties.detail.index, { propertyItemId: propertyItem.id }))
	}

	handleFeatureClick(propertyItem) {
		// redirect to property detail
		this.props.history.push(reverse(routesList.properties.detail.index, { propertyItemId: propertyItem.featureId }))
	}

	handleHideMapClick(e) {
		e.preventDefault()

		const { displayMap } = this.state

		this.setState((state, props) => ({
			displayMap: !displayMap
		}))
	}

	handlePageClick(page) {
		// get items by page
		const getPropertiesEndpoint = this.getPropertiesEndpoint(page)

		this.props.callApi(getPropertiesEndpoint, 'get', {
			headers: {
				'Accept': 'application/ld+json',
			}
		})

		this.setState((state, props) => ({
			propertiesPage: page,
			isChangingPage: true,
		}))
	}

	handlePropertiesFilters(filters) {
		const { isFiltering, propertiesPage } = this.state

		//console.log('handlePropertiesFilters filters', filters)

		if (!isFiltering) {
			const getPropertiesEndpoint = this.getPropertiesEndpoint(propertiesPage, filters)

			this.props.callApi(getPropertiesEndpoint, 'get', {
				headers: {
					'Accept': 'application/ld+json',
				}
			})

			this.setState((state, props) => ({
				isFiltering: true,
				openFiltersPopin: false,
				filterValues: filters,
			}))
		}
	}

	handleOpenFiltersPopin() {
		this.setState((state, props) => ({
			openFiltersPopin: true,
		}))
	}

	handleCloseFiltersPopin() {
		this.setState((state, props) => ({
			openFiltersPopin: false,
		}))
	}

	handlePropertyCollectionClick(propertyItem) {
		this.props.openCollectionsPopin('property', propertyItem)
	}

	render() {
		const {
			properties,
			totalProperties,
			mapHeight,
			displayMap,
			openFiltersPopin,
			isFiltering,
			isChangingPage,
			needsOptions,
		} = this.state

		let mapProperties = undefined

		if (properties) {
			mapProperties = properties.map((property, iP) => {
				return {
					...property,
					coordinates: property.property.coordinates
				}
			})
		}

		return (
			<div className="catalogue">
				<div className="all-projects__header">
					<div className="all-projects__actions is-mobile">
						<div className="all-projects__action filters">
							<ImageButton
								svgImage={IconFilters}
								className="action__filters"
								altText='Filter projects'
								//onClick={(e) => this.handleFilterBtnClick(e, 'filters')}
							/>					
						</div>

						<h1><Trans i18nKey="propertiesIndexPageTitle">propertiesIndexPageTitle</Trans></h1>

						<div className="all-projects__action search">
							<ImageButton
								svgImage={IconSearch}
								className="action__search"
								altText='Search projects'
								//onClick={(e) => this.handleFilterBtnClick(e, 'search')}
							/>
						</div>
					</div>
				</div>

				<PropertiesFilters
					filterCallback={this.handlePropertiesFilters}
					openFiltersPopinCallback={this.handleOpenFiltersPopin}
				/>

				{!properties &&
					<Spinner />
				}

				{properties &&
					<div
						className="catalogue__wrapper"
						ref={ (mapContainer) => this.mapContainer = mapContainer}
					>
						<div className="catalogue__properties">
							{isFiltering &&
								<Spinner />
							}

							{!isFiltering &&
								<div className="catalogue__properties__header">
									<p className="catalogue__properties__title">
										<Trans i18nKey="propertiesIndexCount" count={parseInt(totalProperties)}>
											We've found {{count: parseInt(totalProperties)}} land
										</Trans>
									</p>

									<Pagination
										totalItems={totalProperties}
										itemsPerPage={this.propertiesPerPage}
										pageClickCallback={this.handlePageClick}
									/>
								</div>
							}

							{!isFiltering && !isChangingPage &&
								<MapList
									items={properties}
									ItemComponent={PropertyListItem}
									itemCallback={this.handlePropertyItemClick}
									itemsPerPage={this.propertiesPerPage}
									totalItems={totalProperties}
									className="catalogue__properties__wrapper"
									itemClassName="catalogue__property"
									collectionClickCallback={this.handlePropertyCollectionClick}
								/>
							}

							{!isFiltering && isChangingPage &&
								<Spinner />
							}
						</div>

						{mapHeight && displayMap &&
							<MapWrapper
								items={mapProperties}
								mapType='properties'
								mapHeight={mapHeight}
								className='catalogue__map'
								featureClickCallback={this.handleFeatureClick}
								collectionClickCallback={this.handlePropertyCollectionClick}
							/>
						}
					</div>
				}

				{openFiltersPopin &&
					<Popin
						isOpened={openFiltersPopin}
						className="Properties__filter__popin"
						transTitle='propertiesFiltersPopinTitle'
						transTitleKey='propertiesFiltersPopinTitle'
						onClose={this.handleCloseFiltersPopin}
					>
						<PropertiesFiltersPopin
							needsOptions={needsOptions}
							filterSubmitCallback={this.handlePropertiesFilters}
						/>
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { ...temporaryData } = state.temporary_data
	const { filters: storeFilters } = state.filters
 
	return {
		apiResponse,
		apiResponseEndpoint,
		temporaryData,
		storeFilters,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setTemporaryData: (key, data) => dispatch(setTemporaryData(key, data)),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
		openCollectionsPopin: (collectionType, collectionItem) => dispatch(openCollectionsPopin(collectionType, collectionItem)),
		setFilters: (filterType, filterValues) => dispatch(setFilters(filterType, filterValues)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesIndex)