import React from 'react'
import { withTranslation } from 'react-i18next'

import AccessControl from 'auth/access-control'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'
import OfferStatusTable from 'components/offers/OfferStatusTable'
import truncateFilename from 'utils/truncateFilename'

const getPartnerOffer = (offer) => {
	if (offer.offerQuotes) {
		const accessControl = new AccessControl()
		const userPartnerId = accessControl.getUserPartnerId(offer)

		if (userPartnerId) {
			let partnerOfferQuote = undefined

			offer.offerQuotes.map((offerQuote, i) => {
				if (
					offerQuote.partner
					&& offerQuote.partner.id
					&& parseInt(offerQuote.partner.id) === userPartnerId
				) {
					partnerOfferQuote = offerQuote
				}

				return null
			})

			return partnerOfferQuote
		}
	}

	return undefined
}

export const OfferDetail = (props) => {
	const {
		t,
		offer,
		backBtnCallback,
		isProjectOwner,
		postQuoteClickCallback,
		offerQuoteMessageCallback,
		offerQuoteActionCallback,
		notInterestedClickCallback,
	} = props

	const accessControl = new AccessControl()

	const createdDate = new Date(offer.createdAt)
	const isOfferPartner = accessControl.isOfferPartner(offer)
	const isOfferCreator = accessControl.isOfferCreator(offer)
	const didPostQuote = accessControl.didPostQuote(offer)
	const didRefuseQuote = accessControl.didRefuseQuote(offer)

	const partnerOffer = getPartnerOffer(offer)

	return (
		<div className="OfferDetail">
			{offer &&
				<div>
					<div className="OfferDetail__header">
						<ImageButton image={icons.arrow} altText="Back to Offers list" onClick={(e) => backBtnCallback(e)} />

						<h2>{offer.title}</h2>

						<span className="Offer__date">{createdDate.toLocaleDateString()}</span>
					</div>

					<div className="Offer__description">
						{offer.description}
					</div>

					{offer.files &&
						<div className="Offer__files">
							{offer.files.map((file, iF) => {
								const filename = (file.originalFilename) ? truncateFilename(file.originalFilename, 16, '...') : `${truncateFilename(file.filename, 16, '...')}.${file.extension}`

								return (
									<div className="Offer__file__item" key={iF}>
										<img src={icons.fileBlue} alt="File" />
										<span>{filename}</span>
									</div>
								)
							})}
						</div>
					}

					{(true === isProjectOwner || true === isOfferCreator) &&
						<div className="Offer__partners">
							<OfferStatusTable
								offer={offer}
								tableType='interested'
								offerQuoteMessageCallback={offerQuoteMessageCallback}
								offerQuoteActionCallback={offerQuoteActionCallback}
							/>

							<OfferStatusTable
								offer={offer}
								tableType='not-interested'
							/>

							<OfferStatusTable
								offer={offer}
								tableType='pending'
							/>
						</div>
					}

					{isOfferPartner && !didPostQuote && !didRefuseQuote &&
						<div className="Offer__quote__form">
							<TextButton text="offerDetailPostQuoteBtn" onClick={() => postQuoteClickCallback(offer)} className="BlueButton" />

							<TextButton text="offerDetailRefuseBtn" onClick={() => notInterestedClickCallback(offer)} className="RedButton" />
						</div>
					}

					{isOfferPartner && didPostQuote && partnerOffer && !didRefuseQuote &&
						<div className="Offer__partners">
							<div className="Offer__partner__item">
								<div className="Offer__partner__header">
									<h3>{t('offerDetailYourQuote')}</h3>
								</div>

								<table className="Offer__partner__quotes">
									<thead>
										<tr>
											<th>{t('offerDetailTableSeller')}</th>
											<th className="information">{t('offerDetailTableInformation')}</th>
											<th>{t('offerDetailTableInformation')}</th>
											<th>{t('offerDetailTablePrice')}</th>
											<th>{t('offerDetailTableStatus')}</th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td></td>
											<td className="information">{truncateFilename(partnerOffer.description, 150, '...')}</td>
											<td>{partnerOffer.amount}</td>
											<td></td>
											<td>
												<span className="status">{partnerOffer.status}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					}

					{isOfferPartner && didRefuseQuote && !didPostQuote &&
						<div className="Offer__refused">
							<p>{t('offerDetailUserNotInterested')}</p>
						</div>
					}
				</div>
			}
		</div>
	)
}

export default withTranslation()(OfferDetail)