import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'

import { callApi, resetApiResponse } from 'actions/api'
import { displayMessaging } from 'actions/messaging'
import { collapseLeftMenu } from 'actions/left-menu'
import { openCollectionsPopin, resetDidAddToCollection } from 'actions/collections'
import { setFilters } from 'actions/filters'
import { media } from 'media'
import AccessControl from 'auth/access-control'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import BenefitsList from 'components/benefits/BenefitsList'
import Carousel from 'components/carousel'
import contactForm from 'components/forms/schemas/contactForm'
import ContactSellerForm from 'components/properties/ContactSellerForm'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import Popin from 'components/layout/Popin'
import PropertiesFilters from 'components/properties/PropertiesFilters'
import PropertiesFiltersPopin from 'components/properties/PropertiesFiltersPopin'
import PropertyAttributes from 'components/properties/PropertyAttributes'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import UbikonForm from 'components/forms/UbikonForm'

class PropertyDetail extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			propertyItem: undefined,
			carouselFullscreenImage: undefined,
			forceLike: false,
			openContactSellerPopin: false,
			didContactSeller: false,
			isContactingSeller: false,
			openFiltersPopin: false,
			needsOptions: undefined,
		}

		// bind methods
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)
		this.handleCarouselFullscreen = this.handleCarouselFullscreen.bind(this)
		this.handleLikeClick = this.handleLikeClick.bind(this)
		this.handleContactSellerClick = this.handleContactSellerClick.bind(this)
		this.handleClosePopin = this.handleClosePopin.bind(this)
		this.handleContactSellerSubmit = this.handleContactSellerSubmit.bind(this)
		this.handlePropertiesFilters = this.handlePropertiesFilters.bind(this)
		this.handleOpenFiltersPopin = this.handleOpenFiltersPopin.bind(this)
		this.handleCloseFiltersPopin = this.handleCloseFiltersPopin.bind(this)

		// set private properties
		this.propertyItemId = this.props.match.params.propertyItemId
		this.endpointBuilder = new ApiEndpointBuilder()
		this.accessControl = new AccessControl()

		const searchParams = new URLSearchParams(this.props.location.search)
		this.collectionId = searchParams.get('c')
	}

	componentDidMount() {
		// collapse menu
		this.props.collapseLeftMenu(true)

		// hide messaging
		this.props.displayMessaging(false)

		// get property item
		const propertyItemEndpoint = this.endpointBuilder.getEndpoint('property_item', {
			id: this.propertyItemId
		})

		this.props.callApi(propertyItemEndpoint, 'get')

		// get benefits options
		const getBenefitsOptionsEndpoint = this.endpointBuilder.getEndpoint('benefits')
		this.props.callApi(getBenefitsOptionsEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const { propertyItem } = this.state
		const { apiResponse, apiResponseEndpoint, newCollectionItem } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const propertyItemEndpoint = this.endpointBuilder.getEndpoint('property_item', {
				id: this.propertyItemId
			})

			const contactSellerEndpoint = this.endpointBuilder.getEndpoint('contact_property_seller', {
				propertyItemId: this.propertyItemId,
			})

			const getBenefitsOptionsEndpoint = this.endpointBuilder.getEndpoint('benefits')

			// did get property item
			if (apiResponseEndpoint === propertyItemEndpoint) {
				this.setState((state, props) => ({
					propertyItem: apiResponse.propertyItem,
				}))
			}

			// did contact seller
			if (apiResponseEndpoint === contactSellerEndpoint) {
				this.setState((state, props) => ({
					didContactSeller: true,
					isContactingSeller: false,
				}))
			}

			// did get benefits list
			if (apiResponseEndpoint === getBenefitsOptionsEndpoint) {
				this.setState((state, props) => ({
					needsOptions: apiResponse,
				}))
			}
		}

		if (
			propertyItem
			&& newCollectionItem
			&& newCollectionItem.itemType === 'property'
			&& newCollectionItem.propertyItem
			&& newCollectionItem.propertyItem === `/api/property_items/${propertyItem.id}`
		) {
			this.props.resetDidAddToCollection()

			this.setState((state, props) => ({
				forceLike: true,
			}))
		}
	}

	componentWillUnmount() {
		// show messaging
		this.props.displayMessaging(true)
	}

	getSellerWidget(propertyItem) {
		if (!propertyItem) {
			return null
		}

		let ownerStyle = undefined
		if (propertyItem && propertyItem.createdBy && propertyItem.createdBy.avatar) {
			const avatar = propertyItem.createdBy.avatar
			ownerStyle = { backgroundImage: `url(${media.getMediaUrl(avatar.contentUrl)})` }
		}

		return (
			<div className="LandDetail__owner">
				<div className="avatar" style={ownerStyle}></div>
				<p className="username">
					{propertyItem.createdBy.username}
				</p>
			</div>
		)
	}

	handleBackBtnClick() {
		// redirect to collection if defined
		if (typeof this.collectionId !== 'undefined' && null !== this.collectionId) {
			// redirect to collection detail
			const path = reverse(routesList.collections.detail.index, { collectionId: this.collectionId })
			this.props.history.push(path)
		} else {
			const redirectPath = reverse(routesList.properties.index)
			this.props.history.push(redirectPath)
		}
	}

	handleCarouselFullscreen(fullscreenImage) {
		this.setState((state, props) => ({
			carouselFullscreenImage: fullscreenImage
		}))
	}

	handleLikeClick() {
		const { propertyItem } = this.state
		this.props.openCollectionsPopin('property', propertyItem)
	}

	handleContactSellerClick() {
		this.setState((state, props) => ({
			openContactSellerPopin: true,
		}))
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			openContactSellerPopin: false,
		}))
	}

	handleContactSellerSubmit(formData) {
		const { propertyItem } = this.state

		const contactSellerEndpoint = this.endpointBuilder.getEndpoint('contact_property_seller', {
			propertyItemId: propertyItem.id,
		})

		this.props.callApi(contactSellerEndpoint, 'post', {
			body: JSON.stringify(formData),
		})

		this.setState((state, props) => ({
			isContactingSeller: true,
		}))
	}

	handlePropertiesFilters(filters) {
		this.props.setFilters('properties', filters)

		const redirectPath = reverse(routesList.properties.index)
		this.props.history.push(redirectPath)
	}

	handleOpenFiltersPopin() {
		this.setState((state, props) => ({
			openFiltersPopin: true,
		}))
	}

	handleCloseFiltersPopin() {
		this.setState((state, props) => ({
			openFiltersPopin: false,
		}))
	}

	render() {
		const {
			propertyItem,
			carouselFullscreenImage,
			forceLike,
			openContactSellerPopin,
			didContactSeller,
			isContactingSeller,
			openFiltersPopin,
			needsOptions,
		} = this.state

		let propertyInfosEntity = undefined
		if (propertyItem && propertyItem.property) {
			propertyInfosEntity = {
				address: propertyItem.property.address,
				city: propertyItem.property.city,
				zipcode: propertyItem.property.zipcode,
				squareMeters: propertyItem.squareMeters,
			}
		}

		const carouselClass = `LandDetail__carousel${(carouselFullscreenImage) ? '--fullscreen' : ''}`

		const currentUserId = this.accessControl.getUserId()

		let displayAttributes = false

		if (propertyItem) {
			displayAttributes = (propertyItem.roomsCount && propertyItem.roomsCount > 0)
				|| (propertyItem.bedroomsCount && propertyItem.bedroomsCount > 0)
				|| (propertyItem.bathroomsCount && propertyItem.bathroomsCount > 0)
		}

		let likeClass = (true === forceLike || (propertyItem && true === propertyItem.isCollectionMember)) ? ' is-selected' : ''

		const sellerWidget = this.getSellerWidget(propertyItem)

		return (
			<div className="LandDetail PropertyDetail">
				<PropertiesFilters
					filterCallback={this.handlePropertiesFilters}
					openFiltersPopinCallback={this.handleOpenFiltersPopin}
				/>

				<div className="LandDetail__content">
					<div className="Back__button">
						<ImageButton
							image={icons.arrow}
							altText='Back to Lands List'
							onClick={this.handleBackBtnClick}
						/>
					</div>

					{!propertyItem &&
						<Spinner />
					}

					{propertyItem &&
						<div className="LandDetail__wrapper">
							<div className="LandDetail__infos">
								{sellerWidget}

								<h1>
									{propertyItem.property.name}

				                    <span className="catalogue__property__like" onClick={this.handleLikeClick}>
				                        <svg className={`catalogue__property__like__icon ${likeClass}`} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
				                            <path fillRule="nonzero" d="M9.001 15.334l.974-.852c4.42-3.891 5.802-5.27 6.78-7.033.5-.902.745-1.765.745-2.653C17.5 2.39 15.55.5 13.05.5a4.962 4.962 0 0 0-3.674 1.652L9 2.58l-.376-.428A4.962 4.962 0 0 0 4.95.5C2.45.5.5 2.39.5 4.796c0 .892.247 1.758.752 2.665.98 1.76 2.372 3.146 6.774 7.013l.975.86z"/>
				                        </svg>
				                    </span>
								</h1>

								{propertyItem.price &&
									<p className="LandDetail__price big blue">{parseInt(propertyItem.price).toLocaleString()}&nbsp;€</p>
								}

								{propertyInfosEntity &&
									<LandInfosIcons
										entity={propertyInfosEntity}
										displayZipcode={true}
										fullAddress={true}
									/>
								}

								<div className={`${carouselClass} is-mobile`}>
									<Carousel
										images={propertyItem.photos}
										allowFullscreen={true}
										onFullscreenCallback={this.handleCarouselFullscreen}
									/>
								</div>

								<div className="LandDetail__description medium">
									{propertyItem.property.description}
								</div>

								{displayAttributes &&
									<PropertyAttributes propertyItem={propertyItem} />
								}

								{propertyItem.benefits &&
									<BenefitsList benefits={propertyItem.benefits} />
								}

								{currentUserId !== propertyItem.createdBy.id &&
									<div className="LandDetailButtons">
										<TextButton
											className="Contact__seller__btn BlueButton upper"
											text='propertyDetailContactSellerBtn'
											onClick={this.handleContactSellerClick}
										/>
									</div>
								}
							</div>

							<div className={carouselClass}>
								<Carousel
									images={propertyItem.photos}
									allowFullscreen={true}
									onFullscreenCallback={this.handleCarouselFullscreen}
								/>
							</div>

							{openContactSellerPopin &&
				        		<Popin
				        			isOpened={openContactSellerPopin}
				        			transTitle='propertyDetailContactSellerBtn'
				        			transTitleKey='propertyDetailContactSellerBtn'
				        			onClose={this.handleClosePopin}
				        		>
									<UbikonForm
										form={contactForm}
										theme={ContactSellerForm}
										sellerWidget={sellerWidget}
										propertyItem={propertyItem}
										propertyInfosEntity={propertyInfosEntity}
										didContactSeller={didContactSeller}
										isContactingSeller={isContactingSeller}
										onSubmit={this.handleContactSellerSubmit}
										closeCallback={this.handleClosePopin}
									/>
				        		</Popin>
							}
						</div>
					}

					{openFiltersPopin &&
						<Popin
							isOpened={openFiltersPopin}
							className="Properties__filter__popin"
							title='Filter properties'
							onClose={this.handleCloseFiltersPopin}
						>
							<PropertiesFiltersPopin
								needsOptions={needsOptions}
								filterSubmitCallback={this.handlePropertiesFilters}
							/>
						</Popin>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { newCollectionItem } = state.collections
 
	return { apiResponse, apiResponseEndpoint, newCollectionItem }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
		openCollectionsPopin: (collectionType, collectionItem) => dispatch(openCollectionsPopin(collectionType, collectionItem)),
		resetDidAddToCollection: () => dispatch(resetDidAddToCollection()),
		setFilters: (filterType, filterValues) => dispatch(setFilters(filterType, filterValues)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetail)