import React from 'react'
import { withTranslation } from 'react-i18next'

import ProjectDetailTabContent from 'components/projects/ProjectDetailTabContent'
import TextButton from 'components/buttons/TextButton'

const getTabIcon = (tabSlug) => {
	if ('dashboard' === tabSlug) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19">
			    <g className="project-views__header__nav__item__stroke" fill="none" fillRule="evenodd">
			        <path stroke="#737373" d="M.5 2.5v16h13.037v-16H10.51v2.03l-6.975.016.015-2.046z"/>
			        <path fill="#737373" d="M6.508 9.85l.624-.68 2.342-2.358.712.73-4.138 4.138-2.075-2.075.714-.728.277.293.623.68.461.403"/>
			        <path stroke="#737373" d="M2.57 2.5h1.935v-2h5.019v2h1.663"/>
			        <path fill="#737373" d="M6.508 13.85l.624-.68 2.342-2.358.712.73-4.138 4.138-2.075-2.075.714-.728.277.293.623.68.461.403"/>
			    </g>
			</svg>
		)
	}
	else if ('files' === tabSlug) {
		return (
	        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
	            <g className="project-views__header__nav__item__stroke" fill="none" fillRule="evenodd">
	                <path d="M13.514 17.5H.5V.5h9.796l1.645 1.573 1.573 1.531zM4 7.527h6M4 10.497h6M4 13.505h6"/>
	                <path d="M9.512.5v4.014h4.025"/>
	            </g>
	        </svg>
		)
	} else if ('offers' === tabSlug) {
		return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19">
                <g className="project-views__header__nav__item__stroke" fill="none" fillRule="evenodd">
                    <path d="M4 4.506h2.519c.033-2.68-.807-4.013-2.519-4a2 2 0 0 0 0 4zM10.022 4.506h-2.52c-.032-2.68.807-4.013 2.52-4a2 2 0 0 1 0 4z"/>
                    <path strokeLinecap="square" d="M5.525 17.508v-12M8.499 17.274V5.508"/>
                    <path d="M1.5 7.508h11v10h-11zM.5 4.508h13v3H.5z"/>
                </g>
            </svg>
		)
	} else if ('planning' === tabSlug) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19">
			    <g className="project-views__header__nav__item__stroke" fill="none" fillRule="evenodd">
			        <path stroke="#737373" d="M.5 2.5v16h13.037v-16H10.51v2.03l-6.975.016.015-2.046z"/>
			        <path fill="#737373" d="M6.508 9.85l.624-.68 2.342-2.358.712.73-4.138 4.138-2.075-2.075.714-.728.277.293.623.68.461.403"/>
			        <path stroke="#737373" d="M2.57 2.5h1.935v-2h5.019v2h1.663"/>
			        <path fill="#737373" d="M6.508 13.85l.624-.68 2.342-2.358.712.73-4.138 4.138-2.075-2.075.714-.728.277.293.623.68.461.403"/>
			    </g>
			</svg>
		)
	} else if ('financial-analysis' === tabSlug) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19">
			    <g className="project-views__header__nav__item__stroke" fill="none" fillRule="evenodd">
			        <path stroke="#737373" d="M.5 2.5v16h13.037v-16H10.51v2.03l-6.975.016.015-2.046z"/>
			        <path fill="#737373" d="M6.508 9.85l.624-.68 2.342-2.358.712.73-4.138 4.138-2.075-2.075.714-.728.277.293.623.68.461.403"/>
			        <path stroke="#737373" d="M2.57 2.5h1.935v-2h5.019v2h1.663"/>
			        <path fill="#737373" d="M6.508 13.85l.624-.68 2.342-2.358.712.73-4.138 4.138-2.075-2.075.714-.728.277.293.623.68.461.403"/>
			    </g>
			</svg>
		)
	}

	return null
}

export const ProjectDetailTabs = (props) => {
	const {
		t,
		project,
		projectTasks,
		userOfferRequests,
		tabs,
		tabClickCallback,
		addPartnerCallback,
		requestCallback,
		projetDocumentCheckboxCallback,
		uploadFilesCallback,
		signatureCallback,
		addOfferCallback,
		offerButtonsCallback,
		taskFilesCountClickCallback,
		createOfferRequestCallback,
		currentProjectPipelineSection,
		addTaskCallback,
		taskFileUploadCallback,
		uploadFileCallback,
		downloadCallback,
		downloadFolderCallback,
		displayOfferForm,
		createOfferCallback,
		approveProjectCallback,
		partnerItemClickCallback,
		removePartnerCallback,
		assignTaskCallback,
		editTaskCallback,
		offerBackArrowClickCallback,
		activeTaskPartner,
		taskDetailCloseCallback,
		newFileFolderBtnCallback,
		completeSectionCallback,
	} = props

	return (
		<div className="project-views ProjectDetailTabs">
			<span className="project-views__decorator"></span>

			<header className="project-views__header">
				<div className="project-views__header__inner">
					<ul className="project-views__header__nav">
						{tabs.map((tab, i) => {
							const activeClass = (tab.active) ? ' is-active': '';

							return (
								<li
									className={`project-views__header__nav__item${activeClass}`}
									key={i}
									onClick={(e) => tabClickCallback(e, tab)}
								>
									{getTabIcon(tab.slug)}
									{t(tab.name)}
								</li>
							)
						})}
					</ul>

					{tabs.map((tab, i) => {
						if (tab.active && 'dashboard' === tab.slug) {
							return (
								<TextButton
									key={i}
									text='projectDetailTabActionTask'
									onClick={addTaskCallback}
									className="project-views__header__button btn btn--red"
								/>
							)
						} else if (tab.active && 'offers' === tab.slug) {
							return (
								<TextButton
									key={i}
									text='projectDetailTabActionOffer'
									onClick={addOfferCallback}
									className="project-views__header__button btn btn--red"
								/>
							)
						} else if (tab.active && 'files' === tab.slug) {
							return (
								<TextButton
									key={i}
									text='projectDetailTabActionFile'
									onClick={uploadFileCallback}
									className="project-views__header__button btn btn--red"
								/>
							)
						}

						return null
					})}
				</div>
			</header>

			<div className="ProjectDetailTabs__content">
				{project && tabs.map((tab, i) => {
					if (tab.active) {
						return <ProjectDetailTabContent
							key={i}
							tab={tab}
							project={project}
							projectTasks={projectTasks}
							userOfferRequests={userOfferRequests}
							currentProjectPipelineSection={currentProjectPipelineSection}
							addPartnerCallback={addPartnerCallback}
							requestCallback={requestCallback}
							projetDocumentCheckboxCallback={projetDocumentCheckboxCallback}
							uploadFilesCallback={uploadFilesCallback}
							signatureCallback={signatureCallback}
							addOfferCallback={addOfferCallback}
							offerButtonsCallback={offerButtonsCallback}
							taskFilesCountClickCallback={taskFilesCountClickCallback}
							createOfferRequestCallback={createOfferRequestCallback}
							taskFileUploadCallback={taskFileUploadCallback}
							downloadCallback={downloadCallback}
							downloadFolderCallback={downloadFolderCallback}
							displayOfferForm={displayOfferForm}
							createOfferCallback={createOfferCallback}
							approveProjectCallback={approveProjectCallback}
							partnerItemClickCallback={partnerItemClickCallback}
							removePartnerCallback={removePartnerCallback}
							assignTaskCallback={assignTaskCallback}
							editTaskCallback={editTaskCallback}
							offerBackArrowClickCallback={offerBackArrowClickCallback}
							activeTaskPartner={activeTaskPartner}
							taskDetailCloseCallback={taskDetailCloseCallback}
							newFileFolderBtnCallback={newFileFolderBtnCallback}
							completeSectionCallback={completeSectionCallback}
						/>
					}

					return null
				})}
			</div>
		</div>
	)
}

export default withTranslation()(ProjectDetailTabs)