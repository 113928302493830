import React from 'react'
import { withTranslation } from 'react-i18next'

export const Textarea = (props) => {
	const { t, field, formData, onChange } = props

	let fieldProps = field.props
	if (
		field.props
		&& field.props.name
		&& formData
		&& (formData[field.props.name] || '' === formData[field.props.name])
	) {
		fieldProps = {
			...field.props,
			value: formData[field.props.name]
		}
	}

	const finalProps = {
		...fieldProps,
		placeholder: (fieldProps && fieldProps.placeholder) ? t(fieldProps.placeholder) : '',
	}

	return (
		<textarea
			className="FormInput Textarea"
			onChange={(e) => onChange(e, field)}
			{...finalProps}
		></textarea>
	)
}

export default withTranslation()(Textarea)