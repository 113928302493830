import React from 'react'
import { withTranslation } from 'react-i18next'

import { ReactComponent as IconCheck } from 'assets/svg/icon-check.svg'
import validateField from 'components/forms/validateField'

export const AttachedLabelInput = (props) => {
	const { t, field, value, inputType, fieldProps, formData, onChange } = props

	const isFieldValid = validateField(field, value, formData)
	const emptyClass = (value.length === 0) ? ' is-empty' : ''
	const validClass = (true === isFieldValid) ? ' is-valid' : ''

	return (
		<div className={`TextInput__attached${emptyClass}${validClass}`}>
			<input
				type={inputType}
				className="FormInput TextInput"
				onChange={(e) => onChange(e, field)}
				{...fieldProps}
			/>

			<span>{t(field.label)}</span>

			{field.validation &&
				<IconCheck />
			}
		</div>
	)
}

export default withTranslation()(AttachedLabelInput)