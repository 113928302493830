const textPages = {
	contact: {
		fr: {
			title: 'Contactez-nous',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris malesuada dolor augue. Duis lobortis, lectus dapibus varius pulvinar, risus tellus consequat ante, sed tristique elit eros vel massa. Ut luctus pellentesque lectus, a interdum orci auctor a. Integer diam dui, accumsan at libero sit amet, consectetur facilisis ipsum. Quisque accumsan nulla ac nulla mollis, in vestibulum dolor consequat. Nunc dapibus interdum suscipit. Phasellus nec rutrum nibh, id aliquam diam. Quisque convallis risus eget viverra euismod. Phasellus fringilla venenatis tellus interdum scelerisque. Curabitur iaculis faucibus nisi scelerisque accumsan. In maximus ligula at ex scelerisque bibendum. Proin aliquam leo vel dolor convallis tincidunt. Nunc dictum fringilla ex, a volutpat turpis viverra blandit. Quisque eu tellus at nulla vehicula vestibulum.<br><br>Aliquam a sem sit amet ante pulvinar fringilla. Nam ultricies placerat turpis eu condimentum. Praesent non tellus ante. Maecenas id vestibulum dolor. Nullam vulputate ipsum lorem, ut aliquam turpis ultricies id. Cras varius luctus aliquet. Proin velit nisl, lacinia vitae erat non, tristique pulvinar nisl. Mauris odio eros, dictum sollicitudin aliquet ultricies, pulvinar vitae augue. Phasellus at ante non enim tempor accumsan. Vivamus efficitur porta imperdiet. Donec in dolor sed eros maximus tempor.<br><br>Nam feugiat ex ligula, sit amet laoreet enim molestie at. Nam rhoncus ligula sed massa tempus, non rutrum nibh elementum. Nullam vitae libero imperdiet, posuere nunc id, ornare felis. Nulla velit magna, dictum bibendum ornare nec, malesuada non justo. Nunc porta eu diam ut scelerisque. In congue, nunc et blandit congue, leo risus tristique augue, sit amet feugiat enim justo sit amet diam. Aliquam viverra dolor a eros finibus sollicitudin.',
		},
		en: {
			title: 'Contact-us',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris malesuada dolor augue. Duis lobortis, lectus dapibus varius pulvinar, risus tellus consequat ante, sed tristique elit eros vel massa. Ut luctus pellentesque lectus, a interdum orci auctor a. Integer diam dui, accumsan at libero sit amet, consectetur facilisis ipsum. Quisque accumsan nulla ac nulla mollis, in vestibulum dolor consequat. Nunc dapibus interdum suscipit. Phasellus nec rutrum nibh, id aliquam diam. Quisque convallis risus eget viverra euismod. Phasellus fringilla venenatis tellus interdum scelerisque. Curabitur iaculis faucibus nisi scelerisque accumsan. In maximus ligula at ex scelerisque bibendum. Proin aliquam leo vel dolor convallis tincidunt. Nunc dictum fringilla ex, a volutpat turpis viverra blandit. Quisque eu tellus at nulla vehicula vestibulum.<br><br>Aliquam a sem sit amet ante pulvinar fringilla. Nam ultricies placerat turpis eu condimentum. Praesent non tellus ante. Maecenas id vestibulum dolor. Nullam vulputate ipsum lorem, ut aliquam turpis ultricies id. Cras varius luctus aliquet. Proin velit nisl, lacinia vitae erat non, tristique pulvinar nisl. Mauris odio eros, dictum sollicitudin aliquet ultricies, pulvinar vitae augue. Phasellus at ante non enim tempor accumsan. Vivamus efficitur porta imperdiet. Donec in dolor sed eros maximus tempor.<br><br>Nam feugiat ex ligula, sit amet laoreet enim molestie at. Nam rhoncus ligula sed massa tempus, non rutrum nibh elementum. Nullam vitae libero imperdiet, posuere nunc id, ornare felis. Nulla velit magna, dictum bibendum ornare nec, malesuada non justo. Nunc porta eu diam ut scelerisque. In congue, nunc et blandit congue, leo risus tristique augue, sit amet feugiat enim justo sit amet diam. Aliquam viverra dolor a eros finibus sollicitudin.',
		}
	},
	legal: {
		fr: {
			title: 'Mentions légales',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris malesuada dolor augue. Duis lobortis, lectus dapibus varius pulvinar, risus tellus consequat ante, sed tristique elit eros vel massa. Ut luctus pellentesque lectus, a interdum orci auctor a. Integer diam dui, accumsan at libero sit amet, consectetur facilisis ipsum. Quisque accumsan nulla ac nulla mollis, in vestibulum dolor consequat. Nunc dapibus interdum suscipit. Phasellus nec rutrum nibh, id aliquam diam. Quisque convallis risus eget viverra euismod. Phasellus fringilla venenatis tellus interdum scelerisque. Curabitur iaculis faucibus nisi scelerisque accumsan. In maximus ligula at ex scelerisque bibendum. Proin aliquam leo vel dolor convallis tincidunt. Nunc dictum fringilla ex, a volutpat turpis viverra blandit. Quisque eu tellus at nulla vehicula vestibulum.<br><br>Aliquam a sem sit amet ante pulvinar fringilla. Nam ultricies placerat turpis eu condimentum. Praesent non tellus ante. Maecenas id vestibulum dolor. Nullam vulputate ipsum lorem, ut aliquam turpis ultricies id. Cras varius luctus aliquet. Proin velit nisl, lacinia vitae erat non, tristique pulvinar nisl. Mauris odio eros, dictum sollicitudin aliquet ultricies, pulvinar vitae augue. Phasellus at ante non enim tempor accumsan. Vivamus efficitur porta imperdiet. Donec in dolor sed eros maximus tempor.<br><br>Nam feugiat ex ligula, sit amet laoreet enim molestie at. Nam rhoncus ligula sed massa tempus, non rutrum nibh elementum. Nullam vitae libero imperdiet, posuere nunc id, ornare felis. Nulla velit magna, dictum bibendum ornare nec, malesuada non justo. Nunc porta eu diam ut scelerisque. In congue, nunc et blandit congue, leo risus tristique augue, sit amet feugiat enim justo sit amet diam. Aliquam viverra dolor a eros finibus sollicitudin.',
		},
		en: {
			title: 'Legal notice',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris malesuada dolor augue. Duis lobortis, lectus dapibus varius pulvinar, risus tellus consequat ante, sed tristique elit eros vel massa. Ut luctus pellentesque lectus, a interdum orci auctor a. Integer diam dui, accumsan at libero sit amet, consectetur facilisis ipsum. Quisque accumsan nulla ac nulla mollis, in vestibulum dolor consequat. Nunc dapibus interdum suscipit. Phasellus nec rutrum nibh, id aliquam diam. Quisque convallis risus eget viverra euismod. Phasellus fringilla venenatis tellus interdum scelerisque. Curabitur iaculis faucibus nisi scelerisque accumsan. In maximus ligula at ex scelerisque bibendum. Proin aliquam leo vel dolor convallis tincidunt. Nunc dictum fringilla ex, a volutpat turpis viverra blandit. Quisque eu tellus at nulla vehicula vestibulum.<br><br>Aliquam a sem sit amet ante pulvinar fringilla. Nam ultricies placerat turpis eu condimentum. Praesent non tellus ante. Maecenas id vestibulum dolor. Nullam vulputate ipsum lorem, ut aliquam turpis ultricies id. Cras varius luctus aliquet. Proin velit nisl, lacinia vitae erat non, tristique pulvinar nisl. Mauris odio eros, dictum sollicitudin aliquet ultricies, pulvinar vitae augue. Phasellus at ante non enim tempor accumsan. Vivamus efficitur porta imperdiet. Donec in dolor sed eros maximus tempor.<br><br>Nam feugiat ex ligula, sit amet laoreet enim molestie at. Nam rhoncus ligula sed massa tempus, non rutrum nibh elementum. Nullam vitae libero imperdiet, posuere nunc id, ornare felis. Nulla velit magna, dictum bibendum ornare nec, malesuada non justo. Nunc porta eu diam ut scelerisque. In congue, nunc et blandit congue, leo risus tristique augue, sit amet feugiat enim justo sit amet diam. Aliquam viverra dolor a eros finibus sollicitudin.',
		}
	},
	help: {
		fr: {
			title: 'Aide',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris malesuada dolor augue. Duis lobortis, lectus dapibus varius pulvinar, risus tellus consequat ante, sed tristique elit eros vel massa. Ut luctus pellentesque lectus, a interdum orci auctor a. Integer diam dui, accumsan at libero sit amet, consectetur facilisis ipsum. Quisque accumsan nulla ac nulla mollis, in vestibulum dolor consequat. Nunc dapibus interdum suscipit. Phasellus nec rutrum nibh, id aliquam diam. Quisque convallis risus eget viverra euismod. Phasellus fringilla venenatis tellus interdum scelerisque. Curabitur iaculis faucibus nisi scelerisque accumsan. In maximus ligula at ex scelerisque bibendum. Proin aliquam leo vel dolor convallis tincidunt. Nunc dictum fringilla ex, a volutpat turpis viverra blandit. Quisque eu tellus at nulla vehicula vestibulum.<br><br>Aliquam a sem sit amet ante pulvinar fringilla. Nam ultricies placerat turpis eu condimentum. Praesent non tellus ante. Maecenas id vestibulum dolor. Nullam vulputate ipsum lorem, ut aliquam turpis ultricies id. Cras varius luctus aliquet. Proin velit nisl, lacinia vitae erat non, tristique pulvinar nisl. Mauris odio eros, dictum sollicitudin aliquet ultricies, pulvinar vitae augue. Phasellus at ante non enim tempor accumsan. Vivamus efficitur porta imperdiet. Donec in dolor sed eros maximus tempor.<br><br>Nam feugiat ex ligula, sit amet laoreet enim molestie at. Nam rhoncus ligula sed massa tempus, non rutrum nibh elementum. Nullam vitae libero imperdiet, posuere nunc id, ornare felis. Nulla velit magna, dictum bibendum ornare nec, malesuada non justo. Nunc porta eu diam ut scelerisque. In congue, nunc et blandit congue, leo risus tristique augue, sit amet feugiat enim justo sit amet diam. Aliquam viverra dolor a eros finibus sollicitudin.',
		},
		en: {
			title: 'Help',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris malesuada dolor augue. Duis lobortis, lectus dapibus varius pulvinar, risus tellus consequat ante, sed tristique elit eros vel massa. Ut luctus pellentesque lectus, a interdum orci auctor a. Integer diam dui, accumsan at libero sit amet, consectetur facilisis ipsum. Quisque accumsan nulla ac nulla mollis, in vestibulum dolor consequat. Nunc dapibus interdum suscipit. Phasellus nec rutrum nibh, id aliquam diam. Quisque convallis risus eget viverra euismod. Phasellus fringilla venenatis tellus interdum scelerisque. Curabitur iaculis faucibus nisi scelerisque accumsan. In maximus ligula at ex scelerisque bibendum. Proin aliquam leo vel dolor convallis tincidunt. Nunc dictum fringilla ex, a volutpat turpis viverra blandit. Quisque eu tellus at nulla vehicula vestibulum.<br><br>Aliquam a sem sit amet ante pulvinar fringilla. Nam ultricies placerat turpis eu condimentum. Praesent non tellus ante. Maecenas id vestibulum dolor. Nullam vulputate ipsum lorem, ut aliquam turpis ultricies id. Cras varius luctus aliquet. Proin velit nisl, lacinia vitae erat non, tristique pulvinar nisl. Mauris odio eros, dictum sollicitudin aliquet ultricies, pulvinar vitae augue. Phasellus at ante non enim tempor accumsan. Vivamus efficitur porta imperdiet. Donec in dolor sed eros maximus tempor.<br><br>Nam feugiat ex ligula, sit amet laoreet enim molestie at. Nam rhoncus ligula sed massa tempus, non rutrum nibh elementum. Nullam vitae libero imperdiet, posuere nunc id, ornare felis. Nulla velit magna, dictum bibendum ornare nec, malesuada non justo. Nunc porta eu diam ut scelerisque. In congue, nunc et blandit congue, leo risus tristique augue, sit amet feugiat enim justo sit amet diam. Aliquam viverra dolor a eros finibus sollicitudin.',
		}
	}
}

export default textPages