import AccessControl from 'auth/access-control'

const getConversationTitle = (type, conversation) => {
	if (!conversation) {
		return ''
	}

	const { users, collection } = conversation
	const accessControl = new AccessControl()
	const currentUserId = accessControl.getUserId()

	let title = undefined

	if (('chat' === type || 'offers' === type) && users) {
		users.map((user, i) => {
			if (typeof title === 'undefined' && user.id !== currentUserId) {
				title = user.username
			}

			return null
		})
	} else if ('groups' === type) {
		if (conversation.name && conversation.name.length > 0) {
			title = conversation.name
		} else {
			let usersTitle = ''
			let usersTitleCount = 0

			const limit = 1
			users.map((user, i) => {
				if (
					usersTitleCount <= limit
					&& typeof title === 'undefined'
					&& user.id !== currentUserId
				) {
					if (usersTitleCount === 0) {
						usersTitle = `${user.username}`
					} else {
						usersTitle = `${usersTitle}, ${user.username}`
					}

					usersTitleCount++
				}

				return null
			})

			if (users.length > 3) {
				usersTitle = `${usersTitle}...`
			}

			title = usersTitle
		}
	} else if ('collection' === type && collection && collection.name) {
		title = collection.name
	}

	return title
}

export default getConversationTitle