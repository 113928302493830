import React from 'react'
import NavSectionTitle from 'components/navigation/NavSectionTitle'
import NavItem from 'components/navigation/NavItem'
import AccessControl from 'auth/access-control'

export const NavSection = (props) => {
	const { section, isMobile, navCallback } = props
	const accessControl = new AccessControl()

	return (
		<div>
			{!isMobile &&
				<NavSectionTitle label={section.label} className='sidebar-block__title text--blue-electric' />
			}

			<nav className="nav">
				<ul className="nav__items">
					{section.items && section.items.map((navItem, i) => {
							// check role
							const hasRole = typeof navItem.role !== 'undefined' ? accessControl.hasRole(navItem.role): true

							if (hasRole) {
								return (
									<NavItem
										key={i}
										item={navItem}
										isMobile={isMobile}
										navCallback={navCallback}
									/>
								)
							} else {
								return null
							}
						})
					}
				</ul>
			</nav>
		</div>
	)
}

export default NavSection