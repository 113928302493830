import React from 'react'

class Checkbox extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isChecked: (typeof props.isChecked !== 'undefined') ? props.isChecked: false,
		}

		// bind methods
		this.handleOnChange = this.handleOnChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.isChecked !== this.props.isChecked
			&& this.props.isChecked !== this.state.isChecked
		) {
			this.setState((state, props) => ({
				isChecked: this.props.isChecked,
			}))
		}
	}

	handleOnChange(e, value) {
		const { isChecked } = this.state
		const { onChangeCallback, onChange, field } = this.props
		const isCheckedNow = !isChecked

		if (typeof onChangeCallback !== 'undefined') {
			onChangeCallback(isCheckedNow, value)
		}

		if (field && onChange) {
			onChange(isCheckedNow, field)
		}

		this.setState((state, props) => ({
			isChecked: isCheckedNow
		}))
	}

	render() {
		const { isChecked } = this.state
		const { field, disabled, value } = this.props
		const isDisabled = (typeof disabled !== 'undefined') ? disabled: false

		let checkboxValue = value
		if (
			typeof value === 'undefined'
			&& typeof field !== 'undefined'
			&& field.props
			&& field.props.value
		) {
			checkboxValue = field.props.value
		}

		return (
			<input
				className="Checkbox"
				type="checkbox"
				checked={isChecked}
				disabled={isDisabled}
				onChange={(e) => this.handleOnChange(e, checkboxValue)}
				value={checkboxValue}
			/>
		)
	}
}

export default Checkbox