const priceFormat = (price, currencyDisplay) => {
	if (price) {
		const currencyDisplayOption = (typeof currencyDisplay !== 'undefined') ? currencyDisplay : 'symbol'

		const options = {
			style: 'currency',
			currency: 'EUR',
			currencyDisplay: currencyDisplayOption,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		}

		return new Intl.NumberFormat('fr-FR', options).format(price)
	}

	return undefined
}

export default priceFormat