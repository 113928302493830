import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import TextButton from 'components/buttons/TextButton'

class PartnersSelect extends React.Component {
	constructor(props) {
		super(props)

		// set initial partners list
		let initialPartners = []
		const { field } = props
		if (field && field.props && field.props.value) {
			initialPartners = field.props.value
		}

		// set state
		this.state = {
			expandList: false,
			selectedPartners: initialPartners,
		}

		// bind methods
		this.handleSelectClick = this.handleSelectClick.bind(this)
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
	}

	handleSelectClick(e, forceValue) {
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		const { expandList } = this.state
		const newExpandValue = (typeof forceValue !== 'undefined') ? forceValue: !expandList

		this.setState((state, props) => ({
			expandList: newExpandValue
		}))
	}

	handleCheckboxClick(e, partner) {
		const { selectedPartners, expandList } = this.state
		const { field, onChange } = this.props
		const value = (typeof selectedPartners[partner.value] !== 'undefined') ? !selectedPartners[partner.value] : true
		let newExpandList = expandList

		const newSelectedPartners = (field.props && false === field.props.multiple) 
			? { [partner.value]: partner } 
			: { ...selectedPartners, [partner.value]: partner }

		if (false === value) {
			delete newSelectedPartners[partner.value]
		}

		if (onChange) {
			if (field.props && false === field.props.multiple) {
				const selectedPartner = newSelectedPartners[Object.keys(newSelectedPartners)[0]]
				const onChangeValue = (selectedPartner && selectedPartner.value) ? selectedPartner.value : undefined

				onChange(onChangeValue, field)
			} else {
				onChange(newSelectedPartners, field)
			}
		}

		// close list if not multiple
		if (field.props && false === field.props.multiple) {
			newExpandList = false
		}

		this.setState((state, props) => ({
			selectedPartners: newSelectedPartners,
			expandList: newExpandList,
		}))
	}

	getButtonText() {
		const { selectedPartners } = this.state
		const { t, field } = this.props
		let buttonText = t(field.props.placeholder)
		const labelsArray = []

		Object.keys(selectedPartners).map((partnerId) => {
			const partner = selectedPartners[partnerId]
			labelsArray.push(partner.label)

			return null
		})

		if (labelsArray.length > 0) {
			buttonText = labelsArray.join(', ')
		}

		return buttonText
	}

	render() {
		const { expandList, selectedPartners } = this.state
		const { field } = this.props
		const buttonClassName = `PartnersSelect__input${(expandList) ? '--expanded': ''}`

		const buttonText = this.getButtonText()

		return (
			<OutsideClickHandler onOutsideClick={() => this.handleSelectClick(undefined, false)}>
				<div className="PartnersSelect">
					<TextButton
						text={buttonText}
						className={buttonClassName}
						onClick={this.handleSelectClick}
					/>

					{expandList &&
						<div className="PartnersSelect__list__wrapper">
							<ul className="PartnersSelect__list">
								{field.options.map((partner, i) => {
									const avatarStyle = {
										backgroundImage: (partner.avatar_url) ? 
											`url(${media.getMediaUrl(partner.avatar_url)})`
											: null,
									}

									const isSelected = typeof selectedPartners[partner.value] !== 'undefined'
									const itemClass = `PartnersSelect__list__item${(isSelected) ? '--selected': ''}`

									return (
										<li
											key={i}
											className={itemClass}
											onClick={(e) => this.handleCheckboxClick(e, partner)}
										>
											<div className="PartnersSelect__checkbox">
												<input
													type="checkbox"
													checked={isSelected}
													value={partner.value}
													onChange={(e) => this.handleCheckboxClick(e, partner)}
												/>
											</div>

											<div className="PartnersSelect__label">
												<div className="PartnersSelect__avatar" style={avatarStyle}></div>

												<span className="PartnersSelect__username medium">
													{partner.label}
												</span>
											</div>
										</li>
									)
								})}
							</ul>
						</div>
					}

					{selectedPartners &&
						<div className="PartnersSelect__selected__avatars">
							{Object.keys(selectedPartners).map((partnerId) => {
								const partner = selectedPartners[partnerId]

								// get avatar style
								const avatarStyle = {
									backgroundImage: (partner.avatar_url) ? 
										`url(${media.getMediaUrl(partner.avatar_url)})`
										: null,
								}

								return (
									<div
										key={partnerId}
										className="PartnersSelect__selected__partner"
										style={avatarStyle}
									></div>
								)
							})}
						</div>
					}
				</div>
			</OutsideClickHandler>
		)
	}
}

export default withTranslation()(PartnersSelect)