import React from 'react'

import { ReactComponent as IconCheck } from 'assets/svg/icon-check.svg'
import AccessControl from 'auth/access-control'
import userRoles from 'constants/user-roles'
import projectStatuses from 'constants/project-statuses'
import TextButton from 'components/buttons/TextButton'

export const getIconCheck = (project, section) => {
	const accessControl = new AccessControl()
	const completeCheckClass = (true === section.isComplete) ? 'ProjectPipeline__check--complete' : 'ProjectPipeline__check'
	const disabled = !accessControl.isProjectOwner(project)

	return <IconCheck className={completeCheckClass} disabled={disabled} />
}

export const ProjectPipeline = (props) => {
	const {
		project,
		currentProjectPipelineSection,
		pipelineSectionClickCallback,
		isBlured,
	} = props

	const accessControl = new AccessControl()

	let sections = project.projectPipelineSections.map((pps, i) => {
		return {
			position: parseInt(pps.pipelineSection.position),
			name: pps.pipelineSection.name,
			enabled: pps.enabled,
			isCurrent: currentProjectPipelineSection && currentProjectPipelineSection.id === pps.id,
			isComplete: pps.isComplete,
			projectPipelineSection: pps
		}
	}).sort((a, b) => {
		return a.position - b.position
	})

	const blurClassname = (isBlured) ? '--blured': ''
	let isFuture = false

	return (
		<div className={`ProjectPipeline${blurClassname}`}>
			<ul className="project__steps">
				<span className="project__steps__line">
					{sections.map((section, iS) => {
						const completeClass = (true === section.isComplete) ? 'is-done': ''
						const stepLineClass = `project__steps__line--${section.position} ${completeClass}`

						return (
							<span key={iS} className={stepLineClass}></span>
						)
					})}
				</span>

				{sections.map((section, i) => {
					// hide step 0 if not admin nor project owner
					if (
						0 === section.position
						&& (
							!accessControl.hasRole(userRoles.ROLE_SUPER_ADMIN)
							|| !accessControl.hasRole(userRoles.ROLE_ADMIN)
						)
						&& !accessControl.isProjectOwner(project)
					) {
						return null
					}

					// hide step 0 if complete
					if (0 === section.position && section.isComplete) {
						return null
					}

					// hide all steps except step 0 if project is pending
					if (
						0 !== section.position
						&& projectStatuses.PROJECT_STATUS_PENDING === project.status
					) {
						return null
					}

					const currentClass = (true === section.isCurrent) ? 'is-current' : ''
					const futureClass = (isFuture) ? 'is-future' : ''
					const completeClass = (true === section.isComplete) ? 'is-done': ''

					if (section.isCurrent && !isFuture) {
						isFuture = true
					}

					return (
						<li
							key={i}
							className={`project__steps__step ${completeClass} ${currentClass} ${futureClass}`}
						>
							<span className="project__steps__circle"></span>

							<TextButton
								text={section.name}
								className="project__steps__text"
								onClick={(e) => pipelineSectionClickCallback(e, section.projectPipelineSection)}
							/>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default ProjectPipeline