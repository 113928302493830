import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { setFilters } from 'actions/filters'
import FilterRange from 'components/filters/FilterRange'
import FilterMultiBox from 'components/filters/FilterMultiBox'
import FilterCheckbox from 'components/filters/FilterCheckbox'
import TextButton from 'components/buttons/TextButton'

class PropertiesFiltersPopin extends React.Component {
	constructor(props) {
		super(props)

		const { filters } = props
		const propertiesFilters = (filters && filters['properties']) ? filters['properties'] : undefined

		// set state
		this.state = {
			filters: {
				search: (propertiesFilters && propertiesFilters['search']) ? propertiesFilters['search'] : '',
				surfaceMin: (propertiesFilters && propertiesFilters['surfaceMin']) ? propertiesFilters['surfaceMin'] : '',
				surfaceMax: (propertiesFilters && propertiesFilters['surfaceMax']) ? propertiesFilters['surfaceMax'] : '',
				gardenSurfaceMin: (propertiesFilters && propertiesFilters['gardenSurfaceMin']) ? propertiesFilters['gardenSurfaceMin'] : '',
				gardenSurfaceMax: (propertiesFilters && propertiesFilters['gardenSurfaceMax']) ? propertiesFilters['gardenSurfaceMax'] : '',
				priceMin: (propertiesFilters && propertiesFilters['priceMin']) ? propertiesFilters['priceMin'] : '',
				priceMax: (propertiesFilters && propertiesFilters['priceMax']) ? propertiesFilters['priceMax'] : '',
				benefits: (propertiesFilters && propertiesFilters['benefits']) ? propertiesFilters['benefits'] : [],
				rooms: (propertiesFilters && propertiesFilters['rooms']) ? propertiesFilters['rooms'] : '',
				bedrooms: (propertiesFilters && propertiesFilters['bedrooms']) ? propertiesFilters['bedrooms'] : '',
				bathrooms: (propertiesFilters && propertiesFilters['bathrooms']) ? propertiesFilters['bathrooms'] : '',
			},
		}

		// bind methods
		this.handleOptionClick = this.handleOptionClick.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
		this.handleMultiBoxChange = this.handleMultiBoxChange.bind(this)
		this.handleFiltersSubmit = this.handleFiltersSubmit.bind(this)
	}

	handleFilterChange(e, filterName) {
		if (e && e.target) {
			const { filters } = this.state
			const newFilters = {
				...filters,
				[filterName]: e.target.value,
			}

			this.setState((state, props) => ({
				filters: newFilters,
			}))
		}
	}

	handleOptionClick(filterName, filterValue) {
		const { filters } = this.state
		let oldValues = (filters[filterName]) ? filters[filterName] : []

		// if value is in array, remove it
		if (oldValues.indexOf(filterValue.id) > -1) {
			oldValues = oldValues.filter((valueId) => {
				return valueId !== filterValue.id
			})
		} else {
			// else, add it
			oldValues.push(filterValue.id)
		}

		const newFilters = {
			...filters,
			[filterName]: oldValues,
		}

		this.setState((state, props) => ({
			filters: newFilters,
		}))
	}

	handleMultiBoxChange(uselessVar, boxName, selectedOptions) {
		const { filters } = this.state
		const newFilters = {
			...filters,
			[boxName]: selectedOptions,
		}

		this.setState((state, props) => ({
			filters: newFilters,
		}))
	}

	handleFiltersSubmit() {
		const { filters } = this.state
		const { filterSubmitCallback } = this.props

		// set filters in store
		this.props.setFilters('properties', filters)

		if (filterSubmitCallback) {
			filterSubmitCallback(filters)
		}
	}

	render() {
		const { filters } = this.state
		const { needsOptions } = this.props

		return (
			<div>
				<div className="filters-modal__content">
					<div className="filters-modal__ranges">
						<FilterRange
							isClicked={true}
							filterChangeCallback={this.handleFilterChange}
							valueMin={filters.surfaceMin}
							valueMax={filters.surfaceMax}
							boxName='surface'
							boxTitle='propertiesIndexFilterHouseSurface'
							boxUnit='m²'
						/>

						<FilterRange
							isClicked={true}
							filterChangeCallback={this.handleFilterChange}
							valueMin={filters.gardenSurfaceMin}
							valueMax={filters.gardenSurfaceMax}
							boxName='gardenSurface'
							boxTitle='propertiesIndexFilterGardenSurface'
							boxUnit='m²'
						/>

						<FilterRange
							isClicked={true}
							filterChangeCallback={this.handleFilterChange}
							valueMin={filters.priceMin}
							valueMax={filters.priceMax}
							boxName='price'
							boxTitle='propertiesIndexFilterPrice'
							boxUnit='€'
						/>
					</div>

					<div className="filters-modal__rooms">
						<FilterMultiBox
							boxName='rooms'
							boxTitle='propertiesIndexFilterRooms'
							values={filters.rooms.split(',')}
							changeCallback={this.handleMultiBoxChange}
						/>

						<FilterMultiBox
							boxName='bedrooms'
							boxTitle='propertiesIndexFilterBedrooms'
							values={filters.bedrooms.split(',')}
							changeCallback={this.handleMultiBoxChange}
						/>

						<FilterMultiBox
							boxName='bathrooms'
							boxTitle='propertiesIndexFilterBathrooms'
							values={filters.bathrooms.split(',')}
							changeCallback={this.handleMultiBoxChange}
						/>
					</div>

					{needsOptions &&
						<div className="filters-modal__needs">
							<p className="filters-modal__needs__title">
								<Trans i18nKey='propertiesIndexFilterNeeds'>propertiesIndexFilterNeeds</Trans>
							</p>

							<div className="filters-modal__needs__list">
								<div className="filters-modal__needs__checkboxes">
									{Object.keys(needsOptions).map((i) => {
										const needOption = needsOptions[i]

										// check if option is selected
										let isChecked = false
										if (filters && filters.benefits) {
											filters.benefits.map((benefitId) => {
												if (benefitId === needOption.id) {
													isChecked = true
												}

												return null
											})
										}

										return (
											<FilterCheckbox
												key={i}
												isChecked={isChecked}
												optionLabel={needOption.name}
												optionValue={needOption.slug}
												clickCallback={() => this.handleOptionClick('benefits', needOption)}
											/>
										)
									})}
								</div>
							</div>
						</div>
					}
				</div>

				<div className="Apply__btn__wrapper">
					<TextButton
						text='propertiesFiltersPopinSubmitBtn'
						className="BlueButton upper"
						onClick={this.handleFiltersSubmit}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { filters } = state.filters
 
	return { filters }
}

const mapDispatchToProps = dispatch => {
	return {
		setFilters: (filterType, filterValues) => dispatch(setFilters(filterType, filterValues)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesFiltersPopin)