import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { withTranslation } from 'react-i18next'

import FilterButton from 'components/buttons/FilterButton'

class FilterBedrooms extends React.Component {
	constructor(props) {
		super(props)

		// bind methods
		this.handleBedroomClick = this.handleBedroomClick.bind(this)

		let initialValuesInts = []

		if (props.values && props.values.length > 0) {
			let filterValues = props.values

			if (typeof props.values === 'string') {
				filterValues = filterValues.split(',')
			}

			initialValuesInts = filterValues.map((i) => {
				return parseInt(i)
			})
		}

		// set state
		this.state = {
			selectedBedrooms: initialValuesInts,
		}
	}

	handleBedroomClick(bedroom) {
		const { selectedBedrooms } = this.state
		const { filterChangeCallback } = this.props

		let newSelectedBedrooms = selectedBedrooms

		if (!selectedBedrooms.includes(bedroom)) {
			newSelectedBedrooms.push(bedroom)
		} else {
			const index = newSelectedBedrooms.indexOf(bedroom)
			newSelectedBedrooms.splice(index, 1)
		}

		if (filterChangeCallback) {
			filterChangeCallback(undefined, 'bedrooms', newSelectedBedrooms.join(','))
		}

		this.setState((state, props) => ({
			selectedBedrooms: newSelectedBedrooms,
		}))
	}

	render() {
		const {
			selectedBedrooms,
		} = this.state

		const {
			t,
			clickOutsideCallback,
			btnClickCallback,
			isClicked,
			className,
		} = this.props

		const items = []
		for(let i = 1; i <= 5; i++) {
			const selectedClass = (selectedBedrooms.includes(i)) ? '--selected' : ''
			const valueLabel = `${i}${(i === 5) ? '+' : ''}`

			const item = (
				<div
					key={i}
					className={`Select__value__box${selectedClass} medium`}
					onClick={() => this.handleBedroomClick(i)}
				>
					<span className="Select__value__box__label">{valueLabel}</span>
				</div>
			)

			items.push(item)
		}

		const filterClass = (className) ? className : 'bedrooms'
		const isSelectedClass = (selectedBedrooms.length > 0) ? 'is-selected' : ''

		return (
			<FilterButton
				text={t('propertiesIndexFilterBedroomsBtn')}
				onClick={(e) => btnClickCallback(e, 'isClickedBedrooms')}
				isClicked={isClicked}
				className={`${filterClass} ${isSelectedClass}`}
			>
				<OutsideClickHandler onOutsideClick={() => clickOutsideCallback()}>
					<label className="List__label upper small medium-gray">{t('propertiesIndexFilterBedrooms')}</label>
					{items}
				</OutsideClickHandler>
			</FilterButton>
		)
	}
}

export default withTranslation()(FilterBedrooms)