import React from 'react'
import { withTranslation } from 'react-i18next'

class SubmitButton extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isLoading: false
		}

		// bind methods
		this.handleClick = this.handleClick.bind(this)
	}

	handleClick() {
		const { onClick, onClickProps, displayLoading } = this.props

		if (typeof onClick !== 'undefined') {
			onClick(onClickProps)
		}

		if (false !== displayLoading) {
			this.setState((state, props) => ({
				isLoading: true
			}))
		}
	}

	render() {
		const { isLoading } = this.state
		const { t, text, className, displayLoading, isDisabled } = this.props

		const disabled = (isDisabled) ? isDisabled : false
		const disabledClass = (disabled) ? 'is-disabled': ''

		const buttonClass = (false !== displayLoading && isLoading) ? `SubmitButton--loading ${disabledClass}` : `SubmitButton ${disabledClass}`

		return (
			<button
				type="submit"
				className={`${buttonClass} ${(typeof className !== 'undefined') ? className: ''}`}
				disabled={disabled}
				onClick={this.handleClick}
			>
				{t(text)}
			</button>
		)
	}
}

export default withTranslation()(SubmitButton)