import ApiClient from 'api/ApiClient'
import ApiNormalizer from 'api/ApiNormalizer'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'

const apiClient = new ApiClient()
const apiNormalizer = new ApiNormalizer()
const apiEndpointBuilder = new ApiEndpointBuilder()

export {
	apiClient,
	apiNormalizer,
	apiEndpointBuilder,
}