import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import bytesToSize from 'utils/bytesToSize'
import DragAndDrop from 'components/forms/inputs/DragAndDrop'
import Label from 'components/forms/inputs/Label'
import FileInputErrors from 'components/forms/inputs/FileInputErrors'
import FileInputPreview from 'components/forms/inputs/FileInputPreview'

class FileInput extends React.Component {
	constructor(props) {
		super(props)

		let filesValue = []
		if (props.field && props.field.filesValue) {
			filesValue = props.field.filesValue
		}

		// set state
		this.state = {
			files: [],
			previews: [],
			fileErrors: [],
			filesValue: filesValue,
		}

		// bind methods
		this.handleFileSelect = this.handleFileSelect.bind(this)
		this.handleDrop = this.handleDrop.bind(this)
		this.processFiles = this.processFiles.bind(this)
		this.handleCloseErrorClick = this.handleCloseErrorClick.bind(this)
	}

	base64encodeFile(file, field, onChange) {
        const reader = new FileReader()

    	reader.readAsBinaryString(file)

        reader.onload = (readerEvt) => {
        	const { files } = this.state

			const binaryString = readerEvt.target.result
			const base64File = btoa(binaryString)

			let newSelectedFiles = {
				...files,
			}
			newSelectedFiles[file.name] = base64File

			onChange(newSelectedFiles, field)

			this.setState((state, props) => ({
				files: newSelectedFiles,
			}))
        }
	}

	handleFileSelect(e, field) {
		this.processFiles(e.target.files, field)
	}

	handleDrop(files, field) {
		this.processFiles(files, field)
	}

	handleCloseErrorClick(error) {
		const { fileErrors } = this.state

		if (fileErrors && fileErrors.length > 0) {
			const newFileErrors = fileErrors.filter((fileError) => {
				return fileError.name !== error.name
			})

			this.setState((state, props) => ({
				fileErrors: newFileErrors,
			}))
		}
	}

	processFiles(files, field) {
		const { previews, fileErrors } = this.state
		const { configuration, onChange } = this.props

		const filesArray = Array.from(files)
		const maxFileSize = (configuration && configuration.MAX_UPLOAD_SIZE) ? parseInt(configuration.MAX_UPLOAD_SIZE) : undefined

		let newFiles = []
		let newErrors = []

		filesArray.map((file) => {
			// check file size
			if (!maxFileSize || file.size <= maxFileSize) {
				this.base64encodeFile(file, field, onChange)

				newFiles.push(file)
			} else {
				const maxSize = bytesToSize(maxFileSize)
				const errorMessage = <Trans i18nKey="maxFileSizeError" maxSize={maxSize}>This file exceeds maximum file size ({{maxSize}})</Trans>
				const errorObject = {
					file: file,
					message: errorMessage
				}

				newErrors.push(errorObject)
			}

			return null
		})

		const newPreviews = [
			...previews,
			...newFiles
		]

		const errors = [
			...fileErrors,
			...newErrors
		]

		this.setState((state, props) => ({
			previews: newPreviews,
			fileErrors: errors,
		}))
	}

	render() {
		const { previews, fileErrors, filesValue } = this.state
		const { fileInputKey, field, displayPreviews } = this.props

		const filesList = (previews) ? Array.from(previews): undefined
		const mustDisplayPreviews = (field.props && field.props.displayPreviews) ? field.props.displayPreviews : displayPreviews
		const fileInputWidget = (field.props && field.props.widget) ? field.props.widget : undefined

		const fieldProps = (field.props) ? {...field.props, id: fileInputKey} : undefined

		if (fieldProps) {
			delete fieldProps.displayPreviews
			delete fieldProps.widget
			delete fieldProps.value
		}

		return (
			<div className="FileInput">
				{fileInputWidget && 'white-button' === fileInputWidget &&
					<div className="FileInput__widget--white-button">
						<Label labelText={field.label} />

						{(typeof mustDisplayPreviews === 'undefined' || true === mustDisplayPreviews) &&
							<div className="FileInput__preview">
								{filesList && filesList.map((file, i) => (
									<FileInputPreview key={i} file={file} fileInputWidget={fileInputWidget} />
								))}
							</div>
						}

						<DragAndDrop field={field} handleDrop={this.handleDrop}>
							<Label
								className="Upload__btn"
								forInput={fileInputKey}
								transLabelKey='fileInputLabel'
								transLabelText='Select file'
							/>
						</DragAndDrop>

						{fileErrors && fileErrors.length > 0 &&
							<FileInputErrors errors={fileErrors} closeCallback={this.handleCloseErrorClick} />
						}

						{filesValue &&
							<div className="FileInput__preview Previous__files">
								{filesValue && filesValue.map((file, iF) => (
									<FileInputPreview
										key={iF}
										file={file}
										fileInputWidget={fileInputWidget}
									/>
								))}
							</div>
						}
					</div>
				}

				{!fileInputWidget &&
					<Label forInput={fileInputKey} labelText={field.label} />
				}

				<input
					type="file"
					onChange={(e) => this.handleFileSelect(e, field)}
					{...fieldProps}
				/>

				{
					(!fileInputWidget || 'white-button' !== fileInputWidget)
					&& (typeof mustDisplayPreviews === 'undefined' || true === mustDisplayPreviews)
					&&
					<div className="FileInput__preview">
						{filesList && filesList.map((file, i) => (
							<FileInputPreview key={i} file={file} />
						))}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { configuration } = state.configuration

	return {
		configuration
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FileInput)