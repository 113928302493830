import React from 'react'

export const Spinner = (props) => {
	const { className } = props

	return (
		<div className={`Spinner ${(className) ? className: ''}`}>
			<div className="Spin"></div>
		</div>
	)
}

export default Spinner