import React from 'react'
import { withTranslation } from 'react-i18next'

class FilterMultiBox extends React.Component {
	constructor(props) {
		super(props)

		// bind methods
		this.handleOptionClick = this.handleOptionClick.bind(this)

		const initialValues = (props.values) ? props.values : []
		const initialValuesInts = initialValues.map((i) => {
			return parseInt(i)
		})

		// set state
		this.state = {
			selectedOptions: initialValuesInts,
		}
	}

	handleOptionClick(optionValue) {
		const { selectedOptions } = this.state
		const { boxName, changeCallback } = this.props

		let newSelectedOptions = selectedOptions

		if (!selectedOptions.includes(optionValue)) {
			newSelectedOptions.push(optionValue)
		} else {
			const index = newSelectedOptions.indexOf(optionValue)
			newSelectedOptions.splice(index, 1)
		}

		if (changeCallback) {
			changeCallback(undefined, boxName, newSelectedOptions.join(','))
		}

		this.setState((state, props) => ({
			selectedOptions: newSelectedOptions,
		}))
	}

	render() {
		const {
			selectedOptions,
		} = this.state

		const {
			t,
			boxTitle,
		} = this.props

		const items = []
		for(let i = 1; i <= 5; i++) {
			const selectedClass = (selectedOptions.includes(i)) ? ' is-active' : ''
			const valueLabel = `${i}${(i === 5) ? '+' : ''}`

			const item = (
				<div
					key={i}
					className={`filters-modal__room__elem ${selectedClass}`}
					onClick={() => this.handleOptionClick(i)}
				>
					<span className="Select__value__box__label">{valueLabel}</span>
				</div>
			)

			items.push(item)
		}

		return (
			<div className="FilterMultiBox">
				<p className="filters-modal__room__title">
					{t(boxTitle)}
				</p>

				<div className="filters-modal__room">
					{items}
				</div>
			</div>
		)
	}
}

export default withTranslation()(FilterMultiBox)