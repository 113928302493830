import React from 'react'

import {
	SubmitButton
} from 'components/forms/inputs'

class OrganizationForm extends React.Component {
	renderFormField(field, fieldName) {
		const { formData, formHelper } = this.props

		let newProps = {
			...field.props
		}

		if (formData && formData[fieldName]) {
			newProps['value'] = formData[fieldName]
		}

		const newField = {
			...field,
			name: fieldName,
			props: newProps,
		}

		const fieldKey = `key-${fieldName}`

		return (
			formHelper.renderField(newField, fieldKey)
		)
	}

	render() {
		const {
			formFields,
			submitCallback,
		} = this.props

		if (!formFields) {
			return null
		}

		const fieldDisplay = [
			'name', 'website', 'contactEmail', 'phone', 'address-street', 'address-zipcode', 'address-city'
		]

		return (
			<div className="Organization__content OrganizationForm">
				<form name="organization-form" onSubmit={submitCallback}>
					<div className="Column__left">
						{fieldDisplay.map((fieldName, i) => {
							return (
								<div className="Organization__infobox" key={i}>
									{this.renderFormField(formFields[i], fieldName)}
								</div>
							)
						})}
					</div>

					<div className="Column__right">
						<div className="Organization__infobox">
							{this.renderFormField(formFields[7], 'description')}
						</div>
					</div>

					<div className="Column__full">
						<SubmitButton className="upper" text='organizationFormSubmitBtn' />
					</div>
				</form>
			</div>
		)
	}
}

export default OrganizationForm