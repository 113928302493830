import ActionTypes from 'constants/action-types'

export const setTemporaryData = (key, data) => {
    return {
        type: ActionTypes.TEMPORARY_DATA_SET,
        temporaryDataKey: key,
        temporaryData: data,
    }
}

export const resetTemporaryData = (key) => {
    return {
        type: ActionTypes.TEMPORARY_DATA_SET,
        temporaryDataKey: key,
        temporaryData: undefined,
    }
}