import React from 'react'

import Chat from 'components/chat/Chat'

export const MessagingMenu = (props) => {
	return (
		<div className="right-sidebar sidebar-block">
			<Chat />
		</div>
	)
}

export default MessagingMenu