import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import FilterRange from 'components/filters/FilterRange'
import FilterBedrooms from 'components/filters/FilterBedrooms'
import SearchBar from 'components/search/SearchBar'

class PropertiesFilters extends React.Component {
	constructor(props) {
		super(props)

		const { filterValues } = props
		const propertiesFilters = (filterValues && filterValues['properties']) ? filterValues['properties'] : undefined

		// set state
		this.state = {
			isClickedSurface: false,
			isClickedPrice: false,
			isClickedBedrooms: false,
			filters: {
				search: (propertiesFilters && propertiesFilters['search']) ? propertiesFilters['search'] : '',
				surfaceMin: (propertiesFilters && propertiesFilters['surfaceMin']) ? propertiesFilters['surfaceMin'] : '',
				surfaceMax: (propertiesFilters && propertiesFilters['surfaceMax']) ? propertiesFilters['surfaceMax'] : '',
				gardenSurfaceMin: (propertiesFilters && propertiesFilters['gardenSurfaceMin']) ? propertiesFilters['gardenSurfaceMin'] : '',
				gardenSurfaceMax: (propertiesFilters && propertiesFilters['gardenSurfaceMax']) ? propertiesFilters['gardenSurfaceMax'] : '',
				priceMin: (propertiesFilters && propertiesFilters['priceMin']) ? propertiesFilters['priceMin'] : '',
				priceMax: (propertiesFilters && propertiesFilters['priceMax']) ? propertiesFilters['priceMax'] : '',
				benefits: (propertiesFilters && propertiesFilters['benefits']) ? propertiesFilters['benefits'] : [],
				rooms: (propertiesFilters && propertiesFilters['rooms']) ? propertiesFilters['rooms'] : '',
				bedrooms: (propertiesFilters && propertiesFilters['bedrooms']) ? propertiesFilters['bedrooms'] : '',
				bathrooms: (propertiesFilters && propertiesFilters['bathrooms']) ? propertiesFilters['bathrooms'] : '',
			}
		}

		// bind methods
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.handleFilterBtnClick = this.handleFilterBtnClick.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
		this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	isPopinFilterSelected() {
		const { filterValues } = this.props

		if (!filterValues || !filterValues['properties']) {
			return false
		}

		const filters = filterValues['properties']

		if (
			(filters.gardenSurfaceMin && filters.gardenSurfaceMin.length > 0)
			|| (filters.gardenSurfaceMax && filters.gardenSurfaceMax.length > 0)
		) {
			return true
		}

		if (
			(filters.rooms && filters.rooms.length > 0)
			|| (filters.bathrooms && filters.bathrooms.length > 0)
		) {
			return true
		}

		if (filters.benefits && filters.benefits.length > 0) {
			return true
		}

		return false
	}

	handleClickOutside() {
		this.setState((state, props) => ({
			isClickedSurface: false,
			isClickedPrice: false,
			isClickedBedrooms: false,
		}))
	}

	handleSearchChange(searchQuery) {
		console.log('handleSearchChange searchQuery', searchQuery)
	}

	handleFilterBtnClick(e, stateName) {
		const newState = !this.state[stateName]

		this.setState((state, props) => ({
			[stateName]: newState
		}))
	}

	handleFilterChange(e, filterName, value) {
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		const { filters } = this.state
		const { filterCallback } = this.props

		const filterValue = (typeof e !== 'undefined') ? e.target.value : value
		const newFilters = {
			...filters,
			[filterName]: filterValue
		}

		if (typeof newFilters !== 'undefined') {
			this.setState((state, props) => ({
				filters: newFilters
			}))

			if (
				('search' === filterName || 'bedrooms' === filterName)
				&& filterCallback
			) {
				filterCallback(newFilters)
			}
		}
	}

	handleFilterSubmit(e, filterName) {
		e.preventDefault()

		const { filters } = this.state
		const { filterCallback } = this.props

		if (filterCallback) {
			filterCallback(filters)
		}

		this.setState((state, props) => ({
			isClickedSurface: false,
			isClickedPrice: false,
			isClickedBedrooms: false,
		}))
	}

	render() {
		const {
			filters,
			isClickedSurface,
			isClickedPrice,
			isClickedBedrooms,
		} = this.state

		const {
			openFiltersPopinCallback,
		} = this.props

		const initialSearch = (filters && filters.search) ? filters.search : ''

		const isPopinFilterSelected = this.isPopinFilterSelected()
		const popinBtnClass = (true === isPopinFilterSelected) ? 'is-selected' : ''

		return (
			<header className="catalogue__header">
				<div className="catalogue__header__infos properties">
					<p className="catalogue__header__title text--blue-electric">
						<Trans i18nKey="propertiesIndexPageTitle">propertiesIndexPageTitle</Trans>
					</p>

					<SearchBar
						placeholder='propertiesIndexFilterSearch'
						onSubmit={this.handleFilterChange}
						className="catalogue__header__search"
						initialValue={initialSearch}
					/>
				</div>

				<div className="catalogue__header__filters">
					<FilterRange
						isClicked={isClickedSurface}
						clickOutsideCallback={this.handleClickOutside}
						btnClickCallback={this.handleFilterBtnClick}
						filterChangeCallback={this.handleFilterChange}
						filterSubmitCallback={this.handleFilterSubmit}
						className="catalogue__header__filter"
						valueMin={filters.surfaceMin}
						valueMax={filters.surfaceMax}
						boxName='surface'
						boxTitle='propertiesIndexFilterSurface'
					/>

					<FilterRange
						isClicked={isClickedPrice}
						clickOutsideCallback={this.handleClickOutside}
						btnClickCallback={this.handleFilterBtnClick}
						filterChangeCallback={this.handleFilterChange}
						filterSubmitCallback={this.handleFilterSubmit}
						className="catalogue__header__filter"
						valueMin={filters.priceMin}
						valueMax={filters.priceMax}
						boxName='price'
						boxTitle='propertiesIndexFilterPrice'
					/>

					<FilterBedrooms
						isClicked={isClickedBedrooms}
						values={filters.bedrooms}
						clickOutsideCallback={this.handleClickOutside}
						btnClickCallback={this.handleFilterBtnClick}
						filterChangeCallback={this.handleFilterChange}
						className="catalogue__header__filter bedrooms"
					/>

                    <div className={`catalogue__header__filter--icon ${popinBtnClass}`} onClick={openFiltersPopinCallback}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
                            <g fill="none" fillRule="evenodd">
                                <path fill="#737373" d="M14.006 18.001h4.989v-4.994h-4.989V18zm1.007-.98h2.99v-3.012h-2.99v3.012z"/>
                                <path stroke="#737373" className="icon__vertical__line" strokeLinecap="square" d="M16.5.5v12.87M16.5 17.589v1.905"/>
                                <g>
                                    <path fill="#737373" d="M7 11.995h4.988V7H7v4.995zm1.007-.981h2.99V8.002h-2.99v3.012z"/>
                                    <path stroke="#737373" className="icon__vertical__line" strokeLinecap="square" d="M9.5.5v6.862M9.5 11.995v7.499"/>
                                </g>
                                <g>
                                    <path fill="#737373" d="M0 6.995h4.988V2H0v4.995zm1.007-.981h2.99V3.002h-2.99v3.012z"/>
                                    <path stroke="#737373" className="icon__vertical__line" strokeLinecap="square" d="M2.5.5V2M2.5 6.995v12.499"/>
                                </g>
                            </g>
                        </svg>
                    </div>
				</div>
			</header>
		)
	}
}

const mapStateToProps = state => {
	const { filters: filterValues } = state.filters

	return { filterValues }
}

const mapDispatchToProps = dispatch => {
	return {

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesFilters)