import React from 'react'

import { media } from 'media'

export const MessageUser = (props) => {
	const { user } = props
	const { avatar } = user
	const avatarStyle = {
		backgroundImage: (avatar) ? `url(${media.getMediaUrl(avatar)})`: null,
	}

	return (
		<div className="Message__User">
			<div className="Message__User_avatar" style={avatarStyle}></div>
		</div>
	)
}

export default MessageUser