const resetPasswordForm = {
	name: 'reset-password',
	submitBtn: 'Validate',
	multipart: false,
	fields: [
		{
			type: 'password',
			label: 'Your new password',
			props: {
				name: 'password',
				id: 'password',
				required: true,
			},
		},
		{
			type: 'password',
			label: 'Confirm your new password',
			props: {
				name: 'password-confirm',
				id: 'password-confirm',
				required: true,
			},
		},
	],
}

export default resetPasswordForm