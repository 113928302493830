import React from 'react'

export const ProjectDashboardBox = (props) => {
	const { title, children, className } = props

	return (
		<div className={`ProjectDashboardBox ${className}`}>
			{title &&
				<div className="ProjectDashboardBox__header">
					{title}
				</div>
			}

			<div className="ProjectDashboardBox__content">
				{children}
			</div>
		</div>
	)
}

export default ProjectDashboardBox