import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { setActiveConversation } from 'actions/messaging'
import AccessControl from 'auth/access-control'
import ApiClient from 'api/ApiClient'
import apiEndpoints from 'constants/api-endpoints'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import Label from 'components/forms/inputs/Label'
import OfferDirectoryBreadcrumb from 'components/offers/OfferDirectoryBreadcrumb'
import OfferDetail from 'components/offers/OfferDetail'
import offerQuoteForm from 'components/forms/schemas/offerQuoteForm'
import Popin from 'components/layout/Popin'
import ProjectOfferForm from 'components/offers/ProjectOfferForm'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import UbikonForm from 'components/forms/UbikonForm'
import {
	SubmitButton,
	TextInput
} from 'components/forms/inputs'

class ProjectSectionOffers extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			activeOffer: undefined,
			activeOfferConversation: undefined,
			activeConversationUser: undefined,
			activeConversationOffer: undefined,
			isLoadingMessage: false,
			offerSchema: undefined,
			isFetchingSchema: false,
			displayAddOfferDirectoryPopin: false,
			newDirectoryName: undefined,
			activeDirectories: [],
			offerQuoteOffer: undefined,
			updatedOfferQuote: undefined,
			offerDirectories: undefined,
			isLoadingDirectories: false,
			isLoadingOffer: false,
		}

		// bind methods
		this.handleOfferListItemClick = this.handleOfferListItemClick.bind(this)
		this.handleOfferSubmit = this.handleOfferSubmit.bind(this)
		this.getOfferConversation = this.getOfferConversation.bind(this)
		this.handleAddOfferDirectoryClick = this.handleAddOfferDirectoryClick.bind(this)
		this.handleOfferDirectoryPopinClose = this.handleOfferDirectoryPopinClose.bind(this)
		this.handleNewDirectoryNameChange = this.handleNewDirectoryNameChange.bind(this)
		this.handleNewDirectorySubmit = this.handleNewDirectorySubmit.bind(this)
		this.handleDirectoryHeaderClick = this.handleDirectoryHeaderClick.bind(this)
		this.handleOfferBackBtnClick = this.handleOfferBackBtnClick.bind(this)
		this.handlePostQuoteClick = this.handlePostQuoteClick.bind(this)
		this.handleCloseQuotePopin = this.handleCloseQuotePopin.bind(this)
		this.handleOfferQuoteSubmit = this.handleOfferQuoteSubmit.bind(this)
		this.handleOfferQuoteMessageClick = this.handleOfferQuoteMessageClick.bind(this)
		this.handleNotInterestedClick = this.handleNotInterestedClick.bind(this)
		this.handleOfferActionClick = this.handleOfferActionClick.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
		this.apiClient = new ApiClient()
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const { activeOffer } = this.state
		const { projectPipelineSection } = this.props

		if (projectPipelineSection) {
			this.getDirectories()
		}

		// get active offer conversation
		this.getOfferConversation(activeOffer)
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			activeOffer,
			activeConversationUser,
			activeConversationOffer,
			offerSchema,
			offerDirectories,
			isFetchingSchema,
			updatedOfferQuote,
		} = this.state

		const {
			project,
			displayOfferForm,
			apiResponse,
			apiResponseEndpoint,
			projectPipelineSection
		} = this.props

		const { offersByUser: offers } = project

		const activeOfferEndpoint = (activeOffer) ? apiEndpoints.OFFER_PUT.replace(':offerId', activeOffer.id) : undefined
		const offerFormEndpoint = this.getOfferFormEndpoint()
		const createDirectoryEndpoint = this.endpointBuilder.getEndpoint('offer_directories')
		const createOfferQuoteEndpoint = this.endpointBuilder.getEndpoint('offer_quotes')
		const postOfferEndpoint = apiEndpoints.POST_PROJECT_OFFER
		const notInterestedEndpoint = this.endpointBuilder.getEndpoint('offer_refusals')

		const projectPipelineSectionEndpoint = this.endpointBuilder.getEndpoint('project_pipeline_section', {
			id: projectPipelineSection.id
		})

		const offerQuoteStatusEndpoint = (updatedOfferQuote) ? this.endpointBuilder.getEndpoint('offer_quote', {id: updatedOfferQuote.id}) : undefined

		let conversationEndpoint = undefined
		if (activeConversationUser) {
			if (typeof activeConversationOffer !== 'undefined') {
				conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
					receiverId: activeConversationUser.id,
					offerId: activeConversationOffer.id,
				})
			} else {
				conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
					receiverId: activeConversationUser.id
				})
			}
		}

		// display notification offer
		const notificationOffer = new URL(window.location.href).searchParams.get('o')
		if (!activeOffer && offerDirectories && notificationOffer) {
			let newActiveOffer = undefined
			offerDirectories.map((dir, iD) => {
				dir.offers.map((offer, iO) => {
					if (offer.id === parseInt(notificationOffer)) {
						newActiveOffer = offer
					}

					return null
				})

				return null
			})

			if (typeof newActiveOffer !== 'undefined') {
				this.setState((state, props) => ({
					activeOffer: newActiveOffer
				}))
			}
		}

		// check if active offer status was updated
		if (activeOffer && offers) {
			offers.map((offer, i) => {
				if (offer.id === activeOffer.id && offer.status !== activeOffer.status) {
					this.setState((state, props) => ({
						activeOffer: offer
					}))
				}

				return offer
			})
		}

		// receive offer API response
		if (apiResponse && apiResponseEndpoint === activeOfferEndpoint) {
			this.setState((state, props) => ({
				isLoadingOffer: false,
			}))
		}

		// new quote
		if (apiResponse && apiResponseEndpoint === createOfferQuoteEndpoint) {
			this.props.resetApiResponse()

			this.getDirectories()

			let isLoadingOffer = false
			if (activeOfferEndpoint) {
				this.props.callApi(activeOfferEndpoint, 'get')

				isLoadingOffer = true
			}

			this.setState((state, props) => ({
				offerQuoteOffer: undefined,
				isLoadingOffer: isLoadingOffer,
			}))
		}

		// refresh directories if offer has been posted
		if (apiResponse && apiResponseEndpoint === postOfferEndpoint) {
			this.props.resetApiResponse()

			this.getDirectories()
		}

		// set directories
		if (apiResponse && apiResponseEndpoint === projectPipelineSectionEndpoint) {
			this.props.resetApiResponse()

			if (apiResponse.offerDirectories) {
				const { offerDirectories } = apiResponse

				// reset active offer if defined
				let newActiveOffer = activeOffer
				if (offerDirectories && activeOffer) {
					offerDirectories.map((dir, iD) => {
						dir.offers.map((offer, iO) => {
							if (offer.id === activeOffer.id) {
								newActiveOffer = offer
							}

							return null
						})

						return null
					})
				}

				this.setState((state, props) => ({
					offerDirectories: offerDirectories,
					isLoadingDirectories: false,
					activeOffer: newActiveOffer
				}))
			}
		}

		// new message API response
		/*if (apiResponse && apiResponseEndpoint === apiEndpoints.POST_MESSAGE) {
			this.props.resetApiResponse()

			let newOffer = activeOffer
			let conversationId = activeOffer.conversation
			if (null === conversationId) {
				conversationId = apiResponse.conversation.replace('/api/conversations/', '')
				conversationId = parseInt(conversationId)

				newOffer = {
					...newOffer,
					conversation: {
						id: conversationId
					}
				}
			}

			// reload current conversation
			this.getOfferConversation(newOffer)

			// update activeOffer in state
			this.setState((state, props) => ({
				activeOffer: newOffer
			}))
		}*/

		if (activeConversationUser && apiResponseEndpoint === conversationEndpoint) {
			this.props.setActiveConversation(apiResponse.conversation)

			this.setState((state, props) => ({
				activeConversationUser: undefined,
				activeConversationOffer: undefined,
			}))
		}

		if (displayOfferForm && !isFetchingSchema && !offerSchema) {
			// get offer form
			this.props.callApi(offerFormEndpoint, 'get')

			this.setState((state, props) => ({
				isFetchingSchema: true
			}))
		}

		if (apiResponse && apiResponseEndpoint === offerFormEndpoint) {
			this.setState((state, props) => ({
				offerSchema: apiResponse.model,
				isFetchingSchema: false,
			}))
		}

		if (prevProps.displayOfferForm !== displayOfferForm && !displayOfferForm) {
			this.setState((state, props) => ({
				offerSchema: undefined,
			}))
		}

		if (apiResponse && apiResponseEndpoint === createDirectoryEndpoint && project) {
			this.props.resetApiResponse()

			// refresh offers list
			this.getDirectories()

			this.setState((state, props) => ({
				displayAddOfferDirectoryPopin: false,
				newDirectoryName: undefined,
			}))
		}

		// not interested API response
		if (apiResponse && apiResponseEndpoint === notInterestedEndpoint) {
			// refresh offers list
			this.getDirectories()

			this.setState((state, props) => ({
				activeOffer: undefined,
			}))
		}

		// receive OfferQuote update API response
		if (apiResponse && apiResponseEndpoint === offerQuoteStatusEndpoint) {
			// refresh offers list
			this.getDirectories()

			this.setState((state, props) => ({
				updatedOfferQuote: undefined,
			}))
		}

		return true
	}

	getOfferConversationEndpoint(offer) {
		if (offer && offer.conversation && null !== offer.conversation) {
			return apiEndpoints.GET_CONVERSATION.replace(':conversationId', offer.conversation.id)
		}

		return undefined
	}

	getOfferFormEndpoint() {
		const { project, projectPipelineSection } = this.props

		if (typeof projectPipelineSection !== 'undefined') {
			return apiEndpoints.GET_PROJECT_OFFER_FORM
				.replace(':projectId', project.id)
				.replace(':projectPipelineSectionId', projectPipelineSection.id)
		}
	}

	getOfferConversation(offer) {
		if (offer && offer.conversation && null !== offer.conversation) {
			const conversationEndpoint = this.getOfferConversationEndpoint(offer)
			this.props.callApi(conversationEndpoint, 'get')

			this.setState((state, props) => ({
				isLoadingMessage: true,
			}))
		}
	}

	getDirectories() {
		const { projectPipelineSection } = this.props

		if (projectPipelineSection) {
			const endpoint = this.endpointBuilder.getEndpoint('project_pipeline_section', {
				id: projectPipelineSection.id
			})

			this.props.callApi(endpoint, 'get')

			this.setState((state, props) => ({
				isLoadingDirectories: true,
			}))
		}
	}

	handleOfferListItemClick(e, offer) {
		e.preventDefault()

		this.getOfferConversation(offer)

		this.setState((state, props) => ({
			activeOffer: offer,
			activeOfferConversation: undefined,
		}))
	}

	handleOfferSubmit(formData, formFiles) {
		const { createOfferCallback } = this.props
		createOfferCallback(formData, formFiles)
	}

	handleAddOfferDirectoryClick(e) {
		e.preventDefault()

		const { displayAddOfferDirectoryPopin } = this.state
		const display = !displayAddOfferDirectoryPopin

		this.setState((state, props) => ({
			displayAddOfferDirectoryPopin: display,
		}))
	}

	handleOfferDirectoryPopinClose() {
		this.setState((state, props) => ({
			displayAddOfferDirectoryPopin: false,
			newDirectoryName: undefined,
		}))
	}

	handleNewDirectoryNameChange(e, field) {
		const name = e.target.value

		this.setState((state, props) => ({
			newDirectoryName: name
		}))
	}

	handleNewDirectorySubmit(e) {
		e.preventDefault()

		const { newDirectoryName } = this.state
		const { projectPipelineSection } = this.props

		if (newDirectoryName && newDirectoryName.length > 0) {
			const createDirectoryEndpoint = this.endpointBuilder.getEndpoint('offer_directories')

			const formData = {
				name: newDirectoryName,
				projectPipelineSection: projectPipelineSection.id,
			}

			const newDirectoryData = this.apiClient.normalizeRequest(formData, [{
				name: 'projectPipelineSection',
				path: '/api/project_pipeline_sections/{id}'
			}])

			this.props.callApi(createDirectoryEndpoint, 'post', {
				body: JSON.stringify(newDirectoryData)
			})
		}
	}

	handleDirectoryHeaderClick(directory) {
		const { activeDirectories } = this.state

		// if directory is already active
		if (activeDirectories.includes(directory.id)) {
			const newActiveDirectories = activeDirectories.filter((activeDirectoryId, i) => {
				return parseInt(activeDirectoryId) !== parseInt(directory.id)
			})

			this.setState((state, props) => ({
				activeDirectories: newActiveDirectories
			}))
		} else {
			const newActiveDirectories = [...activeDirectories, directory.id]

			this.setState((state, props) => ({
				activeDirectories: newActiveDirectories
			}))
		}
	}

	handleOfferBackBtnClick(e) {
		e.preventDefault()

		this.setState((state, props) => ({
			activeOffer: undefined,
		}))
	}

	handlePostQuoteClick(offer) {
		this.setState((state, props) => ({
			offerQuoteOffer: offer,
		}))
	}

	handleCloseQuotePopin() {
		this.setState((state, props) => ({
			offerQuoteOffer: undefined,
		}))
	}

	handleOfferQuoteSubmit(formData) {
		const { offerQuoteOffer } = this.state

		if (offerQuoteOffer) {
			const newFormData = {
				...formData,
				offer: offerQuoteOffer.id,
			}

			const quoteData = this.apiClient.normalizeRequest(newFormData, [{
				name: 'offer',
				path: '/api/offers/{id}'
			}])

			const createOfferQuoteEndpoint = this.endpointBuilder.getEndpoint('offer_quotes')

			this.props.callApi(createOfferQuoteEndpoint, 'post', {
				body: JSON.stringify(quoteData)
			})
		}
	}

	handleOfferQuoteMessageClick(offerQuote, offer) {
		// open Chat conversation
		const conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
			receiverId: offerQuote.partner.user.id,
			offerId: offer.id
		})

		this.props.callApi(conversationEndpoint, 'get')

		this.setState((state, props) => ({
			activeConversationUser: offerQuote.partner.user,
			activeConversationOffer: offer,
		}))
	}

	handleNotInterestedClick(offer) {
		const notInterestedEndpoint = this.endpointBuilder.getEndpoint('offer_refusals')

		const offerIRI = `/api/offers/${offer.id}`
		const notInterestedData = {
			offer: offerIRI
		}

		this.props.callApi(notInterestedEndpoint, 'post', {
			body: JSON.stringify(notInterestedData)
		})
	}

	handleOfferActionClick(offerQuote, actionType) {
		const offerQuoteStatusEndpoint = this.endpointBuilder.getEndpoint('offer_quote', {
			id: offerQuote.id,
		})

		this.props.callApi(offerQuoteStatusEndpoint, 'put', {
			body: JSON.stringify({status: actionType}),
		})

		this.setState((state, props) => ({
			updatedOfferQuote: offerQuote,
		}))
	}

	render() {
		const {
			activeOffer,
			activeOfferConversation,
			isLoadingMessage,
			offerSchema,
			displayAddOfferDirectoryPopin,
			offerDirectories,
			activeDirectories,
			offerQuoteOffer,
			isLoadingDirectories,
			isLoadingOffer,
		} = this.state

		const {
			project,
			displayOfferForm,
			offerBackArrowClickCallback,
			//addOfferCallback,
			offerButtonsCallback,
			//createOfferRequestCallback
		} = this.props

		const isProjectOwner = this.accessControl.isProjectOwner(project)

		const folderField = {
			name: "folder-name",
			required: true,
		}

		return (
			<div className="ProjectSection--offers">
				{displayOfferForm && !offerSchema &&
					<Spinner />
				}

				{displayOfferForm && offerSchema &&
					<div>
						<div className="Offer__back">
							<ImageButton
								image={icons.arrow}
								altText='Back'
								onClick={offerBackArrowClickCallback}
							/>
						</div>

						<UbikonForm form={offerSchema} onSubmit={this.handleOfferSubmit} theme={ProjectOfferForm} />
					</div>
				}

				{activeOffer && isLoadingOffer &&
					<Spinner />
				}

				{activeOffer && !isLoadingOffer &&
					<OfferDetail
						offer={activeOffer}
						offerButtonsCallback={offerButtonsCallback}
						offerConversation={activeOfferConversation}
						isLoadingMessage={isLoadingMessage}
						isProjectOwner={isProjectOwner}
						backBtnCallback={this.handleOfferBackBtnClick}
						postQuoteClickCallback={this.handlePostQuoteClick}
						offerQuoteMessageCallback={this.handleOfferQuoteMessageClick}
						offerQuoteActionCallback={this.handleOfferActionClick}
						notInterestedClickCallback={this.handleNotInterestedClick}
					/>
				}

				{!displayOfferForm && !activeOffer &&
					<div className="OffersList__body">
						{isLoadingDirectories &&
							<Spinner />
						}

						{!isLoadingDirectories &&
							<div className="OffersList__body__header">
								<h2>
									<Trans i18nKey="projectDetailOffersAllTitle">projectDetailOffersAllTitle</Trans>
								</h2>

								<div className="Buttons">
									<ImageButton image={icons.add} altText="New folder" onClick={this.handleAddOfferDirectoryClick} />
									<TextButton text='projectDetailOffersNewFolder' className="upper blue small" onClick={this.handleAddOfferDirectoryClick} />
								</div>
							</div>
						}

						{offerDirectories &&
							<div className="folders">
								{offerDirectories.map((directory, i) => {
									const isActive = activeDirectories && activeDirectories.includes(directory.id)

									return (
										<div className="folder" key={i}>
											<div className="folder__group" onClick={() => this.handleDirectoryHeaderClick(directory)}>
												<h3 className="folder__title">{directory.name}</h3>

												{directory.offers && directory.offers.length > 0 &&
													<span className="folder__title__infos">
														<Trans i18nKey="projectDetailOffersCount" count={directory.offers.length}>
															{{count: directory.offers.length}} offers
														</Trans>
													</span>
												}
											</div>

											{isActive &&
												<OfferDirectoryBreadcrumb />
											}

											{isActive && directory.offers &&
												directory.offers.map((offer, iO) => {
													const createdDate = new Date(offer.createdAt)

													return (
														<div className="folder__line" key={iO}>
															<div className="folder__name" onClick={(e) => this.handleOfferListItemClick(e, offer)}>
																{offer.title}
															</div>

															<div className="folder__date" onClick={(e) => this.handleOfferListItemClick(e, offer)}>
																{createdDate.toLocaleDateString()}
															</div>

															<div className="folder__pending" onClick={(e) => this.handleOfferListItemClick(e, offer)}>
																
															</div>

															<div className="folder__refused" onClick={(e) => this.handleOfferListItemClick(e, offer)}>
															</div>

															<div className="folder__interested" onClick={(e) => this.handleOfferListItemClick(e, offer)}>
															</div>

															<div className="folder__actions">
															</div>
														</div>
													)
												})
											}

											{isActive && !directory.offers &&
												<div className="folder__line">
													<div>
														<Trans i18nKey="projectDetailOffersFolderEmpty">projectDetailOffersFolderEmpty</Trans>
													</div>
												</div>
											}
										</div>
									)
								})}
							</div>
						}
					</div>
				}

				{displayAddOfferDirectoryPopin &&
					<Popin isOpened={true} transTitle="projectDetailOffersPopinTitle" transTitleKey="projectDetailOffersPopinTitle" onClose={this.handleOfferDirectoryPopinClose}>
						<div className="Offer__directory__popin">
							<form onSubmit={this.handleNewDirectorySubmit}>
								<Label forInput="folder-name" labelText="projectDetailOffersPopinLabel" />
								<TextInput field={folderField} onChange={this.handleNewDirectoryNameChange} />

								<SubmitButton text="projectDetailOffersPopinSubmitBtn" />
							</form>
						</div>
					</Popin>
				}

				{offerQuoteOffer &&
					<Popin isOpened={true} transTitle="projectDetailOffersQuotePopinTitle" transTitleKey="projectDetailOffersQuotePopinTitle" onClose={this.handleCloseQuotePopin}>
						<UbikonForm form={offerQuoteForm} onSubmit={this.handleOfferQuoteSubmit} />
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api

	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSectionOffers)