import React from 'react'

import {
	SubmitButton
} from 'components/forms/inputs'
import TextButton from 'components/buttons/TextButton'

const renderFormField = (props, field, fieldName) => {
	const { formData, formHelper } = props

	let newProps = {
		...field.props
	}

	if (formData && formData[fieldName]) {
		newProps['value'] = formData[fieldName]
	}

	const newField = {
		...field,
		name: fieldName,
		props: newProps,
	}

	const fieldKey = `key-${fieldName}`

	return (
		formHelper.renderField(newField, fieldKey)
	)
}

export const UserProfileForm = (props) => {
	const {
		formHelper,
		formFields,
		submitCallback,
	} = props

	const { onCancel } = formHelper.props

	if (!formFields) {
		return false;
	}

	const fieldDisplay = [
		'username', 'email', 'organization'
	]

	return (
		<div className="Organization__content">
			<form name="user-profile" onSubmit={submitCallback}>
				<div className="Column__left">
					{fieldDisplay.map((fieldName, i) => {
						return (
							<div className="Organization__infobox" key={i}>
								{renderFormField(props, formFields[i], fieldName)}
							</div>
						)
					})}
				</div>

				<div className="Column__right">
					<div className="Organization__infobox">
						{renderFormField(props, formFields[3], 'bio')}
					</div>
				</div>

				<div className="Column__full">
					<SubmitButton className="upper" text='userProfileSubmitBtn' />
					<TextButton className="Cancel__btn" text='userProfileCancelBtn' onClick={onCancel} />
				</div>
			</form>
		</div>
	)
}

export default UserProfileForm