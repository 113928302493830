import routesList from 'routing/routes-list'
import userRoles from 'constants/user-roles'

import { ReactComponent as IconCollections } from 'assets/svg/icon-heart.svg'
import { ReactComponent as IconProjects } from 'assets/svg/icon-projects.svg'
import { ReactComponent as IconLands } from 'assets/svg/icon-lands.svg'
import { ReactComponent as IconMessages } from 'assets/svg/icon-messages.svg'
import { ReactComponent as IconProperties } from 'assets/svg/icon-properties.svg'
import { ReactComponent as IconOrganizationsSimple } from 'assets/svg/icon-organization-simple.svg'
/*import { ReactComponent as IconOrganizationsGroup } from 'assets/svg/icon-organization-group.svg'*/
import { ReactComponent as IconAdmin } from 'assets/svg/icon-gear.svg'

export const leftMenuItems = {
	sections: [
		{
			label: 'leftMenuSectionNavigation',
			items: [
				{
					label: 'leftMenuProjects',
					path: routesList.projects.index,
					icon: IconProjects,
					svgIcon: true,
					svgClass: 'project',
				},
				{
					label: 'leftMenuLands',
					path: routesList.lands.index,
					icon: IconLands,
					svgIcon: true,
					svgClass: 'foncier',
					role: userRoles.ROLE_SUPER_ADMIN,
				},
				{
					label: 'leftMenuProperties',
					path: routesList.properties.index,
					icon: IconProperties,
					svgIcon: true,
					svgClass: 'properties',
				},
				{
					label: 'leftMenuCollections',
					path: routesList.collections.index,
					icon: IconCollections,
					svgIcon: true,
					svgClass: 'collection',
					/*role: userRoles.ROLE_ORGANIZATION_MANAGER,*/
				},
				{
					label: 'leftMenuOrganization',
					path: routesList.organizations.user.edit,
					icon: IconOrganizationsSimple,
					svgIcon: true,
					svgClass: 'organization',
					/*role: userRoles.ROLE_ORGANIZATION_MANAGER,*/
				},
				{
					label: 'Conversations',
					path: routesList.messenger,
					icon: IconMessages,
					svgIcon: true,
					svgClass: 'message',
					mobile: true,
				},
				{
					label: 'leftMenuAdministration',
					path: routesList.admin,
					icon: IconAdmin,
					svgIcon: true,
					svgClass: 'administration',
					role: userRoles.ROLE_SUPER_ADMIN,
				},
			]
		},
	]
}

export default leftMenuItems