import ActionTypes from 'constants/action-types';

const initialState = {
	collections: [],
};

const collectionsReducer = (state = initialState, action) => {
	const {
		isCollectionsPopinOpen,
		collectionType,
		collectionItem,
		newCollectionItem,
	} = action

	switch(action.type) {
		case ActionTypes.OPEN_COLLECTIONS_POPIN:
			return {
				...state,
				isCollectionsPopinOpen: isCollectionsPopinOpen,
		        collectionType,
		        collectionItem,
			};

		case ActionTypes.CLOSE_COLLECTIONS_POPIN:
			return {
				...state,
				isCollectionsPopinOpen: isCollectionsPopinOpen,
		        collectionType,
		        collectionItem,
			};

		case ActionTypes.DID_ADD_TO_COLLECTION:
			return {
				...state,
				newCollectionItem,
			}

		default:
			return {...state}
	}
}

export default collectionsReducer