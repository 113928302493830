import React from 'react'

import SubmitButton from 'components/forms/inputs/SubmitButton'

const getBucketDescription = (bucket) => {
	if ('construction' === bucket.slug) {
		return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie enim orci, commodo egestas augue blandit nec. Nullam rhoncus vulputate tincidunt. Nulla facilisi. Phasellus id purus malesuada, convallis nibh non, lacinia dui. Donec nunc nunc, consectetur sed arcu id, posuere blandit libero. Praesent turpis arcu, sagittis et dolor non, faucibus consequat ex. Sed sed dapibus urna, ut luctus ante. Morbi auctor lobortis hendrerit.'
	} else if ('sale' === bucket.slug) {
		return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie enim orci, commodo egestas augue blandit nec. Nullam rhoncus vulputate tincidunt. Nulla facilisi. Phasellus id purus malesuada, convallis nibh non, lacinia dui. Donec nunc nunc, consectetur sed arcu id, posuere blandit libero. Praesent turpis arcu, sagittis et dolor non, faucibus consequat ex. Sed sed dapibus urna, ut luctus ante. Morbi auctor lobortis hendrerit.'
	}

	return ''
}

export const CreateProjectBucket = (props) => {
	const { bucket, createProjectCallback } = props
	const description = getBucketDescription(bucket)

	return (
		<div className="CreateProjectBucket">
			<div className="Col__left">
				<h3>{bucket.name} project</h3>

				{description && description.length > 0 &&
					<p>{description}</p>
				}
			</div>

			<div className="Col__right">
				<SubmitButton text='Create project' onClick={() => createProjectCallback(bucket)} />
			</div>
		</div>
	)
}

export default CreateProjectBucket