import React from 'react'
import { withTranslation } from 'react-i18next'

export const handleClick = (e, props) => {
	e.preventDefault()

	const { onClick, disabled } = props

	if (true === disabled) {
		return false
	}

	if (onClick) {
		onClick(e)
	}
}

export const TextButton = (props) => {
	const { t, text, className, buttonType, disabled } = props

	const isDisabled = (disabled) ? disabled : false
	const disabledClass = (isDisabled) ? 'disabled' : ''

	const buttonClass = `TextButton ${disabledClass} ${(typeof className !== 'undefined') ? className: ''}`

	const buttonTypeName = (buttonType) ? buttonType : 'button';

	return (
		<button
			type={buttonTypeName}
			className={buttonClass}
			disabled={isDisabled}
			onClick={(e) => handleClick(e, props)}
		>
			{ t(text) }
		</button>
	)
}

export default withTranslation()(TextButton)