import React from 'react'

import AdminPopinBlock from 'components/admin/AdminPopinBlock'
import BenefitsList from 'components/benefits/BenefitsList'
import LandBlock from 'components/admin/popins/blocks/LandBlock'
import SubmitButton from 'components/forms/inputs/SubmitButton'

export const LandPopin = (props) => {
	const {
		section,
		entity,
		actionCallback,
	} = props

	return (
		<div className="AdminPopin__content">
			<div className="AdminPopin__blocks">
				<LandBlock land={entity} displayZipcode={true} fullAddress={true} />

				{entity.description &&
					<AdminPopinBlock title='Description'>
						<p className="Description">{entity.description}</p>
					</AdminPopinBlock>
				}

				{entity.benefits && entity.benefits.length > 0 &&
					<AdminPopinBlock title='Benefits'>
						<BenefitsList benefits={entity.benefits} displayTitle={false} />
					</AdminPopinBlock>
				}
			</div>

			<div className="AdminPopin__buttons">
				<SubmitButton
					text='Validate'
					onClick={() => actionCallback('approved', section, entity)}
				/>

				<SubmitButton
					text='Deny'
					className="deny"
					onClick={() => actionCallback('display-denied', section, entity)}
				/>
			</div>
		</div>
	)
}

export default LandPopin