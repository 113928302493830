import React from 'react'

import AdminPopinBlock from 'components/admin/AdminPopinBlock'
import SubmitButton from 'components/forms/inputs/SubmitButton'

const OrganizationRequestsPopin = (props) => {
	const {
		section,
		entity,
		actionCallback,
	} = props

	return (
		<div className="AdminPopin__content">
			<div className="AdminPopin__blocks">
				<AdminPopinBlock title='User' className='half'>
					<p>
						<strong>Username</strong>
						<br />
						{entity.user.username}
					</p>

					<p>
						<strong>Email</strong>
						<br />
						{entity.user.email}
					</p>

					<p>
						<strong>Member since</strong>
						<br />
						{entity.user.createdAt}
					</p>

					<p>
						<strong>Bio</strong>
						<br />
						{entity.user.bio}
					</p>
				</AdminPopinBlock>

				<AdminPopinBlock title='Organization' className='half'>
					<p>
						<strong>Name</strong>
						<br />
						{entity.organization.name}
					</p>

					<p>
						<strong>Website</strong>
						<br />
						{entity.organization.website}
					</p>

					<p>
						<strong>Email</strong>
						<br />
						{entity.organization.contactEmail}
					</p>
				</AdminPopinBlock>
			</div>

			<div className="AdminPopin__buttons">
				<SubmitButton
					text='Validate'
					onClick={() => actionCallback('approved', section, entity)}
				/>

				<SubmitButton
					text='Deny'
					className="deny"
					onClick={() => actionCallback('display-denied', section, entity)}
				/>
			</div>
		</div>
	)
}

export default OrganizationRequestsPopin