import { include } from 'named-urls'

const routesList = {
	index: '/',
	login: '/login',
	admin: '/admin',
	messenger: '/messenger',
	collections: include('/collections', {
		index: '',
		detail: include(':collectionId/', {
			index: 'index/',
			edit: 'edit/',
			delete: 'delete/'
		})
	}),
	lands: include('/lands', {
		index: '',
		new: 'new/',
		detail: include(':landId/', {
			index: 'index/',
			edit: 'edit/',
			delete: 'delete/'
		})
	}),
	organizations: include('/organizations', {
		index: '',
		new: 'new/',
		detail: include(':organizationId/', {
			edit: 'edit/',
		}),
		user: include('/', {
			edit: 'my-organization/',
		})
	}),
	projects: include('/projects', {
		index: '',
		new: 'new/',
		detail: include(':projectId/', {
			index: 'index/',
			edit: 'edit/',
			delete: 'delete/'
		})
	}),
	properties: include('/properties', {
		index: '',
		detail: include(':propertyItemId/', {
			index: 'index/',
			edit: 'edit/',
			delete: 'delete/'
		})
	}),
	user_profile: '/my-profile',
	contact_page: '/contact',
	legal_notice: '/legal-notice',
};

export default routesList;