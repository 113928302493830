import React from 'react'

import AdminDenyForm from 'components/admin/AdminDenyForm'
import adminSectionTypes from 'constants/admin-section-types'
import LandPopin from 'components/admin/popins/LandPopin'
import OrganizationRequestsPopin from 'components/admin/popins/OrganizationRequestsPopin'
import PropertyPopin from 'components/admin/popins/PropertyPopin'
import ProjectRequestsPopin from 'components/admin/popins/ProjectRequestsPopin'

class AdminPopin extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			activeTab: undefined,
		}

		// bind methods
		this.handleTabClick = this.handleTabClick.bind(this)
	}

	handleTabClick(tabName) {
		this.setState((state, props) => ({
			activeTab: tabName
		}))
	}

	render() {
		const {
			activeTab,
		} = this.state

		const {
			section,
			entity,
			displayDenyForm,
			cancelCallback,
			actionCallback,
		} = this.props

		return (
			<div className="AdminPopin">

				{!displayDenyForm && adminSectionTypes.ORGANIZATION_REQUESTS === section.type &&
					<OrganizationRequestsPopin
						section={section}
						entity={entity}
						actionCallback={actionCallback}
					/>
				}

				{!displayDenyForm && adminSectionTypes.PROJECT_REQUESTS === section.type &&
					<ProjectRequestsPopin
						section={section}
						entity={entity}
						activeTab={activeTab}
						tabClickCallback={this.handleTabClick}
						actionCallback={actionCallback}
					/>
				}

				{!displayDenyForm && adminSectionTypes.LAND_REQUESTS === section.type &&
					<LandPopin
						section={section}
						entity={entity}
						actionCallback={actionCallback}
					/>
				}

				{!displayDenyForm && adminSectionTypes.PROPERTY_REQUESTS === section.type &&
					<PropertyPopin
						section={section}
						entity={entity}
						actionCallback={actionCallback}
					/>
				}

				{displayDenyForm &&
					<AdminDenyForm
						section={section}
						entity={entity}
						cancelCallback={cancelCallback}
						submitCallback={actionCallback}
					/>
				}
			</div>
		)
	}
}

export default AdminPopin