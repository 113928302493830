import React from 'react'
import ReactSelect from 'react-select'
import { withTranslation } from 'react-i18next'

import SelectDownArrow from 'components/forms/inputs/SelectDownArrow'

class Select extends React.Component {
	constructor(props) {
		super(props)

		const { field } = props
		const { props: fieldProps } = field

		const initialValue = (fieldProps && typeof fieldProps.value !== 'undefined') ? fieldProps.value : '';

		// set state
		this.state = {
			selectedOption: initialValue,
		}

		// bind methods
		this.handleSelectChange = this.handleSelectChange.bind(this)
	}

	handleSelectChange(selectedOption) {
		const { field, onChange } = this.props

		if (onChange) {
			onChange(selectedOption.value, field)
		}

		this.setState((state, props) => ({
			selectedOption: selectedOption,
		}))
	}

	render() {
		const { selectedOption } = this.state
		const { t, classname, field, formData } = this.props
		const { options, props: fieldProps } = field
		//const placeholder = options && options[0] && options[0].value

		let value = selectedOption

		const fieldName = (field.props.name) ? field.props.name : field.name

		if (fieldName && formData && typeof formData[fieldName] !== 'undefined') {
			// check if field not disabled
			if (!fieldProps.disabled) {
				value = formData[fieldName]
			}
		}

		if (null === value) {
			value = ''
		}

		const selectClassName = (classname) ? `Select ${classname}` : "Select"
		const selectComponents = {
			DropdownIndicator: SelectDownArrow,
		}

		const placeholder = (field && field.label) ? t(field.label) : undefined

		return (
			<ReactSelect
				className={selectClassName}
				value={selectedOption}
				onChange={this.handleSelectChange}
				options={options}
				components={selectComponents}
				placeholder={placeholder}
			/>
		)
		/*
		return (
			<select
				className="Select"
				{...fieldProps}
				placeholder={placeholder}
				onChange={(e) => onChange(e, field)}
				value={value}
			>
				{options && options.map((option, i) => {
					return (
						<option key={i} value={option.value} disabled={option.disabled}>
							{option.label}
						</option>
					)
				})}
			</select>
		)
		*/
	}
}

export default withTranslation()(Select)