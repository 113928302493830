import ActionTypes from 'constants/action-types';

const initialState = {
	messaging: [],
};

const messagingReducer = (state = initialState, action) => {
	switch(action.type) {
		case ActionTypes.MESSAGING_HIDE:
			return {
				...state,
				displayMessaging: action.displayBool
			};

		case ActionTypes.MESSAGING_SET_ACTIVE_CONVERSATION:
			return {
				...state,
				activeConversation: action.conversation
			}

		case ActionTypes.MESSAGING_LISTEN_TOPIC:
			const { listenedTopics } = state
			const { hubUrl, topic, callback } = action
			let addTopic = true

			// check if topic is already in array
			if (listenedTopics) {
				listenedTopics.map((listenedTopic) => {
					if (listenedTopic === topic) {
						addTopic = false
					}

					return null
				})
			}

			if (addTopic) {
				const topicUrl = `${hubUrl}?topic=${encodeURIComponent(topic)}`
				const hubListener = new EventSource(topicUrl)

				if (callback) {
					hubListener.onmessage = e => callback(e)
				}

				const newListenedTopics = (typeof listenedTopics === 'undefined') ? [topic]: [...listenedTopics, topic]

				return {
					...state,
					listenedTopics: newListenedTopics
				}
			} else {
				return {
					...state
				}
			}

		default:
			return {...state}
	}
}

export default messagingReducer