import React from 'react'

import AdminPopinBlock from 'components/admin/AdminPopinBlock'
import AdminPropertyItemBlock from 'components/admin/popins/blocks/AdminPropertyItemBlock'
import BenefitsList from 'components/benefits/BenefitsList'
import SubmitButton from 'components/forms/inputs/SubmitButton'

export const PropertyPopin = (props) => {
	const {
		section,
		entity,
		actionCallback,
	} = props

	return (
		<div className="AdminPopin__content">
			<div className="AdminPopin__blocks">
				<AdminPopinBlock title='Property name'>
					<h4>{entity.name}</h4>
				</AdminPopinBlock>

				<AdminPopinBlock title='Property type'>
					<h4 className="Type__title">{entity.propertyType.name}</h4>

					{entity.description &&
						<p className="Description">{entity.description}</p>
					}
				</AdminPopinBlock>

				{entity.benefits &&
					<AdminPopinBlock title='Benefits'>
						<BenefitsList benefits={entity.benefits} displayTitle={false} />
					</AdminPopinBlock>
				}

				{entity.propertyItems &&
					<AdminPopinBlock title='Property items'>
						{entity.propertyItems.map((propertyItem, i) => {
							return (
								<AdminPropertyItemBlock key={i} propertyItem={propertyItem} />
							)
						})}
					</AdminPopinBlock>
				}
			</div>

			<div className="AdminPopin__buttons">
				<SubmitButton
					text='Validate'
					onClick={() => actionCallback('approved', section, entity)}
				/>

				<SubmitButton
					text='Deny'
					className="deny"
					onClick={() => actionCallback('display-denied', section, entity)}
				/>
			</div>
		</div>
	)
}

export default PropertyPopin