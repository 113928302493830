import React from 'react'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'

class UbikonDatePicker extends React.Component {
	constructor(props) {
		super(props)

		// set initial input date
		let initialDate = moment()
		const { field } = props
		if (field && field.props && field.props.value) {
			initialDate = moment(field.props.value)
		}

		// set state
		this.state = {
			date: initialDate,
			focused: false
		}

		// bind methods
		this.handleFocusChange = this.handleFocusChange.bind(this)
		this.handleDateChange = this.handleDateChange.bind(this)
	}

	handleDateChange(selectedDate) {
		const { field, onChange } = this.props

		onChange(selectedDate.format(), field)

		this.setState((state, props) => ({
			date: selectedDate
		}))
	}

	handleFocusChange() {
		const { focused } = this.state

		this.setState((state, props) => ({
			focused: !focused
		}))
	}

	render() {
		const { date, focused } = this.state

		return (
			<SingleDatePicker
				date={date}
				onDateChange={this.handleDateChange}
				onFocusChange={this.handleFocusChange}
				small={true}
				numberOfMonths={1}
				focused={focused}
			/>
		)
	}
}

export default UbikonDatePicker