import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { resetTemporaryData } from 'actions/temporary-data'
import { collapseLeftMenu } from 'actions/left-menu'
import { displayMessaging, setActiveConversation } from 'actions/messaging'
import { media } from 'media'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import CollectionsFilter from 'components/collections/CollectionsFilter'
import iconCalendar from 'assets/png/icon-calendar.png'
import iconProperty from 'assets/png/icon-property.png'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import PropertyListItem from 'components/properties/PropertyListItem'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'

class CollectionDetail extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			collection: undefined,
			activeConversationUser: undefined,
		}

		// bind methods
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)
		this.handleCollectionItemClick = this.handleCollectionItemClick.bind(this)
		this.handleCollectionLikeClick = this.handleCollectionLikeClick.bind(this)
		this.handleCollectionPartnerClick = this.handleCollectionPartnerClick.bind(this)
		this.handleCollectionMessage = this.handleCollectionMessage.bind(this)

		// set private properties
		this.collectionId = this.props.match.params.collectionId
		this.temporaryDataKey = `tmp-collection-${this.collectionId}`
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const { temporaryData } = this.props

		// collapse menu
		this.props.collapseLeftMenu(true)

		// hide messaging
		this.props.displayMessaging(false)

		// remove temporary data
		this.props.resetTemporaryData(this.temporaryDataKey)

		if (typeof temporaryData[this.temporaryDataKey] !== 'undefined') {
			this.setState((state, props) => ({
				collection: temporaryData[this.temporaryDataKey]
			}))
		}

		// get collection from API
		const collectionEndpoint = this.endpointBuilder.getEndpoint('collection', {
			id: this.collectionId
		})
		this.props.callApi(collectionEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const { activeConversationUser } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const collectionEndpoint = this.endpointBuilder.getEndpoint('collection', {
				id: this.collectionId
			})

			const collectionConversationEndpoint = this.endpointBuilder.getEndpoint('collection_conversation', {
				collectionId: this.collectionId
			})

			if (apiResponseEndpoint === collectionEndpoint) {
				this.setState((state, props) => ({
					collection: apiResponse,
				}))
			} else if (
				apiResponseEndpoint === collectionConversationEndpoint
				&& apiResponse.conversation
				&& apiResponse.conversation.id
			) {
				this.props.setActiveConversation(apiResponse.conversation)
			} else if (activeConversationUser) {
				const conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
					receiverId: activeConversationUser.id
				})

				if (
					apiResponse.conversation
					&& apiResponse.conversation.id
					&& conversationEndpoint === apiResponseEndpoint
				) {
					this.props.setActiveConversation(apiResponse.conversation)

					// reset user chat
					const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat')
					this.props.callApi(userChatEndpoint, 'get')
				}
			}
		}
	}

	handleBackBtnClick() {
		const redirectPath = reverse(routesList.collections.index)
		this.props.history.push(redirectPath)
	}

	handleCollectionItemClick(collectionItem) {
		const { collection } = this.state

		if ('property' === collectionItem.itemType && collectionItem.propertyItem) {
			const propertyUrl = reverse(routesList.properties.detail.index, {
				propertyItemId: collectionItem.propertyItem.id
			})

			// redirect to property detail
			this.props.history.push(`${propertyUrl}?c=${collection.id}`)
		}
	}

	handleCollectionLikeClick(collectionItem) {
		// TODO ?
	}

	handleCollectionPartnerClick(user) {
		const conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
			receiverId: user.id
		})

		this.props.setActiveConversation(undefined)
		this.props.callApi(conversationEndpoint, 'get')

		this.setState((state, props) => ({
			activeConversationUser: user,
		}))
	}

	handleCollectionMessage() {
		const { collection } = this.state

		const collectionConversationEndpoint = this.endpointBuilder.getEndpoint('collection_conversation', {
			collectionId: collection.id
		})

		this.props.callApi(collectionConversationEndpoint)
	}

	render() {
		const { collection } = this.state

		let ownerStyle = undefined
		if (collection && collection.createdBy && collection.createdBy.avatar) {
			const avatar = collection.createdBy.avatar
			ownerStyle = { backgroundImage: `url(${media.getMediaUrl(avatar.contentUrl)})` }
		}

		const collectionDate = (collection) ? new Date(collection.createdAt) : undefined

		let propertiesCount = 0
		if (collection) {
			propertiesCount = collection.collectionItems.length
		}

		return (
			<div className="LandDetail CollectionDetail">
				<CollectionsFilter />

				<div className="LandDetail__content">
					<div className="Back__button">
						<ImageButton
							image={icons.arrow}
							altText='Back to Collections List'
							onClick={this.handleBackBtnClick}
						/>
					</div>

					{!collection &&
						<Spinner />
					}

					{collection &&
						<div className="LandDetail__wrapper">
							<div className={`LandDetail__infos${(!collection.collectionItems || collection.collectionItems.length <= 0) ? ' is-empty' : ''}`}>
								<div className="LandDetail__owner">
									<div className="avatar" style={ownerStyle}></div>
									<p className="username">
										{collection.createdBy.username}
									</p>
								</div>

								<h1>
									{collection.name}
								</h1>

								<div className="">
									{collectionDate &&
										<p className="all-projects__project__location collection">
											<img src={iconCalendar} className="collection__infos__icon" alt="calendar" /> {collectionDate.toLocaleDateString()}
										</p>
									}

									<p className="all-projects__project__location collection">
										<img src={iconProperty} className="collection__infos__icon" alt="calendar" /> 
										
										{propertiesCount > 0 &&
											<Trans i18nKey="collectionDetailPropertiesCount" count={propertiesCount}>
												{{count: propertiesCount}} property
											</Trans>
										}

										{propertiesCount <= 0 &&
											<Trans i18nKey="collectionDetailNoProperties">collectionDetailNoProperties</Trans>
										}
									</p>
								</div>

								<div className="LandDetail__description medium">
									{collection.description}
								</div>

								{collection.collectionUsers &&
									<div className="all-projects__project__profiles collection">
										{collection.collectionUsers.map((collectionUser, iU) => {
											const user = collectionUser.user
											const userStyle = {
												backgroundImage: (user.avatar && user.avatar.contentUrl) ? 
													`url(${media.getMediaUrl(user.avatar.contentUrl)})`
													: null,
											}

											return (
												<div
													key={iU}
													className="all-projects__project__profile"
													style={userStyle}
													onClick={() => this.handleCollectionPartnerClick(user)}
												></div>
											)
										})}

										<TextButton
											text="collectionDetailGroupMessageBtn"
											className="BlueButton upper small"
											onClick={this.handleCollectionMessage}
										/>
									</div>
								}
							</div>

							{collection.collectionItems && collection.collectionItems.length > 0 &&
								<div className="LandDetail__carousel">
									<div className="CollectionDetail__list">
										{collection.collectionItems.map((collectionItem, iCI) => {
											
											if ('property' === collectionItem.itemType && collectionItem.propertyItem) {
												return (
													<PropertyListItem
														key={iCI}
														item={collectionItem.propertyItem}
														itemCallback={() => this.handleCollectionItemClick(collectionItem)}
														className="catalogue__property"
														collectionClickCallback={() => this.handleCollectionLikeClick(collectionItem)}
														isCollectionMember={true}
													/>
												)
											}

											return null
										})}
									</div>
								</div>
							}
						</div>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	const { ...temporaryData } = state.temporary_data

	return {
		apiResponse,
		apiResponseEndpoint,
		temporaryData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		resetTemporaryData: (temporaryProjectKey) => dispatch(resetTemporaryData(temporaryProjectKey)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetail)