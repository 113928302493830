import React from 'react'

import adminSectionTypes from 'constants/admin-section-types'
import { media } from 'media'
import TextButton from 'components/buttons/TextButton'

const getSectionActions = (props) => {
	const { section, entity, entityClickCallback } = props

	const avatarStyle = {
		backgroundImage: (entity.createdBy && entity.createdBy.avatar) ? 
			`url(${media.getMediaUrl(entity.createdBy.avatar)})`
			: null,
	}

	return (
		<div className="AdminSectionEntity__actions">
			{entity.createdBy &&
				<div className="CreatedBy">
					<div className="Avatar" style={avatarStyle}></div>
					<div className="Username medium">{entity.createdBy.username}</div>
				</div>
			}

			<TextButton
				text='see request'
				className="upper medium"
				onClick={() => entityClickCallback(section, entity)}
			/>
		</div>
	)
}

const LandHeader = (land) => {
	return (
		<p>
			<strong>Land:</strong> {land.name}
			<span className="AdminSectionEntity__description">
				{land.squareMeters} m<sup>2</sup> - {land.city} {land.zipcode}
			</span>
		</p>
	)
}

const PropertyHeader = (property) => {
	return (
		<p>
			<strong>Property:</strong> {property.name}
			<span className="AdminSectionEntity__description">
				{property.propertyType.name} - {property.city} {property.zipcode}
			</span>
		</p>
	)
}

const OrganizationRequestHeader = (organizationRequest) => {
	return (
		<div>
			User {organizationRequest.user.username} wants to join {organizationRequest.organization.name}
		</div>
	)
}

const renderEntity = (props) => {
	const { section, entity } = props

	return (
		<div className="AdminSectionEntity">
			<div className="AdminSectionEntity__content">
				{adminSectionTypes.PROJECT_REQUESTS === section.type && entity.land &&
					<div>
						Project type: {entity.projectType.name}
						{LandHeader(entity.land)}
					</div>
				}

				{adminSectionTypes.PROJECT_REQUESTS === section.type && entity.property &&
					<div>
						Project type: {entity.projectType.name}
						{PropertyHeader(entity)}
					</div>
				}

				{adminSectionTypes.ORGANIZATION_REQUESTS === section.type && entity.organization &&
					OrganizationRequestHeader(entity)
				}

				{adminSectionTypes.LAND_REQUESTS === section.type && 
					LandHeader(entity)
				}

				{adminSectionTypes.PROPERTY_REQUESTS === section.type && 
					PropertyHeader(entity)
				}
			</div>

			{getSectionActions(props)}
		</div>
	)
}

export const AdminSectionEntity = (props) => {
	return renderEntity(props)
}

export default AdminSectionEntity