import ActionTypes from 'constants/action-types'

export const setCenterClass = (className) => {
    return {
        type: ActionTypes.SET_CENTER_CLASS,
        centerClass: className
    }
}

export const displayHeader = (bool) => {
    return {
        type: ActionTypes.SET_DISPLAY_HEADER,
        displayHeader: bool
    }
}

export const displayDecorators = (bool) => {
    return {
        type: ActionTypes.SET_DISPLAY_DECORATORS,
        displayDecorators: bool
    }
}

export const setElementSize = (key, value) => {
    return {
        type: ActionTypes.SET_ELEMENT_SIZE,
        sizeKey: key,
        sizeValue: value,
    }
}