import React from 'react'

import {
	SubmitButton
} from 'components/forms/inputs'
import taskTypes from 'constants/task-types'

class TaskPopinForm extends React.Component {
	constructor(props) {
		super(props)

		const initialSelectedFileType = (props.formData && props.formData.fileType) ? props.formData.fileType : undefined

		// set state
		this.state = {
			selectedFileType: initialSelectedFileType,
		}

		// bind methods
		this.handleFormChange = this.handleFormChange.bind(this)

		// set theme callback
		props.formHelper.setThemeOnChangeCallback(this.handleFormChange)
	}

	handleFormChange(formData) {
		if (formData.fileType) {
			this.setState((state, props) => ({
				selectedFileType: formData.fileType
			}))
		}
	}

	renderFormField(field, fieldName) {
		const { formData, formHelper } = this.props

		let newProps = {
			...field.props
		}

		if (formData && formData[fieldName]) {
			newProps['value'] = formData[fieldName]
		}

		const newField = {
			...field,
			name: fieldName,
			props: newProps,
		}

		const fieldKey = `key-${fieldName}`

		return (
			formHelper.renderField(newField, fieldKey)
		)
	}

	render() {
		const {
			selectedFileType,
		} = this.state

		const {
			formFields,
			submitCallback,
		} = this.props

		if (!formFields) {
			return null
		}

		const fieldDisplay = [
			'title', 'description'
		]

		return (
			<div className="Organization__content TaskPopinForm">
				<form name="organization-form" onSubmit={submitCallback} onChange={this.handleFormChange}>
					<div className="Column__left">
						{fieldDisplay.map((fieldName, i) => {
							return (
								<div className="Organization__infobox" key={i}>
									{this.renderFormField(formFields[i], fieldName)}
								</div>
							)
						})}
					</div>

					<div className="Column__right">
						<div className="Organization__infobox">
							{this.renderFormField(formFields[2], 'partners')}
						</div>

						<div className="Organization__infobox">
							{this.renderFormField(formFields[3], 'dueDate')}
						</div>

						<div className="Organization__infobox">
							{this.renderFormField(formFields[4], 'fileType')}
						</div>

						{taskTypes.TASK_TYPE_FILE_UPLOAD === selectedFileType &&
							<div className="Organization__infobox">
								{this.renderFormField(formFields[5], 'filesToUpload')}
							</div>
						}

						{taskTypes.TASK_TYPE_SIGNATURE === selectedFileType &&
							<div className="Organization__infobox">
								{this.renderFormField(formFields[6], 'taskFiles')}
							</div>
						}

						{this.renderFormField(formFields[7], 'task')}
						{this.renderFormField(formFields[8], 'project')}
						{this.renderFormField(formFields[9], 'projectPipelineSection')}
					</div>

					<div className="Column__full">
						<SubmitButton className="upper" text="projectDetailTaskEditSubmitBtn" />
					</div>
				</form>
			</div>
		)
	}
}

export default TaskPopinForm