import React from 'react'

import landStatuses from 'constants/land-statuses'
import SubmitButton from 'components/forms/inputs/SubmitButton'

export const LandDetailButtons = (props) => {
	const {
		land,
		isLandOwner,
		validateCallback,
		createProjectCallback,
		validateLabel,
		createProjectLabel,
		displayValidate,
		updateLandCallback,
	} = props

	return (
		<div className="LandDetailButtons">
			{displayValidate &&
				<SubmitButton
					text={validateLabel}
					onClick={validateCallback}
					onClickProps={land}
				/>
			}

			{landStatuses.LAND_STATUS_PENDING !== land.status &&
				<SubmitButton
					text={createProjectLabel}
					onClick={createProjectCallback}
					onClickProps={land}
				/>
			}

			{isLandOwner &&
				<SubmitButton
					text='landDetailBtnModify'
					onClick={updateLandCallback}
					displayLoading={false}
				/>
			}
		</div>
	)
}

export default LandDetailButtons