import React from 'react'

export const AdminPopinBlock = (props) => {
	const { title, className, children } = props

	return (
		<div className={`AdminPopinBlock ${(className) ? className: ''}`}>
			{title &&
				<div className="AdminPopinBlock__header">
					<h4 className="upper">{title}</h4>
				</div>
			}

			<div className="AdminPopinBlock__content">
				{children}
			</div>
		</div>
	)
}

export default AdminPopinBlock