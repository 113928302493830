import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'

import { callApi, resetApiResponse } from 'actions/api'
import { setTemporaryData } from 'actions/temporary-data'
import { displayMessaging } from 'actions/messaging'
import AccessControl from 'auth/access-control'
import ActivityFeed from 'components/projects/ActivityFeed'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import MobileProjectsHeader from 'components/projects/MobileProjectsHeader'
import OfferIndexList from 'components/projects/OfferIndexList'
import ProjectsListFilters from 'components/projects/ProjectsListFilters'
import ProjectsList from 'components/projects/ProjectsList'
import ProjectsListTabs from 'components/projects/ProjectsListTabs'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import userRoles from 'constants/user-roles'

class ProjectIndex extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			projects: undefined,
			filtersModel: undefined,
			isFilteringProjects: false,
			filtersArray: [],
			filters: {
				search: undefined,
				location: undefined,
				projectType: undefined,
			},
			tabs: [
				{
					name: 'projects',
					label: 'projectsIndexTabProjects',
					active: true,
				},
				{
					name: 'offers',
					label: 'projectsIndexTabOffers',
					active: false,
				},
				{
					name: 'activity',
					label: 'projectsIndexTabActivity',
					active: false,
				},
			],
		}

		// bind methods
		this.handleAddProjectClick = this.handleAddProjectClick.bind(this)
		this.handleFiltersSubmit = this.handleFiltersSubmit.bind(this)
		this.handleProjectClick = this.handleProjectClick.bind(this)
		this.handleTabClick = this.handleTabClick.bind(this)

		// set private properties
		this.temporaryDataKey = 'projects-list'
		this.endpointBuilder = new ApiEndpointBuilder()
		this.accessControl = new AccessControl()
	}

	componentDidMount() {
		const { temporaryData } = this.props

		// display chat
		this.props.displayMessaging(true)

		// get user's projects
		const projectsEndpoint = this.endpointBuilder.getEndpoint('projects')
		this.props.callApi(projectsEndpoint)

		// get tabs counts
		const tabsCountsEndpoint = this.endpointBuilder.getEndpoint('tabs_counts')
		this.props.callApi(tabsCountsEndpoint)

		// get filters form
		const filtersFormEndpoint = this.endpointBuilder.getEndpoint('project_filters_form')
		this.props.callApi(filtersFormEndpoint)

		// set projects list if previously loaded
		if (typeof temporaryData[this.temporaryDataKey] !== 'undefined') {
			this.setState((state, props) => ({
				projects: temporaryData[this.temporaryDataKey]
			}))
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { projects, filtersArray, tabs } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		let projectsEndpoint = this.endpointBuilder.getEndpoint('projects')
		if (filtersArray && filtersArray.length > 0) {
			const joinedFilters = filtersArray.join('&')
			projectsEndpoint = `${projectsEndpoint}?${joinedFilters}`
		}

		const filtersFormEndpoint = this.endpointBuilder.getEndpoint('project_filters_form')
		const tabsCountsEndpoint = this.endpointBuilder.getEndpoint('tabs_counts')

		if (apiResponse) {
			this.props.resetApiResponse()

			// projects list
			if (!projects && apiResponseEndpoint === projectsEndpoint) {
				// set temporary data
				this.props.setTemporaryData(this.temporaryDataKey, apiResponse)

				this.setState((state, props) => ({
					projects: apiResponse
				}))
			}

			// search query
			if (projects && apiResponseEndpoint === projectsEndpoint) {
				this.setState((state, props) => ({
					projects: apiResponse,
					isFilteringProjects: false,
				}))
			}

			// get tabs counts
			if (apiResponseEndpoint === tabsCountsEndpoint) {
				const tabsCounts = apiResponse.tabsCounts

				const newTabs = tabs.map((tab, iT) => {
					return {
						...tab,
						count: (tabsCounts[tab.name]) ? tabsCounts[tab.name] : undefined,
					}
				})

				this.setState((state, props) => ({
					tabs: newTabs,
				}))
			}

			// get filters
			if (apiResponse.model && apiResponseEndpoint === filtersFormEndpoint) {
				this.setState((state, props) => ({
					filtersModel: apiResponse.model
				}))
			}
		}
	}

	getActiveTab() {
		const { tabs } = this.state
		let activeTab = undefined

		tabs.map((tab, i) => {
			if (tab.active) {
				activeTab = tab
			}

			return null
		})

		return activeTab
	}

	handleAddProjectClick(e) {
		e.preventDefault()

		this.props.history.push(reverse(routesList.projects.new))
	}

	handleFiltersSubmit(filterType, searchQuery, location, projectType) {
		const { filters } = this.state

		let projectsEndpoint = this.endpointBuilder.getEndpoint('projects')
		let newFilters = {
			...filters,
		}

		let filtersArray = []

		// reload list of projects filtered by search query
		if ('search' === filterType) {
			if (searchQuery && searchQuery.length > 2) {
				filtersArray.push(`q=${searchQuery}`)
			}

			newFilters['search'] = searchQuery
		} else if ('filters' === filterType) {
			newFilters['location'] = location
			newFilters['projectType'] = projectType

			if (location) {
				filtersArray.push(`location=${location}`)
			}

			if (projectType) {
				filtersArray.push(`projectType=${projectType}`)
			}
		}

		if (filtersArray.length > 0) {
			const joinedFilters = filtersArray.join('&')
			projectsEndpoint = `${projectsEndpoint}?${joinedFilters}`
		}

		this.setState((state, props) => ({
			filters: newFilters,
			filtersArray: filtersArray,
			isFilteringProjects: true,
		}))

		this.props.callApi(projectsEndpoint)
	}

	handleProjectClick(e, project) {
		e.preventDefault()

		// set temporary data for next page
		this.props.setTemporaryData(`tmp-project-${project.id}`, project)

		// redirect to project detail
		const path = reverse(routesList.projects.detail.index, { projectId: project.id })
		this.props.history.push(path)
	}

	handleTabClick(e, clickedTab) {
		const { tabs, filters } = this.state
		const newTabs = tabs.map((tab) => {
			const isActive = (tab.name === clickedTab.name) ? true: false

			return {
				...tab,
				active: isActive
			}
		})

		const newFilters = {
			...filters,
			search: undefined,
			location: undefined,
			projectType: undefined,
		}

		this.setState((state, props) => ({
			tabs: newTabs,
			isFilteringProjects: false,
			filters: newFilters,
		}))
	}

	render() {
		const { projects, filtersModel, isFilteringProjects, tabs } = this.state
		const activeTab = this.getActiveTab()

		const isUbikonAdmin = this.accessControl.hasRole(userRoles.ROLE_SUPER_ADMIN)

		return (
			<div className="all-projects">
				<MobileProjectsHeader
					filtersSchema={filtersModel}
					filtersCallback={this.handleFiltersSubmit}
				/>

				<ProjectsListTabs
					projects={projects}
					tabs={tabs}
					onClick={this.handleTabClick}
				/>

				{'projects' === activeTab.name && projects && projects.length > 0 && filtersModel &&
					<ProjectsListFilters
						filtersSchema={filtersModel}
						filtersCallback={this.handleFiltersSubmit}
					/>
				}

				{true === isFilteringProjects &&
					<Spinner />
				}

				{true !== isFilteringProjects && 'projects' === activeTab.name && projects && filtersModel &&
					<ProjectsList
						projects={projects}
						addBtn={isUbikonAdmin}
						addProjectCallback={(e) => this.handleAddProjectClick(e)}
						projectClickCallback={this.handleProjectClick}
					/>
				}
				{true !== isFilteringProjects && 'projects' === activeTab.name && (!projects || !filtersModel) &&
					<Spinner />
				}

				{'offers' === activeTab.name &&
					<OfferIndexList />
				}

				{'activity' === activeTab.name &&
					<ActivityFeed />
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { ...temporaryData } = state.temporary_data
 
	return { apiResponse, apiResponseEndpoint, temporaryData }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setTemporaryData: (key, data) => dispatch(setTemporaryData(key, data)),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndex)