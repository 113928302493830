import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom"
import { I18nextProvider } from 'react-i18next'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import i18n from 'config/i18n'

import './index.css'

const Root = ({ store }) => (
	<Provider store={store}>
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
	    		<App />
	    	</I18nextProvider>
	    </BrowserRouter>
	</Provider>
)

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
