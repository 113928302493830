import React from 'react'

import icons from 'assets/svg'

export const LandInfosIcons = (props) => {
	const { entity, fullAddress, displayZipcode } = props

	return (
		<div className="LandInfosIcons">
			{entity.squareMeters &&
				<p>
					<span className="icon"><img src={icons.surface} alt='Square Meters' /></span>
					<span className="info">{entity.squareMeters} m<sup>2</sup></span>
				</p>
			}

			{entity.zipcode && entity.city &&
				<p>
					<span className="icon"><img src={icons.location} alt='Location' /></span>

					{displayZipcode &&
						<span className="info">
							{`${(true === fullAddress && entity.address) ? `${entity.address}, ` : ''}${entity.zipcode} ${entity.city}`}
						</span>
					}

					{!displayZipcode &&
						<span className="info">
							{`${(true === fullAddress && entity.address) ? `${entity.address}, ` : ''}${entity.city}`}
						</span>
					}
				</p>
			}
		</div>
	)
}

export default LandInfosIcons