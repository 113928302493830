const landForm = {
	name: 'land',
	submitBtn: 'Create Land',
	multipart: true,
	fields: [
		{
			type: 'text',
			label: 'Name',
			props: {
				name: 'name',
				id: 'name',
				required: true,
				placeholder: 'Name',
			},
		},
		{
			type: 'textarea',
			label: 'Description',
			props: {
				name: 'description',
				id: 'description',
				required: false,
				placeholder: 'Description',
			},
		},
		{
			type: 'number',
			label: 'Square Meters',
			props: {
				name: 'squareMeters',
				id: 'squareMeters',
				required: false,
				placeholder: 'Square Meters',
			},
		},
		{
			type: 'number',
			label: 'Price',
			props: {
				name: 'price',
				id: 'price',
				required: false,
				placeholder: 'Price',
			},
		},
		{
			type: 'text',
			label: 'Address',
			props: {
				name: 'address',
				id: 'address',
				required: false,
				placeholder: 'Address',
			},
		},
		{
			type: 'text',
			label: 'Zipcode',
			props: {
				name: 'zipcode',
				id: 'zipcode',
				required: true,
				placeholder: 'Zipcode',
			},
		},
		{
			type: 'text',
			label: 'City',
			props: {
				name: 'city',
				id: 'city',
				required: true,
				placeholder: 'City',
			},
		},
		{
			type: 'benefits',
			label: 'Benefits',
			props: {
				name: 'benefits',
				id: 'benefits',
				required: false,
				placeholder: 'Benefits',
			},
		},
		{
			type: 'file',
			label: 'Photos',
			props: {
				name: 'photos',
				id: 'photos',
				required: false,
				multiple: true,
			},
		},
	],
}

export default landForm