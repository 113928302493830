import React from 'react'
import { withTranslation } from 'react-i18next'

import {
	SubmitButton
} from 'components/forms/inputs'

class ProjectPartnersFormTheme extends React.Component {
	render() {
		const {
			t,
			formFields,
			formHelper,
			submitCallback,
		} = this.props

		const {
			currentProjectPipelineSection,
		} = formHelper.props

		if (!formFields) {
			return null
		}

		return (
			<div className="ProjectsPartnersForm">
				<form name="project-partner-form" onSubmit={submitCallback}>
					<div className="Column__left">
						<h3>{currentProjectPipelineSection.pipelineSection.name}</h3>
						<p>{t('projectDetailPartnersAddDescription')}</p>

						<SubmitButton className="upper" text="projectDetailPartnersAddSubmitBtn" />
					</div>

					<div className="Column__right">
						{formHelper.renderField(formFields[0], 'user')}
						{formHelper.renderField(formFields[1], 'partnerType')}
					</div>
				</form>
			</div>
		)
	}
}

export default withTranslation()(ProjectPartnersFormTheme)