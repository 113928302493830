import ActionTypes from 'constants/action-types'

const initialState = {
	api: [],
}

const apiReducer = (state = initialState, action) => {
	const {
		apiResponse,
		apiOptions,
		endpoint,
		formSchema,
		schemaName,
		error,
        uploadResponse,
        uploadedFiles,
        uploadEndpoint,
	} = action

	switch(action.type) {
		case ActionTypes.API_SUCCESS:
			//console.log('ActionTypes.API_SUCCESS', action)

			return {
				...state,
				apiResponse,
				apiOptions,
				apiResponseEndpoint: endpoint
			}

		case ActionTypes.API_RECEIVE_FORM_SCHEMA:

			return {
				...state,
				formSchema,
				schemaName
			}

		case ActionTypes.API_ERROR:

			return {
				...state,
				apiError: error,
				apiErrorEndpoint: endpoint
			}

		case ActionTypes.API_RESET_RESPONSE:

			return {
				...state,
				apiResponse: undefined,
				apiOptions: undefined
			}

		case ActionTypes.API_RESET_ERROR:

			return {
				apiError: undefined,
				apiErrorEndpoint: undefined
			}

		case ActionTypes.UPLOAD_SUCCESS:

			return {
				uploadResponse,
				uploadedFiles,
				uploadEndpoint
			}

		case ActionTypes.RESET_UPLOAD_RESPONSE:

			return {
				uploadResponse: undefined,
				uploadedFiles: undefined,
				uploadEndpoint: undefined
			}

		default:
			return {...state}
	}
}

export default apiReducer