import React from 'react'

import Label from 'components/forms/inputs/Label'

class CheckboxGroup extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			selectedOption: undefined,
			selectedOptions: {}
		}

		// bind methods
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
	}

	handleCheckboxChange(option) {
		const { selectedOptions } = this.state
		const { field, onChange } = this.props

		if (true === field.multiple) {
			const newSelectedOptions = {
				...selectedOptions,
				option
			}

			onChange(newSelectedOptions, field)

			this.setState((state, props) => ({
				selectedOptions: newSelectedOptions
			}))
		} else {
			onChange(option.value, field)

			this.setState((state, props) => ({
				selectedOption: option
			}))
		}
	}

	isOptionSelected(option) {
		const { selectedOptions, selectedOption } = this.state
		const { field } = this.props

		let isSelected = false

		if (true === field.multiple && selectedOptions) {
			selectedOptions.map((selectedOption, iopt) => {
				if (selectedOption.value === option.value) {
					isSelected = true
				}

				return null
			})
		} else if (selectedOption && selectedOption.value === option.value) {
			isSelected = true
		}

		return isSelected
	}

	render() {
		const { field } = this.props
		const { options } = field

		return (
			<div className="CheckboxGroup">
				{options && options.map((option, i) => {
					const optionId = `checkbox-option-${option.value}`
					const isSelected = this.isOptionSelected(option)
					const groupItemClassname = `CheckboxGroup__item${(isSelected) ? '--selected' : ''}`

					return (
						<div key={i} className={groupItemClassname}>
							<Label
								forInput={optionId}
								labelText={option.label}
								onClick={this.handleCheckboxChange}
								onClickProps={option}
							/>
						</div>
					)
				})}
			</div>
		)
	}
}

export default CheckboxGroup