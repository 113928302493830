import React from 'react'

import { ReactComponent as IconCross } from 'assets/svg/icon-cross.svg'
import Carousel from 'components/carousel'
import ImageButton from 'components/buttons/ImageButton'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import landStatuses from 'constants/land-statuses'
import PropertyAttributes from 'components/properties/PropertyAttributes'

export const MapPopup = (props) => {
	const {
		mapType,
		feature,
		onClose,
		onFeatureClick,
		collectionClickCallback,
	} = props

	const mapItem = (feature.properties) ? feature.properties : undefined
	let property = undefined
	let land = undefined

	console.log('mapItem', mapItem)

	if (!mapItem) {
		return null
	}

	let carouselImages = undefined
	let infosEntity = undefined
	let likeClass = ''
	let pendingClass = ''

	if ('properties' === mapType) {
		if (true === mapItem.isCollectionMember) {
			likeClass = ' is-selected'
		}

		property = (mapItem.property) ? JSON.parse(mapItem.property) : undefined

		infosEntity = {
			address: property.address,
			city: property.city,
			zipcode: property.zipcode,
			squareMeters: mapItem.squareMeters,
		}

		carouselImages = (mapItem.photos) ? JSON.parse(mapItem.photos) : undefined
	} else if ('lands' === mapType) {
		land = mapItem

		infosEntity = {
			address: land.address,
			city: land.city,
			zipcode: land.zipcode,
			squareMeters: land.squareMeters,
		}

		carouselImages = (land.photos) ? JSON.parse(land.photos) : undefined

		pendingClass = (land.status === landStatuses.LAND_STATUS_PENDING) ? 'is-pending' : ''
	}

	return (
		<div className={`MapPopup ${pendingClass}`}>
			{onClose &&
				<ImageButton
					className="Close__btn"
					svgImage={IconCross}
					altText='Close'
					onClick={onClose}
				/>
			}

			<div className="MapPopup__top">
				<h3 onClick={() => { if (onFeatureClick) { onFeatureClick(mapItem) } }}>
					{'properties' === mapType && property && property.name}

					{'lands' === mapType && land && land.name}
				</h3>

				{infosEntity &&
					<LandInfosIcons entity={infosEntity} fullAddress={true} displayZipcode={true} />
				}

				{property &&
					<PropertyAttributes propertyItem={mapItem} />
				}

				{collectionClickCallback &&
	                <span className="catalogue__property__like" onClick={() => collectionClickCallback(mapItem)}>
	                    <svg className={`catalogue__property__like__icon ${likeClass}`} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
	                        <path fillRule="nonzero" d="M9.001 15.334l.974-.852c4.42-3.891 5.802-5.27 6.78-7.033.5-.902.745-1.765.745-2.653C17.5 2.39 15.55.5 13.05.5a4.962 4.962 0 0 0-3.674 1.652L9 2.58l-.376-.428A4.962 4.962 0 0 0 4.95.5C2.45.5.5 2.39.5 4.796c0 .892.247 1.758.752 2.665.98 1.76 2.372 3.146 6.774 7.013l.975.86z"/>
	                    </svg>
	                </span>
				}
			</div>

			<div className="MapPopup__bottom">
				<div className="MapPopup__bottom__wrapper">
					{'lands' === mapType && land && land.status === landStatuses.LAND_STATUS_PENDING &&
	                    <p className="all-projects__project__type">
	                        <span className="all-projects__project__type__icon">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
	                                <path fill="none" fillRule="nonzero" strokeLinecap="square" d="M.5 11.495V.754l6.153 2.292L.75 5.504"/>
	                            </svg>
	                        </span>
	                        Pending validation
	                    </p>
					}

					{'properties' === mapType && mapItem && mapItem.price &&
						<p className="catalogue__property__price">
							{`${parseInt(mapItem.price).toLocaleString()} €`}
						</p>
					}

					{carouselImages &&
						<Carousel
							images={carouselImages}
							allowFullscreen={false}
						/>
					}
				</div>
			</div>
		</div>
	)
}

export default MapPopup