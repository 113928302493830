/*
    based on https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
*/

import React from 'react'
import { Trans } from 'react-i18next'

class DragAndDrop extends React.Component {
    state = {
        drag: false,
    }

    dropRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState((state, props) => ({
                drag: true,
            }))
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter--

        if (this.dragCounter === 0) {
            this.setState((state, props) => ({
                drag: false,
            }))
        }
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.setState((state, props) => ({
            drag: false,
        }))

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files, this.props.field)
            e.dataTransfer.clearData()
            this.dragCounter = 0    
        }
    }

    componentDidMount() {
        let div = this.dropRef.current

        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }

    componentWillUnmount() {
        let div = this.dropRef.current

        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    render() {
        return (
            <div className="DragAndDrop" ref={this.dropRef}>
                {this.state.drag &&
                    <div className="DragWrapper">
                        <div className="DragWrapper__inner">
                            <div>
                                <Trans i18nKey="draggingLabel">drop here</Trans>
                            </div>
                        </div>
                    </div>
                }
                
                {this.props.children}
            </div>
        )
    }
}

export default DragAndDrop