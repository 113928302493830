import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import { setActiveConversation, listenToTopic } from 'actions/messaging'
import { ReactComponent as IconSearch } from 'assets/svg/icon-search.svg'
/*import { ReactComponent as IconFilters } from 'assets/svg/icon-filters.svg'*/
import AccessControl from 'auth/access-control'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ConversationsList from 'components/chat/ConversationsList'
import ImageButton from 'components/buttons/ImageButton'
import Spinner from 'components/spinner/Spinner'

class Messenger extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			hubUrls: [],
			conversations: undefined,
			isFiltering: false,
			tabs: [
				{
					name: 'Offers',
					slug: 'offers',
					active: true,
				},
				{
					name: 'Groups',
					slug: 'groups',
					active: false,
				},
				{
					name: 'Chat',
					slug: 'chat',
					active: false,
				},
			]
		}

		// bind methods
		this.handleTabClick = this.handleTabClick.bind(this)
		this.handleConversationClick = this.handleConversationClick.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
		this.hubUrl = new URL(process.env.REACT_APP_MERCURE_HUB_URL)
	}

	componentDidMount() {
		// get user's chat (conversations, contacts, etc.)
		const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat')
		this.props.callApi(userChatEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props
		const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat')

		let formatedApiResponseEndpoint = apiResponseEndpoint && apiResponseEndpoint.split('?')[0]

		if (apiResponse) {
			this.props.resetApiResponse()

			// did get list of conversations
			if (formatedApiResponseEndpoint === userChatEndpoint) {
				const { conversations, contacts } = apiResponse.chat

				// init hub listener
				const userChatHub = `ubikon-chat-${this.accessControl.getUserId()}`
				this.props.listenToTopic(
					process.env.REACT_APP_MERCURE_HUB_URL,
					userChatHub,
					this.chatListener
				)

				this.setState((state, props) => ({
					conversations,
					contacts,
					isFiltering: false,
					didGetFirstConversations: true,
				}))
			}
		}
	}

	getConversationsByType(conversations, conversationType) {
		if (conversations) {
			if ('offers' === conversationType) {
				return conversations.filter((conversation, iC) => {
					return false === conversation.isGroup && true === conversation.isOffer
				})
			} else if ('groups' === conversationType) {
				return conversations.filter((conversation, iC) => {
					return true === conversation.isGroup && false === conversation.isOffer
				})
			}
		}

		return undefined
	}

	handleTabClick(tab) {
		const { tabs } = this.state

		const newTabs = tabs.map((stateTab) => {
			const isActive = stateTab.slug === tab.slug

			return {
				...stateTab,
				active: isActive,
			}
		})

		this.setState((state, props) => ({
			tabs: newTabs,
		}))
	}

	handleConversationClick(conversation) {
		// add conversation IRI to list of URLs
		const { hubUrls } = this.state
		const newHubUrls = [
			...hubUrls,
			conversation.iri
		]

		this.props.setActiveConversation(conversation)

		this.setState((state, props) => ({
			hubUrls: [...new Set(newHubUrls)],
			activeConversation: conversation
		}))
	}

	render() {
		const {
			contacts,
			conversations,
			isFiltering,
			tabs,
		} = this.state

		const { activeConversation: chatActiveConversation } = this.props

		// filter conversations
		const offerConversations = this.getConversationsByType(conversations, 'offers')
		const groupConversations = this.getConversationsByType(conversations, 'groups')
		const chatConversations = contacts

		return (
			<div className="catalogue Messenger">
				<div className="all-projects__header">
					<div className="all-projects__actions is-mobile">
						{/*
						<div className="all-projects__action filters">
							<ImageButton
								svgImage={IconFilters}
								className="action__filters"
								altText='Filter projects'
								//onClick={(e) => this.handleFilterBtnClick(e, 'filters')}
							/>					
						</div>
						*/}

						<h1>Conversations</h1>

						<div className="all-projects__action search">
							<ImageButton
								svgImage={IconSearch}
								className="action__search"
								altText='Search projects'
								//onClick={(e) => this.handleFilterBtnClick(e, 'search')}
							/>
						</div>
					</div>
				</div>

				<div className="messenger__tabs">
					{tabs.map((tab, iT) => {
						const activeClass = (true === tab.active) ? 'is-active' : ''

						return (
							<div
								className={`messenger__tab ${activeClass}`}
								key={iT}
								onClick={() => this.handleTabClick(tab)}
							>
								<p>{tab.name}</p>
							</div>
						)
					})}
				</div>

				<div className="messenger__list">
					{(isFiltering || !conversations) &&
						<Spinner />
					}

					{!isFiltering && tabs.map((tab, iT) => {
						if (true === tab.active) {
							if ('offers' === tab.slug && offerConversations && offerConversations.length > 0) {
								return (
									<ConversationsList
										key={iT}
										isMobile={true}
										title='offers'
										listType='offers'
										className="chat"
										conversations={offerConversations}
										chatActiveConversation={chatActiveConversation}
										onClickCallback={this.handleConversationClick}
									/>
								)
							} else if ('groups' === tab.slug && groupConversations && groupConversations.length > 0) {
								return (
									<ConversationsList
										key={iT}
										isMobile={true}
										title='groups'
										listType='groups'
										className="chat"
										conversations={groupConversations}
										chatActiveConversation={chatActiveConversation}
										onClickCallback={this.handleConversationClick}
									/>
								)
							} else if ('chat' === tab.slug && chatConversations && chatConversations.length > 0) {
								return (
									<ConversationsList
										key={iT}
										isMobile={true}
										title='chat'
										listType='chat'
										className="chat"
										conversations={chatConversations}
										chatActiveConversation={chatActiveConversation}
										onClickCallback={this.handleConversationClick}
									/>
								)
							}
						}

						return null
					})}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { activeConversation } = state.messaging

	return {
		apiResponse,
		apiResponseEndpoint,
		activeConversation,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
		listenToTopic: (hubUrl, topic, callback) => dispatch(listenToTopic(hubUrl, topic, callback)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Messenger)