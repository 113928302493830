import React from 'react'
import { withTranslation } from 'react-i18next'

export const OfferDirectoryBreadcrumb = (props) => {
	const { t, widget } = props

	if (!widget || 'project-detail' === widget) {
		return (
			<div className="folder__breadcrumb">
				<div className="folder__name">
					{t('projectDetailOffersFolderName')}
					<span className="folder__arrow--filter-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
					
					<span className="folder__arrow--filter-up">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
				</div>

				<div className="folder__date"> {t('projectDetailOffersFolderDate')}
					<span className="folder__arrow--filter-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
					
					<span className="folder__arrow--filter-up">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
				</div>

				<div className="folder__pending">{t('projectDetailOffersFolderPending')}</div>
				<div className="folder__refused">{t('projectDetailOffersFolderNotInterested')}</div>
				<div className="folder__interested">{t('projectDetailOffersFolderInterested')}</div>
				
				<div className="folder__actions">
					<span className="folder__actions__check">
						<svg className="folder__actions__check__svg" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6">
							<path fillRule="evenodd" d="M7.501 0L4.158 3.357l-.623.68-.46.404-.461-.404-.624-.68L.713 2.065 0 2.793l3.075 3.074L8.213.728z"/>
						</svg>
					</span>
				</div>
			</div>
		)
	}

	if (widget && 'offers-index-created' === widget) {
		return (
			<div className="folder__breadcrumb">
				<div className="folder__name">
					{t('projectDetailOffersFolderName')}
					<span className="folder__arrow--filter-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
					
					<span className="folder__arrow--filter-up">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
				</div>

				<div className="folder__date"> {t('projectDetailOffersFolderDate')}
					<span className="folder__arrow--filter-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
					
					<span className="folder__arrow--filter-up">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
				</div>

				<div className="folder__pending"> project</div>
				<div className="folder__actions"></div>
			</div>
		)
	}

	if (widget && 'offers-index-requested' === widget) {
		return (
			<div className="folder__breadcrumb">
				<div className="folder__name">
					{t('projectDetailOffersFolderName')}
					<span className="folder__arrow--filter-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
					
					<span className="folder__arrow--filter-up">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
				</div>

				<div className="folder__date"> {t('projectDetailOffersFolderDate')}
					<span className="folder__arrow--filter-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
					
					<span className="folder__arrow--filter-up">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
							<g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square" opacity=".698">
								<path d="M1.5 1.5l5.813 5.813M13.148 1.5L7.336 7.313"/>
							</g>
						</svg>
					</span>
				</div>

				<div className="folder__pending"> {t('projectDetailOffersFolderProject')}</div>
				<div className="folder__pending"> {t('projectDetailOffersFolderRequestedBy')}</div>
				<div className="folder__actions"></div>
			</div>
		)
	}

	return null
}

export default withTranslation()(OfferDirectoryBreadcrumb)