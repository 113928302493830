import React from 'react'
import { withTranslation } from 'react-i18next'

export const getButtonImage = (props) => {
	const { t, image, svgImage: SvgIcon, altText } = props

	if (image) {
		return <img src={image} alt={t(altText)} />
	}

	if (SvgIcon) {
		return <SvgIcon />
	}

	return null
}

export const handleDisabledClick = (e) => {
	e.preventDefault()
	return false
}

export const ImageButton = (props) => {
	const { t, onClick, className, altText, disabled, buttonType } = props
	const buttonClass = `ImageButton ${(typeof className !== 'undefined') ? className: ''}`
	const buttonImage = getButtonImage(props)
	const onClickCallback = (false === disabled) ? (e) => handleDisabledClick(e) : onClick
	const btnType = (buttonType) ? buttonType : 'button'

	return (
		<button
			className={buttonClass}
			onClick={onClickCallback}
			title={t(altText)}
			type={btnType}
		>
			{buttonImage}
		</button>
	)
}

export default withTranslation()(ImageButton)