import React from 'react'
import { withTranslation } from 'react-i18next'

import NavSectionTitle from 'components/navigation/NavSectionTitle'
import projectStatuses from 'constants/project-statuses'
import TextButton from 'components/buttons/TextButton'

export const LeftMenuAdmin = (props) => {
	const { t, projects, projectCallback, createAdminProjectCallback } = props

	if (!projects || projects.length <= 0) {
		return null
	}

	return (
		<div className="LeftMenuAdmin">
			<NavSectionTitle label='leftMenuSectionProjects' className='sidebar-block__title text--blue-electric' />

			<nav className="nav">
				<ul className="nav__items">
					{projects.map((project, i) => {
						const isActiveClass = ''
						let projectLocation = ''

						if (project.land && project.land.city) {
							const projectZipcode = (project.land.zipcode) ? `, ${project.land.zipcode}` : ''
							projectLocation = `${project.land.city}${projectZipcode}`
						}

						return (
							<li
								key={i}
								className={`nav__item${isActiveClass} nav__item__project`} onClick={(e) => projectCallback(e, project)}
							>
								<span className="nav__item__text">{project.name}</span>

								{'' !== projectLocation &&
									<span className="nav__item__location">{projectLocation}</span>
								}

								{projectStatuses.PROJECT_STATUS_PENDING === project.status &&
									<span className="nav__item__status">{t('statusPending')}</span>
								}
							</li>
						)
					})}
				</ul>
			</nav>

			{createAdminProjectCallback &&
				<TextButton
					text='leftMenuAdminProjectBtn'
					className="left-sidebar__button"
					onClick={createAdminProjectCallback}
				/>
			}
		</div>
	)
}

export default withTranslation()(LeftMenuAdmin)