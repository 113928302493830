import React from 'react'

import AdminPopinBlock from 'components/admin/AdminPopinBlock'
import AdminPropertyItemBlock from 'components/admin/popins/blocks/AdminPropertyItemBlock'
import BenefitsList from 'components/benefits/BenefitsList'
import LandBlock from 'components/admin/popins/blocks/LandBlock'
import SubmitButton from 'components/forms/inputs/SubmitButton'

const ProjectRequestsPopin = (props) => {
	const {
		section,
		entity,
		activeTab,
		tabClickCallback,
		actionCallback,
	} = props

	return (
		<div className="AdminPopin__content">
			{entity.land && entity.property &&
				<ul className="AdminPopin__tabs">
					<li
						className={(!activeTab || 'land' === activeTab) ? 'active' : ''}
						onClick={() => tabClickCallback('land')}
					>
						Land
					</li>

					<li
						className={(activeTab && 'property' === activeTab) ? 'active': ''}
						onClick={() => tabClickCallback('property')}
					>
						Property
					</li>
				</ul>
			}

			{(!activeTab || 'land' === activeTab) &&
				<div className="AdminPopin__blocks">
					<LandBlock land={entity.land} />

					<AdminPopinBlock title='Project Type'>
						{entity.projectType &&
							<p className="Type__title">{entity.projectType.name}</p>
						}

						<p className="Description">{entity.land.description}</p>
					</AdminPopinBlock>

					{entity.land.benefits && entity.land.benefits.length > 0 &&
						<AdminPopinBlock title='Benefits'>
							<BenefitsList benefits={entity.land.benefits} displayTitle={false} />
						</AdminPopinBlock>
					}
				</div>
			}

			{activeTab && 'property' === activeTab && entity.property &&
				<div className="AdminPopin__blocks">
					<AdminPopinBlock title='Property name'>
						<h4>{entity.property.name}</h4>
					</AdminPopinBlock>

					<AdminPopinBlock title='Property type'>
						<h4>{entity.property.propertyType.name}</h4>
					</AdminPopinBlock>

					{entity.property.propertyItems &&
						<AdminPopinBlock title='Property items'>
							{entity.property.propertyItems.map((propertyItem, i) => {
								return (
									<AdminPropertyItemBlock key={i} propertyItem={propertyItem} />
								)
							})}
						</AdminPopinBlock>
					}
				</div>
			}

			<div className="AdminPopin__buttons">
				<SubmitButton
					text='Validate'
					onClick={() => actionCallback('approved', section, entity)}
				/>

				<SubmitButton
					text='Deny'
					className="deny"
					onClick={() => actionCallback('display-denied', section, entity)}
				/>
			</div>
		</div>
	)
}

export default ProjectRequestsPopin