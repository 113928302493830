import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'

import routesList from 'routing/routes-list'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import { callApi, upload, resetApiResponse, resetUploadResponse } from 'actions/api'
import UbikonForm from 'components/forms/UbikonForm'
import landForm from 'components/forms/schemas/landForm'

class CreateLand extends React.Component {
	constructor(props) {
		super(props)

		// bind methods
		this.handleCreateLandSubmit = this.handleCreateLandSubmit.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint, uploadResponse } = this.props
		const landsEndpoint = this.endpointBuilder.getEndpoint('lands')

		if (apiResponse) {
			this.props.resetApiResponse()

			// handle POST land API response
			if (landsEndpoint === apiResponseEndpoint) {
				// redirect to lands list
				this.props.history.push(reverse(routesList.lands.index))
			}
		}

		if (uploadResponse) {
			this.props.resetUploadResponse()
		}
	}

	handleCreateLandSubmit(formData, formFiles) {
		const landData = {
			...formData,
			files: formFiles.photos
		}

		this.props.callApi(this.endpointBuilder.getEndpoint('lands'), 'post', {
			body: JSON.stringify(landData),
		})
	}

	render() {
		return (
			<div className="CreateLand">
				<h1>Create Land</h1>

				<UbikonForm form={landForm} onSubmit={this.handleCreateLandSubmit} />
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint, uploadResponse } = state.api

	return { apiResponse, apiResponseEndpoint, uploadResponse }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		upload: (endpoint, fileName, file, linkedEntity) => dispatch(upload(endpoint, fileName, file, linkedEntity)),
		resetUploadResponse: () => dispatch(resetUploadResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLand)