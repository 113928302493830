import React from 'react'

import Label from 'components/forms/inputs/Label'

class Radio extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			isChecked: (typeof props.isChecked !== 'undefined') ? props.isChecked : false,
		}

		// bind methods
		this.handleRadioChange = this.handleRadioChange.bind(this)
		this.handleEmptyChange = this.handleEmptyChange.bind(this)
	}

	handleRadioChange(radioValue) {
		const { isChecked } = this.state
		const { option, field, onChange } = this.props

		const newIsChecked = !isChecked

		let finalValue = radioValue
		if (!newIsChecked && field.forceRadioValue) {
			finalValue = ''
		} else if (newIsChecked && field.forceRadioValue) {
			finalValue = '1'
		}

		if (onChange) {
			if (typeof option !== 'undefined') {
				onChange(option, field)
			} else {
				onChange(finalValue, field)
			}
		}

		this.setState((state, props) => ({
			isChecked: newIsChecked
		}))
	}

	handleEmptyChange() {

	}

	render() {
		const { isChecked } = this.state
		const { field, option, isChecked: isCheckedProp } = this.props
		const isRequired = (field.props && field.props.required) ? field.props.required : false

		const isRadioChecked = (typeof isCheckedProp !== 'undefined') ? isCheckedProp : isChecked
		const radioId = (option && option.value) ? `radio-${option.value}` : ''

		let radioValue = (option && option.value) ? option.value : undefined
		if (!radioValue && field && field.props && field.props.value) {
			radioValue = field.props.value
		}

		let radioLabel = (option && option.label) ? option.label : undefined
		if (!radioLabel && field && field.label) {
			radioLabel = field.label
		}

		return (
			<div className="Radio">
				<input
					type='radio'
					className="Radio"
					{...field.props}
					checked={isRadioChecked}
					value={radioValue}
					onClick={() => this.handleRadioChange(radioValue)}
					onChange={this.handleEmptyChange}
					id={radioId}
				/>

				{radioLabel &&
					<Label
						forInput={radioId}
						labelText={radioLabel}
						required={isRequired}
					/>
				}
			</div>
		)
	}
}

export default Radio