import React from 'react'

import { ReactComponent as IconCross } from 'assets/svg/icon-cross.svg'
import ImageButton from 'components/buttons/ImageButton'

const handleCloseErrorClick = (e, error, closeCallback) => {
	e.preventDefault()

	if (closeCallback) {
		closeCallback(error)
	}
}

export const FileInputErrors = (props) => {
	const { errors, closeCallback } = props

	if (!errors || errors.length <= 0) {
		return null
	}

	return (
		<div className="FileInputErrors">
			{errors.map((error, eI) => {
				return (
					<div key={eI}>
						<p className="Error__msg">
							{error.message}
						</p>

						<ImageButton
							svgImage={IconCross}
							className="Error__close"
							onClick={(e) => handleCloseErrorClick(e, error, closeCallback)}
						/>
					</div>
				)
			})}
		</div>
	)
}

export default FileInputErrors