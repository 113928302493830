const forgottenPasswordForm = {
	name: 'forgotten-password',
	submitBtn: 'Reset your password',
	multipart: false,
	fields: [
		{
			type: 'text',
			label: 'forgottenPasswordEmailLabel',
			props: {
				name: 'email',
				id: 'email',
				required: true,
				placeholder: 'forgottenPasswordEmailLabel',
			},
		},
	],
}

export default forgottenPasswordForm