import React from 'react'
import { withTranslation } from 'react-i18next'

export const BenefitsList = (props) => {
	const { t, benefits, displayTitle } = props

	if (!benefits || benefits.length <= 0) {
		return null
	}

	return (
		<div className="BenefitsList">
			{false !== displayTitle &&
				<h3>
					{t('propertyDetailBenefitsTitle')}
				</h3>
			}

			<div className="filters-modal__needs__list">
				<div className="filters-modal__needs__checkboxes">
					{benefits.map((benefit, i) => {
						return (
							<label className="custom-checkbox filters-modal__needs__checkbox is-checked">
					            <input
					            	className="custom-checkbox__input"
					            	type="checkbox"
					            	checked={true}
					            	disabled={true}
					            />

					            <span className="custom-checkbox__check">
					                <svg className="custom-checkbox__check__svg" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6">
					                    <path fill="#004BFF" fillRule="evenodd" d="M7.501 0L4.158 3.357l-.623.68-.46.404-.461-.404-.624-.68L.713 2.065 0 2.793l3.075 3.074L8.213.728z"/>
					                </svg>
					            </span>

					            <p className="custom-checkbox__text">
					                {benefit.name}
					            </p>
							</label>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default withTranslation()(BenefitsList)