import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import { closeCollectionsPopin, didAddToCollection } from 'actions/collections'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import Select from 'components/forms/inputs/Select'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import UbikonForm from 'components/forms/UbikonForm'

class CollectionsPopin extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			collections: undefined,
			selectedCollection: undefined,
			isSavingCollectionItem: false,
			didSaveCollectionItem: false,
			displayCreateCollection: false,
			collectionForm: undefined,
			isSavingNewCollection: false,
		}

		// bind methods
		this.handleCollectionSelect = this.handleCollectionSelect.bind(this)
		this.handleAddToCollectionClick = this.handleAddToCollectionClick.bind(this)
		this.handleCreateCollectionClick = this.handleCreateCollectionClick.bind(this)
		this.handleCollectionFormSubmit = this.handleCollectionFormSubmit.bind(this)
		this.addItemToCollection = this.addItemToCollection.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		// get collections
		const collectionsEndpoint = this.endpointBuilder.getEndpoint('collections')
		this.props.callApi(collectionsEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const { didSaveCollectionItem, isSavingNewCollection } = this.state
		const { apiResponse, apiResponseEndpoint, closeCollectionsPopin } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const collectionsEndpoint = this.endpointBuilder.getEndpoint('collections')
			const collectionItemEndpoint = this.endpointBuilder.getEndpoint('collection_items')
			const collectionFormEndpoint = this.endpointBuilder.getEndpoint('collections_get_form')

			// did get collections list / did save new collection
			if (apiResponseEndpoint === collectionsEndpoint) {
				// did save new collection
				if (isSavingNewCollection) {
					// add item to newly created collection
					this.addItemToCollection(apiResponse)

					this.setState((state, props) => ({
						selectedCollection: apiResponse,
					}))
				} else {
					this.setState((state, props) => ({
						collections: apiResponse,
					}))
				}
			}

			// did add new item to collection
			if (apiResponseEndpoint === collectionItemEndpoint) {
				// dispatch to others components
				this.props.didAddToCollection(apiResponse)

				this.setState((state, props) => ({
					isSavingCollectionItem: false,
					isSavingNewCollection: false,
					didSaveCollectionItem: true,
					collectionForm: undefined,
				}))
			}

			// set create collection form
			if (apiResponseEndpoint === collectionFormEndpoint) {
				this.setState((state, props) => ({
					collectionForm: apiResponse.model,
				}))
			}
		}

		if (true === didSaveCollectionItem) {
			setTimeout(() => {
				closeCollectionsPopin()
			}, 3000)
		}
	}

	handleCollectionSelect(selectedOption, field) {
		const { collections } = this.state
		const selectedCollectionId = parseInt(selectedOption)

		if (selectedCollectionId > 0) {
			let selectedCollection = undefined
			collections.map((collection, iC) => {
				if (parseInt(collection.id) === selectedCollectionId) {
					selectedCollection = collection
				}

				return null
			})

			this.setState((state, props) => ({
				selectedCollection: selectedCollection,
			}))
		} else {
			this.setState((state, props) => ({
				selectedCollection: undefined,
			}))
		}
	}

	handleAddToCollectionClick() {
		const { selectedCollection } = this.state

		this.addItemToCollection(selectedCollection)
	}

	handleCreateCollectionClick() {
		// get create collection form
		const collectionFormEndpoint = this.endpointBuilder.getEndpoint('collections_get_form')
		this.props.callApi(collectionFormEndpoint, 'get')

		this.setState((state, props) => ({
			displayCreateCollection: true,
		}))
	}

	handleCollectionFormSubmit(formData) {
		const collectionsEndpoint = this.endpointBuilder.getEndpoint('collections')

		// format users ()
		const users = Object.keys(formData.users).map((userId) => {
			return `/api/users/${userId}`
		})

		const newFormData = {
			...formData,
			users: users
		}

		this.props.callApi(collectionsEndpoint, 'post', {
			body: JSON.stringify(newFormData)
		})

		this.setState((state, props) => ({
			isSavingNewCollection: true,
		}))
	}

	addItemToCollection(selectedCollection) {
		const { collectionType, collectionItem } = this.props

		let collectionItemData = undefined

		if ('property' === collectionType) {
			collectionItemData = {
				itemType: collectionType,
				itemId: collectionItem.id,
				collection: `/api/collections/${selectedCollection.id}`,
				propertyItem: `/api/property_items/${collectionItem.id}`,
			}
		} else if ('land' === collectionType) {
			collectionItemData = {
				itemType: collectionType,
				itemId: collectionItem.id,
				collection: `/api/collections/${selectedCollection.id}`,
				land: `/api/lands/${collectionItem.id}`,
			}
		}

		if (collectionItemData) {
			const collectionItemEndpoint = this.endpointBuilder.getEndpoint('collection_items')

			this.props.callApi(collectionItemEndpoint, 'post', {
				body: JSON.stringify(collectionItemData),
			})

			this.setState((state, props) => ({
				isSavingCollectionItem: true,
			}))
		}
	}

	render() {
		const {
			collections,
			selectedCollection,
			isSavingCollectionItem,
			isSavingNewCollection,
			didSaveCollectionItem,
			displayCreateCollection,
			collectionForm,
		} = this.state

		let collectionsOptions = []
		collectionsOptions[0] = {
			label: 'Name of the collection',
			value: 0,
		}

		if (collections) {
			collections.map((collection, iC) => {
				collectionsOptions[collection.id] = {
					label: collection.name,
					value: collection.id,
				}

				return null
			})
		}

		const field = {
			props: {
				name: 'collection',
			},
			options: collectionsOptions,
		}

		const formData = {
			collection: (selectedCollection) ? selectedCollection.id : undefined,
		}

		const btnDisabled = typeof collections === 'undefined' || typeof selectedCollection === 'undefined'
		const selectValue = (selectedCollection) ? selectedCollection.id : ''

		if (isSavingCollectionItem || isSavingNewCollection) {
			return (
				<div className="CollectionsPopin">
					<Spinner />
				</div>
			)
		}

		if (didSaveCollectionItem) {
			return (
				<div className="CollectionsPopin">
					<div className="Collection__success">
						<p>Item was added to the collection</p>

						<h2>{selectedCollection.name}</h2>
					</div>
				</div>
			)
		}

		if (displayCreateCollection) {
			return (
				<div className="CollectionsPopin">
					<div className="Collection__create__form">
						{!collectionForm &&
							<Spinner />
						}

						{collectionForm &&
							<UbikonForm form={collectionForm} onSubmit={this.handleCollectionFormSubmit} />
						}
					</div>
				</div>
			)
		}

		return (
			<div className="CollectionsPopin">
				<div className="Collection__select">
					{collectionsOptions && collectionsOptions.length > 1 &&
						<div>
							<label htmlFor="">Collection's name</label>
							<Select
								field={field}
								onChange={this.handleCollectionSelect}
								formData={formData}
								value={selectValue}
							/>

							<div className="Button__wrapper">
								<TextButton
									className="BlueButton upper"
									text="add"
									disabled={btnDisabled}
									onClick={this.handleAddToCollectionClick}
								/>
							</div>
						</div>
					}
				</div>

				{collectionsOptions && collectionsOptions.length > 1 &&
					<p className="Collection__popin__separator">or</p>
				}

				<div className="Collection__create">
					<h2>Create a collection</h2>

					<TextButton
						className="WhiteButton upper"
						text="create"
						onClick={this.handleCreateCollectionClick}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { ...temporaryData } = state.temporary_data
 
	return {
		apiResponse,
		apiResponseEndpoint,
		temporaryData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		closeCollectionsPopin: () => dispatch(closeCollectionsPopin()),
		didAddToCollection: (collectionItem) => dispatch(didAddToCollection(collectionItem))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPopin)