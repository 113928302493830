import React from 'react'

const getMessageDate = (message) => {
	const messageDate = (message.createdAt.date) ? message.createdAt.date: message.createdAt
	const messageDateObject = new Date(messageDate)

	let hours = messageDateObject.getHours()
	let minutes = messageDateObject.getMinutes()
	const ampm = hours >= 12 ? 'pm' : 'am'

	hours = hours % 12
	hours = hours ? hours : 12
	minutes = minutes < 10 ? '0' + minutes : minutes

	const strTime = hours + ':' + minutes + ' ' + ampm

	return `${messageDateObject.toLocaleDateString()} ${strTime}`
}

export const MessageBottom = (props) => {
	const { message, user } = props
	const messageDateFormat = getMessageDate(message)

	return (
		<div className="MessageBottom">
			<p>
				{user.username} <span className="Message__date">{messageDateFormat}</span>
			</p>
		</div>
	)
}

export default MessageBottom