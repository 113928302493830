import React from 'react'

import icons from 'assets/svg'
import LeftMenuAdmin from 'components/admin/LeftMenuAdmin'
import NavSection from 'components/navigation/NavSection'
import AccessControl from 'auth/access-control'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'

export const LeftMenu = (props) => {
	const {
		sections,
		isAdmin,
		adminProjects,
		navCallback,
		projectCallback,
		createProjectCallback,
		createAdminProjectCallback,
		collapseCallback,
		collapseMenu,
	} = props
	const accessControl = new AccessControl()

	const collapsedClass = (collapseMenu) ? 'is-collapsed': ''
	const menuClass = `left-sidebar sidebar-block LeftMenu ${collapsedClass}`

	return (
		<aside className={menuClass}>
			<div className="sidebar-block__inner">
				{
					sections && sections.map((section, i) => {
						// check role
						const hasRole = typeof section.role !== 'undefined' ? accessControl.hasRole(section.role): true

						if (hasRole) {
							return (
								<NavSection
									key={i}
									section={section}
									isMobile={false}
									navCallback={navCallback}
								/>
							)
						} else {
							return null
						}
					})
				}

				{isAdmin && adminProjects &&
					<LeftMenuAdmin
						projects={adminProjects}
						projectCallback={projectCallback}
						createAdminProjectCallback={createAdminProjectCallback}
					/>
				}

				{!collapseMenu &&
					<TextButton
						text='leftMenuSellOnUbikonBtn'
						onClick={createProjectCallback}
						className="left-sidebar__button"
					/>
				}

				<div className="LeftMenu__actions LeftMenu__collapse__btn">
					<ImageButton
						image={icons.arrowDown}
						altText="Collapse menu"
						onClick={collapseCallback}
					/>
				</div>
			</div>
		</aside>
	)
}

export default LeftMenu