import React from 'react'

import { media } from 'media'
import icons from 'assets/svg'
import CarouselImage from 'components/carousel/CarouselImage'
import ImageButton from 'components/buttons/ImageButton'

class Carousel extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			currentSlide: 0,
			fullscreenImage: undefined,
		}

		// bind methods
		this.handleNavClick = this.handleNavClick.bind(this)
		this.handleFullscreen = this.handleFullscreen.bind(this)
		this.handleFullscreenClose = this.handleFullscreenClose.bind(this)
	}

	handleNavClick(e, slideIndex) {
		e.preventDefault()

		this.setState((state, props) => ({
			currentSlide: slideIndex
		}))
	}

	handleFullscreen(e) {
		e.preventDefault()

		const { currentSlide } = this.state
		const { images, onFullscreenCallback } = this.props

		let newFullscreenImage = undefined
		if (images) {
			images.map((image, i) => {
				if (i === currentSlide) {
					newFullscreenImage = image
				}

				return null
			})
		}

		if (newFullscreenImage) {
			onFullscreenCallback(newFullscreenImage)

			this.setState((state, props) => ({
				fullscreenImage: newFullscreenImage
			}))
		}
	}

	handleFullscreenClose() {
		const { onFullscreenCallback } = this.props

		onFullscreenCallback(undefined)

		this.setState((state, props) => ({
			fullscreenImage: undefined
		}))
	}

	render() {
		const { currentSlide, fullscreenImage } = this.state
		const { images, allowFullscreen } = this.props

		return (
			<div className="Carousel">
				{fullscreenImage &&
					<div className="Carousel__fullscreen__image">
						<ImageButton
							image={icons.cross}
							altText='Close Fullscreen'
							className="Carousel__fullscreen__close"
							onClick={this.handleFullscreenClose}
						/>

						<img src={media.getMediaUrl(fullscreenImage.contentUrl)} alt='Carousel Fullscreen' />
					</div>
				}

				{!fullscreenImage && images && images.map((image, i) => {
					const imageUrl = media.getMediaUrl(image.contentUrl)
					const isActive = (i === currentSlide) ? true : false

					return (
						<CarouselImage key={i} imageUrl={imageUrl} isActive={isActive} />
					)
				})}

				{!fullscreenImage &&
					<ul className="Carousel__nav">
						{images && images.length > 1 && images.map((image, i) => {
							const navItemClass = `Carousel__nav__item${(i === currentSlide) ? '--active': ''}`

							return (
								<li
									key={i}
									className={navItemClass}
									onClick={(e) => this.handleNavClick(e, i)}
								></li>
							)
						})}
					</ul>
				}

				{!fullscreenImage && allowFullscreen &&
					<ImageButton
						image={icons.fullscreen}
						altText='Fullscreen'
						className="Carousel__fullscreen__button"
						onClick={this.handleFullscreen}
					/>
				}
			</div>
		)
	}
}

export default Carousel