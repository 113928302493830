import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import FilterRange from 'components/filters/FilterRange'
import SearchBar from 'components/search/SearchBar'

class LandsFilters extends React.Component {
	constructor(props) {
		super(props)

		const { filters } = props
		const landsFilters = (filters && filters['lands']) ? filters['lands'] : undefined

		/*
		console.log('filters', filters)
		console.log('landsFilters', landsFilters)
		*/

		// set state
		this.state = {
			isClickedSurface: false,
			isClickedPrice: false,
			filters: {
				search: (landsFilters && landsFilters['search']) ? landsFilters['search'] : '',
				surfaceMin: (landsFilters && landsFilters['surfaceMin']) ? landsFilters['surfaceMin'] : '',
				surfaceMax: (landsFilters && landsFilters['surfaceMax']) ? landsFilters['surfaceMax'] : '',
				priceMin: (landsFilters && landsFilters['priceMin']) ? landsFilters['priceMin'] : '',
				priceMax: (landsFilters && landsFilters['priceMax']) ? landsFilters['priceMax'] : '',
			}
		}

		// bind methods
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.handleFilterBtnClick = this.handleFilterBtnClick.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
		this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	handleClickOutside() {
		this.setState((state, props) => ({
			isClickedSurface: false,
			isClickedPrice: false,
		}))
	}

	handleSearchChange(searchQuery) {
		console.log('handleSearchChange searchQuery', searchQuery)
	}

	handleFilterBtnClick(e, stateName) {
		const newState = !this.state[stateName]

		this.setState((state, props) => ({
			[stateName]: newState
		}))
	}

	handleFilterChange(e, filterName, value) {
		if (typeof e !== 'undefined') {
			e.preventDefault()
		}

		const { filters } = this.state
		const { filterCallback } = this.props

		const filterValue = (typeof e !== 'undefined') ? e.target.value : value
		const newFilters = {
			...filters,
			[filterName]: filterValue
		}

		if (typeof newFilters !== 'undefined') {
			this.setState((state, props) => ({
				filters: newFilters
			}))

			if (
				('search' === filterName || 'bedrooms' === filterName)
				&& filterCallback
			) {
				filterCallback(newFilters)
			}
		}

		/*
		const filterValue = e.target.value
		let newFilters = undefined

		if ('surfaceMin' === filterName) {
			newFilters = {
				...filters,
				[filterName]: filterValue
			}
		}

		if (typeof newFilters !== 'undefined') {
			this.setState((state, props) => ({
				filters: newFilters
			}))
		}
		*/
	}

	handleFilterSubmit(e, filterName) {
		e.preventDefault()

		const { filters } = this.state
		const { filterCallback } = this.props

		if (filterCallback) {
			filterCallback(filters)
		}

		this.setState((state, props) => ({
			isClickedSurface: false,
			isClickedPrice: false,
		}))
	}

	render() {
		const {
			filters,
			isClickedSurface,
			isClickedPrice,
		} = this.state

		const initialSearch = (filters && filters.search) ? filters.search : ''

		return (
			<header className="catalogue__header">
				<div className="catalogue__header__infos">
					<p className="catalogue__header__title text--blue-electric">
						<Trans i18nKey="landsIndexPageTitle">landsIndexPageTitle</Trans>
					</p>

					<SearchBar
						placeholder='landsIndexFilterSearch'
						className="catalogue__header__search"
						initialValue={initialSearch}
						onSubmit={this.handleFilterChange}
					/>
				</div>

				<div className="catalogue__header__filters">
					<FilterRange
						isClicked={isClickedSurface}
						clickOutsideCallback={this.handleClickOutside}
						btnClickCallback={this.handleFilterBtnClick}
						filterChangeCallback={this.handleFilterChange}
						filterSubmitCallback={this.handleFilterSubmit}
						className="catalogue__header__filter"
						valueMin={filters.surfaceMin}
						valueMax={filters.surfaceMax}
						boxName='surface'
						boxTitle='landsIndexFilterSurface'
					/>

					<FilterRange
						isClicked={isClickedPrice}
						clickOutsideCallback={this.handleClickOutside}
						btnClickCallback={this.handleFilterBtnClick}
						filterChangeCallback={this.handleFilterChange}
						filterSubmitCallback={this.handleFilterSubmit}
						className="catalogue__header__filter"
						valueMin={filters.priceMin}
						valueMax={filters.priceMax}
						boxName='price'
						boxTitle='landsIndexFilterPrice'
					/>
				</div>
			</header>
		)
	}
}

const mapStateToProps = state => {
	const { filters } = state.filters
 
	return { filters }
}

const mapDispatchToProps = dispatch => {
	return {

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandsFilters)