import React from 'react'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'
import PartnersListItem from 'components/partners/PartnersListItem'

const getTitle = (tableType, t) => {
	if ('interested' === tableType) {
		return t('offerDetailTableInterestedTitle')
	} else if ('not-interested' === tableType) {
		return t('offerDetailTableNotInterestedTitle')
	} else if ('pending' === tableType) {
		return t('offerDetailTablePendingTitle')
	}

	return undefined
}

const getTableCount = (offer, tableType) => {
	if ('pending' === tableType) {
		const pendingPartners = getPendingPartners(offer)
		return pendingPartners.length
	} else if ('interested' === tableType && offer.offerQuotes) {
		return offer.offerQuotes.length
	} else if ('not-interested' === tableType && offer.offerRefusals) {
		return offer.offerRefusals.length
	}

	return 0
}

const getPendingPartners = (offer) => {
	return offer.partners.filter((partner, iP) => {
		const hasQuote = isQuotePartner(partner, offer)
		const hasRefusal = isRefusalPartner(partner, offer)

		return (!hasQuote && !hasRefusal)
	})
}

const getNotInterestedPartners = (offer) => {
	return offer.partners.filter((partner, iP) => {
		const hasQuote = isQuotePartner(partner, offer)
		const hasRefusal = isRefusalPartner(partner, offer)

		return (!hasQuote && hasRefusal)
	})
}

const isQuotePartner = (partner, offer) => {
	let isQuotePartner = false
	offer.offerQuotes.map((offerQuote, iO) => {
		if (offerQuote.partner.id === partner.id) {
			isQuotePartner = true
		}

		return null
	})

	return isQuotePartner
}

const isRefusalPartner = (partner, offer) => {
	let isRefusalPartner = false
	offer.offerRefusals.map((offerRefusal, iR) => {
		if (offerRefusal.partner.id === partner.id) {
			isRefusalPartner = true
		}

		return null
	})

	return isRefusalPartner
}

export const OfferStatusTable = (props) => {
	const {
		t,
		offer,
		tableType,
		offerQuoteMessageCallback,
		offerQuoteActionCallback
	} = props

	const tableTitle = getTitle(tableType, t)
	const tableCount = getTableCount(offer, tableType)

	if (tableCount <= 0) {
		return null
	}

	const pendingPartners = getPendingPartners(offer)
	const notInterestedPartners = getNotInterestedPartners(offer)

	const descriptionLimit = 25

	return (
		<div className="Offer__partner__item">
			<div className="Offer__partner__header">
				<h3>{tableTitle}</h3>
				{tableCount && <span className="count">{tableCount}</span>}
			</div>

			{'interested' === tableType &&
				<table className="Offer__partner__quotes">
					<thead>
						<tr>
							<th>{t('offerDetailTableSeller')}</th>
							<th className="information">{t('offerDetailTableInformation')}</th>
							<th>{t('offerDetailTablePrice')}</th>
							<th>{t('offerDetailTableMessages')}</th>
							<th>{t('offerDetailTableActions')}</th>
						</tr>
					</thead>

					{offer.offerQuotes &&
						<tbody>
							{offer.offerQuotes.map((offerQuote, iQ) => {
								const quoteUser = offerQuote.partner.user
								const partnerStyle = {
									backgroundImage: (quoteUser.avatar && quoteUser.avatar.contentUrl) ? `url(${media.getMediaUrl(quoteUser.avatar.contentUrl)})`: null,
								}

								return (
									<tr key={iQ}>
										<td>
											<div className="Offer__quote__avatar" style={partnerStyle}></div>
											<div className="Offer__quote__user">
												<span className="username">{offerQuote.partner.user.username}</span>

												{offerQuote.partner.user.organization &&
													<span className="organization">{offerQuote.partner.user.organization.name}</span>
												}
											</div>
										</td>

										<td className="information">
											{offerQuote.description.substring(0, descriptionLimit)}{offerQuote.description.length > descriptionLimit && '...'}

											{offerQuote.description.length > descriptionLimit &&
												<ImageButton image={icons.arrowDown} altText='See more' />
											}
										</td>

										<td>{offerQuote.amount}</td>

										<td>
											<ImageButton image={icons.messageInactive} onClick={() => offerQuoteMessageCallback(offerQuote, offer)} />
										</td>

										<td>
											{'pending' === offerQuote.status &&
												<div className="Offer__quote__actions">
													<TextButton
														text='offerDetailActionDeny'
														className="WhiteButton upper"
														onClick={() => offerQuoteActionCallback(offerQuote, 'denied')}
													/>

													<TextButton
														text='offerDetailActionAccept'
														className="BlueButton upper"
														onClick={() => offerQuoteActionCallback(offerQuote, 'approved')}
													/>
												</div>
											}

											{'denied' === offerQuote.status &&
												<p className="Offer__quote__status is-denied">{t('offerDetailStatusDenied')}</p>
											}

											{'approved' === offerQuote.status &&
												<p className="Offer__quote__status is-approved">{t('offerDetailStatusApproved')}</p>
											}
										</td>
									</tr>
								)
							})}
						</tbody>
					}
				</table>
			}

			{'not-interested' === tableType && notInterestedPartners &&
				<div className="PartnersList--tiles">
					<div className="PartnersList__partners">
						{notInterestedPartners.map((partner, iP) => {
							return (
								<PartnersListItem
									key={iP}
									partner={partner}
									displayRoles={true}
								/>
							)
						})}
					</div>
				</div>
			}

			{'pending' === tableType && pendingPartners &&
				<div className="PartnersList--tiles">
					<div className="PartnersList__partners">
						{pendingPartners.map((partner, iP) => {
							return (
								<PartnersListItem
									key={iP}
									partner={partner}
									displayRoles={true}
								/>
							)
						})}
					</div>
				</div>
			}
		</div>
	)
}

export default withTranslation()(OfferStatusTable)