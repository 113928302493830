import ActionTypes from 'constants/action-types'

const initialState = {
	configuration: [],
}

const configurationReducer = (state = initialState, action) => {
	const {
		configuration,
	} = action

	switch(action.type) {
		case ActionTypes.SET_CONFIGURATION:
			let newConfiguration = []

			if (configuration && configuration.length > 0) {
				configuration.map((configItem) => {
					newConfiguration[configItem.name] = configItem.value
					return null
				})
			}

			return {
				...state,
				configuration: newConfiguration,
			}

		default:
			return {...state}
	}
}

export default configurationReducer