import React from 'react'

export const FilterButton = (props) => {
	const { onClick, text, children, isClicked, className } = props
	const isClickedClassName = (true === isClicked) ? 'is-clicked' : ''

	return (
		<div className={`FilterButton ${(className) ? className: ''} ${isClickedClassName}`}>
			<button type='button' onClick={onClick} className="FilterButton__button">
				{text}
			</button>

			{isClicked &&
				<div className={`FilterButton__content ${(className) ? className: ''}`}>
					{children}
				</div>
			}
		</div>
	)
}

export default FilterButton