import React from 'react'

import MessageUser from 'components/chat/MessageUser'
import MessageContent from 'components/chat/MessageContent'

export const Message = (props) => {
	const {
		message,
		user,
		position,
		displayMessageDate,
		downloadFileCallback,
		filePreviewCallback,
	} = props

	const positionName = (position) ? 'left': 'right'
	const bottomClass = (false === displayMessageDate) ? ' no-bottom': ''
	const messageClass = `Message--${positionName}${bottomClass}`

	if ('left' === positionName) {
		return (
			<div className={messageClass}>
				<MessageUser user={user} />
				<MessageContent
					message={message}
					user={user}
					displayMessageDate={displayMessageDate}
					downloadFileCallback={downloadFileCallback}
					filePreviewCallback={filePreviewCallback}
				/>
			</div>
		)
	} else {
		return (
			<div className={messageClass}>
				<MessageUser user={user} />
				<MessageContent
					message={message}
					user={user}
					displayMessageDate={displayMessageDate}
					downloadFileCallback={downloadFileCallback}
					filePreviewCallback={filePreviewCallback}
				/>
			</div>
		)
	}
}

export default Message