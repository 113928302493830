import ActionTypes from 'constants/action-types';

const initialState = {
	leftMenu: [],
};

const leftMenuReducer = (state = initialState, action) => {
	switch(action.type) {
		case ActionTypes.MENU_COLLAPSE:
			return {
				...state,
				displayLeftMenu: action.displayBool
			};

		default:
			return {...state}
	}
}

export default leftMenuReducer