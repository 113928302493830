import React from 'react'

import icons from 'assets/svg'

const getFileType = (file) => {
	const mimeType = file.type

	if (mimeType.indexOf('image') > -1) {
		return 'image'
	} else if (mimeType.indexOf('png') > -1) {
		return 'image'
	} else if (mimeType.indexOf('jpg') > -1) {
		return 'image'
	} else if (mimeType.indexOf('jpeg') > -1) {
		return 'image'
	} else if (mimeType.indexOf('gif') > -1) {
		return 'image'
	} else if (mimeType.indexOf('bmp') > -1) {
		return 'image'
	} else if (mimeType.indexOf('svg') > -1) {
		return 'image'
	} else if (mimeType.indexOf('pdf') > -1) {
		return 'pdf'
	}

	return undefined
}

const truncateFilename = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr;

    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);

    return fullStr.substr(0, frontChars) + 
           separator + 
           fullStr.substr(fullStr.length - backChars);
}

export const FileInputPreview = (props) => {
	const { file, fileInputWidget } = props
	const fileType = getFileType(file)

	return (
		<div className="FileInputPreview__item">
			{fileInputWidget && 'white-button' === fileInputWidget &&
				<div className="Preview">
					<img src={icons.file} alt='' />
					<span>{truncateFilename(file.name, 20, '...')}</span>
				</div>
			}

			{(!fileInputWidget || 'white-button' !== fileInputWidget) && 'image' === fileType &&
				<img src={URL.createObjectURL(file)} className="FileInputPreview__item__image" alt='File Upload Preview' />
			}

			{(!fileInputWidget || 'white-button' !== fileInputWidget) && 'pdf' === fileType &&
				<img src={icons.pdf} className="FileInputPreview__item__image--pdf" alt='PDF File upload' />
			}
		</div>
	)
}

export default FileInputPreview