import React from 'react'
import { withTranslation } from 'react-i18next'

import { UbikonForm } from 'components/forms'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import OrganizationDetailContent from 'components/organizations/OrganizationDetailContent'
import OrganizationForm from 'components/organizations/OrganizationForm'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'

export const OrganizationDetail = (props) => {
	const {
		t,
		organization,
		isEditMode,
		isOrganizationManager,
		addUserCallback,
		switchEditModeCallback,
		organizationSchema,
		organizationSubmitCallback,
		backBtnCallback,
	} = props

	return (
		<div className="Organization__Wrapper">
			<div className="Organization__Detail">
				<div className="Organization__nav">
					<ImageButton
						image={icons.arrow}
						altText='Back'
						onClick={backBtnCallback}
					/>

					{!isEditMode && isOrganizationManager &&
						<div className="Edit__btns">
							<ImageButton
								image={icons.edit}
								altText='organizationDetailEditBtn'
								onClick={switchEditModeCallback}
							/>

							<TextButton
								text='organizationDetailEditBtn'
								onClick={switchEditModeCallback}
								className="blue"
							/>
						</div>
					}
				</div>

				<div className="Organization__header">
					<div className="Organization__name">
						<h1>{organization.name}</h1>
					</div>

					<div className="Organization__cover"></div>
				</div>

				{isEditMode && isOrganizationManager && !organizationSchema &&
					<Spinner />
				}

				{isEditMode && isOrganizationManager && organizationSchema &&
					<UbikonForm form={organizationSchema} theme={OrganizationForm} onSubmit={organizationSubmitCallback} />
				}

				{!isEditMode &&
					<OrganizationDetailContent
						organization={organization}
						addUserCallback={addUserCallback}
						isOrganizationManager={isOrganizationManager}
						backBtnCallback={backBtnCallback}
					/>
				}
			</div>
		</div>
	)
}

export default withTranslation()(OrganizationDetail)