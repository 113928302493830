import React from 'react'

import LandFormTheme from 'components/lands/LandFormTheme'
import PropertyForm from 'components/properties/PropertyForm'
import Spinner from 'components/spinner/Spinner'
import UbikonForm from 'components/forms/UbikonForm'

export const RequestForm = (props) => {
	const {
		landForm,
		propertyForm,
		benefits,
		projectRequestType,
		onSubmitCallback,
		onCancelCallback,
	} = props

	return (
		<div className="RequestForm">
			{projectRequestType && 'land' === projectRequestType &&
				<UbikonForm
					form={landForm}
					theme={LandFormTheme}
					benefits={benefits}
					onSubmit={onSubmitCallback}
				/>
			}

			{projectRequestType && 'property' === projectRequestType &&
				<div>
					{!propertyForm &&
						<Spinner />
					}

					{propertyForm &&
						<UbikonForm
							form={propertyForm}
							theme={PropertyForm}
							onSubmit={onSubmitCallback}
							onCancelCallback={onCancelCallback}
						/>
					}
				</div>
			}
		</div>
	)
}

export default RequestForm