const fileFolderForm = {
	name: 'file-folder',
	submitBtn: 'projectFileNewFolderPopinSubmitBtn',
	multipart: false,
	fields: [
		{
			type: 'text',
			props: {
				name: 'name',
				id: 'name',
				required: true,
				placeholder: 'projectFileNewFolderPopinPlaceholder',
			},
		},
	],	
}

export default fileFolderForm