import React from 'react'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import projectStatuses from 'constants/project-statuses'
import PartnersList from 'components/partners/PartnersList'

const ProjectListItem = (props) => {
	const { t, project, projectClickCallback } = props
	const projectLocation = (project) ? `${project.land.city}, ${project.land.zipcode}` : undefined

	const projectImage = (project && project.cover) ? project.cover: undefined
	const projectImageStyle = {
		backgroundImage: (projectImage) ? `url(${media.getMediaUrl(projectImage.contentUrl)})`: null,
	}

	const pendingClass = (projectStatuses.PROJECT_STATUS_PENDING === project.status) ? 'is-pending': ''

	return (
		<div className={`all-projects__project ${pendingClass}`} onClick={(e) => projectClickCallback(e, project)}>
			<div className="all-projects__project__infos">
				<p className="all-projects__project__title">
					{project.name}
				</p>

				<p className="all-projects__project__location">
					<span className="all-projects__project__location__icon">
		                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14">
		                    <g fill="none" fillRule="nonzero" stroke="#004BFF">
		                        <path d="M5.002 13.017c3-3.496 4.5-6.047 4.5-7.654C9.502 2.953 7.488 1 5.002 1c-2.485 0-4.5 1.953-4.5 4.363 0 1.607 1.5 4.158 4.5 7.654z"/>
		                        <path d="M5.025 6.528a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
		                    </g>
		                </svg>
					</span>

					{projectLocation}
				</p>

				{projectStatuses.PROJECT_STATUS_PENDING !== project.status && project.bucket &&
					<p className="all-projects__project__type">
						<span className="all-projects__project__type__icon">
			                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
			                    <path fill="none" fillRule="nonzero" stroke="#004BFF" strokeLinecap="square" d="M.5 11.495V.754l6.153 2.292L.75 5.504"/>
			                </svg>
						</span>

						{project.bucket && project.bucket.name}
					</p>
				}

				{projectStatuses.PROJECT_STATUS_PENDING === project.status &&
                    <p className="all-projects__project__type">
                        <span className="all-projects__project__type__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
                                <path fill="none" fillRule="nonzero" strokeLinecap="square" d="M.5 11.495V.754l6.153 2.292L.75 5.504"/>
                            </svg>
                        </span>
                        {t('statusPendingFull')}
                    </p>
				}

				{projectStatuses.PROJECT_STATUS_PENDING !== project.status &&
					<div className="all-projects__project__people">
						<PartnersList
							project={project}
							displayRoles={false}
							widget='project_index'
							limit={3}
							avoidDuplicates={true}
						/>
					</div>
				}
			</div>

			<div className="all-projects__project__picture" style={projectImageStyle}></div>
		</div>
	)
}

export default withTranslation()(ProjectListItem)