import React from 'react'

import PartnersListItem from 'components/partners/PartnersListItem'

export const PartnersList = (props) => {
	const {
		project,
		projectTasks,
		displayRoles,
		widget,
		currentProjectPipelineSection,
		partnerItemClickCallback,
		removePartnerCallback,
		assignTaskCallback,
		limit,
		displayTasksCount,
		avoidDuplicates,
	} = props

	let renderedUsers = []

	return (
		<div className={`PartnersList${(widget) ? `--${widget}`: ''}`}>
			<div className="PartnersList__partners">
				{project && project.partners &&
					project.partners.map((partner, i) => {

						if (typeof limit !== 'undefined' && i >= limit) {
							return null
						}

						// check if current pipeline section is equal to partners pipeline section
						if (
							typeof currentProjectPipelineSection !== 'undefined'
							&& partner.projectPipelineSection
							&& partner.projectPipelineSection.id
							&& partner.projectPipelineSection.id !== currentProjectPipelineSection.id
						) {
							return null
						}

						// do not render same user twice
						if (true === avoidDuplicates && renderedUsers.includes(partner.user.id)) {
							return null
						}

						renderedUsers.push(partner.user.id)

						return (
							<PartnersListItem
								key={i}
								partner={partner}
								displayRoles={displayRoles}
								widget={widget}
								displayTasksCount={displayTasksCount}
								projectTasks={projectTasks}
								partnerItemClickCallback={partnerItemClickCallback}
								removePartnerCallback={removePartnerCallback}
								assignTaskCallback={assignTaskCallback}
							/>
						)
					})
				}
			</div>
		</div>
	)
}

export default PartnersList