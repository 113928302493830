import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import { setActiveConversation } from 'actions/messaging'
import { media } from 'media'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'

class PartnersListItem extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			openActions: false,
			activeConversationUser: undefined,
			isTaskHover: false,
		}

		// bind methods
		this.handleActionsClick = this.handleActionsClick.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this)
		this.handlePartnerClick = this.handlePartnerClick.bind(this)
		this.handleTasksHover = this.handleTasksHover.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidUpdate(prevProps, prevState) {
		const { activeConversationUser } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse && activeConversationUser) {
			this.props.resetApiResponse()

			const conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
				receiverId: activeConversationUser.id
			})

			if (
				apiResponse.conversation
				&& apiResponse.conversation.id
				&& conversationEndpoint === apiResponseEndpoint
			) {
				this.props.setActiveConversation(apiResponse.conversation)

				// reset user chat
				const userChatEndpoint = this.endpointBuilder.getEndpoint('user_chat')
				this.props.callApi(userChatEndpoint, 'get')
			}
		}
	}

	handleActionsClick(e) {
		e.preventDefault()

		const { openActions } = this.state

		this.setState((state, props) => ({
			openActions: !openActions
		}))
	}

	handleOutsideClick() {
		this.setState((state, props) => ({
			openActions: false
		}))
	}

	handleMessagePartnerClick(user) {
		const conversationEndpoint = this.endpointBuilder.getEndpoint('conversation_with_user', {
			receiverId: user.id
		})

		this.props.setActiveConversation(undefined)
		this.props.callApi(conversationEndpoint, 'get')

		this.setState((state, props) => ({
			activeConversationUser: user,
		}))
	}

	handlePartnerClick(partner) {
		const { partnerItemClickCallback } = this.props

		if (partnerItemClickCallback) {
			partnerItemClickCallback(partner)
		}
	}

	handleTasksHover() {
		const { isTaskHover } = this.state

		this.setState((state, props) => ({
			isTaskHover: !isTaskHover,
		}))
	}

	render() {
		const { openActions, isTaskHover } = this.state

		const {
			partner,
			userProp,
			displayRoles,
			widget,
			projectTasks,
			displayTasksCount,
			trimUsername,
			removePartnerCallback,
			assignTaskCallback,
		} = this.props

		const user = (typeof userProp !== 'undefined') ? userProp : partner.user
		const { avatar, username } = user
		const displayUsername = true === trimUsername && username.length > 10 ? `${username.substring(0, 10)}...` : username

		let partnerStyle = {backgroundImage: null}

		if (avatar && avatar.contentUrl) {
			partnerStyle = {backgroundImage: `url(${media.getMediaUrl(avatar.contentUrl)})`}
		} else if (avatar && avatar.length > 0) {
			partnerStyle = {backgroundImage: `url(${media.getMediaUrl(avatar)})`}
		}

		let tasksCount = 0
		if (projectTasks && true === displayTasksCount) {
			projectTasks.map((task, i) => {
				task.partners.map((taskPartner, iP) => {
					if (taskPartner.user.id === user.id) {
						tasksCount++
					}

					return null
				})

				return null
			})
		}

		const usernameClass = (displayTasksCount && tasksCount > 0) ? ' auto' : ''
		const hoverClass = (isTaskHover) ? ' is-hover' : ''

		return (
			<div className={`PartnersListItem ${hoverClass}`}>
				{'tiles' === widget &&
					<OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
						{isTaskHover &&
							<div className="Partners__view__tasks" onMouseLeave={this.handleTasksHover}>
								<p className="upper" onClick={() => this.handlePartnerClick(partner)}>
									<Trans i18nKey="projectDetailPartnersOngoingTasks">projectDetailPartnersOngoingTasks</Trans>
								</p>
							</div>
						}

						<div className="PartnersListItem__avatar" style={partnerStyle}></div>

						{user &&
							<div className={`PartnersListItem__username medium ${usernameClass}`}>
								<span className={`username${usernameClass}`}>{user.username}</span>

								{displayTasksCount && tasksCount > 0 &&
									<span
										className="partner__profile__tasks"
										onMouseEnter={this.handleTasksHover}
									>
										<Trans i18nKey="projectDetailPartnersTasksCount" tasksCount={tasksCount}>{{tasksCount}} tasks</Trans>
									</span>
								}

								{displayRoles && partner.partnerType &&
									<span className="role">{partner.partnerType.name}</span>
								}
							</div>
						}

						<div className="PartnersListItem__actions">
							<ImageButton
								image={icons.message}
								altText={`Send ${user.username} a message`}
								onClick={(e) => this.handleMessagePartnerClick(user)}
							/>

							<ImageButton
								image={icons.actions}
								altText={`More actions`}
								onClick={(e) => this.handleActionsClick(e)}
							/>
						</div>

						{openActions &&
							<ul className="PartnersListItem__actions__list">
								{partner.partnerType && 'ubikon' !== partner.partnerType.slug && removePartnerCallback &&
									<li className="PartnersListItem__actions__list__item" onClick={() => removePartnerCallback(partner)}>
										<Trans i18nKey="projectDetailPartnersRemove">projectDetailPartnersRemove</Trans>
									</li>
								}

								<li className="PartnersListItem__actions__list__item" onClick={() => assignTaskCallback(partner)}>
									<Trans i18nKey="projectDetailPartnersAssign">projectDetailPartnersAssign</Trans>
								</li>
							</ul>
						}
					</OutsideClickHandler>
				}

				{'file_upload' === widget &&
					<div>
						<div className="PartnersListItem__avatar" style={partnerStyle}></div>

						{user &&
							<div className="PartnersListItem__username medium">
								<span className="username">{displayUsername}</span>
							</div>
						}
					</div>
				}

				{'project_index' === widget &&
					<div className="PartnersListItem__avatar" style={partnerStyle}></div>
				}

				{!widget && displayRoles &&
					<div>
						<div className="PartnersListItem__avatar" style={partnerStyle}></div>

						<div className="PartnersListItem__username">
							{user &&
								<span className="username">{user.username}</span>
							}

							{partner.partnerType &&
								<span className="role">{partner.partnerType.name}</span>
							}
						</div>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api

	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersListItem)