import ActionTypes from 'constants/action-types';

const initialState = {
	displayHeader: true,
	displayDecorators: true,
	sizes: {},
};

const layoutReducer = (state = initialState, action) => {
	const {
		centerClass,
		displayHeader,
		displayDecorators,
		sizeKey,
		sizeValue,
	} = action

	const { sizes } = state

	switch(action.type) {
		case ActionTypes.SET_CENTER_CLASS:
			return {
				...state,
				centerClass,
			};

		case ActionTypes.SET_DISPLAY_HEADER:
			return {
				...state,
				displayHeader,
			}

		case ActionTypes.SET_DISPLAY_DECORATORS:
			return {
				...state,
				displayDecorators,
			}

		case ActionTypes.SET_ELEMENT_SIZE:
			const newSizes = {
				...sizes,
				[sizeKey]: sizeValue
			}

			return {
				...state,
				sizes: newSizes,
			}

		default:
			return {...state}
	}
}

export default layoutReducer