import React from 'react'
import { Trans } from 'react-i18next'

export const PropertyAttributes = (props) => {
	const { propertyItem } = props

	return (
		<div className="catalogue__property__attributes">
			<ul className="catalogue__property__attributes__list">
				{propertyItem.roomsCount &&
					<li className="catalogue__property__attribute">
						<Trans i18nKey="propertyAttributeRoomsCount" count={parseInt(propertyItem.roomsCount)}>
							{{count:  parseInt(propertyItem.roomsCount)}} Room
						</Trans>
					</li>
				}

				{propertyItem.bedroomsCount &&
					<li className="catalogue__property__attribute">
						<Trans i18nKey="propertyAttributeBedroomsCount" count={parseInt(propertyItem.bedroomsCount)}>
							{{count:  parseInt(propertyItem.bedroomsCount)}} Bedroom
						</Trans>
					</li>
				}

				{propertyItem.bathroomsCount &&
					<li className="catalogue__property__attribute">
						<Trans i18nKey="propertyAttributeBathroomsCount" count={parseInt(propertyItem.bathroomsCount)}>
							{{count:  parseInt(propertyItem.bathroomsCount)}} Bathroom
						</Trans>
					</li>
				}
			</ul>
		</div>
	)
}

export default PropertyAttributes