import React from 'react'
import { withTranslation } from 'react-i18next'

import { AttachedLabelInput } from 'components/forms/inputs'

export const PasswordInput = (props) => {
	const { t, field, formData, onChange } = props

	let finalProps = {
		...field.props,
		placeholder: (field.props && field.props.placeholder) ? t(field.props.placeholder) : '',
	}

	if (field.attachLabel && true === field.attachLabel && field.label) {
		if (
			field.props
			&& field.props.name
			&& formData
			&& (formData[field.props.name] || '' === formData[field.props.name])
		) {
			finalProps = {
				...field.props,
				value: formData[field.props.name]
			}
		}

		const attachedValue = (finalProps.value) ? finalProps.value : ''

		return (
			<AttachedLabelInput
				field={field}
				value={attachedValue}
				inputType='password'
				fieldProps={finalProps}
				formData={formData}
				onChange={onChange}
			/>
		)
	}

	return (
		<input
			type="password"
			className="FormInput TextInput PasswordInput"
			onChange={(e) => onChange(e, field)}
			{...finalProps}
		/>
	)
}

export default withTranslation()(PasswordInput)