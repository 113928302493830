const analysisConfigForm = {
	name: 'analysis-config',
	submitBtn: 'projectAnalysisConfigSubmitBtn',
	multipart: false,
	fields: [
		{
			type: 'number',
			label: 'projectAnalysisConfigSurface',
			props: {
				name: 'landSquareMeters',
				id: 'landSquareMeters',
				required: false,
				disabled: true,
				placeholder: 'm²',
			},
		},
		{
			type: 'number',
			label: 'projectAnalysisConfigLivingSpace',
			props: {
				name: 'livingSpace',
				id: 'livingSpace',
				required: true,
				placeholder: 'm²',
			},
		},
		{
			type: 'number',
			label: 'projectAnalysisConfigSPC',
			props: {
				name: 'floorSpace',
				id: 'floorSpace',
				required: false,
				placeholder: 'm²',
			},
		},
		{
			type: 'number',
			label: 'projectAnalysisConfigLandCharge',
			props: {
				name: 'landCharge',
				id: 'landCharge',
				required: false,
				placeholder: '€',
			},
		},
	],
}

export default analysisConfigForm