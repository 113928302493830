import React from 'react'
import { connect } from 'react-redux'

import { callApi, resetApiResponse } from 'actions/api'
import { media } from 'media'
import {
	Checkbox,
	SubmitButton,
} from 'components/forms/inputs'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'

class AssignTaskPopin extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			selectedTasks: undefined,
			isSelectAll: false,
		}

		// bind methods
		this.handleTaskClick = this.handleTaskClick.bind(this)
		this.handleHeaderActionClick = this.handleHeaderActionClick.bind(this)
		this.handleTasksSubmit = this.handleTasksSubmit.bind(this)

		// set private properties
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const { partner, projectTasks } = this.props

		if (projectTasks) {
			// set selected tasks
			const selectedTasks = projectTasks.filter((task, iT) => {
				let isPartnerTask = false

				task.partners.map((taskPartner, iTP) => {
					if (parseInt(taskPartner.id) === parseInt(partner.id)) {
						isPartnerTask = true
					}

					return null
				})

				return isPartnerTask
			})

			this.setState((state, props) => ({
				selectedTasks: selectedTasks,
			}))
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedTasks } = this.state

		const {
			apiResponse,
			apiResponseEndpoint,
			partner,
			saveCallback,
		} = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const assignTasksEndpoint = this.endpointBuilder.getEndpoint('assign_tasks')

			if (apiResponseEndpoint === assignTasksEndpoint) {
				if (saveCallback) {
					saveCallback(partner, selectedTasks)
				}
			}
		}
	}

	handleTaskClick(task, wasChecked) {
		const { selectedTasks } = this.state
		const isCheckedNow = !wasChecked
		let newSelectedTasks = selectedTasks

		if (true === isCheckedNow) {
			// add task to selected tasks
			newSelectedTasks.push(task)
		} else {
			// remove task from selected tasks
			newSelectedTasks = newSelectedTasks.filter((selectedTask, iST) => {
				return selectedTask.id !== task.id
			})
		}

		this.setState((state, props) => ({
			selectedTasks: newSelectedTasks,
		}))
	}

	handleTasksSubmit() {
		const { partner, projectPipelineSection } = this.props
		const { selectedTasks } = this.state

		const selectedTasksIds = (selectedTasks) ? selectedTasks.map((task) => {
			return task.id
		}) : null

		const assignData = {
			partnerId: partner.id,
			selectedTasks: selectedTasksIds,
			projectPipelineSectionId: projectPipelineSection.id,
		}

		const assignTasksEndpoint = this.endpointBuilder.getEndpoint('assign_tasks')
		this.props.callApi(assignTasksEndpoint, 'post', {
			body: JSON.stringify(assignData),
		})
	}

	isTaskChecked(task) {
		const { selectedTasks } = this.state
		let isSelected = false

		if (selectedTasks) {
			selectedTasks.map((selectedTask, iT) => {
				if (parseInt(task.id) === parseInt(selectedTask.id)) {
					isSelected = true
				}

				return null
			})
		}

		return isSelected
	}

	handleHeaderActionClick() {
		const { isSelectAll } = this.state
		const { projectTasks } = this.props

		const newSelectAll = !isSelectAll

		// select/unselect all tasks
		const selectedTasks = projectTasks.filter((task, iT) => {
			return newSelectAll
		})

		this.setState((state, props) => ({
			isSelectAll: newSelectAll,
			selectedTasks: selectedTasks,
		}))
	}

	render() {
		const { isSelectAll } = this.state

		const {
			partner,
			projectTasks
		} = this.props

		const maxTaskPartners = 2

		const user = partner.user
		let partnerStyle = {backgroundImage: null}
		let partnerName = undefined

		if (user) {
			const { avatar, username } = user	
			partnerName = username

			if (avatar && avatar.contentUrl) {
				partnerStyle = {backgroundImage: `url(${media.getMediaUrl(avatar.contentUrl)})`}
			} else if (avatar && avatar.length > 0) {
				partnerStyle = {backgroundImage: `url(${media.getMediaUrl(avatar)})`}
			}
		}

		return (
			<div className="AssignTaskPopin">
				<div className="Col__left">
					<div className="PartnersListItem__avatar" style={partnerStyle}></div>

					{partnerName && <p className="PartnersListItem__username">{partnerName}</p>}

					<SubmitButton
						className="upper"
						text="Save"
						onClick={this.handleTasksSubmit}
					/>
				</div>

				<div className="Col__right">
					<div className="folder">
						<div className="folder__breadcrumb">
							<div className="folder__name">
								Task title
							</div>

							<div className="folder__name">
								Creation
							</div>

							<div className="folder__name">
								Due date
							</div>

							<div className="folder__pending">
								Assigned to
							</div>

							<div className="folder__actions">
								<Checkbox
									isChecked={isSelectAll}
									onChangeCallback={this.handleHeaderActionClick}
								/>
							</div>
						</div>

						{projectTasks && projectTasks.map((task, iT) => {
							const isChecked = this.isTaskChecked(task)
							const dueDate = new Date(task.dueDate)
							const created = new Date(task.createdAt)

							return (
								<div key={iT} className="folder__line">
									<div className="folder__name">{task.title}</div>

									<div className="folder__name">
										{created.toLocaleDateString()}
									</div>

									<div className="folder__name">
										{dueDate.toLocaleDateString()}
									</div>

									<div className="folder__pending">
										{task.partners.map((taskPartner, iTP) => {
											if ((iTP + 1) > maxTaskPartners) {
												return null
											}

											let avatarStyle = {}
											if (taskPartner.user && taskPartner.user.avatar) {
												avatarStyle = {backgroundImage: `url(${media.getMediaUrl(taskPartner.user.avatar)})`}
											}

											return (
												<span key={iTP} className="folder__profile" style={avatarStyle}></span>
											)
										})}

										{task.partners.length > maxTaskPartners &&
											<p className="folder__more-people">+ {task.partners.length - maxTaskPartners}</p>
										}
									</div>

									<div className="folder__actions">
										<Checkbox
											isChecked={isChecked}
											onChangeCallback={() => this.handleTaskClick(task, isChecked)}
										/>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignTaskPopin)