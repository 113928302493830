import React from 'react'

import {
	SubmitButton,
	Textarea
} from 'components/forms/inputs'
import { media } from 'media'
import Label from 'components/forms/inputs/Label'
import TextButton from 'components/buttons/TextButton'

class AdminDenyForm extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			message: '',
		}

		// bind methods
		this.handleMessageChange = this.handleMessageChange.bind(this)
		this.handleSubmitMessage = this.handleSubmitMessage.bind(this)
		this.handleCancelClick = this.handleCancelClick.bind(this)
	}

	handleMessageChange(e, field) {
		const newMessage = e.target.value

		this.setState((state, props) => ({
			message: newMessage,
		}))
	}

	handleSubmitMessage() {
		const { message } = this.state
		const { section, entity, submitCallback } = this.props

		if (typeof submitCallback !== 'undefined') {
			submitCallback('denied', section, entity, message)
		}

		this.setState((state, props) => ({
			message: '',
		}))
	}

	handleCancelClick() {
		const { section, entity, cancelCallback } = this.props

		if (typeof cancelCallback !== 'undefined') {
			cancelCallback('cancel-deny', section, entity)
		}
	}

	render() {
		const { message } = this.state
		const { entity } = this.props

		const isSubmitDisabled = message.length <= 0

		const denyField = {
			type: 'textarea',
			props: {
				name: 'message',
				placeholder: 'Your message',
			}
		}

		const formData = { value: message }

		const avatarStyle = {
			backgroundImage: (entity && entity.createdBy && entity.createdBy.avatar) ? 
				`url(${media.getMediaUrl(entity.createdBy.avatar)})`
				: null,
		}

		return (
			<div className="AdminPopin__content AdminDenyForm">
				{entity && entity.createdBy &&
					<div className="AdminDenyForm__Header">
						<Label className="Label" labelText="Request author" />

						<div className="User">
							{entity.createdBy.avatar &&
								<div className="Avatar" style={avatarStyle}></div>
							}

							<p>{entity.createdBy.username}<br />{entity.createdBy.email}</p>
						</div>
					</div>
				}

				<form name="admin-deny" onSubmit={this.handleSubmitMessage}>
					<Label className="Label" labelText="deny form" />
					<Textarea
						field={denyField}
						formData={formData}
						onChange={this.handleMessageChange}
					/>
				</form>

				<div className="AdminPopin__buttons">
					<TextButton
						className="WhiteButton"
						text='Back to request'
						onClick={this.handleCancelClick}
					/>

					<SubmitButton
						text='Send'
						isDisabled={isSubmitDisabled}
						onClick={this.handleSubmitMessage}
					/>
				</div>
			</div>
		)
	}
}

export default AdminDenyForm