import iconActions from 'assets/svg/icon-actions.svg'
import iconAdd from 'assets/svg/icon-add.svg'
import iconAddRound from 'assets/svg/icon-add-round.svg'
import iconArrow from 'assets/svg/icon-arrow.svg'
import iconArrowDown from 'assets/svg/icon-arrow-down.svg'
import iconArrowSmall from 'assets/svg/icon-arrow-small.svg'
import iconAttachment from 'assets/svg/icon-attachment.svg'
import iconCheck from 'assets/svg/icon-check.svg'
import iconCross from 'assets/svg/icon-cross.svg'
import iconDelete from 'assets/svg/icon-delete.svg'
import iconDownload from 'assets/svg/icon-download.svg'
import iconEdit from 'assets/svg/icon-edit.svg'
import iconFile from 'assets/svg/icon-file.svg'
import iconFileBlue from 'assets/svg/icon-file-blue.svg'
import iconFilters from 'assets/svg/icon-filters.svg'
import iconFlag from 'assets/svg/icon-flag.svg'
import iconFolder from 'assets/svg/icon-folder.svg'
import iconFolderActive from 'assets/svg/icon-folder-active.svg'
import iconFullscreen from 'assets/svg/icon-fullscreen.svg'
import iconInfo from 'assets/svg/icon-info.svg'
import iconLocation from 'assets/svg/icon-location.svg'
import iconMessage from 'assets/svg/icon-message.svg'
import iconMessageInactive from 'assets/svg/icon-message-inactive.svg'
import iconMessages from 'assets/svg/icon-messages.svg'
import iconNotifications from 'assets/svg/icon-notifications.svg'
import iconPdf from 'assets/svg/icon-pdf.svg'
import iconSearch from 'assets/svg/icon-search.svg'
import iconSurface from 'assets/svg/icon-surface.svg'
import iconUser from 'assets/svg/icon-user.svg'
import iconView from 'assets/svg/icon-view.svg'

const icons = {
	actions: iconActions,
	add: iconAdd,
	addRound: iconAddRound,
	arrow: iconArrow,
	arrowDown: iconArrowDown,
	arrowSmall: iconArrowSmall,
	attachment: iconAttachment,
	check: iconCheck,
	cross: iconCross,
	delete: iconDelete,
	download: iconDownload,
	edit: iconEdit,
	flag: iconFlag,
	file: iconFile,
	fileBlue: iconFileBlue,
	filters: iconFilters,
	folder: iconFolder,
	folderActive: iconFolderActive,
	fullscreen: iconFullscreen,
	info: iconInfo,
	location: iconLocation,
	message: iconMessage,
	messageInactive: iconMessageInactive,
	messages: iconMessages,
	notifications: iconNotifications,
	pdf: iconPdf,
	search: iconSearch,
	surface: iconSurface,
	user: iconUser,
	view: iconView,
}

export default icons