import React from 'react'
import { withTranslation } from 'react-i18next'

import { ReactComponent as IconAttachment } from 'assets/svg/icon-attachment.svg'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'

class MessageForm extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			files: [],
		}

		// bind methods
		this.handleFormSubmit = this.handleFormSubmit.bind(this)
		this.handleAttachmentChange = this.handleAttachmentChange.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
		this.base64encodeFile = this.base64encodeFile.bind(this)
	}

	base64encodeFile(file) {
        const reader = new FileReader()

        reader.onload = (readerEvt) => {
        	const { files } = this.state

			const binaryString = readerEvt.target.result
			const base64File = btoa(binaryString)

			let newSelectedFiles = {
				...files,
			}
			newSelectedFiles[file.name] = base64File

			this.setState((state, props) => ({
				files: newSelectedFiles,
			}))
        }

        reader.readAsBinaryString(file)
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			e.preventDefault()

			this.handleFormSubmit()
		}
	}

	handleFormSubmit() {
		const { files } = this.state
		const { submitMessageCallback } = this.props

		submitMessageCallback(files)

		this.setState((state, props) => ({
			files: [],
		}))
	}

	handleAttachmentChange(e) {
		const files = e.target.files
		const filesArray = Array.from(files)

		filesArray.map((file) => {
			this.base64encodeFile(file)
			return null
		})
	}

	render() {
		const { files } = this.state

		const {
			t,
			messageChangeCallback,
			newMessageContent,
		} = this.props

		const filesBtnClass = (files && Object.keys(files).length > 0) ? 'is-selected' : ''

		return (
			<div className="MessageForm__wrapper">
				<div className="MessageForm">
					<div className="MessageForm__attachment">
						<label htmlFor="conversation-file">
							<IconAttachment className={`MessageForm__attachment__btn ${filesBtnClass}`} />

							{files && Object.keys(files).length > 0 &&
								<span className="MessageForm__files__count">{Object.keys(files).length}</span>
							}
						</label>

						<input
							type="file"
							id="conversation-file"
							name="conversation-file"
							className="MessageForm__file_input"
							onChange={(e) => this.handleAttachmentChange(e)}
						/>
					</div>

					<form onSubmit={this.handleFormSubmit}>
						<textarea
							className="MessageForm__textarea"
							placeholder={t('chatMessageFormText')}
							rows="1"
							onChange={(e) => { messageChangeCallback(e.target.value) }}
							onKeyPress={(e) => { this.handleKeyPress(e) }}
							value={newMessageContent}
						>
						</textarea>

						<TextButton
							buttonType='submit'
							text='chatMessageFormSubmitBtn'
							className="MessageForm__submit upper text--blue-carolina"
							onClick={this.handleFormSubmit}
						/>
					</form>
				</div>
			</div>
		)
	}
}

export default withTranslation()(MessageForm)