import React from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'

import { media } from 'media'
import { callApi, resetApiResponse } from 'actions/api'
import { setActiveConversation } from 'actions/messaging'
import { listenToTopic } from 'actions/messaging'
import icons from 'assets/svg'
import AccessControl from 'auth/access-control'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import ApiClient from 'api/ApiClient'
import Conversation from 'components/chat/Conversation'
import getConversationTitle from 'utils/getConversationTitle'
import ImageButton from 'components/buttons/ImageButton'
import MessageForm from 'components/chat/MessageForm'
import PartnersListItem from 'components/partners/PartnersListItem'
import Popin from 'components/layout/Popin'
import PreviewPopin from 'components/preview/PreviewPopin'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import UbikonForm from 'components/forms/UbikonForm'

class ChatCenterWrapper extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			messages: undefined,
			isLoadingMessage: false,
			newMessageContent: '',
			forceScroll: false,
			isHeaderExpanded: false,
			openAddUserPopin: false,
			addUserForm: undefined,
			previewedFile: undefined,
		}

		// bind methods
		this.conversationListener = this.conversationListener.bind(this)
		this.getFileExtension = this.getFileExtension.bind(this)
		this.handleMessageChange = this.handleMessageChange.bind(this)
		this.handleSubmitMessage = this.handleSubmitMessage.bind(this)
		this.handleCloseConversation = this.handleCloseConversation.bind(this)
		this.handleExpandClick = this.handleExpandClick.bind(this)
		this.handleFileDownload = this.handleFileDownload.bind(this)
		this.handleFilePreview = this.handleFilePreview.bind(this)
		this.handleClosePreview = this.handleClosePreview.bind(this)
		this.handleAddUserClick = this.handleAddUserClick.bind(this)
		this.handleAddUserSubmit = this.handleAddUserSubmit.bind(this)
		this.handleClosePopin = this.handleClosePopin.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
		this.apiClient = new ApiClient()
	}

	componentDidMount() {
		// get previous messages
		const getMessagesEndpoint = this.getMessagesEndpoint()
		if (getMessagesEndpoint) {
			this.props.callApi(getMessagesEndpoint, 'get')
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { messages } = this.state
		const { apiResponse, apiResponseEndpoint, activeConversation } = this.props
		const getMessagesEndpoint = this.getMessagesEndpoint()
		const addUserFormEndpoint = this.getAddUserFormEndpoint()
		const conversationEndpoint = this.getConversationEndpoint()

		let conversationDidChange = false
		if (!activeConversation && prevProps.activeConversation) {
			conversationDidChange = true
		} else {
			conversationDidChange = activeConversation && prevProps.activeConversation && prevProps.activeConversation.id !== activeConversation.id
		}

		let shouldForceScroll = false

		if (conversationDidChange) {
			this.setState((state, props) => ({
				messages: undefined,
			}))
		}

		if (activeConversation) {
			const postMessageEndpoint = this.endpointBuilder.getEndpoint('conversation_message', {
				conversationId: activeConversation.id
			})

			// get previous messages
			if (!apiResponse && null !== messages && (!messages || conversationDidChange)) {
				this.props.callApi(getMessagesEndpoint, 'get')
			}

			if (apiResponse) {
				this.props.resetApiResponse()

				// did get messages list
				if (apiResponseEndpoint === getMessagesEndpoint) {
					// init hub listener
					if (!messages || conversationDidChange) {
						this.props.listenToTopic(
							process.env.REACT_APP_MERCURE_HUB_URL,
							activeConversation.iri,
							this.conversationListener
						)
					}

					if (
						typeof prevState.messages === 'undefined'
						&& apiResponse.messages
					) {
						shouldForceScroll = true
					}

					// set state
					this.setState((state, props) => ({
						messages: apiResponse.messages,
						isLoadingMessage: false,
						forceScroll: shouldForceScroll
					}))
				}

				// has posted new message
				if (apiResponseEndpoint === postMessageEndpoint) {
					// set state
					this.setState((state, props) => ({
						isLoadingMessage: false,
					}))
				}

				// did get add user form
				if (apiResponseEndpoint === addUserFormEndpoint) {
					this.setState((state, props) => ({
						addUserForm: apiResponse.model,
					}))
				}

				// did post add users to conversation
				if (apiResponse.didPutConversation && apiResponseEndpoint === conversationEndpoint) {
					// set new active conversation in case group was created
					this.props.setActiveConversation(apiResponse.conversation)

					this.setState((state, props) => ({
						openAddUserPopin: false,
						addUserForm: undefined,
					}))
				}
			}
		}
	}

	getMessagesEndpoint() {
		const { activeConversation } = this.props

		if (activeConversation && activeConversation.id) {
			return this.endpointBuilder.getEndpoint(
				'conversation_messages',
				{ conversationId: activeConversation.id}
			)
		}

		return undefined
	}

	getAddUserFormEndpoint() {
		const { activeConversation } = this.props
		if (!activeConversation) {
			return undefined
		}

		return this.endpointBuilder.getEndpoint('conversations_get_add_user_form', {
			conversationId: activeConversation.id,
		})
	}

	getConversationEndpoint() {
		const { activeConversation } = this.props
		if (!activeConversation) {
			return undefined
		}

		return this.endpointBuilder.getEndpoint('conversation', {
			id: activeConversation.id,
		})
	}

	conversationListener(e) {
		const { messages } = this.state
		const message = JSON.parse(e.data)
		let newMessages = undefined

		if (null !== messages) {
			newMessages = [
				...messages,
				message
			]
		} else {
			newMessages = [
				message
			]
		}

		this.setState((state, props) => ({
			messages: newMessages
		}))
	}

	getFileExtension(filename) {
		return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
	}

	handleMessageChange(messageContent) {
		this.setState((state, props) => ({
			newMessageContent: messageContent
		}))
	}

	handleSubmitMessage(files) {
		const { newMessageContent } = this.state
		const { activeConversation } = this.props
		const postMessageEndpoint = this.endpointBuilder.getEndpoint('conversation_message', {
			conversationId: activeConversation.id
		})

		const messageData = {
			message: newMessageContent,
			files: files,
		}

		this.props.callApi(postMessageEndpoint, 'post', {
			body: JSON.stringify(messageData)
		})

		this.setState((state, props) => ({
			isLoadingMessage: true,
			newMessageContent: ''
		}))
	}

	handleCloseConversation() {
		this.props.setActiveConversation(undefined)

		this.setState((state, props) => ({
			isHeaderExpanded: false,
		}))
	}

	handleExpandClick() {
		const { isHeaderExpanded } = this.state

		this.setState((state, props) => ({
			isHeaderExpanded: !isHeaderExpanded
		}))
	}

	handleFileDownload(file) {
		// redirect to download link
		const downloadUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}/media_object/download/${file.id}`
		window.location.href = downloadUrl
	}

	handleFilePreview(file) {
		console.log('handleFilePreview file', file)

		this.setState((state, props) => ({
			previewedFile: file,
		}))
	}

	handleClosePreview() {
		this.setState((state, props) => ({
			previewedFile: undefined,
		}))
	}

	handleAddUserClick() {
		// get add user form
		const addUserFormEndpoint = this.getAddUserFormEndpoint()
		this.props.callApi(addUserFormEndpoint)

		this.setState((state, props) => ({
			openAddUserPopin: true,
		}))
	}

	handleAddUserSubmit(formData) {
		const users = Object.keys(formData.users).map((userId) => {
			return userId
		})

		const newFormData = {
			...formData,
			users: users
		}

		const conversationEndpoint = this.getConversationEndpoint()

		this.props.callApi(conversationEndpoint, 'put', {
			body: JSON.stringify({conversation: newFormData}),
		})
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			openAddUserPopin: false,
			addUserForm: undefined,
		}))
	}

	render() {
		const {
			messages,
			isLoadingMessage,
			forceScroll,
			newMessageContent,
			isHeaderExpanded,
			previewedFile,
			openAddUserPopin,
			addUserForm,
		} = this.state

		const { t, activeConversation } = this.props

		if (!activeConversation) {
			return null
		}

		const convObject = {
			...activeConversation,
			messages
		}

		const currentUserId = this.accessControl.getUserId()
		const expandedClass = (isHeaderExpanded) ? ' expanded': ''

		let conversationType = 'chat'
		if (activeConversation && activeConversation.isOffer) {
			conversationType = 'offers'
		} else if (activeConversation && activeConversation.isGroup) {
			conversationType = 'groups'
		} else if (activeConversation && activeConversation.isCollection) {
			conversationType = 'collection'
		}

		const conversationTitle = getConversationTitle(conversationType, activeConversation)

		return (
			<div className="ChatCenterWrapper">
				<div className="Close__chat">
					<ImageButton image={icons.cross} altText='chatCloseConversationBtn' onClick={this.handleCloseConversation} />
					<TextButton text='chatCloseConversationBtn' className="upper blue small" onClick={this.handleCloseConversation} />
				</div>

				<div className="Close__chat__arrow">
					<ImageButton image={icons.arrow} altText='chatCloseConversationBtn' onClick={this.handleCloseConversation} />
				</div>

				<h1>{conversationTitle}</h1>

				<div className="Conversation__header">
					<div className="Conversation__users">
						{activeConversation.users.map((conversationUser, i) => {
							const { avatar } = conversationUser
							const avatarStyle = {
								backgroundImage: (avatar) ? `url(${media.getMediaUrl(avatar)})`: null,
							}

							return (
								<div key={i} className="Conversation__user" style={avatarStyle}></div>
							)
						})}

						<p className="Conversation__users__count">
							<Trans i18nKey="chatConversationUsersCount" count={activeConversation.users.length}>
								{{count: activeConversation.users.length}} user
							</Trans>
						</p>
					</div>

					{activeConversation.files && activeConversation.files.length > 0 &&
						<div className="Conversation__files">
							<img src={icons.file} alt='Conversation Files' className="Conversation__files__icon" />

							<p className="Conversation__files__count">
								<Trans i18nKey="chatConversationFilesCount" count={activeConversation.files.length}>
									{{count: activeConversation.files.length}} user
								</Trans>
							</p>
						</div>
					}

					<ImageButton
						image={icons.arrowDown}
						altText='Expand'
						className={`Conversation__expand__btn${expandedClass}`}
						onClick={this.handleExpandClick}
					/>

					<div className="Conversation__actions">
						{!activeConversation.isCollection &&
							<ImageButton image={icons.add} className="Add__user__image__btn" onClick={this.handleAddUserClick} />
						}

						{!activeConversation.isCollection &&
							<TextButton text='chatConversationAddUserBtn' className="Add__user__text__btn upper blue" onClick={this.handleAddUserClick} />
						}

						<ImageButton className="Actions__btn" image={icons.actions} />
					</div>

					<div className={`Conversation__header__content${expandedClass}`}>
						<div className="Conversations__users__list">
							<h3 className="Conversation__dropdown__title">{t('chatConversationUsersLabel')}</h3>

							<div className="PartnersList--tiles">
								<div className="PartnersList__partners">
									{activeConversation.users && activeConversation.users.map((user, i) => {
										return (
											<PartnersListItem
												key={i}
												userProp={user}
												displayRoles={false}
												widget='tiles'
											/>
										)
									})}
								</div>
							</div>
						</div>

						{activeConversation.files && activeConversation.files.length > 0 &&
							<div className="Conversation__files__list">
								<h3 className="Conversation__dropdown__title">{t('chatConversationFilesLabel')}</h3>

								<table className="Files__list">
									<tbody>
										{activeConversation.files.map((file, i) => {
											const avatarStyle = (file.createdBy) ? {
												backgroundImage: (file.createdBy.avatar) ? `url(${media.getMediaUrl(file.createdBy.avatar)})`: null,
											} : null
											const fileCreatedDate = (file.createdAt) ? new Date(file.createdAt): null
											const fileExtension = this.getFileExtension(file.filePath)

											const previewStyle = (fileExtension && (
														fileExtension === 'png'
														|| fileExtension === 'jpg'
														|| fileExtension === 'jpeg'
														|| fileExtension === 'gif'
														|| fileExtension === 'bmp')) ? {
													backgroundImage: (file.contentUrl) ? `url("${media.getMediaUrl(file.contentUrl)}")`: null,
												}: null;

											return (
												<tr key={i}>
													<td>
														{previewStyle &&
															<div className="File__preview" style={previewStyle}></div>
														}
													</td>
													<td>{file.filePath}</td>
													<td>{null !== fileCreatedDate && fileCreatedDate.toLocaleDateString()}</td>
													<td>
														{file.createdBy &&
															<div className="File__user">
																<div className="File__user__avatar" style={avatarStyle}></div>
																<p className="File__user__username">
																	{file.createdBy.username}
																</p>
															</div>
														}
													</td>
													<td>
														<ImageButton
															image={icons.download}
															altText='Download file'
															className="File__action"
															onClick={(e) => this.handleFileDownload(file)}
														/>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						}
					</div>
				</div>

				{typeof messages === 'undefined' &&
					<Spinner />
				}

				{typeof messages !== 'undefined' &&
					<Conversation
						conversation={convObject}
						currentUserId={currentUserId}
						isLoadingMessage={isLoadingMessage}
						forceScroll={forceScroll}
						downloadFileCallback={this.handleFileDownload}
						filePreviewCallback={this.handleFilePreview}
					/>
				}

				{typeof messages !== 'undefined' &&
					<MessageForm
						conversation={activeConversation}
						messageChangeCallback={this.handleMessageChange}
						submitMessageCallback={this.handleSubmitMessage}
						newMessageContent={newMessageContent}
					/>
				}

				{previewedFile &&
					<PreviewPopin
						file={previewedFile}
						fileType='media_object'
						downloadCallback={this.handleFileDownload}
						closePreviewCallback={this.handleClosePreview}
					/>
				}

				{openAddUserPopin &&
					<Popin
						isOpened={openAddUserPopin}
						transTitle='chatConversationAddUserPopinTitle'
						transTitleKey='chatConversationAddUserPopinTitle'
						onClose={this.handleClosePopin}
					>
						{addUserForm &&
							<UbikonForm form={addUserForm} onSubmit={this.handleAddUserSubmit} />
						}

						{!addUserForm && <Spinner />}
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { activeConversation } = state.messaging

	return {
		apiResponse,
		apiResponseEndpoint,
		activeConversation,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		listenToTopic: (hubUrl, topic, callback) => dispatch(listenToTopic(hubUrl, topic, callback)),
		setActiveConversation: (conversation) => dispatch(setActiveConversation(conversation)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatCenterWrapper))