import React from 'react'
import { connect } from 'react-redux'

import { media } from 'media'
import { resetDidAddToCollection } from 'actions/collections'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import PropertyAttributes from 'components/properties/PropertyAttributes'

class PropertyListItem extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			forceLike: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { item, newCollectionItem } = this.props

		if (
			newCollectionItem
			&& newCollectionItem.itemType === 'property'
			&& newCollectionItem.propertyItem
			&& newCollectionItem.propertyItem === `/api/property_items/${item.id}`
		) {
			this.props.resetDidAddToCollection()

			this.setState((state, props) => ({
				forceLike: true,
			}))
		}
	}

	render() {
		const {
			forceLike,
		} = this.state

		const {
			item,
			itemCallback,
			className,
			collectionClickCallback,
			isCollectionMember,
		} = this.props

		const firstImage = item.photos && item.photos[0]
		const itemStyle = {
			backgroundImage: (firstImage && firstImage.contentUrl) ? `url(${media.getMediaUrl(firstImage.contentUrl)})`: null,
		}

		const infosEntity = {
			address: item.property.address,
			city: item.property.city,
			zipcode: item.property.zipcode,
			squareMeters: item.squareMeters,
		}

		const containerClass = (className) ? className : 'ListItem--large MapList__item'
		let likeClass = (forceLike || isCollectionMember || (item.isCollectionMember && true === item.isCollectionMember)) ? ' is-selected' : ''

		return (
			<div className={containerClass} onClick={(e) => itemCallback(e, item)}>
				<div className="catalogue__property__bg" style={itemStyle}></div>

				<div className="catalogue__property__content">
					<div className="catalogue__property__header">
						{item.price &&
							<p className="catalogue__property__price">
								{`${parseInt(item.price).toLocaleString()} €`}
							</p>
						}

	                    <span className="catalogue__property__like" onClick={() => collectionClickCallback(item)}>
	                        <svg className={`catalogue__property__like__icon ${likeClass}`} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
	                            <path fillRule="nonzero" d="M9.001 15.334l.974-.852c4.42-3.891 5.802-5.27 6.78-7.033.5-.902.745-1.765.745-2.653C17.5 2.39 15.55.5 13.05.5a4.962 4.962 0 0 0-3.674 1.652L9 2.58l-.376-.428A4.962 4.962 0 0 0 4.95.5C2.45.5.5 2.39.5 4.796c0 .892.247 1.758.752 2.665.98 1.76 2.372 3.146 6.774 7.013l.975.86z"/>
	                        </svg>
	                    </span>
					</div>

					<div className="catalogue__property__description">
						<div className="catalogue__property__description__wrapper">
							<p className="catalogue__property__title">
								{item.property.name}
							</p>

							<div className="catalogue__property__infos ListItem__infos">
								<LandInfosIcons entity={infosEntity} displayZipcode={false} />
							</div>

							<PropertyAttributes propertyItem={item} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { newCollectionItem } = state.collections
 
	return {
		newCollectionItem,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		resetDidAddToCollection: () => dispatch(resetDidAddToCollection()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyListItem)