import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { withRouter } from 'react-router'

import { callApi, resetApiResponse } from 'actions/api'
import { apiEndpointBuilder } from 'api'
import {
	Select,
	SubmitButton,
	Textarea,
	TextInput
} from 'components/forms/inputs'
import AccessControl from 'auth/access-control'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import Label from 'components/forms/inputs/Label'
import OrganizationDetailContent from 'components/organizations/OrganizationDetailContent'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'

class CreateOrganization extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			newOrganizationName: '',
			organizations: undefined,
			selectedOrganizationId: '',
			organizationRequest: undefined,
			didCheckOrganizationRequest: false,
			isCreatingOrganization: false,
			newOrganizationData: {
				name: '',
				website: '',
				contactEmail: '',
				description: '',
			}
		}

		// bind methods
		this.handleOrganizationFormChange = this.handleOrganizationFormChange.bind(this)
		this.handleOrganizationSelectChange = this.handleOrganizationSelectChange.bind(this)
		this.handleOrganizationClick = this.handleOrganizationClick.bind(this)
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
	}

	componentDidMount() {
		const organizationsEndpoint = apiEndpointBuilder.getEndpoint('organizations')
		this.props.callApi(organizationsEndpoint)

		// check if user has pending organization request
		const userOrganizationRequestEndpoint = this.getUserOrganizationRequestEndpoint()
		this.props.callApi(userOrganizationRequestEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const { isCreatingOrganization } = this.state
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			const userOrganizationEndpoint = apiEndpointBuilder.getEndpoint('user_organization')
			const organizationsEndpoint = apiEndpointBuilder.getEndpoint('organizations')
			const organizationRequestEndpoint = apiEndpointBuilder.getEndpoint('organization_requests')
			const userOrganizationRequestEndpoint = this.getUserOrganizationRequestEndpoint()

			// did get list of organizations
			if (!isCreatingOrganization && apiResponseEndpoint === organizationsEndpoint) {
				this.setState((state, props) => ({
					organizations: apiResponse,
				}))
			}

			// did create organization
			if (isCreatingOrganization && apiResponseEndpoint === organizationsEndpoint) {
				// get newly created user organization
				this.props.callApi(userOrganizationEndpoint)

				this.setState((state, props) => ({
					isCreatingOrganization: false,
				}))
			}

			// did send organization request
			if (apiResponseEndpoint === organizationRequestEndpoint) {
				this.setState((state, props) => ({
					organizationRequest: apiResponse,
				}))
			}

			// did check user organization request
			if (apiResponseEndpoint === userOrganizationRequestEndpoint) {
				const organizationRequest = (apiResponse[0]) ? apiResponse[0] : undefined

				this.setState((state, props) => ({
					didCheckOrganizationRequest: true,
					organizationRequest: organizationRequest,
				}))
			}
		}
	}

	getUserOrganizationRequestEndpoint() {
		const currentUserId = this.accessControl.getUserId()
		return  `${apiEndpointBuilder.getEndpoint('organization_requests')}?user=${currentUserId}&status=pending`
	}

	getOrganizationsOptions() {
		const { organizations } = this.state

		let organizationOptions = [
			{
				value: 0,
				label: 'Select an organization',
			}
		]

		if (organizations) {
			organizations.map((organization, iO) => {
				organizationOptions.push({
					label: organization.name,
					value: organization.id,
				})

				return null
			})
		}

		return organizationOptions
	}

	handleOrganizationFormChange(e, field) {
		const { newOrganizationData } = this.state
		const newValue = e.target.value

		const newData = {
			...newOrganizationData,
			[field.props.name]: newValue,
		}

		this.setState((state, props) => ({
			newOrganizationData: newData,
		}))
	}

	handleOrganizationSelectChange(value, field) {
		let newValue = value
		if (parseInt(newValue) === 0) {
			newValue = undefined
		}

		this.setState((state, props) => ({
			selectedOrganizationId: newValue,
		}))
	}

	handleOrganizationClick() {
		const {
			newOrganizationData,
			selectedOrganizationId,
		} = this.state

		// organization request (become a member of)
		if (selectedOrganizationId) {
			const endpoint = apiEndpointBuilder.getEndpoint('organization_requests')
			const data = {
				organization: `/api/organizations/${selectedOrganizationId}`,
			}

			this.props.callApi(endpoint, 'post', {
				body: JSON.stringify(data),
			})
		} else if (newOrganizationData.name) {
			const endpoint = apiEndpointBuilder.getEndpoint('organizations')

			this.props.callApi(endpoint, 'post', {
				body: JSON.stringify(newOrganizationData),
			})

			this.setState((state, props) => ({
				isCreatingOrganization: true,
			}))
		}
	}

	handleBackBtnClick() {
		this.props.history.push(reverse(routesList.projects.index))
	}

	render() {
		const {
			newOrganizationData,
			selectedOrganizationId,
			organizationRequest,
			didCheckOrganizationRequest,
		} = this.state

		const newOrganizationFieldName = {
			props: {
				name: 'name',
				value: newOrganizationData.name,
				placeholder: 'Name of your organization',
			}
		}

		const newOrganizationFieldWebsite = {
			props: {
				name: 'website',
				value: newOrganizationData.website,
				placeholder: 'http://www.your-organization.com',
			}
		}

		const newOrganizationFieldContactEmail = {
			props: {
				name: 'contactEmail',
				value: newOrganizationData.contactEmail,
				placeholder: 'example@your-organization.com',
			}
		}

		const newOrganizationFieldDescription = {
			props: {
				name: 'description',
				value: newOrganizationData.description,
				placeholder: 'Description',
			}
		}

		const joinOrganizationField = {
			props: {
				name: 'organization',
				value: selectedOrganizationId,
			},
			options: this.getOrganizationsOptions(),
		}

		const isSubmitDisabled = (!newOrganizationData.name && !selectedOrganizationId)

		if (!didCheckOrganizationRequest) {
			return (
				<div className="CreateOrganization">
					<Spinner />
				</div>
			)
		}

		if (!organizationRequest) {
			return (
				<div className="CreateOrganization">
					<h1>You are not a member of an organization yet</h1>

					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
						Mauris arcu risus, sodales eu blandit porta, aliquet nec enim. 
						Curabitur egestas, ipsum sit amet volutpat blandit, augue nunc viverra nisl, 
						vel rhoncus quam dui ut sapien. Proin bibendum gravida pharetra. 
						Proin sapien metus, aliquam sed maximus a, pellentesque pellentesque dui. 
						Morbi sit amet enim nulla. Ut eros mi, euismod nec porttitor nec, 
						tincidunt ac ipsum. Morbi consectetur magna ac luctus volutpat.
					</p>

					<div className="CreateOrganization__columns">
						<div className="Col__left">
							<h2>Create an organization</h2>

							<div className="FormRow">
								<Label forInput='name' labelText='Name' />
								<TextInput
									field={newOrganizationFieldName}
									onChange={this.handleOrganizationFormChange}
								/>
							</div>

							<div className="FormRow">
								<Label forInput='website' labelText='Website' />
								<TextInput
									field={newOrganizationFieldWebsite}
									onChange={this.handleOrganizationFormChange}
								/>
							</div>

							<div className="FormRow">
								<Label forInput='contactEmail' labelText='Contact Email' />
								<TextInput
									field={newOrganizationFieldContactEmail}
									onChange={this.handleOrganizationFormChange}
								/>
							</div>

							<div className="FormRow">
								<Label forInput='description' labelText='Description' />
								<Textarea
									field={newOrganizationFieldDescription}
									onChange={this.handleOrganizationFormChange}
								/>
							</div>
						</div>

						<div className="Col__right">
							<h2>Join an organization</h2>

							<Select
								field={joinOrganizationField}
								onChange={this.handleOrganizationSelectChange}
							/>
						</div>

						<div className="Col__full">
							<SubmitButton
								text='Validate'
								onClick={this.handleOrganizationClick}
								isDisabled={isSubmitDisabled}
							/>
						</div>
					</div>
				</div>
			)
		}

		if (didCheckOrganizationRequest && organizationRequest) {
			return (
				<div className="Organization__Wrapper">
					<div className="Organization__Detail">
						<div className="Organization__nav">
							<ImageButton
								image={icons.arrow}
								altText='Back to Lands List'
								onClick={this.handleBackBtnClick}
							/>
						</div>

						<div className="Organization__header">
							<div className="Organization__name">
								<h1>{organizationRequest.organization.name}</h1>
							</div>

							<div className="Organization__cover"></div>
						</div>

						<OrganizationDetailContent
							organization={organizationRequest.organization}
							isOrganizationManager={false}
							isPendingRequest={true}
						/>
					</div>
				</div>
			)
		}

		return (
			<div className="CreateOrganization">
				
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateOrganization))