import React from 'react'
import { withTranslation } from 'react-i18next'

export const Address = (props) => {
	const { t, address, title } = props

	return (
		<div className="Address">
			{title &&
				<h2>{t(title)}</h2>
			}

			<p>
				{`${address.streetNumber} ${address.street}`}
			</p>

			<p>
				{`${address.zipcode} ${address.city}`}
			</p>

			{address.country &&
				<p>{address.country}</p>
			}
		</div>
	)
}

export default withTranslation()(Address)