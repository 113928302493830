import React from 'react'

class Pagination extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			currentPage: 1,
		}

		// bind methods
		this.handlePageClick = this.handlePageClick.bind(this)
	}

	componentDidMount() {
		const { startPage } = this.props

		if (startPage) {
			this.setState((state, props) => ({
				currentPage: startPage,
			}))
		}
	}

	handlePageClick(e, page) {
		e.preventDefault()

		const { pageClickCallback } = this.props

		this.setState((state, props) => ({
			currentPage: page,
		}))

		if (pageClickCallback) {
			pageClickCallback(page)
		}
	}

	render() {
		const {
			currentPage
		} = this.state

		const {
			totalItems,
			itemsPerPage,
		} = this.props

		const lastPage = Math.ceil(totalItems / itemsPerPage)

		let firstPage = currentPage - 1
		if (firstPage <= 0) {
			firstPage = 1
		}

		const maxPagesToDisplay = firstPage + 2
		const lastDisplayedPage = firstPage + (maxPagesToDisplay - 1)

		const pages = []
		for (let i = firstPage; i < maxPagesToDisplay && i <= lastPage; i++) {
			pages.push(i)
		}

		// console.log('currentPage', currentPage)
		// console.log('firstPage', firstPage)
		// console.log('lastDisplayedPage', lastDisplayedPage)
		// console.log('lastPage', lastPage)
		// console.log('----')

		if (pages.length <= 0) {
			return null
		}

		return (
			<nav className="catalogue__properties__nav">
				<span
					className="catalogue__properties__nav__arrow--left"
					onClick={(e) => this.handlePageClick(e, currentPage - 1)}
				>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13">
                        <g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square">
                            <path d="M.5 6.5l5.806 5.806M.5 6.5L6.296.704M1.547 6.5H17.5"/>
                        </g>
                    </svg>
				</span>

				{pages.map((page, iP) => {
					const currentClass = (page === currentPage) ? ' is-active' : ''

					return (
						<span
							className={`catalogue__properties__nav__page${currentClass}`}
							key={iP}
							onClick={(e) => this.handlePageClick(e, page)}
						>
							{page}
						</span>
					)
				})}

				{/*lastDisplayedPage < lastPage &&
					<span className="catalogue__properties__nav__page--more">...</span>
				*/}

				{lastDisplayedPage <= lastPage &&
					<span
						className="catalogue__properties__nav__page"
						onClick={(e) => this.handlePageClick(e, lastPage)}
					>
						{lastPage}
					</span>
				}

				<span
					className="catalogue__properties__nav__arrow--right"
					onClick={(e) => this.handlePageClick(e, currentPage + 1)}
				>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13">
                        <g fill="none" fillRule="nonzero" stroke="#737373" strokeLinecap="square">
                            <path d="M.5 6.5l5.806 5.806M.5 6.5L6.296.704M1.547 6.5H17.5"/>
                        </g>
                    </svg>
				</span>
			</nav>
		)
	}
}

export default Pagination