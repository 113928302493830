import React from 'react'
import { withTranslation } from 'react-i18next'

import {
	SubmitButton
} from 'components/forms/inputs'

export const ProjectOfferForm = (props) => {
	const {
		t,
		formFields,
		submitCallback,
		formHelper,
	} = props

	if (!formFields) {
		return false;
	}

	return (
		<div className="ProjectOfferForm">
			<div className="Form__title">
				<h2>{t('offerFormTitle')}</h2>
			</div>

			<div className="Form__wrapper">
				<form name="project-offer" onSubmit={submitCallback}>
					{formHelper.renderField(formFields[0], 'offer-name')}

					{formHelper.renderField(formFields[1], 'offer-directory')}

					{formHelper.renderField(formFields[2], 'offer-description')}

					{formHelper.renderField(formFields[3], 'offer-files')}

					{formHelper.renderField(formFields[4], 'offer-partners')}

					<SubmitButton className="upper" text='offerFormSubmitBtn' />
				</form>
			</div>
		</div>
	)
}

export default withTranslation()(ProjectOfferForm)