class ApiNormalizer {
	normalize(parameters, fields) {
		let normalizedParams = []
		fields.map((field, i) => {
			if (typeof parameters[field.name] !== 'undefined' && null !== parameters[field.name]) {
				normalizedParams[field.name] = field.path.replace('{id}', parameters[field.name])
			}
			return undefined
		})

		return {
			...parameters,
			...normalizedParams
		}
	}	
}

export default ApiNormalizer