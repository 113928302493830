import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse, resetApiError } from 'actions/api'
import { setCenterClass, displayHeader } from 'actions/layout'
import AccessControl from 'auth/access-control'
import apiEndpoints from 'constants/api-endpoints'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import forgottenPasswordForm from 'components/forms/schemas/forgottenPasswordForm'
import loginForm from 'components/forms/schemas/loginForm'
import LoginFormTheme from 'components/forms/LoginFormTheme'
import Popin from 'components/layout/Popin'
import resetPasswordForm from 'components/forms/schemas/resetPasswordForm'
import routesList from 'routing/routes-list'
import signUpForm from 'components/forms/schemas/signUpForm'
import signUpFormTheme from 'components/forms/themes/signUpFormTheme'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'
import textPages from 'config/text-pages'
import UbikonForm from 'components/forms/UbikonForm'
import ubikonLogo from 'assets/svg/logo-ubikon.svg'

class Login extends React.Component {
	constructor(props) {
		super(props)

		// get password token if defined
		const searchParams = new URLSearchParams(this.props.location.search)
		const passwordToken = searchParams.get('token')

		// set state
		this.state = {
			displayIntro: true,
			moveIntroLogo: false,
			moveIntroLogoImage: false,
			loginFormValues: {
				email: undefined,
				password: undefined,
			},
			signupForm: {
				username: undefined,
				email: undefined,
				password: undefined,
				passwordConfirm: undefined,
			},
			didCreateUser: false,
			displaySignup: false,
			isLoading: false,
			isError: false,
			isPasswordPopinOpened: false,
			isHelpPopinOpened: false,
			isResettingPassword: false,
			didSendResettingEmail: false,
			passwordToken: passwordToken,
			displayResetPasswordForm: false,
			didSubmitResetPassword: false,
			resetPasswordSuccess: false,
			helpType: undefined,
		}

		// bind methods
		this.handleLoginFormSubmit = this.handleLoginFormSubmit.bind(this)
		this.handleSignupFormSubmit = this.handleSignupFormSubmit.bind(this)
		this.handleFormChange = this.handleFormChange.bind(this)
		this.handleTabClick = this.handleTabClick.bind(this)
		this.handleHelpBtnClick = this.handleHelpBtnClick.bind(this)
		this.handleClosePopin = this.handleClosePopin.bind(this)
		this.handleForgottentPasswordSubmit = this.handleForgottentPasswordSubmit.bind(this)
		this.handleResetFormSubmit = this.handleResetFormSubmit.bind(this)

		// init access control helper
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const { passwordToken } = this.state

		// if user is logged in, redirect to index
		if (this.accessControl.isAuthenticated()) {
			const indexUrl = reverse(routesList.projects.index)
			this.props.history.push(indexUrl)
		}

		this.props.setCenterClass('full-width no-padding no-margin no-border')
		this.props.displayHeader(false)

		if (passwordToken && passwordToken.length > 0) {
			// check token validity
			const tokenValidity = `${this.endpointBuilder.getEndpoint('forgotten_password_validity')}?token=${passwordToken}`
			this.props.callApi(tokenValidity)
		}

		setTimeout(() => {
			this.setState((state, props) => ({
				moveIntroLogo: true,
			}))
		}, 1500)

		setTimeout(() => {
			this.setState((state, props) => ({
				moveIntroLogoImage: true,
			}))
		}, 2500)

		setTimeout(() => {
			this.props.setCenterClass('full-width no-padding no-margin')
			this.props.displayHeader(true)

			this.setState((state, props) => ({
				displayIntro: false
			}))
		}, 3200)
	}

	componentDidUpdate(prevProps, prevState) {
		const { passwordToken } = this.state
		const { apiResponse, apiResponseEndpoint, apiError } = this.props

		if (apiError) {
			this.props.resetApiError()

			// TODO: display error message
			this.setState((state, props) => ({
				isLoading: false,
				isError: true,
			}))
		} else if (apiResponse) {
			// remove api response
			this.props.resetApiResponse()

			// if token, authenticate
			if (apiResponse.token) {
				const { token, refresh_token, user } = apiResponse

				this.accessControl.authenticate(token, refresh_token, user, (isAuthenticated) => {
					if (true === isAuthenticated) {
						// set refresh token
						this.accessControl.setRefreshToken(refresh_token)

						// get config
						const configEndpoint = this.endpointBuilder.getEndpoint('config')
						this.props.callApi(configEndpoint)

						const indexUrl = reverse(routesList.projects.index)
						this.props.history.push(indexUrl)
					} else {
						// TODO: display error message
						this.setState((state, props) => ({
							isLoading: false,
							isError: true,
						}))
					}
				})
			} else if (apiResponse.id) { // did create user
				// display login form
				this.setState((state, props) => ({
					loginFormValues: {
						email: apiResponse.email,
						password: undefined
					},
					displaySignup: false,
					isLoading: false,
					didCreateUser: true,
				}))
			} else {
				const forgottenPasswordEndpoint = this.endpointBuilder.getEndpoint('forgotten_password')
				const tokenValidityEndpoint = `${this.endpointBuilder.getEndpoint('forgotten_password_validity')}?token=${passwordToken}`
				const resetPasswordEndpoint = this.endpointBuilder.getEndpoint('reset_password')

				if (apiResponseEndpoint === forgottenPasswordEndpoint) {
					this.setState((state, props) => ({
						isResettingPassword: false,
						didSendResettingEmail: true,
					}))
				}

				if (apiResponse.isValid && apiResponseEndpoint === tokenValidityEndpoint) {
					this.setState((state, props) => ({
						displayResetPasswordForm: apiResponse.isValid,
					}))
				}

				if (apiResponseEndpoint === resetPasswordEndpoint) {
					this.setState((state, props) => ({
						didSubmitResetPassword: false,
						resetPasswordSuccess: true,
					}))
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.setCenterClass(undefined)
	}

	handleFormChange(e, formName) {
		const inputName = e.target.name
		const newValue = e.target.value

		const newFormData = {
			...this.state[formName],
			[inputName]: newValue
		}

		this.setState((state, props) => ({
			[formName]: newFormData
		}))
	}

	handleLoginFormSubmit(formData) {
		const { loginFormValues } = this.state
		let newFormData = {
			...formData,
		}

		if (!newFormData.email && loginFormValues.email) {
			newFormData = {
				...formData,
				email: loginFormValues.email,
			}
		}

		this.props.callApi(apiEndpoints.GET_AUTH_TOKEN, 'post', {
			body: JSON.stringify(newFormData)
		})

		this.setState((state, props) => ({
			isLoading: true,
			isError: false,
		}))
	}

	handleSignupFormSubmit(formData) {
		// TODO: check if password/confirmPassword match, check if email is valid

		let newFormData = {
			...formData,
		}

		if ('1' === newFormData.optin || 1 === newFormData.optin) {
			newFormData = {
				...newFormData,
				optin: true,
			}
		}

		this.props.callApi(apiEndpoints.POST_USERS, 'post', {
			body: JSON.stringify(newFormData)
		})

		this.setState((state, props) => ({
			isLoading: true,
			isError: false,
		}))
	}

	handleTabClick(e, tabName) {
		const displaySignup = ('sign-in' === tabName) ? false : true

		this.setState((state, props) => ({
			displaySignup: displaySignup
		}))
	}

	handleHelpBtnClick(helpType) {
		let isPasswordPopinOpened = false
		let isHelpPopinOpened = false

		if ('password' === helpType) {
			isPasswordPopinOpened = true
			isHelpPopinOpened = false
		} else if ('help' === helpType || 'terms' === helpType) {
			isPasswordPopinOpened = false
			isHelpPopinOpened = true
		}

		this.setState((state, props) => ({
			isHelpPopinOpened: isHelpPopinOpened,
			isPasswordPopinOpened: isPasswordPopinOpened,
			helpType: helpType,
		}))
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			isPasswordPopinOpened: false,
			isHelpPopinOpened: false,
			displayResetPasswordForm: false,
			helpType: undefined,
		}))
	}

	handleForgottentPasswordSubmit(formData) {
		const forgottenPasswordEndpoint = this.endpointBuilder.getEndpoint('forgotten_password')

		this.props.callApi(forgottenPasswordEndpoint, 'post', {
			body: JSON.stringify(formData),
		})

		this.setState((state, props) => ({
			isResettingPassword: true,
		}))
	}

	handleResetFormSubmit(formData) {
		// TODO: check if both passwords match
		const { passwordToken } = this.state
		const resetPasswordEndpoint = this.endpointBuilder.getEndpoint('reset_password')
		const newFormData = {
			...formData,
			token: passwordToken,
		}

		this.props.callApi(resetPasswordEndpoint, 'post', {
			body: JSON.stringify(newFormData),
		})

		this.setState((state, props) => ({
			didSubmitResetPassword: true,
		}))
	}

	render() {
		const {
			isError,
			displayIntro,
			moveIntroLogo,
			moveIntroLogoImage,
			displaySignup,
			didCreateUser,
			isPasswordPopinOpened,
			isHelpPopinOpened,
			isResettingPassword,
			didSendResettingEmail,
			displayResetPasswordForm,
			didSubmitResetPassword,
			resetPasswordSuccess,
			loginFormValues,
			helpType,
		} = this.state

		if (displayIntro) {
			const introLogoClassName = (true === moveIntroLogo) ? 'up' : ''
			const introLogoImageClassName = (true === moveIntroLogoImage) ? 'left' : ''

			return (
				<div className="Login is-intro">
					<div className="Intro">
						<div className={`Intro__logo ${introLogoClassName}`}>
							<img src={ubikonLogo} alt='Ubikon Logo' className={introLogoImageClassName} />
						</div>
					</div>
				</div>
			)
		}

		const language = 'en'
		const textPageContent = (textPages['help'] && textPages['help'][language]) ? textPages['help'][language] : undefined
		const legalPageContent = (textPages['legal'] && textPages['legal'][language]) ? textPages['legal'][language] : undefined

		const helpPopinTitleKey = ('help' === helpType) ? 'helpPopinTitleHelp' : 'helpPopinTitleTerms'

		return (
			<div className="Login">
				<div className="Login__center">
					<div className="Login__tabs">
						<button
							type='button'
							className={`Login__tab__button${(!displaySignup) ? '--active': ''}`}
							onClick={(e) => this.handleTabClick(e, 'sign-in')}
						>
							<Trans i18nKey="loginTabsSignInTitle">Sign in</Trans>
						</button>

						<button
							type='button'
							className={`Login__tab__button${(displaySignup) ? '--active': ''}`}
							onClick={(e) => this.handleTabClick(e, 'sign-up')}
						>
							<Trans i18nKey="loginTabsSignUpTitle">Sign up</Trans>
						</button>
					</div>

					<div className="Login__tab__content">
						{!displaySignup &&
							<div className="Login__form">
								{didCreateUser &&
									<p className="User__created">
										<Trans i18nKey="signupAccountCreatedMessage1">signupAccountCreatedMessage1</Trans>
										<br />
										<Trans i18nKey="signupAccountCreatedMessage2">signupAccountCreatedMessage2</Trans>
									</p>
								}

								<UbikonForm
									form={loginForm}
									theme={LoginFormTheme}
									loginFormValues={loginFormValues}
									helpClickCallback={this.handleHelpBtnClick}
									onSubmit={this.handleLoginFormSubmit}
									isError={isError}
								/>

								<TextButton
									text='loginNewUserLink'
									className="Login__help signup"
									onClick={(e) => this.handleTabClick(e, 'sign-up')}
								/>
							</div>
						}

						{displaySignup &&
							<div className="Login__signup_form">
								<UbikonForm
									form={signUpForm}
									theme={signUpFormTheme}
									helpClickCallback={this.handleHelpBtnClick}
									onSubmit={this.handleSignupFormSubmit}
									isError={isError}
								/>
							</div>
						}
					</div>
				</div>

				{displayResetPasswordForm &&
					<Popin
						isOpened={displayResetPasswordForm}
						transTitle='resetPasswordPopinTitle'
						transTitleKey='resetPasswordPopinTitle'
						onClose={this.handleClosePopin}
					>
						{resetPasswordSuccess && !didSubmitResetPassword &&
							<div className="Forgotten__password__success">
								<p><Trans i18nKey="renewPasswordSuccess1">renewPasswordSuccess1</Trans></p>
								<p><Trans i18nKey="renewPasswordSuccess2">renewPasswordSuccess2</Trans></p>

								<TextButton
									text='actionClose'
									className="BlueButton upper"
									onClick={this.handleClosePopin}
								/>
							</div>
						}

						{!resetPasswordSuccess && didSubmitResetPassword &&
							<Spinner />
						}

						{!resetPasswordSuccess && !didSubmitResetPassword &&
							<UbikonForm
								form={resetPasswordForm}
								onSubmit={this.handleResetFormSubmit}
							/>
						}
					</Popin>
				}

				{isPasswordPopinOpened && 
					<Popin
						isOpened={isPasswordPopinOpened}
						transTitle='forgottenPasswordPopinTitle'
						transTitleKey='forgottenPasswordPopinTitle'
						onClose={this.handleClosePopin}
					>
						<div>
							{!didSendResettingEmail && !isResettingPassword &&
								<UbikonForm
									form={forgottenPasswordForm}
									onSubmit={this.handleForgottentPasswordSubmit}
								/>
							}

							{!didSendResettingEmail && isResettingPassword &&
								<Spinner />
							}

							{didSendResettingEmail &&
								<div className="Forgotten__password__success">
									<p><Trans i18nKey="renewPasswordPendingEmail">renewPasswordPendingEmail</Trans></p>

									<TextButton
										className="BlueButton upper"
										text='actionClose'
										onClick={this.handleClosePopin}
									/>
								</div>
							}
						</div>
					</Popin>
				}

				{isHelpPopinOpened &&
					<Popin
						isOpened={isHelpPopinOpened}
						transTitle={helpPopinTitleKey}
						transTitleKey={helpPopinTitleKey}
						onClose={this.handleClosePopin}
					>
						{'help' === helpType && textPageContent && textPageContent.content &&
							<div
								className="Help__popin__content"
								dangerouslySetInnerHTML={{__html: textPageContent.content}}
							>
							</div>
						}

						{'terms' === helpType && legalPageContent && legalPageContent.content &&
							<div
								className="Help__popin__content"
								dangerouslySetInnerHTML={{__html: legalPageContent.content}}
							>
							</div>
						}
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint, apiError } = state.api

	return { apiResponse, apiResponseEndpoint, apiError }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		resetApiError: () => dispatch(resetApiError()),
		setCenterClass: (className) => dispatch(setCenterClass(className)),
		displayHeader: (bool) => dispatch(displayHeader(bool)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)