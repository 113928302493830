import React from 'react'
import { withTranslation } from 'react-i18next'

import { ReactComponent as IconSearch } from 'assets/svg/icon-search.svg'
import { ReactComponent as IconFilters } from 'assets/svg/icon-filters.svg'
import ProjectsListFiltersForm from 'components/forms/ProjectsListFiltersForm'
import ImageButton from 'components/buttons/ImageButton'
import SearchBar from 'components/search/SearchBar'

class ProjectsListFilters extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			activeFilter: undefined
		}

		// bind methods
		this.handleFilterBtnClick = this.handleFilterBtnClick.bind(this)
		this.handleSearchFilter = this.handleSearchFilter.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
	}

	handleFilterBtnClick(e, btnName) {
		e.preventDefault()

		const { activeFilter } = this.state
		let newActiveFilter = (typeof activeFilter === 'undefined' || btnName) ? btnName : undefined

		if (btnName === activeFilter) {
			newActiveFilter = undefined
		}

		this.setState((state, props) => ({
			activeFilter: newActiveFilter
		}))
	}

	handleSearchFilter(unusedVar, filterType, searchQuery) {
		const { filtersCallback } = this.props
		if (filtersCallback) {
			filtersCallback(filterType, searchQuery)
		}
	}

	handleFilterChange(formData) {
		const { filtersCallback } = this.props
		if (filtersCallback) {
			filtersCallback('filters', undefined, formData.location, formData.projectType)
		}
	}

	render() {
		const { activeFilter } = this.state
		const { t, isMobile, filtersSchema, filtersSchemaCallback } = this.props

		const searchBarClass = (activeFilter && 'search' === activeFilter) ? 'visible' : ''
		const searchBarFocus = 'search' === activeFilter

		if (true === isMobile) {
			return (
				<div className="all-projects__actions is-mobile">
					<div className="all-projects__action filters">
						<ImageButton
							svgImage={IconFilters}
							className="action__filters"
							altText='Filter projects'
							onClick={(e) => this.handleFilterBtnClick(e, 'filters')}
						/>					
					</div>

					<h1>{t('leftMenuProjects')}</h1>

					<div className="all-projects__action search">
						<ImageButton
							svgImage={IconSearch}
							className="action__search"
							altText='Search projects'
							onClick={(e) => this.handleFilterBtnClick(e, 'search')}
						/>
					</div>
				</div>
			)
		}

		return (
			<div className="all-projects__actions">
				<SearchBar
					placeholder='projectsIndexSearchPlaceholder'
					className={searchBarClass}
					focus={searchBarFocus}
					onSubmit={this.handleSearchFilter}
				/>

				<div className="all-projects__action">
					<ImageButton
						svgImage={IconSearch}
						className="action__search"
						altText='Search projects'
						onClick={(e) => this.handleFilterBtnClick(e, 'search')}
					/>
				</div>

				{filtersSchema &&
					<ProjectsListFiltersForm
						schema={filtersSchema}
						callback={filtersSchemaCallback}
						changeCallback={this.handleFilterChange}
						isVisible={activeFilter === 'filters'}
					/>
				}

				<div className="all-projects__action">
					<ImageButton
						svgImage={IconFilters}
						className="action__filters"
						altText='Filter projects'
						onClick={(e) => this.handleFilterBtnClick(e, 'filters')}
					/>					
				</div>
			</div>
		)
	}
}

export default withTranslation()(ProjectsListFilters)