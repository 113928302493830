import React from 'react'

class FileUpload extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			selectedFiles: [],
			previewUrls: [],
		}

		// bind methods
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(e, inputName) {
		const { selectedFiles, previewUrls } = this.state
		const { onChangeCallback } = this.props
		const selectedFile = e.target.files[0]
		const previewUrl = URL.createObjectURL(selectedFile)

		const newSelectedFiles = {
			...selectedFiles,
			[inputName]: selectedFile
		}

		const newPreviewUrls = {
			...previewUrls,
			[inputName]: previewUrl
		}

		this.setState((state, props) => ({
			selectedFiles: newSelectedFiles,
			previewUrls: newPreviewUrls,
		}))

		if (typeof onChangeCallback !== 'undefined') {
			onChangeCallback(e)
		}
	}

	handleSubmit(e) {
		e.preventDefault()

		const { selectedFiles } = this.state
		const { schema, onSubmitCallback } = this.props

		if (typeof onSubmitCallback !== 'undefined') {
			onSubmitCallback(e, schema, selectedFiles)
		}
	}

	getFormProperties() {
		const { schema } = this.props

		if (typeof schema !== 'undefined') {
			const { properties: formProperties } = schema
			return formProperties
		}

		return undefined
	}

	render() {
		const { previewUrls } = this.state
		const { schema } = this.props
		const formProperties = this.getFormProperties()

		return (
			<div className="FileUpload">
				<form name={schema.title} onSubmit={(e) => this.handleSubmit(e)}>
					{formProperties && Object.keys(formProperties).map((inputName) => {
						//const formProperty = formProperties[inputName]
						const previewUrl = (typeof previewUrls[inputName] !== 'undefined') ? previewUrls[inputName]: undefined

						return (
							<div key={inputName}>
								<input
									type="file"
									name={inputName}
									onChange={(e) => this.handleChange(e, inputName)}
								/>

								{previewUrl &&
									<div className="FileUpload__Preview">
										<img src={previewUrl} alt='FileUpload__Preview' />
									</div>
								}
							</div>
						)
					})}

					{formProperties &&
						<button type="submit">Upload</button>
					}
				</form>
			</div>
		)
	}
}

export default FileUpload