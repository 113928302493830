import ActionTypes from 'constants/action-types'

export function openCollectionsPopin(collectionType, collectionItem) {
    return {
        type: ActionTypes.OPEN_COLLECTIONS_POPIN,
        isCollectionsPopinOpen: true,
        collectionType,
        collectionItem,
    }
}

export function closeCollectionsPopin() {
    return {
        type: ActionTypes.CLOSE_COLLECTIONS_POPIN,
        isCollectionsPopinOpen: false,
        collectionType: undefined,
        collectionItem: undefined,
    }
}

export function didAddToCollection(collectionItem) {
    return {
        type: ActionTypes.DID_ADD_TO_COLLECTION,
        newCollectionItem: collectionItem,
    }
}

export function resetDidAddToCollection() {
    return {
        type: ActionTypes.DID_ADD_TO_COLLECTION,
        newCollectionItem: undefined,
    }
}