import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { callApi, resetApiResponse } from 'actions/api'
import AnalysisConfigForm from 'components/projects/AnalysisConfigForm'
import ApiClient from 'api/ApiClient'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import Spinner from 'components/spinner/Spinner'

class ProjectSectionFinancialAnalysis extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			analysis: undefined,
			analysisConfig: undefined,
			analysisData: [],
			isUpdatingAnalysis: false,
			configEndpoint: undefined,
		}

		// bind methods
		this.handleFieldChange = this.handleFieldChange.bind(this)
		this.handleFieldSave = this.handleFieldSave.bind(this)
		this.handleConfigSubmit = this.handleConfigSubmit.bind(this)

		// set private properties
		this.apiClient = new ApiClient()
		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		// call analysis endpoint
		const projectAnalysisEndpoint = this.getProjectAnalysisEndpoint()
		this.props.callApi(projectAnalysisEndpoint)
	}

	componentDidUpdate(prevProps, prevState) {
		const { analysis, analysisData, configEndpoint } = this.state
		const {
			apiResponse,
			apiResponseEndpoint,
		} = this.props

		if (apiResponse) {
			this.props.resetApiResponse()

			if (typeof apiResponse.analysis !== 'undefined') {
				const newAnalysisData = (!analysis) ? this.getInitialAnalysisData(apiResponse.analysis) : analysisData

				this.setState((state, props) => ({
					analysis: apiResponse.analysis,
					analysisConfig: apiResponse.config,
					analysisData: newAnalysisData,
					isUpdatingAnalysis: false,
				}))
			} else {
				this.setState((state, props) => ({
					isUpdatingAnalysis: false,
				}))
			}

			if (apiResponseEndpoint === configEndpoint) {
				const projectAnalysisEndpoint = this.getProjectAnalysisEndpoint()
				this.props.callApi(projectAnalysisEndpoint)

				this.setState((state, props) => ({
					configEndpoint: undefined,
				}))
			}
		}
	}

	getProjectAnalysisEndpoint() {
		const { project } = this.props

		return this.endpointBuilder.getEndpoint('get_project_analysis', {
			projectId: project.id,
		})
	}

	getRowPersistEndpoint(analysisRow) {
		return this.endpointBuilder.getEndpoint('persist_analysis', {
			id: analysisRow.id,
		})
	}

	getInputKey(fieldName, analysisRow) {
		return `field-${fieldName}-${analysisRow.id}`
	}

	getTaxRateAmount(analysisRowId, analysisRowData, actionType) {
		let newTaxRateAmount = null

		if (analysisRowData) {
			const { analysisData } = this.state
			const {
				priceTaxExcluded,
				priceTaxIncluded,
			} = analysisRowData

			const stateRowData = analysisData[analysisRowId]
			const { taxRate } = stateRowData

			if (taxRate && taxRate.length > 0 && parseFloat(taxRate) > 0) {
				if ('excluded-to-included' === actionType && priceTaxExcluded) {
					newTaxRateAmount = (priceTaxExcluded * taxRate) / 100
				} else if ('included-to-excluded' === actionType && priceTaxIncluded) {
					const divider = parseFloat(`1.${taxRate}`)
					newTaxRateAmount = priceTaxIncluded - (priceTaxIncluded / divider)
				}
			}
		}

		/*
		return new Intl.NumberFormat('fr-FR', {
			maximumFractionDigits: 2,
		}).format(newTaxRateAmount)
		*/

		return newTaxRateAmount
	}

	getInitialAnalysisData(analysis) {
		let initialAnalysisData = []

		if (analysis) {
			Object.keys(analysis).map((iSection) => {
				const analysisSection = analysis[iSection]
				const analysisRows = (analysisSection && analysisSection.rows) ? analysisSection.rows : undefined

				if (analysisRows) {
					analysisRows.map((analysisRow, iAR) => {
						let rowData = undefined

						if ('land_charges' === analysisSection.sectionType) {
							rowData = {
								description: (analysisRow.description) ? analysisRow.description : '',
								taxRate: (analysisRow.taxRate) ? analysisRow.taxRate : '',
								priceTaxExcluded: (analysisRow.priceTaxExcluded) ? analysisRow.priceTaxExcluded : '',
								priceTaxIncluded: (analysisRow.priceTaxIncluded) ? analysisRow.priceTaxIncluded : '',
								taxRateAmount: (analysisRow.taxRateAmount) ? analysisRow.taxRateAmount : '',
							}
						}

						if (rowData) {
							initialAnalysisData = {
								...initialAnalysisData,
								[analysisRow.id]: rowData
							}
						}

						return null
					})
				}

				return null
			})
		}

		return initialAnalysisData
	}

	getIncomesTotals() {
		const { analysis } = this.state
		let totals = {
			priceTaxExcluded: 0,
			priceTaxIncluded: 0,
			taxRateAmount: 0,
		}

		if (null !== analysis) {
			Object.keys(analysis).map((iSection) => {
				const analysisSection = analysis[iSection]

				if ('incomes' === analysisSection.sectionType) {
					if (analysisSection && analysisSection.rows && analysisSection.rows.length > 0) {
						analysisSection.rows.map((analysisRow) => {
							const analysisPriceTaxExcluded = parseFloat(analysisRow.priceTaxExcluded)
							const analysisPriceTaxIncluded = parseFloat(analysisRow.priceTaxIncluded)
							const analysisTaxRateAmount = parseFloat(analysisRow.taxRateAmount)

							if (!isNaN(analysisPriceTaxExcluded)) {
								totals['priceTaxExcluded'] = parseFloat(totals.priceTaxExcluded) + analysisPriceTaxExcluded
							}

							if (!isNaN(analysisPriceTaxIncluded)) {
								totals['priceTaxIncluded'] = parseFloat(totals.priceTaxIncluded) + analysisPriceTaxIncluded
							}

							if (!isNaN(analysisTaxRateAmount)) {
								totals['taxRateAmount'] = parseFloat(totals.taxRateAmount) + analysisTaxRateAmount
							}

							return null
						})
					}
				}

				return null
			})
		}

		return totals
	}

	getAnalysisSectionTotals(analysisRows) {
		let totals = {
			priceTaxExcluded: 0,
			priceTaxIncluded: 0,
			taxRateAmount: 0,
		}

		analysisRows.map((analysisRow) => {
			const analysisPriceTaxExcluded = parseFloat(analysisRow.priceTaxExcluded)
			const analysisPriceTaxIncluded = parseFloat(analysisRow.priceTaxIncluded)
			const analysisTaxRateAmount = parseFloat(analysisRow.taxRateAmount)

			if (!isNaN(analysisPriceTaxExcluded)) {
				totals['priceTaxExcluded'] = parseFloat(totals.priceTaxExcluded) + analysisPriceTaxExcluded
			}

			if (!isNaN(analysisPriceTaxIncluded)) {
				totals['priceTaxIncluded'] = parseFloat(totals.priceTaxIncluded) + analysisPriceTaxIncluded
			}

			if (!isNaN(analysisTaxRateAmount)) {
				totals['taxRateAmount'] = parseFloat(totals.taxRateAmount) + analysisTaxRateAmount
			}

			return null
		})

		return totals
	}

	getAnalysisLandChargesTotals() {
		const { analysis } = this.state

		if (!analysis || null === analysis || typeof analysis === 'undefined') {
			return null
		}

		let totals = {
			priceTaxExcluded: 0,
			priceTaxIncluded: 0,
			taxRateAmount: 0,
		}

		Object.keys(analysis).map((iSection) => {
			const analysisSection = analysis[iSection]

			if ('land_charges' === analysisSection.sectionType && analysisSection.rows) {
				const sectionTotals = this.getAnalysisSectionTotals(analysisSection.rows)

				totals['priceTaxExcluded'] = totals['priceTaxExcluded'] + sectionTotals.priceTaxExcluded
				totals['priceTaxIncluded'] = totals['priceTaxIncluded'] + sectionTotals.priceTaxIncluded
				totals['taxRateAmount'] = totals['taxRateAmount'] + sectionTotals.taxRateAmount
			}

			return null
		})

		return totals
	}

	updateAnalysisData(analysisRow, fieldName, fieldValue) {
		const { analysisData } = this.state
		const fieldValueFloat = parseFloat(fieldValue)

		let rowData = (analysisData[analysisRow.id]) ? analysisData[analysisRow.id] : []
		let priceFieldName = undefined
		let newTaxRateAmount = undefined
		let newPriceFieldValue = undefined

		let newRowData = {
			...rowData,
			[fieldName]: fieldValue,
		}

		// if field is priceTaxExcluded, calculate priceTaxIncluded
		if ('priceTaxExcluded' === fieldName) {
			priceFieldName = 'priceTaxIncluded'
			newTaxRateAmount = parseFloat(this.getTaxRateAmount(analysisRow.id, newRowData, 'excluded-to-included'))
			newPriceFieldValue = (newTaxRateAmount > 0) ? parseFloat(fieldValueFloat + newTaxRateAmount) : fieldValue
		} else if ('priceTaxIncluded' === fieldName) {
			priceFieldName = 'priceTaxExcluded'
			newTaxRateAmount = parseFloat(this.getTaxRateAmount(analysisRow.id, newRowData, 'included-to-excluded'))
			newPriceFieldValue = (newTaxRateAmount > 0) ? parseFloat(fieldValueFloat - newTaxRateAmount) : fieldValue
		}

		if (newTaxRateAmount <= 0) {
			newTaxRateAmount = ''
		}

		newRowData = {
			...rowData,
			[fieldName]: fieldValue,
		}

		if (typeof newTaxRateAmount !== 'undefined' && !isNaN(newTaxRateAmount)) {
			newRowData = {
				...newRowData,
				'taxRateAmount': newTaxRateAmount,
			}
		}

		if (typeof priceFieldName !== 'undefined') {
			if ('string' === typeof newPriceFieldValue) {
				newPriceFieldValue = parseFloat(newPriceFieldValue)
			}

			newRowData = {
				...newRowData,
				[priceFieldName]: newPriceFieldValue,
			}
		}

		const newAnalysisData = {
			...analysisData,
			[analysisRow.id]: newRowData,
		}

		this.setState((state, props) => ({
			analysisData: newAnalysisData,
		}))
	}

	handleFieldChange(e, field) {
		this.updateAnalysisData(field.analysisRow, field.props.name, e.target.value)
	}

	handleFieldSave(field) {
		const { analysisData } = this.state
		const { analysisRow } = field

		const fieldData = analysisData[analysisRow.id]

		if (typeof fieldData !== 'undefined') {
			const persistAnalysisData = this.getRowPersistEndpoint(field.analysisRow)
			const requestData = {
				data: fieldData,
			}

			this.props.callApi(persistAnalysisData, 'post', {
				body: JSON.stringify(requestData),
			})

			this.setState((state, props) => ({
				isUpdatingAnalysis: true,
			}))
		}
	}

	handleConfigSubmit(formData) {
		const { analysisConfig } = this.state
		const { project } = this.props

		let method = 'post'
		let configEndpoint = undefined
		let configRequestData = undefined

		if (null === analysisConfig) {
			configEndpoint = this.endpointBuilder.getEndpoint('analysis_project_configs')

			const newFormData = {
				...formData,
				project: project.id,
			}

			configRequestData = this.apiClient.normalizeRequest(newFormData, [{
				name: 'project',
				path: '/api/projects/{id}'
			}])
		} else {
			configEndpoint = this.endpointBuilder.getEndpoint('analysis_project_config', {
				id: analysisConfig.id,
			})

			method = 'put'

			// convert values to to float
			configRequestData = {}
			Object.keys(formData).map((fieldName) => {
				configRequestData[fieldName] = parseFloat(formData[fieldName])
				return null
			})
		}

		if (configEndpoint && method) {
			this.props.callApi(configEndpoint, method, {
				body: JSON.stringify(configRequestData),
			})
		}

		this.setState((state, props) => ({
			analysis: undefined,
			analysisConfig: undefined,
			configEndpoint: configEndpoint,
		}))
	}

	renderAnalysisSection(sectionIndex) {
		const { analysis } = this.state
		const analysisSection = analysis[sectionIndex]

		if (analysisSection && analysisSection.rows && analysisSection.rows.length > 0) {
			const analysisRows = analysisSection.rows

			if ('land_charges' === analysisSection.sectionType) {
				return (
					<div className={`FinancialAnalysis__section land_charges ${analysisSection.slug}`} key={sectionIndex}>
						<div className="Section__header">
							<h3 className="offers__title">
								<p className="offers__title__text upper">
									{analysisSection.name}
								</p>
							</h3>

							<div className="folders">
								{this.renderAnalysisRowHeader(analysisSection)}

								{analysisRows.map((analysisRow, iRow) => {
									return this.renderAnalysisRow(analysisRow, analysisSection.sectionType)
								})}

								{this.renderAnalysisRowTotal(analysisRows, analysisSection)}

								{'depenses-annexes' === analysisSection.slug &&
									this.renderAnalysisChargesTotal()}
							</div>
						</div>
					</div>
				)
			} else if ('incomes' === analysisSection.sectionType) {
				return (
					<div className="FinancialAnalysis__section incomes" key={sectionIndex}>
						<div className="Section__header">
							<div className="folders">
								{analysisRows.map((analysisRow, iRow) => {
									return this.renderAnalysisRow(analysisRow, analysisSection.sectionType)
								})}
							</div>
						</div>
					</div>
				)
			}
		}

		return null
	}

	renderAnalysisRowHeader(analysisSection) {
		const { id, sectionType } = analysisSection

		if ('land_charges' === sectionType) {
			return (
				<div className="folder__breadcrumb land_charges" key={`section-${id}`}>
					<div className="folder__name name"><Trans i18nKey="projectAnalysisRowHeaderName">projectAnalysisRowHeaderName</Trans></div>
					<div className="folder__name description"><Trans i18nKey="projectAnalysisRowHeaderInfo">projectAnalysisRowHeaderInfo</Trans></div>
					<div className="folder__name priceTaxExcluded"><Trans i18nKey="projectAnalysisRowHeaderTaxExcl">projectAnalysisRowHeaderTaxExcl</Trans></div>
					<div className="folder__name taxRateAmount"><Trans i18nKey="projectAnalysisRowHeaderTax">projectAnalysisRowHeaderTax</Trans></div>
					<div className="folder__name priceTaxIncluded"><Trans i18nKey="projectAnalysisRowHeaderTaxIncl">projectAnalysisRowHeaderTaxIncl</Trans></div>
				</div>
			)
		}

		return null
	}

	renderAnalysisRow(analysisRow, sectionType) {
		const { id } = analysisRow

		if ('land_charges' === sectionType) {
			return (
				<div className={`folder__line ${sectionType}`} key={`analysis-row-${id}`}>
					{this.renderAnalysisRowField(analysisRow, 'name', false)}
					{this.renderAnalysisRowField(analysisRow, 'description', false)}
					{this.renderAnalysisRowField(analysisRow, 'priceTaxExcluded', true)}
					{this.renderAnalysisRowField(analysisRow, 'taxRateAmount', false)}
					{this.renderAnalysisRowField(analysisRow, 'priceTaxIncluded', true)}
				</div>
			)
		} else if ('incomes' === sectionType) {
			return (
				<div className={`folder__line ${sectionType}`} key={`analysis-row-${id}`}>
					{this.renderAnalysisRowField(analysisRow, 'name', false)}
					{this.renderAnalysisRowField(analysisRow, 'units', true)}
					{this.renderAnalysisRowField(analysisRow, 'spc', false)}
					{this.renderAnalysisRowField(analysisRow, 'squareMeters', false)}
					{this.renderAnalysisRowField(analysisRow, 'priceTaxExcluded', false)}
					{this.renderAnalysisRowField(analysisRow, 'taxRateAmount', false)}
					{this.renderAnalysisRowField(analysisRow, 'priceTaxIncluded', false)}
					{this.renderAnalysisRowField(analysisRow, 'unitPrice', true)}
				</div>
			)
		}

		return null
	}

	renderAnalysisRowField(analysisRow, fieldName, isEditable, forceFieldValue) {
		const { analysisData, isUpdatingAnalysis } = this.state

		const fieldKey = this.getInputKey(fieldName, analysisRow)
		let fieldValue = (typeof forceFieldValue === 'undefined') ? analysisRow[fieldName] : forceFieldValue

		if (typeof analysisData[analysisRow.id] !== 'undefined') {
			const rowData = analysisData[analysisRow.id]
			if (typeof rowData[fieldName] !== 'undefined') {
				fieldValue = rowData[fieldName]
			}
		}

		const inputField = {
			type: 'number',
			analysisRow: analysisRow,
			props: {
				name: fieldName,
				value: (fieldValue) ? fieldValue: '',
				disabled: isUpdatingAnalysis,
			}
		}

		return (
			<div className={`folder__name ${fieldName} ${(isEditable) ? 'is-editable' : ''}`} key={fieldKey}>
				{!isEditable &&
					<p>{fieldValue}</p>
				}

				{isEditable &&
					<input
						type={inputField.type}
						className="FormInput TextInput"
						onChange={(e) => this.handleFieldChange(e, inputField)}
						onBlur={() => this.handleFieldSave(inputField)}
						{...inputField.props}
					/>
				}
			</div>
		)
	}

	renderAnalysisRowTotal(analysisRows, analysisSection) {
		if ('land_charges' === analysisSection.sectionType) {
			const sectionTotals = this.getAnalysisSectionTotals(analysisRows)

			return (
				<div className={`folder__line ${analysisSection.sectionType} total`}>
					<div className="folder__name name">TOTAL {analysisSection.name}</div>
					<div className="folder__name description"></div>
					<div className="folder__name priceTaxExcluded">{sectionTotals.priceTaxExcluded.toFixed(2)}</div>
					<div className="folder__name taxRateAmount">{sectionTotals.taxRateAmount.toFixed(2)}</div>
					<div className="folder__name priceTaxIncluded">{sectionTotals.priceTaxIncluded.toFixed(2)}</div>
				</div>
			)
		}

		return null
	}

	renderAnalysisChargesTotal() {
		const totals = this.getAnalysisLandChargesTotals()

		return (
			<div className="folder__line land_charges total">
				<div className="folder__name name">Prix de revient de l'opération</div>
				<div className="folder__name description"></div>
				<div className="folder__name priceTaxExcluded">{totals.priceTaxExcluded.toFixed(2)}</div>
				<div className="folder__name taxRateAmount">{totals.taxRateAmount.toFixed(2)}</div>
				<div className="folder__name priceTaxIncluded">{totals.priceTaxIncluded.toFixed(2)}</div>
			</div>
		)
	}

	render() {
		const {
			analysis,
			analysisConfig,
			isUpdatingAnalysis,
		} = this.state

		const { project } = this.props

		const updatingClass = (true === isUpdatingAnalysis) ? 'is-updating' : ''

		if (typeof analysis === 'undefined' && typeof analysisConfig === 'undefined') {
			return (<Spinner />)
		}

		// get incomes totals
		const incomesTotals = this.getIncomesTotals()

		// get land charges totals
		const landChargesTotals = this.getAnalysisLandChargesTotals()

		const margin = (incomesTotals.priceTaxExcluded && landChargesTotals.priceTaxExcluded) ? incomesTotals.priceTaxExcluded - landChargesTotals.priceTaxExcluded : undefined
		const marginPercent = (margin && incomesTotals.priceTaxExcluded) ? (margin / incomesTotals.priceTaxExcluded) * 100 : undefined

		return (
			<div className="ProjectSectionFinancialAnalysis">
				<div className={`FinancialAnalysis ${updatingClass}`}>
					{!analysis && typeof analysisConfig !== 'undefined' &&
						<p className="FinancialAnalysis__empty">
							<Trans i18nKey="projectAnalysisEmpty">projectAnalysisEmpty</Trans>
						</p>
					}

					<AnalysisConfigForm
						project={project}
						config={analysisConfig}
						submitCallback={this.handleConfigSubmit}
					/>

					<div className="FinancialAnalysis__form">
						{isUpdatingAnalysis &&
							<div className="UpdatingOverlay"></div>
						}

						{analysis && Object.keys(analysis).map((iSection) => {
							if ('land_charges' === analysis[iSection].sectionType) {
								return this.renderAnalysisSection(iSection)
							}

							return null
						})}

						{analysis &&
							<div className="folder__breadcrumb incomes">
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableIncomes">projectAnalysisFooterTableIncomes</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableNumber">projectAnalysisFooterTableNumber</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableSPC">projectAnalysisFooterTableSPC</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableSurface">projectAnalysisFooterTableSurface</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableTaxExcl">projectAnalysisFooterTableTaxExcl</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableTax">projectAnalysisFooterTableTax</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableTaxIncl">projectAnalysisFooterTableTaxIncl</Trans></div>
								<div className="folder__name"><Trans i18nKey="projectAnalysisFooterTableUnitPrice">projectAnalysisFooterTableUnitPrice</Trans></div>
							</div>
						}

						{analysis && Object.keys(analysis).map((iSection) => {
							if ('incomes' === analysis[iSection].sectionType) {
								return this.renderAnalysisSection(iSection)
							}

							return null
						})}

						{analysis &&
							<div className={`folder__line incomes total`}>
								<div className="folder__name large">TOTAL DES RECETTES</div>
								<div className="folder__name"></div>
								<div className="folder__name"></div>
								<div className="folder__name">{incomesTotals.priceTaxExcluded.toFixed(2)}</div>
								<div className="folder__name">{incomesTotals.taxRateAmount.toFixed(2)}</div>
								<div className="folder__name">{incomesTotals.priceTaxIncluded.toFixed(2)}</div>
								<div className="folder__name"></div>
							</div>
						}

						{analysis && marginPercent && margin &&
							<div className={`folder__line incomes total`}>
								<div className="folder__name">MARGE</div>
								<div className="folder__name">% du CA HT</div>
								<div className="folder__name">{marginPercent.toFixed(2)} %</div>
								<div className="folder__name large">{margin.toFixed(2)}</div>
								<div className="folder__name"></div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {
		apiResponse,
		apiResponseEndpoint,
	} = state.api

	return {
		apiResponse,
		apiResponseEndpoint,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSectionFinancialAnalysis)