import React from 'react'

import {
	BenefitsGroup,
	Checkbox,
	CheckboxGroup,
	CustomCheckbox,
	UbikonDatePicker,
	FileInput,
	Hidden,
	NumberInput,
	PartnersSearch,
	PartnersSelect,
	PasswordInput,
	Radio,
	RadioGroup,
	Select,
	SubmitButton,
	Textarea,
	TextInput
} from 'components/forms/inputs'
import Label from 'components/forms/inputs/Label'
import TextButton from 'components/buttons/TextButton'
import validateField from 'components/forms/validateField'

class UbikonForm extends React.Component {
	constructor(props) {
		super(props)

		const { form } = props

		let initialFormData = undefined
		let repeatedFieldsArray = []

		// simple form (no steps)
		if (form.fields) {
			// get initial form data
			initialFormData = this.getInitialFormData(form.fields)

			// set repeated fields state array
			repeatedFieldsArray = this.getRepeatedFieldsArray(form.fields)
		} else if (form.steps) {
			form.steps.map((step, i) => {
				if (step.fields) {
					// get initial form data
					initialFormData = {
						...initialFormData,
						...this.getInitialFormData(step.fields)
					}

					// set repeated fields state array
					repeatedFieldsArray = {
						...repeatedFieldsArray,
						...this.getRepeatedFieldsArray(step.fields)
					}
				}

				return null
			})
		}

		// get current step if defined
		let currentStep = undefined
		if (form.steps) {
			form.steps.map((step, i) => {
				if (i === 0) {
					currentStep = step
				}
				return null
			})
		}

		// set state
		this.state = {
			formData: initialFormData,
			repeatedFields: repeatedFieldsArray,
			currentStep: currentStep,
			dependedFields: {},
		}

		// bind methods
		this.handleFormSubmit = this.handleFormSubmit.bind(this)
		this.handleFormChange = this.handleFormChange.bind(this)
		this.handleFormFilesChange = this.handleFormFilesChange.bind(this)
		this.handleSingleValueChange = this.handleSingleValueChange.bind(this)
		this.handleRadioChange = this.handleRadioChange.bind(this)
		this.handleRadioInputChange = this.handleRadioInputChange.bind(this)
		this.handleRepeatAddClick = this.handleRepeatAddClick.bind(this)
		this.handleRepeatRemoveClick = this.handleRepeatRemoveClick.bind(this)
		this.handleStepSubmit = this.handleStepSubmit.bind(this)

		// set private properties
		this.themeOnChangeCallback = undefined
	}

	getFormInput(field, key) {
		const { formData } = this.state

		switch(field.type) {
			case 'benefits':
				return <BenefitsGroup key={key} field={field} onChange={this.handleSingleValueChange} />
			case 'checkbox':
				return <Checkbox key={key} field={field} onChange={this.handleSingleValueChange} />
			case 'checkbox-group':
				return <CheckboxGroup key={key} field={field} onChange={this.handleSingleValueChange} />
			case 'custom-checkbox':
				return <CustomCheckbox key={key} field={field} onChange={this.handleFormChange} />
			case 'date':
				return <UbikonDatePicker key={key} field={field} onChange={this.handleSingleValueChange} />
			case 'file':
				return <FileInput key={key} fileInputKey={key} field={field} onChange={this.handleFormFilesChange} />
			case 'hidden':
				return <Hidden key={key} field={field} />
			case 'number':
				return <NumberInput key={key} field={field} onChange={this.handleFormChange} formData={formData} />
			case 'partners-search':
				return <PartnersSearch key={key} field={field} onChange={this.handleSingleValueChange} />
			case 'partners-select':
				return <PartnersSelect key={key} field={field} onChange={this.handleSingleValueChange} />
			case 'password':
				return <PasswordInput key={key} field={field} formData={formData} onChange={this.handleFormChange} />
			case 'radio':
				return <Radio key={key} field={field} onChange={this.handleRadioInputChange} />
			case 'radio-group':
				return <RadioGroup key={key} field={field} onChange={this.handleRadioChange} />
			case 'select':
				return <Select key={key} field={field} onChange={this.handleSingleValueChange} formData={formData} />
			case 'email':
			case 'text':
				return <TextInput key={key} field={field} onChange={this.handleFormChange} formData={formData} />
			case 'textarea':
				return <Textarea key={key} field={field} onChange={this.handleFormChange} formData={formData} />
			default:
				return null
		}
	}

	getInitialFormData(fields) {
		let initialFormData = {}

		fields.map((field, i) => {
			const { props: fieldProps } = field

			if ('repeat' !== field.type) {
				initialFormData[fieldProps.name] = fieldProps.value
			} else {
				initialFormData[field.name] = []
			}

			return null
		})

		return initialFormData
	}

	getRepeatedFieldsArray(fields) {
		let repeatedFieldsArray = []

		fields.map((field, i) => {
			if ('repeat' === field.type) {
				repeatedFieldsArray[field.name] = 0
			}

			return null
		})

		return repeatedFieldsArray
	}

	handleFormSubmit(e) {
		const { formData } = this.state
		const { form, onSubmit } = this.props

		e.preventDefault()

		// separate files from other data
		let formFiles = []

		// simple form
		if (form.fields) {
			form.fields.map((field) => {
				if ('file' === field.type && typeof formData[field.props.name] !== 'undefined') {
					formFiles[field.props.name] = formData[field.props.name]

					delete formData[field.props.name]
				}

				// repeated field
				if ('repeat' === field.type && typeof field.fields !== 'undefined' && typeof field.props !== 'undefined') {
					let repeatedFieldFiles = []

					field.fields.map((repeatedField, i) => {
						if ('file' === repeatedField.type && typeof formData[repeatedField.props.name] !== 'undefined') {
							repeatedFieldFiles[repeatedField.props.name] = formData[repeatedField.props.name]
						}

						return null;
					});

					formFiles[field.props.name] = repeatedFieldFiles
					delete formData[field.props.name]
				}

				return null
			})
		}

		// steps form
		if (form.steps) {
			form.steps.map((step, i) => {
				if (step.fields) {
					step.fields.map((field) => {
						if ('file' === field.type && typeof formData[field.props.name] !== 'undefined') {
							formFiles[field.props.name] = formData[field.props.name]

							delete formData[field.props.name]
						}

						return null
					})
				}

				return null
			})
		}

		onSubmit(formData, formFiles)
	}

	handleFormChange(e, field) {
		this.updateFormData(e.target.value, field)
	}

	handleFormFilesChange(files, field) {
		this.updateFormData(files, field)
	}

	handleSingleValueChange(value, field) {
		this.updateFormData(value, field)
	}

	handleRadioInputChange(value, field) {
		this.updateFormData(value, field)
	}

	handleRadioChange(option, field) {
		this.updateFormData(option.value, field)
	}

	handleRepeatAddClick(e, repeatedField) {
		e.preventDefault()

		const { repeatedFields } = this.state
		const fieldName = ('repeat' !== repeatedField.type) ? repeatedField.props.name : repeatedField.name
		const repeatNumber = (repeatedFields[fieldName]) ? repeatedFields[fieldName] + 1 : 1

		const newRepeatedFields = {
			...repeatedFields,
			[fieldName]: repeatNumber
		}

		this.setState((state, props) => ({
			repeatedFields: newRepeatedFields
		}))
	}

	handleRepeatRemoveClick(e, repeatedField) {
		e.preventDefault()

		const { formData, repeatedFields } = this.state
		const fieldName = ('repeat' !== repeatedField.type) ? repeatedField.props.name : repeatedField.name
		const repeatNumber = (repeatedFields[fieldName]) ? repeatedFields[fieldName] - 1 : 1
		const indexToDelete = (repeatedFields[fieldName]) ? repeatedFields[fieldName] + 1 : undefined

		if (indexToDelete) {
			const newRepeatedFields = {
				...repeatedFields,
				[fieldName]: repeatNumber
			}

			// remove data from formData
			let repeatedData = formData[fieldName]
			delete repeatedData[indexToDelete]

			const newFormData = {
				...formData,
				[fieldName]: repeatedData,
			}

			this.setState((state, props) => ({
				repeatedFields: newRepeatedFields,
				formData: newFormData,
			}))
		}
	}

	handleStepSubmit(e, currentStep, direction) {
		const { form } = this.props
		const { steps } = form

		if (steps) {
			let newCurrentStep = undefined
			steps.map((step, i) => {
				if (direction === 'next' && step.position === currentStep.position + 1) {
					newCurrentStep = step
				} else if (direction === 'previous' && step.position === currentStep.position - 1) {
					newCurrentStep = step
				}

				return null
			})

			if (newCurrentStep) {
				this.setState((state, props) => ({
					currentStep: newCurrentStep,
				}))
			}
		}
	}

	updateFormData(value, field) {
		const { formData, dependedFields } = this.state
		const { form, onChange } = this.props

		const fieldType = field.type
		const fieldName = field.props.name
		let newDependedFields = {
			...dependedFields
		}

		if ('number' === fieldType) {
			value = parseFloat(value)
		}

		if (true !== field.isRepeatedField) {

			const newFormData = {
				...formData,
				[fieldName]: value
			}

			// check if changed field is depended on other field
			let isDependedOnField = undefined

			if (form.fields) {
				form.fields.map((formField, iField) => {
					if (formField.dependsOn && formField.dependsOnValue && formField.dependsOn === fieldName) {
						isDependedOnField = formField
					}

					return null
				})
			} else if (form.steps) {
				form.steps.map((step, iStep) => {
					if (step.fields) {
						step.fields.map((stepField, iStepField) => {
							if (stepField.dependsOn && stepField.dependsOnValue && stepField.dependsOn === fieldName) {
								isDependedOnField = stepField
							}

							return null
						})
					}

					return null
				})
			}

			if (isDependedOnField) {
				const isDependedOnTrue = isDependedOnField.dependsOnValue === value

				newDependedFields = {
					...dependedFields,
					[isDependedOnField.props.name]: isDependedOnTrue
				}
			}

			if (typeof this.themeOnChangeCallback !== 'undefined') {
				this.themeOnChangeCallback(newFormData)
			}

			if (typeof onChange !== 'undefined') {
				onChange(newFormData)
			}

			this.setState((state, props) => ({
				formData: newFormData,
				dependedFields: newDependedFields
			}))
		} else {
			// get repeated field number and parent
			const { parentField, repeatNumber } = field

			const parentFieldData = formData[parentField.name]
			let thisFieldArray = {
				[fieldName]: value
			}

			let finalRepeatedFieldData = null
			if (typeof parentFieldData[repeatNumber] !== 'undefined') {
				finalRepeatedFieldData = {
					...parentFieldData[repeatNumber],
					...thisFieldArray
				}
			} else {
				finalRepeatedFieldData = {
					...thisFieldArray
				}
			}

			const newFormData = {
				...formData,
				[parentField.name]: {
					...formData[parentField.name],
					[repeatNumber]: finalRepeatedFieldData
				}
			}

			if (typeof this.themeOnChangeCallback !== 'undefined') {
				this.themeOnChangeCallback(newFormData)
			}

			if (typeof onChange !== 'undefined') {
				onChange(newFormData)
			}

			this.setState((state, props) => ({
				formData: newFormData
			}))
		}
	}

	setThemeOnChangeCallback(callbackFunc) {
		this.themeOnChangeCallback = callbackFunc
	}

	isFormValid() {
		const { formData } = this.state
		const { form } = this.props

		if (form.fields) {
			let fieldsToValidate = 0
			let validatedFieldsCount = 0

			Object.keys(formData).map((fieldName) => {
				let field = undefined
				form.fields.map((formField) => {
					if (formField.props && formField.props.name === fieldName) {
						field = formField
					}

					return null
				})

				if (field && field.validation) {
					fieldsToValidate++

					const fieldValue = (formData[fieldName]) ? formData[fieldName] : ''
					const isFieldValid = validateField(field, fieldValue, formData)

					if (isFieldValid) {
						validatedFieldsCount++
					}
				}

				return null
			})

			return fieldsToValidate === validatedFieldsCount
		}

		return false
	}

	renderField(field, key) {
		const { repeatedFields, dependedFields } = this.state
		const fieldName = ('repeat' !== field.type) ? field.props.name : field.name
		const rowClass = `FormRow ${field.type} ${fieldName}`

		const displayDependsOn = (field.dependsOnAction && field.dependsOnAction === 'show') ? true : false
		const isDependingOnConditionTrue = (dependedFields && dependedFields[fieldName]) ? dependedFields[fieldName] : false
		const hiddenClass = (displayDependsOn && !isDependingOnConditionTrue) ? 'hidden' : ''

		const displayLabel = (typeof field.displayLabel !== 'undefined') ? field.displayLabel : true

		return (
			<div className={`${rowClass} ${hiddenClass}`} key={`form-row-${key}`}>
				{'file' !== field.type && field.label && displayLabel &&
					<Label className={('repeat' === field.type) ? 'repeat' : ''} forInput={fieldName} labelText={field.label} />
				}

				{'repeat' !== field.type &&
					this.getFormInput(field, key)
				}

				{'repeat' === field.type &&
					<div className="FormRow__repeat">
						{field.fields.map((repeatField, ir) => {
							let repeatNumber = 1
							const fieldToRepeat = {
								...repeatField,
								isRepeatedField: true,
								parentField: field,
								repeatNumber
							}
							const renderArray = []

							if (fieldToRepeat.repeatedValues) {
								fieldToRepeat.repeatedValues.map((repeatedValue, iRV) => {
									const newProps = {
										...fieldToRepeat.props,
										value: repeatedValue.value,
									}

									const fieldToRepeatWithValue = {
										...fieldToRepeat,
										props: newProps
									}

									renderArray.push(<Label key={`iRV-label-${iRV}`} forInput={fieldToRepeat.props.id} labelText={fieldToRepeat.label} />)
									renderArray.push(this.getFormInput(fieldToRepeatWithValue, `iRV-${iRV}`))

									return null
								})
							}

							renderArray.push(<Label key={`label-${ir}`} forInput={fieldToRepeat.props.id} labelText={fieldToRepeat.label} />)
							renderArray.push(this.getFormInput(fieldToRepeat, ir))

							if (repeatedFields[fieldName]) {
								for (let i = 0; i < repeatedFields[fieldName]; i++) {
									repeatNumber++
									const newRepeatedField = {
										...fieldToRepeat,
										repeatNumber
									}

									renderArray.push(<Label key={`repeated-label-${i}`} forInput={newRepeatedField.props.id} labelText={newRepeatedField.label} />)
									renderArray.push(this.getFormInput(newRepeatedField, `repeated-${i}`))
								}
							}

							return renderArray
						})}

						<TextButton
							className="RepeatedField__action add"
							text='repeatedFieldActionAdd'
							onClick={(e) => this.handleRepeatAddClick(e, field)}
						/>

						<TextButton
							className="RepeatedField__action remove"
							text='repeatedFieldActionRemove'
							onClick={(e) => this.handleRepeatRemoveClick(e, field)}
						/>
					</div>
				}
			</div>
		)
	}

	render() {
		const { formData, currentStep } = this.state
		const { theme: Theme, form } = this.props

		if (typeof Theme !== 'undefined') {
			let fields = []

			// simple form
			if (form.fields) {
				form.fields.map((field, i) => {

					if ('repeat' === field.type && field.fields) {
						const repeatedFieldsArray = []

						field.fields.map((repeatedField, iRF) => {
							repeatedFieldsArray[iRF] = this.getFormInput(repeatedField)

							return null
						})

						fields[field.name] = repeatedFieldsArray
					} else {
						fields[field.props.name] = this.getFormInput(field)
					}

					return null
				})
			}

			// steps form
			if (form.steps) {
				form.steps.map((step, i) => {
					if (step.fields) {
						step.fields.map((field, i) => {
							if ('repeat' === field.type && field.fields) {
								const repeatedFieldsArray = []

								field.fields.map((repeatedField, iRF) => {
									repeatedFieldsArray[iRF] = this.getFormInput(repeatedField)

									return null
								})

								fields[field.name] = repeatedFieldsArray
							} else {
								if (typeof field.props !== 'undefined') {
									fields[field.props.name] = this.getFormInput(field)
								}
							}

							return null
						})
					}

					if (step.submitBtn) {
						const stepSubmitKey = `step-${step.position}-submit`
						fields[stepSubmitKey] = <TextButton className="SubmitButton" text={step.submitBtn} />
					}

					return null
				})
			}

			if (form.submitBtn) {
				fields['submit'] = <SubmitButton text={form.submitBtn} />
			}

			return (
				<Theme
					fields={fields}
					form={form}
					formFields={form.fields}
					formData={formData}
					formHelper={this}
					submitCallback={this.handleFormSubmit}
				/>
			)
		} else {
			const noValidate = form.novalidate && true === form.novalidate

			return (
				<div className="UbikonForm">
					<form name={form.name} onSubmit={this.handleFormSubmit} noValidate={noValidate}>
						{form.fields && form.fields.map((field, i) => {
							return this.renderField(field, i)
						})}

						{form.steps && form.steps.map((step, iStep) => {
							const stepDisplayClass = (step.position !== currentStep.position) ? '--hidden' : ''

							return (
								<div className={`UbikonForm__step${stepDisplayClass}`} key={iStep}>
									{step.label &&
										<h3 className="UbikonForm__step__title">
											{step.label}
										</h3>
									}

									{step.fields && step.fields.map((field, iField) => {
										return this.renderField(field, iField)
									})}

									<div className="UbikonForm__step__buttons">
										{iStep > 0 &&
											<TextButton className="SubmitButton PreviousButton" text='Back' onClick={(e) => this.handleStepSubmit(e, step, 'previous')} />
										}

										{step.submitBtn &&
											<TextButton className="SubmitButton NextButton" text={step.submitBtn} onClick={(e) => this.handleStepSubmit(e, step, 'next')} />
										}
									</div>
								</div>
							)
						})}

						{form.submitBtn &&
							<SubmitButton text={form.submitBtn} />
						}
					</form>
				</div>
			)
		}
	}
}

export default UbikonForm