const contactForm = {
	name: 'contact',
	submitBtn: 'propertySellerPopinSubmitBtn',
	multipart: false,
	fields: [
		{
			type: 'text',
			label: 'propertySellerContactFormName',
			props: {
				name: 'name',
				id: 'name',
				required: true,
				placeholder: 'propertySellerContactFormName',
			},
		},
		{
			type: 'email',
			label: 'propertySellerContactFormEmail',
			props: {
				name: 'email',
				id: 'email',
				required: true,
				placeholder: 'propertySellerContactFormEmail',
			},
		},
		{
			type: 'textarea',
			label: 'propertySellerContactFormMessage',
			props: {
				name: 'message',
				id: 'message',
				required: false,
				placeholder: 'propertySellerContactFormMessage',
			},
		},
	],
}

export default contactForm