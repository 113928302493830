import React from 'react'
import { withTranslation } from 'react-i18next'

export const NavSectionTitle = (props) => {
	const { t, label, className } = props
	const titleClass = `NavSection__title ${(typeof className !== 'undefined') ? className: ''}`

	return (
		<h3 className={titleClass}>
			{t(label)}
		</h3>
	)
}

export default withTranslation()(NavSectionTitle)