import React from 'react'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import landStatuses from 'constants/land-statuses'

export const LandsListItem = (props) => {
	const { t, item, itemCallback, className } = props

	/*
	const itemDescription = item.description && item.description.length > 150
			? `${item.description.substring(0, 150)}...` 
			: item.description
	*/

	const firstImage = item.photos && item.photos[0]
	const itemStyle = {
		backgroundImage: (firstImage && firstImage.contentUrl) ? `url(${media.getMediaUrl(firstImage.contentUrl)})`: null,
	}

	const pendingClass = (item.status === landStatuses.LAND_STATUS_PENDING) ? 'is-pending' : ''
	const containerClass = (className) ? className : 'ListItem--large MapList__item'

	return (
		<div className={`${containerClass} ${pendingClass}`} onClick={(e) => itemCallback(e, item)}>
			<div className="catalogue__property__bg" style={itemStyle}></div>

			<div className="catalogue__property__content">
				{item.status === landStatuses.LAND_STATUS_PENDING &&
                    <p className="all-projects__project__type">
                        <span className="all-projects__project__type__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
                                <path fill="none" fillRule="nonzero" strokeLinecap="square" d="M.5 11.495V.754l6.153 2.292L.75 5.504"/>
                            </svg>
                        </span>
                        {t('landsIndexPendingValidation')}
                    </p>
				}

				<div className="catalogue__property__header">
					{item.price &&
						<p className="catalogue__property__price">
							{`${parseInt(item.price).toLocaleString()} €`}
						</p>
					}

					{/*
					<span className="catalogue__property__like">
                        <svg className="catalogue__property__like__icon" xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                            <path fillRule="nonzero" d="M9.001 15.334l.974-.852c4.42-3.891 5.802-5.27 6.78-7.033.5-.902.745-1.765.745-2.653C17.5 2.39 15.55.5 13.05.5a4.962 4.962 0 0 0-3.674 1.652L9 2.58l-.376-.428A4.962 4.962 0 0 0 4.95.5C2.45.5.5 2.39.5 4.796c0 .892.247 1.758.752 2.665.98 1.76 2.372 3.146 6.774 7.013l.975.86z"/>
                        </svg>
					</span>
					*/}
				</div>

				<div className="catalogue__property__description">
					<div className="catalogue__property__description__wrapper">
						<p className="catalogue__property__title">
							{item.name}
						</p>

						<div className="catalogue__property__infos ListItem__infos">
							<LandInfosIcons entity={item} displayZipcode={false} />
						</div>
					</div>
				</div>
			</div>
		</div>
	) 
}

export default withTranslation()(LandsListItem)