import React from 'react'
import { Switch, Route } from 'react-router-dom'

import routesList from 'routing/routes-list'
import PrivateRoute from 'routing/PrivateRoute'
import userRoles from 'constants/user-roles'

import AdminIndex from 'pages/admin/AdminIndex'
import CollectionsIndex from 'pages/collections/CollectionsIndex'
import CollectionDetail from 'pages/collections/CollectionDetail'
import CreateLand from 'pages/lands/CreateLand'
import CreateProject from 'pages/projects/CreateProject'
import Index from 'pages/index/Index'
import LandDetail from 'pages/lands/LandDetail'
import LandsIndex from 'pages/lands/LandsIndex'
import Login from 'pages/security/Login'
import Messenger from 'pages/chat/Messenger'
import OrganizationEdit from 'pages/organizations/OrganizationEdit'
import ProjectDetail from 'pages/projects/ProjectDetail'
import ProjectIndex from 'pages/projects/ProjectIndex'
import PropertiesIndex from 'pages/properties/PropertiesIndex'
import PropertyDetail from 'pages/properties/PropertyDetail'
import UserProfile from 'pages/user/UserProfile'
import TextPage from 'pages/textpage/TextPage'

export default function Routes(props) {
	return (
		<Switch>
			<PrivateRoute exact path={routesList.index} component={Index} role={userRoles.ROLE_USER} />
			<PrivateRoute exact path={routesList.user_profile} component={UserProfile} role={userRoles.ROLE_USER} />
			<PrivateRoute path={routesList.projects.detail.index} component={ProjectDetail} role={userRoles.ROLE_USER} />
			<PrivateRoute exact path={routesList.projects.index} component={ProjectIndex} role={userRoles.ROLE_USER} />
			<PrivateRoute exact path={routesList.projects.new} component={CreateProject} role={userRoles.ROLE_USER} />
			<PrivateRoute path={routesList.lands.detail.index} component={LandDetail} role={userRoles.ROLE_USER} />
			<PrivateRoute exact path={routesList.lands.index} component={LandsIndex} role={userRoles.ROLE_SUPER_ADMIN} />
			<PrivateRoute exact path={routesList.lands.new} component={CreateLand} role={userRoles.ROLE_SUPER_ADMIN} />
			<PrivateRoute exact path={routesList.organizations.user.edit} component={OrganizationEdit} role={userRoles.ROLE_USER} />
			<PrivateRoute exact path={routesList.properties.index} component={PropertiesIndex} role={userRoles.ROLE_USER} />
			<PrivateRoute path={routesList.properties.detail.index} component={PropertyDetail} role={userRoles.ROLE_USER} />
			<PrivateRoute exact path={routesList.admin} component={AdminIndex} role={userRoles.ROLE_SUPER_ADMIN} />
			<PrivateRoute exact path={routesList.collections.index} component={CollectionsIndex} role={userRoles.ROLE_USER} />
			<PrivateRoute path={routesList.collections.detail.index} component={CollectionDetail} role={userRoles.ROLE_USER} />
			<PrivateRoute path={routesList.messenger} component={Messenger} role={userRoles.ROLE_USER} />
			<Route exact path={routesList.login} component={Login} />
			<Route exact path={routesList.contact_page} render={(props) => <TextPage {...props} textType="contact" />} />
			<Route exact path={routesList.legal_notice} render={(props) => <TextPage {...props} textType="legal" />} />
		</Switch>
	)
}