import React from 'react'

import {
	SubmitButton
} from 'components/forms/inputs'
import PropertyFormFieldsGroup from 'components/properties/PropertyFormFieldsGroup'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'

class PropertyForm extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			propertyFormData: undefined,
			currentStep: 1,
			totalSteps: (props.form && props.form.steps) ? props.form.steps.length : 1,
			displayAddress: false,
			displayPropertyItemsCount: false,
			nextBtnEnabled: false,
		}

		// bind methods
		this.renderFormField = this.renderFormField.bind(this)
		this.handleFormChange = this.handleFormChange.bind(this)
		this.handleNextStepBtnClick = this.handleNextStepBtnClick.bind(this)
		this.handlePreviousStepBtnClick = this.handlePreviousStepBtnClick.bind(this)

		// set theme callback
		props.formHelper.setThemeOnChangeCallback(this.handleFormChange)
	}

	handleFormChange(formData) {
		const { currentStep } = this.state

		//console.log('formData', formData)

		if (formData.propertyType) {
			this.setState((state, props) => ({
				displayAddress: true,
				displayPropertyItemsCount: formData.propertyType === 'multiple-appartments',
			}))
		}

		if (
			currentStep === 1
			&& formData.name
			&& formData.propertyType
			&& formData.address
			&& formData.zipcode
			&& formData.city
		) {
			this.setState((state, props) => ({
				nextBtnEnabled: true,
			}))
		}

		this.setState((state, props) => ({
			propertyFormData: formData,
		}))
	}

	handleNextStepBtnClick(nextStep) {
		this.setState((state, props) => ({
			currentStep: nextStep.position,
		}))
	}

	handlePreviousStepBtnClick() {
		const { currentStep } = this.state
		const { form } = this.props

		const previousStepIndex = ((currentStep - 2) > 0) ? currentStep - 2 : 0
		const step = (form.steps && form.steps[previousStepIndex]) ? form.steps[previousStepIndex] : undefined

		if (step) {
			this.setState((state, props) => ({
				currentStep: step.position,
			}))
		}
	}

	renderFormField(field, fieldName) {
		const { formData, formHelper } = this.props

		let newProps = {
			...field.props
		}

		if (formData && formData[fieldName]) {
			newProps['value'] = formData[fieldName]
		}

		const newField = {
			...field,
			name: fieldName,
			props: newProps,
		}

		const fieldKey = `key-${fieldName}`

		return formHelper.renderField(newField, fieldKey)
	}

	render() {
		const {
			propertyFormData,
			currentStep,
			totalSteps,
			displayAddress,
			displayPropertyItemsCount,
			nextBtnEnabled,
		} = this.state

		const {
			form,
			fields,
			submitCallback,
			formHelper,
		} = this.props

		const { onCancelCallback } = formHelper.props

		if (!fields) {
			return <Spinner />
		}

		const stepIndex = currentStep - 1
		const step = (form.steps && form.steps[stepIndex]) ? form.steps[stepIndex] : undefined
		const nextStep = (step && form.steps[stepIndex + 1]) ? form.steps[stepIndex + 1] : undefined

		let fieldDisplay = []
		let propertyItemFieldsArray = []
		let propertyItemsBoxesCount = 1

		if (step && 'type-location' === step.name) {
			fieldDisplay = [
				'name', 'propertyType',
			]

			if (displayPropertyItemsCount) {
				fieldDisplay.push('propertyItemsCount')
			}

			if (displayAddress) {
				fieldDisplay.push('address', 'zipcode', 'city')
			}
		} else if (step && 'details' === step.name) {
			const propertyItemFields = fields['propertyItems']

			// set number of property items
			if (displayPropertyItemsCount && propertyFormData.propertyItemsCount > 0) {
				propertyItemsBoxesCount = propertyFormData.propertyItemsCount

				for (let i = 1; i <= propertyItemsBoxesCount; i++) {
					propertyItemFieldsArray.push(propertyItemFields)
				}
			} else {
				propertyItemFieldsArray.push(propertyItemFields)
			}
		} else if (step && 'sales-agent' === step.name) {
			fieldDisplay = [
				'sellers'
			]
		}

		const nextBtnClass = (!nextBtnEnabled) ? ' disabled' : ''

		return (
			<div className="PropertyForm">
				<form name="property-form" onSubmit={submitCallback}>
					<div className="PropertyForm__left">
						<div className="PropertyForm__progress">
							<div className="progress__count">
								<div className="progress__blue"></div>
								<span className="progress__label">
									{currentStep} of {totalSteps}
								</span>
							</div>

							{step &&
								<p className="progress__title">{step.label}</p>
							}

							{nextStep &&
								<p className="progress__subtitle">Next: {nextStep.label}</p>
							}
						</div>

						<div className="progress__nav">
							{step.submitBtn && nextStep &&
								<TextButton
									text={step.submitBtn}
									className={`BlueButton upper ${nextBtnClass}`}
									onClick={() => this.handleNextStepBtnClick(nextStep)}
								/>
							}

							{step.position === totalSteps &&
								<SubmitButton
									text={form.submitBtn}
									className="BlueButton upper"
								/>
							}

							{currentStep > 1 &&
								<TextButton
									text='Previous'
									className="WhiteButton upper"
									onClick={() => this.handlePreviousStepBtnClick()}
								/>
							}

							<TextButton
								text='Cancel'
								className="progress__cancel upper"
								onClick={onCancelCallback}
							/>
						</div>
					</div>

					<div className="PropertyForm__right">
						{fieldDisplay.map((fieldName, i) => {
							const fieldToDisplay = fields[fieldName]

							if (!fieldToDisplay) {
								return null
							}

							const rowClass = `PropertyForm__row ${fieldName}`

							return (
								<div className={rowClass} key={i}>
									{fieldToDisplay.props &&
										this.renderFormField(fieldToDisplay.props.field, fieldName)
									}
								</div>
							)
						})}

						{step && step.name === 'details' && propertyItemFieldsArray.map((fieldGroup, iFG) => {
							const appartmentNbr = iFG + 1
							const isGroupActive = appartmentNbr === 1

							return (
								<PropertyFormFieldsGroup
									key={iFG}
									fieldGroup={fieldGroup}
									propertyForm={this}
									appartmentNbr={appartmentNbr}
									parentFieldName='propertyItems'
									isActive={isGroupActive}
								/>
							)
						})}
					</div>
				</form>
			</div>
		)
	}
}

export default PropertyForm