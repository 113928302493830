import React from 'react'
import { withTranslation } from 'react-i18next'

export const GreyBlockActionButton = (props) => {
	const { t, text, onClick } = props

	return (
		<button className="grey-block__action add-partner__btn" onClick={onClick}>
            <span className="grey-block__action__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                    <g fill="#737373" fillRule="evenodd">
                        <path d="M5.01 10.998h1V0h-1z"/>
                        <path d="M0 6.003h10.998v-1H0z"/>
                    </g>
                </svg>     
            </span>

			<p className="grey-block__action__text add-partner__btn__text">
				{t(text)}
			</p>
		</button>
	)
}

export default withTranslation()(GreyBlockActionButton)