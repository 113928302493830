import React from 'react'
import { withTranslation } from 'react-i18next'
import InputBoxDoneTyping from 'react-input-box-done-typing'

import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'

class SearchBar extends React.Component {
	constructor(props) {
		super(props)

		const initialValue = (props.initialValue) ? props.initialValue : ''

		// set state
		this.state = {
			searchQuery: initialValue,
			reloadInputBox: false,
		}

		// bind methods
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
		this.handleSearchChange = this.handleSearchChange.bind(this)
	}

	handleSearchSubmit(value) {
		const { searchQuery } = this.state
		const { onSubmit } = this.props

		if (onSubmit) {
			onSubmit(undefined, 'search', searchQuery)
		}
	}

	handleSearchChange(value, isCancel) {
		const { onChange, onSubmit } = this.props
		if (onChange) {
			onChange(undefined, 'search', value)
		}

		let refresh = false
		if (true === isCancel && onSubmit) {
			onSubmit(undefined, 'search', value)
			refresh = true
		}

		if (true === refresh) {
			setTimeout(() => {
				this.setState((state, props) => ({
					reloadInputBox: false,
				}))
			}, 500)
		}

		this.setState((state, props) => ({
			searchQuery: value,
			reloadInputBox: refresh,
		}))
	}

	render() {
		const { searchQuery, reloadInputBox } = this.state
		const {
			t,
			placeholder,
			noBorderClass,
			inputClassName,
			className,
		} = this.props

		let barClass = 'SearchBar'
		if (className) {
			barClass = `${barClass} ${className}`
		}

		if (noBorderClass) {
			barClass = `${barClass} ${noBorderClass}`
		}

		const inputClass = (inputClassName) ? inputClassName : 'default catalogue__header__search__input'

		return (
			<div className={barClass}>
				<form onSubmit={(e) => this.handleSearchSubmit(e)}>
					<ImageButton
						image={icons.cross}
						altText='Cancel search'
						className="Search__cancel"
						onClick={() => this.handleSearchChange('', true)}
					/>

					<InputBoxDoneTyping
						key={reloadInputBox}
						className={inputClass}
						placeholder={t(placeholder)}
						maxLength={20}
						defaultValue={searchQuery}
						autoComplete="off"
						onChange={(value) => { this.handleSearchChange(value, false) }}
						doneTyping={(value) => { this.handleSearchSubmit(value) }}
						doneTypingInterval={800}
					/>

					{/*
					<ImageButton
						image={icons.search}
						altText='Search'
						className="catalogue__header__search__icon"
						onClick={(e) => this.handleSearchSubmit(e)}
					/>
					*/}
				</form>
			</div>
		)
	}
}

export default withTranslation()(SearchBar)