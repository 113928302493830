import React from 'react'

const getPerformanceWidget = (title, performanceIndex) => {
	if (!performanceIndex || performanceIndex.length <= 0) {
		return null
	}

	return (
		<div className="PropertyPerformance__content">
			<p className="PropertyPerformance__title">{title}</p>
			<p className="PropertyPerformance__index">{performanceIndex}</p>
		</div>
	)
}

export const PropertyPerformance = (props) => {
	const { propertyItem } = props

	if (!propertyItem.energyPerformance && !propertyItem.greenhouseIndex) {
		return null
	}

	return (
		<div className="PropertyPerformance">
			{getPerformanceWidget('Energy Performance', propertyItem.energyPerformance)}
			{getPerformanceWidget('Greenhouse Index', propertyItem.greenhouseIndex)}
		</div>
	)
}

export default PropertyPerformance