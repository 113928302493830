import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'

import { callApi, resetApiResponse } from 'actions/api'
import { displayMessaging } from 'actions/messaging'
import { resetTemporaryData } from 'actions/temporary-data'
import { collapseLeftMenu } from 'actions/left-menu'
import { setFilters } from 'actions/filters'
import apiEndpoints from 'constants/api-endpoints'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import AccessControl from 'auth/access-control'
import BenefitsList from 'components/benefits/BenefitsList'
import Carousel from 'components/carousel'
import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import LandDetailButtons from 'components/lands/LandDetailButtons'
import LandFormTheme from 'components/lands/LandFormTheme'
import landStatuses from 'constants/land-statuses'
import LandsFilters from 'components/lands/LandsFilters'
import PartnersListItem from 'components/partners/PartnersListItem'
import Popin from 'components/layout/Popin'
import priceFormat from 'utils/priceFormat'
import routesList from 'routing/routes-list'
import Spinner from 'components/spinner/Spinner'
import UbikonForm from 'components/forms/UbikonForm'
import userRoles from 'constants/user-roles'

class LandDetail extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			land: undefined,
			buckets: undefined,
			projectRequestForm: undefined,
			openBucketPopin: false,
			openUpdateLandPopin: false,
			carouselFullscreenImage: undefined,
			landForm: undefined,
			isUpdatingLand: false,
		}

		// bind methods
		this.handleValidateLand = this.handleValidateLand.bind(this)
		this.handleCreateProject = this.handleCreateProject.bind(this)
		this.handleBackBtnClick = this.handleBackBtnClick.bind(this)
		this.handleCarouselFullscreen = this.handleCarouselFullscreen.bind(this)
		this.handleProjectRequestSubmit = this.handleProjectRequestSubmit.bind(this)
		this.handleUpdateLandClick = this.handleUpdateLandClick.bind(this)
		this.handleLandUpdateSubmit = this.handleLandUpdateSubmit.bind(this)
		this.handleClosePopin = this.handleClosePopin.bind(this)
		this.handleLandsFilters = this.handleLandsFilters.bind(this)

		// set private properties
		this.accessControl = new AccessControl()
		this.endpointBuilder = new ApiEndpointBuilder()
		this.landId = this.props.match.params.landId
		this.temporaryDataKey = `tmp-land-${this.landId}`
	}

	componentDidMount() {
		const { temporaryData } = this.props

		this.props.collapseLeftMenu(true)

		if (typeof temporaryData[this.temporaryDataKey] !== 'undefined') {
			this.setState((state, props) => ({
				land: temporaryData[this.temporaryDataKey]
			}))
		}

		// hide messaging
		this.props.displayMessaging(false)

		// remove temporary data
		this.props.resetTemporaryData(this.temporaryDataKey)

		// get land
		const landEndpoint = this.endpointBuilder.getEndpoint('land', {
			id: this.landId,
		})
		this.props.callApi(landEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props

		if (apiResponse) {
			const getLandEndpoint = `${apiEndpoints.GET_LAND}${this.landId}`
			const validateEndpoint = apiEndpoints.LAND_VALIDATE.replace(':landId', this.landId)
			const getProjectRequestFormEndpoint = this.endpointBuilder.getEndpoint('get_project_request_form', {
				id: this.landId,
				projectRequestType: 'land',
			})
			const projectRequestsEndpoint = this.endpointBuilder.getEndpoint('project_requests')
			const landFormEndpoint = this.getLandFormEndpoint()
			const landsEndpoint = this.endpointBuilder.getEndpoint('lands')

			this.props.resetApiResponse()

			if (apiResponseEndpoint === getLandEndpoint) {
				// set current land
				this.setState((state, props) => ({
					land: apiResponse
				}))
			}

			if (apiResponseEndpoint === validateEndpoint) {
				// refresh page
				if (apiResponse.success && true === apiResponse.success) {
					window.location.reload()
				}
			}

			if (apiResponseEndpoint === getProjectRequestFormEndpoint) {
				this.setState((state, props) => ({
					projectRequestForm: apiResponse.model
				}))
			}

			if (apiResponseEndpoint === projectRequestsEndpoint) {
				// refresh land
				this.props.callApi(getLandEndpoint, 'get')

				this.setState((state, props) => ({
					land: undefined,
					projectRequestForm: undefined,
					openBucketPopin: false,
				}))
			}

			// did get land form
			if (apiResponseEndpoint === landFormEndpoint) {
				this.setState((state, props) => ({
					openUpdateLandPopin: true,
					landForm: apiResponse.model,
				}))
			}

			// did post land update
			if (apiResponseEndpoint === landsEndpoint) {
				// reset land
				this.props.callApi(getLandEndpoint, 'get')

				this.setState((state, props) => ({
					isUpdatingLand: true,
					openUpdateLandPopin: false,
					landForm: undefined,
				}))
			}
		}
	}

	componentWillUnmount() {
		// show messaging
		this.props.displayMessaging(true)
	}

	getLandFormEndpoint() {
		const { land } = this.state
		if (land) {
			return `${this.endpointBuilder.getEndpoint('get_land_form')}?landId=${land.id}`
		}

		return undefined
	}

	handleValidateLand(land) {
		const validateEndpoint = apiEndpoints.LAND_VALIDATE.replace(':landId', land.id)
		this.props.callApi(validateEndpoint, 'post')
	}

	handleCreateProject(land) {
		const getProjectRequestFormEndpoint = this.endpointBuilder.getEndpoint('get_project_request_form', {
			id: land.id,
			projectRequestType: 'land',
		})

		// get project request form
		this.props.callApi(getProjectRequestFormEndpoint, 'get')

		// close popin
		this.setState((state, props) => ({
			openBucketPopin: true
		}))
	}

	handleBackBtnClick() {
		const redirectPath = reverse(routesList.lands.index)
		this.props.history.push(redirectPath)
	}

	handleCarouselFullscreen(fullscreenImage) {
		this.setState((state, props) => ({
			carouselFullscreenImage: fullscreenImage
		}))
	}

	handleProjectRequestSubmit(formData) {
		const projectRequestsEndpoint = this.endpointBuilder.getEndpoint('project_requests')

		this.props.callApi(projectRequestsEndpoint, 'post', {
			body: JSON.stringify(formData)
		})
	}

	handleUpdateLandClick() {
		const landFormEndpoint = this.getLandFormEndpoint()
		this.props.callApi(landFormEndpoint)

		this.setState((state, props) => ({
			openUpdateLandPopin: true,
		}))
	}

	handleLandUpdateSubmit(formData, formFiles) {
		const { land } = this.state
		const landData = {
			...formData,
			id: land.id,
			files: formFiles.photos,
		}

		const landsEndpoint = this.endpointBuilder.getEndpoint('lands')
		this.props.callApi(landsEndpoint, 'post', {
			body: JSON.stringify(landData),
		})

		this.setState((state, props) => ({
			isUpdatingLand: true,
			landForm: undefined,
		}))
	}

	handleClosePopin() {
		this.setState((state, props) => ({
			openBucketPopin: false,
			openUpdateLandPopin: false,
			landForm: undefined,
		}))
	}

	handleLandsFilters(filters) {
		this.props.setFilters('lands', filters)

		const redirectPath = reverse(routesList.lands.index)
		this.props.history.push(redirectPath)
	}

	render() {
		const {
			land,
			openBucketPopin,
			openUpdateLandPopin,
			carouselFullscreenImage,
			projectRequestForm,
			landForm,
			isUpdatingLand,
		} = this.state

		const isLandOwner = true
		const isAdmin = this.accessControl.hasRole(userRoles.ROLE_SUPER_ADMIN)
		const displayValidate = (land && land.status === landStatuses.LAND_STATUS_PENDING) ? true: false
		const carouselClass = `LandDetail__carousel${(carouselFullscreenImage) ? '--fullscreen' : ''}`
		const landPrice = (land) ? priceFormat(land.price) : undefined

		console.log('landPrice', landPrice)

		return (
			<div className="LandDetail">
				<LandsFilters filterCallback={this.handleLandsFilters} />

				<div className="LandDetail__content">
					<div className="Back__button">
						<ImageButton
							image={icons.arrow}
							altText='Back to Lands List'
							onClick={this.handleBackBtnClick}
						/>
					</div>

					{!land &&
						<Spinner />
					}

					{land &&
						<div className="LandDetail__wrapper">
							<div className="LandDetail__infos">
								{land.owner &&
									<div className="LandDetail__owner">
										<div className="PartnersList">
											<div className="PartnersList__partners">
												<PartnersListItem
													userProp={land.owner}
													widget='file_upload'
													trimUsername={false}
												/>
											</div>
										</div>
									</div>
								}

								<h1 className="big">
									{land.name}
								</h1>

								{landPrice &&
									<p className="LandDetail__price big blue">{landPrice}</p>
								}

								<LandInfosIcons
									entity={land}
									displayZipcode={true}
									fullAddress={true}
								/>

								<div className={`${carouselClass} is-mobile`}>
									<Carousel
										images={land.photos}
										allowFullscreen={true}
										onFullscreenCallback={this.handleCarouselFullscreen}
									/>
								</div>

								<div className="LandDetail__description medium">
									{land.description}
								</div>

								{land.benefits &&
									<BenefitsList benefits={land.benefits} />
								}

								<LandDetailButtons
									land={land}
									isLandOwner={isLandOwner}
									isAdmin={isAdmin}
									validateCallback={this.handleValidateLand}
									createProjectCallback={this.handleCreateProject}
									validateLabel='landDetailBtnValidate'
									createProjectLabel='landDetailBtnStartProject'
									displayValidate={displayValidate}
									updateLandCallback={this.handleUpdateLandClick}
								/>
							</div>

							<div className={carouselClass}>
								<Carousel
									images={land.photos}
									allowFullscreen={true}
									onFullscreenCallback={this.handleCarouselFullscreen}
								/>
							</div>
						</div>
					}
				</div>

				{openBucketPopin &&
					<Popin isOpened={openBucketPopin} transTitle='projectRequestPopinTitle' transTitleKey='projectRequestPopinTitle' onClose={this.handleClosePopin}>
						{!projectRequestForm &&
							<Spinner />
						}

						{projectRequestForm &&
							<UbikonForm form={projectRequestForm} onSubmit={this.handleProjectRequestSubmit} />
						}
					</Popin>
				}

				{openUpdateLandPopin &&
					<Popin isOpened={openUpdateLandPopin} transTitle='landFormPopinTitle' transTitleKey='landFormPopinTitle' className="Land__popin" onClose={this.handleClosePopin}>
						{(!landForm || isUpdatingLand) &&
							<Spinner />
						}

						{landForm &&
							<UbikonForm
								form={landForm}
								theme={LandFormTheme}
								onSubmit={this.handleLandUpdateSubmit}
							/>
						}
					</Popin>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api
	const { ...temporaryData } = state.temporary_data

	return { apiResponse, apiResponseEndpoint, temporaryData }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
		resetTemporaryData: (temporaryProjectKey) => dispatch(resetTemporaryData(temporaryProjectKey)),
		displayMessaging: (booleanValue) => dispatch(displayMessaging(booleanValue)),
		collapseLeftMenu: (displayBool) => dispatch(collapseLeftMenu(displayBool)),
		setFilters: (filterType, filterValues) => dispatch(setFilters(filterType, filterValues)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandDetail)