import React from 'react'
import { connect } from 'react-redux'
import { reverse } from 'named-urls'
import { withRouter } from 'react-router'

import { callApi, resetApiResponse } from 'actions/api'
import { media } from 'media'
import routesList from 'routing/routes-list'
import ApiEndpointBuilder from 'api/ApiEndpointBuilder'
import OfferDirectoryBreadcrumb from 'components/offers/OfferDirectoryBreadcrumb'
import TextButton from 'components/buttons/TextButton'
import Spinner from 'components/spinner/Spinner'

class OfferIndexList extends React.Component {
	constructor(props) {
		super(props)

		// set state
		this.state = {
			offers: undefined,
			isLoadingOffers: true,
		}

		this.endpointBuilder = new ApiEndpointBuilder()
	}

	componentDidMount() {
		const offersEndpoint = this.getOffersEndpoint()
		this.props.callApi(offersEndpoint, 'get')
	}

	componentDidUpdate(prevProps, prevState) {
		const { apiResponse, apiResponseEndpoint } = this.props
		const offersEndpoint = this.getOffersEndpoint()

		if (apiResponse) {
			this.props.resetApiResponse()

			if (apiResponseEndpoint === offersEndpoint) {
				this.setState((state, props) => ({
					offers: apiResponse,
					isLoadingOffers: false,
				}))
			}
		}
	}

	getOffersEndpoint() {
		return this.endpointBuilder.getEndpoint('offers_index')
	}

	handleViewProject(offer) {
		const { project, projectPipelineSectionId } = offer

		// redirect to project detail
		let path = reverse(routesList.projects.detail.index, {
			projectId: project.id,
		})

		// add offer id
		path = `${path}?pps=${projectPipelineSectionId}&o=${offer.id}`

		this.props.history.push(path)
	}

	render() {
		const { offers, isLoadingOffers } = this.state

		return (
			<div className="OfferIndexList">

				{isLoadingOffers &&
					<Spinner />
				}

				{!isLoadingOffers && offers && offers.created &&
					<div className="Offer__list">
						<h2 className="Offer__list__title">
							Offers you created
						</h2>

						<OfferDirectoryBreadcrumb widget='offers-index-created' />

						{offers.created.length > 0 && offers.created.map((offer, iO) => {
							const { createdAt } = offer
							const offerDate = new Date(createdAt.date)

							return (
								<div className="folder__line" key={iO} onClick={() => this.handleViewProject(offer)}>
									<div className="folder__name">
										{offer.title}
									</div>

									<div className="folder__date">
										{offerDate.toLocaleDateString()}
									</div>

									<div className="folder__pending">
										{offer.project.name}
									</div>

									<div className="folder__actions">
										<TextButton
											text='view offer'
											className="BlueBorderButton upper"
											onClick={() => this.handleViewProject(offer)}
										/>
									</div>
								</div>
							)
						})}

						{offers.created.length <= 0 &&
							<div className="folder__line is-empty">
								<p>No offer yet</p>
							</div>
						}
					</div>
				}

				{!isLoadingOffers && offers && offers.requests &&
					<div className="Offer__list">
						<h2 className="Offer__list__title">
							Offers requests
						</h2>

						<OfferDirectoryBreadcrumb widget='offers-index-requested' />

						{offers.requests.length > 0 && offers.requests.map((offer, iOR) => {
							const { createdAt } = offer
							const offerDate = new Date(createdAt.date)

							let createdByAvatar = undefined
							if (offer.createdBy && offer.createdBy.avatar) {
								createdByAvatar = {
									backgroundImage: (offer.createdBy.avatar) ? `url(${media.getMediaUrl(offer.createdBy.avatar)})`: null,
								}
							}

							return (
								<div className="folder__line" key={iOR}>
									<div className="folder__name">
										{offer.title}
									</div>

									<div className="folder__date">
										{offerDate.toLocaleDateString()}
									</div>

									<div className="folder__pending">
										{offer.project.name}
									</div>

									<div className="folder__interested">
										<div className="folder__profile" style={createdByAvatar}></div>
									</div>

									<div className="folder__actions">
										<TextButton
											text='view offer request'
											className="BlueBorderButton upper"
											onClick={() => this.handleViewProject(offer)}
										/>
									</div>
								</div>
							)
						})}

						{offers.requests.length <= 0 &&
							<div className="folder__line is-empty">
								<p>No offer request yet</p>
							</div>
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { apiResponse, apiResponseEndpoint } = state.api

	return { apiResponse, apiResponseEndpoint }
}

const mapDispatchToProps = dispatch => {
	return {
		callApi: (endpoint, method, options) => dispatch(callApi(endpoint, method, options)),
		resetApiResponse: () => dispatch(resetApiResponse()),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferIndexList))