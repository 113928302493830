import React from 'react'

import { media } from 'media'
import icons from 'assets/svg'
import MessageBottom from 'components/chat/MessageBottom'
import ImageButton from 'components/buttons/ImageButton'

const getFileExtension = (filename) => {
  return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
}

const isImage = (extension) => {
	const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg']

	return imageExtensions.indexOf(extension) > -1
}

export const MessageContent = (props) => {
	const {
		message,
		user,
		displayMessageDate,
		downloadFileCallback,
		filePreviewCallback,
	} = props

	const hasFiles = message.files && message.files.length > 0

	return (
		<div className="Message__content">
			{hasFiles && message.files.map((file, i) => {
				const fileExtension = getFileExtension(file.filePath)
				const isImagePreview = isImage(fileExtension)
				let previewStyle = (isImagePreview) ? {
						backgroundImage: (file.contentUrl) ? `url("${media.getMediaUrl(file.contentUrl)}")`: null,
					}: null

				const previewClass = (!isImagePreview) ? 'is-not-image' : ''

				return (
					<div key={i} className="Message__file">
						<div className="Message__file__actions">
							<ImageButton
								image={icons.download}
								altText='Download File'
								onClick={() => downloadFileCallback(file)}
							/>
						</div>

						<div
							className={`Message__file__preview ${previewClass}`}
							style={previewStyle}
							onClick={() => filePreviewCallback(file)}
						>
							{!isImagePreview &&
								<p>{file.filePath}</p>
							}
						</div>
					</div>
				)
			})}

			{message.message && null !== message.message && message.message.length > 0 &&
				<p className={`Content ${(hasFiles) ? 'margin-top': ''}`}>{message.message}</p>
			}

			{true === displayMessageDate &&
				<MessageBottom message={message} user={user} />
			}
		</div>
	)
}

export default MessageContent