import React from 'react'

import Message from 'components/chat/Message'
import Spinner from 'components/spinner/Spinner'

class Conversation extends React.Component {
	constructor(props) {
		super(props)

		// bind methods
		this.scrollToBottom = this.scrollToBottom.bind(this)
	}

	componentDidMount() {
		const { conversation, forceScroll } = this.props

		if (true === forceScroll || (conversation.messages && conversation.messages.length > 0)) {
			this.scrollToBottom()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { conversation, forceScroll } = this.props
		const { conversation: prevConversation } = prevProps

		if (true === forceScroll || (conversation.messages && prevConversation.messages && conversation.messages.length !== prevConversation.messages.length)) {
			this.scrollToBottom()
		}
	}

	scrollToBottom(slow) {
		if (true !== slow) {
			this.messagesEnd.scrollIntoView({ behavior: 'instant' })
		} else {
			this.messagesEnd.scrollIntoView()
		}
	}

	render() {
		const {
			conversation,
			isLoadingMessage,
			currentUserId,
			downloadFileCallback,
			filePreviewCallback,
		} = this.props

		let previousMessage = undefined

		return (
			<div className="Conversation">
				{conversation.messages && conversation.messages.map((message, i) => {
					const { createdBy: user } = message
					let messagePosition = true
					let displayMessageDate = true

					if (parseInt(user.id) === parseInt(currentUserId)) {
						messagePosition = false

						if (typeof previousMessage !== 'undefined') {
							// check if previous message was sent more than 10 minutes after current message
							const currentMessageDate = (message.createdAt.date) ? message.createdAt.date: message.createdAt
							const previousMessageDate = (previousMessage.createdAt.date) ? previousMessage.createdAt.date: previousMessage.createdAt
							
							const diff = Math.abs(
								new Date(currentMessageDate) - new Date(previousMessageDate)
							)

							const minutesDiff = Math.floor((diff / 1000) / 60)

							if (minutesDiff <= 10) {
								displayMessageDate = false
							}
						}
					}

					previousMessage = message

					return <Message
						key={i}
						message={message}
						user={user}
						position={messagePosition}
						displayMessageDate={displayMessageDate}
						downloadFileCallback={downloadFileCallback}
						filePreviewCallback={filePreviewCallback}
					/>
				})}

				<div ref={el => { this.messagesEnd = el; }} />

				{true === isLoadingMessage &&
					<Spinner />
				}
			</div>
		)
	}
}

export default Conversation