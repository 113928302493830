import React from 'react'

export const MapList = (props) => {
	const {
		items,
		ItemComponent,
		itemCallback,
		className,
		itemClassName,
		collectionClickCallback,
	} = props

	const mapClass = (className) ? className : 'MapList'

	return (
		<div className={mapClass}>
			{items && ItemComponent && items.map((item, i) => {
				return <ItemComponent
					key={i}
					item={item}
					itemCallback={itemCallback}
					className={itemClassName}
					collectionClickCallback={collectionClickCallback}
				/>
			})}
		</div>
	)
}

export default MapList