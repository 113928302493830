const offerQuoteForm = {
	name: 'offer-quote',
	submitBtn: 'Create Quote',
	multipart: true,
	fields: [
		{
			type: 'number',
			label: 'Amount',
			props: {
				name: 'amount',
				id: 'amount',
				required: true,
				placeholder: 'Amount',
			},
		},
		{
			type: 'textarea',
			label: 'Description',
			props: {
				name: 'description',
				id: 'description',
				required: false,
				placeholder: 'Description',
			},
		},
	],
}

export default offerQuoteForm