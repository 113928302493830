import React from 'react'
import { withTranslation } from 'react-i18next'

import {
	SubmitButton
} from 'components/forms/inputs'
import LandInfosIcons from 'components/lands/LandInfosIcons'
import Spinner from 'components/spinner/Spinner'
import TextButton from 'components/buttons/TextButton'

class ContactSellerForm extends React.Component {
	renderFormField(field, fieldName) {
		const { formData, formHelper } = this.props

		let newProps = {
			...field.props
		}

		if (formData && formData[fieldName]) {
			newProps['value'] = formData[fieldName]
		}

		const newField = {
			...field,
			name: fieldName,
			props: newProps,
		}

		const fieldKey = `key-${fieldName}`

		return (
			formHelper.renderField(newField, fieldKey)
		)
	}

	render() {
		const {
			t,
			formFields,
			formHelper,
			submitCallback,
		} = this.props

		const {
			closeCallback,
			sellerWidget,
			propertyItem,
			propertyInfosEntity,
			didContactSeller,
			isContactingSeller,
		} = formHelper.props

		return (
			<div className="ContactSellerForm ContactForm">
				<div className="Col__left">
					<h3>{t('propertySellerPopinSubtitle')}</h3>
					{sellerWidget}

					<h3>{t('propertySellerPopinProperty')}</h3>
					<p className="Property__detail">{propertyItem.property.name}</p>

					{propertyInfosEntity &&
						<LandInfosIcons entity={propertyInfosEntity} />
					}

					<p className="Property__detail price">{parseInt(propertyItem.price).toLocaleString()}&nbsp;€</p>
				</div>

				<div className="Col__right">
					{isContactingSeller && !didContactSeller &&
						<Spinner />
					}

					{!isContactingSeller && didContactSeller &&
						<div className="Contact__success">
							<p>{t('propertySellerPopinSuccess1')}<br />{t('propertySellerPopinSuccess2')}</p>

							<TextButton
								text='propertySellerPopinClose'
								onClick={closeCallback}
							/>
						</div>
					}

					{!isContactingSeller && !didContactSeller &&
						<form name="contact-property-seller" onSubmit={submitCallback}>
							{this.renderFormField(formFields[0], 'name')}
							{this.renderFormField(formFields[1], 'email')}
							{this.renderFormField(formFields[2], 'message')}

							<SubmitButton className="upper" text='propertySellerPopinSubmitBtn' />
							<TextButton text='propertySellerPopinCancelBtn' onClick={closeCallback} />
						</form>
					}
				</div>
			</div>
		)
	}
}

export default withTranslation()(ContactSellerForm)