import React from 'react'

class FilterCheckbox extends React.Component {
	constructor(props) {
		super(props)

		const isChecked = (typeof props.isChecked !== 'undefined') ? props.isChecked : false

		// set state
		this.state = {
			isChecked: isChecked,
		}

		// bind methods
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
	}

	handleCheckboxClick(optionValue) {
		const { isChecked } = this.state
		const { clickCallback } = this.props

		const newIsChecked = !isChecked

		if (clickCallback) {
			clickCallback(optionValue, newIsChecked)
		}

		this.setState((state, props) => ({
			isChecked: newIsChecked,
		}))
	}

	render() {
		const {
			isChecked,
		} = this.state

		const {
			optionLabel,
			optionValue,
		} = this.props

		const checkedClass = (isChecked) ? 'is-checked' : ''

		return (
	        <label className={`custom-checkbox filters-modal__needs__checkbox ${checkedClass}`}>
	            <input
	            	className="custom-checkbox__input"
	            	type="checkbox"
	            	checked={isChecked}
	            	value={optionValue}
	            	onChange={() => this.handleCheckboxClick(optionValue)}
	            />

	            <span className="custom-checkbox__check">
	                <svg className="custom-checkbox__check__svg" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6">
	                    <path fill="#004BFF" fillRule="evenodd" d="M7.501 0L4.158 3.357l-.623.68-.46.404-.461-.404-.624-.68L.713 2.065 0 2.793l3.075 3.074L8.213.728z"/>
	                </svg>
	            </span>

	            <p className="custom-checkbox__text">
	                {optionLabel}
	            </p>
	        </label>
		)
	}
}

export default FilterCheckbox