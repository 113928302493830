import React from 'react'
import { reverse } from 'named-urls'
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import { media } from 'media'
import AccessControl from 'auth/access-control'
import logo from 'assets/svg/logo-ubikon.svg'
import Notifier from 'components/notifier/Notifier'
import routesList from 'routing/routes-list'

export const Header = (props) => {
	const {
		t,
		username,
		className,
		isInformationExpanded,
		logoutCallback,
		userProfileCallback,
		logoClickCallback,
		notificationClickCallback,
		informationClickCallback,
	} = props

	const accessControl = new AccessControl()
	const headerClass = `header ${className}`

	const avatar = accessControl.getUserProperty('avatar')
	const avatarStyle = {
		backgroundImage: (avatar) ? `url(${media.getMediaUrl(avatar)})`: null,
	}

	const informationExpandedClass = (isInformationExpanded) ? 'expanded': ''

	const legalLink = reverse(routesList.legal_notice)
	const contactLink = reverse(routesList.contact_page)

	return (
		<header className={headerClass}>
			<div className="header__logo" onClick={(e) => logoClickCallback(e)}>
				<img src={logo} className="Logo" alt="Ubikon Logo" />
			</div>

			{accessControl.isAuthenticated() &&
				<p className="header__welcome">
					<Trans i18nKey="headerWelcomeMessage" username={username}>
						Hello <span>{{username}}</span>, welcome back
					</Trans>
				</p>
			}

			{accessControl.isAuthenticated() &&
				<div className="header__dashboard">
	                <div className="header__dashboard__login">
	                    <p className="header__dashboard__name" onClick={(e) => userProfileCallback(e)}>{username}</p>
	                    <p
	                    	className="header__dashboard__signout text--blue-carolina"
	                    	onClick={(e) => logoutCallback(e)}
	                    >
	                    	{t('headerSignOut')}
	                    </p>
	                </div>

	                <div
	                	className="header__dashboard__profile"
	                	style={avatarStyle}
	                	onClick={(e) => userProfileCallback(e)}
	                ></div>

	                <span className="header__separator"></span>

	                <div className="header__dashboard__infos">
	                	<ul className={`header__dashboard__infos__links ${informationExpandedClass}`}>
	                		<li>
	                			<a href={legalLink}>{t('headerInfoLegal')}</a>
	                		</li>
	                		<li>
	                			<a href={contactLink}>{t('headerInfoContact')}</a>
	                		</li>
	                	</ul>

	                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" onClick={informationClickCallback}>
	                        <g fill="#737373" fillRule="evenodd">
	                            <path d="M11.5 0C5.149 0 0 5.149 0 11.5S5.149 23 11.5 23 23 17.851 23 11.5 17.851 0 11.5 0m0 1.15c5.707 0 10.35 4.644 10.35 10.35 0 5.706-4.643 10.35-10.35 10.35-5.706 0-10.35-4.644-10.35-10.35 0-5.706 4.644-10.35 10.35-10.35"/>
	                            <path d="M10.725 17h1.209V9.198h-1.209V17zm.604-9.631c-.262 0-.451-.063-.567-.189a.698.698 0 0 1-.174-.492v-.196c0-.202.058-.366.174-.491.116-.126.305-.189.567-.189.262 0 .451.063.567.189a.694.694 0 0 1 .174.491v.196a.698.698 0 0 1-.174.492c-.116.126-.305.189-.567.189z"/>
	                        </g>
	                    </svg>
	                </div>

					{accessControl.isAuthenticated() &&
						<Notifier widget='header' notificationClickCallback={notificationClickCallback} />
					}
				</div>
			}
		</header>
	);
}

export default withTranslation()(Header)