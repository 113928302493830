import React from 'react'
import MapBox from 'components/map/MapBox'

import icons from 'assets/svg'
import ImageButton from 'components/buttons/ImageButton'
import TextButton from 'components/buttons/TextButton'

class MapWrapper extends React.Component {
	shouldComponentUpdate(nextProps, nextState) {
		const { items: previousItems } = this.props
		const { items: nextItems } = nextProps

		let shouldUpdate = false

		if (previousItems.length !== nextItems.length) {
			shouldUpdate = true
		}

		// compare coordinates
		Object.keys(previousItems).map((previousIndex) => {
			const previousItem = previousItems[previousIndex]
			const nextItem = (nextItems && nextItems[previousIndex]) ? nextItems[previousIndex] : undefined

			if (typeof previousItem.coordinates === 'undefined') {
				shouldUpdate = true
			} else if (!nextItem || typeof nextItem.coordinates === 'undefined') {
				shouldUpdate = true
			} else if (previousItem.coordinates.longitude !== nextItem.coordinates.longitude) {
				shouldUpdate = true
			}

			return null
		})

		return shouldUpdate
	}

	render() {
		const {
			items,
			mapHeight,
			className,
			mapType,
			featureClickCallback,
			hideMapCallback,
			collectionClickCallback,
		} = this.props

		return (
			<div className={`MapWrapper ${(className) ? className: ''}`}>
				{hideMapCallback &&
					<div className="HideMap__button">
						<ImageButton
							image={icons.view}
							altText='Hide Map'
							onClick={hideMapCallback}
						/>

						<TextButton
							text='Hide Map'
							className="upper small blue"
							onClick={hideMapCallback}
						/>
					</div>
				}

				<MapBox
					mapType={mapType}
					mapHeight={mapHeight}
					features={items}
					featureClickCallback={featureClickCallback}
					collectionClickCallback={collectionClickCallback}
				/>
			</div>
		)
	}
}

export default MapWrapper