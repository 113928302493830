import React from 'react'

import {
	SubmitButton
} from 'components/forms/inputs'

class LandFormTheme extends React.Component {
	renderFormField(field, fieldName) {
		const { formData, formHelper } = this.props
		const { benefits } = formHelper.props

		let newProps = (field && field.props) ? {...field.props} : {}

		if (formData && formData[fieldName]) {
			newProps['value'] = formData[fieldName]
		}

		const newField = {
			...field,
			name: fieldName,
			props: newProps,
			benefits: benefits,
		}

		const fieldKey = `key-${fieldName}`

		return (
			<div className="LandForm__row">
				{formHelper.renderField(newField, fieldKey)}
			</div>
		)
	}

	render() {
		const {
			formFields,
			submitCallback,
		} = this.props

		if (!formFields) {
			return null
		}

		return (
			<div className="LandFormTheme">
				<form name="land-form" onSubmit={submitCallback}>
					{/* NAME, DESCRIPTION */}
					<div className="LandFormTheme__left">
						{this.renderFormField(formFields[0], 'name')}
						{this.renderFormField(formFields[1], 'description')}
					</div>

					{/* DESCRIPTION, SQUARE METERS, PRICE */}
					<div className="LandFormTheme__right">
						{this.renderFormField(formFields[2], 'squareMeters')}
						{this.renderFormField(formFields[3], 'price')}

						{/* ADDRESS */}
						<div className="address">
							{this.renderFormField(formFields[4], 'address')}
							{this.renderFormField(formFields[5], 'zipcode')}
							{this.renderFormField(formFields[6], 'city')}
						</div>
					</div>

					{/* BENEFITS */}
					<div className="LandFormTheme__full">
						{this.renderFormField(formFields[7], 'benefits')}
					</div>

					{/* PHOTOS */}
					<div className="LandFormTheme__full">
						{this.renderFormField(formFields[8], 'photos')}
					</div>

					<div className="LandFormTheme__full">
						<SubmitButton className="upper" text="landFormSubmitBtn" />
					</div>
				</form>
			</div>
		)
	}
}

export default LandFormTheme