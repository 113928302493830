import React from 'react'

export const Hidden = (props) => {
	const { field } = props

	return (
		<input type='hidden' {...field.props} readOnly={true} />
	)
}

export default Hidden