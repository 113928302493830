import React from 'react'

import { ReactComponent as IconArrow } from 'assets/svg/icon-arrow-down.svg'

export const SelectDownArrow = () => {
	return (
		<IconArrow />
	)
}

export default SelectDownArrow